import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";

const COLORS = ["#ff3a29", "#3a0ca3"];

const DataFormater = (number) => {
  return number.toString() + "%";
};

const ProductUsageChart = ({ data }) => {
  return (
    <ResponsiveContainer>
      <PieChart width={155} height={155}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          // labelLine={false}
          // label={renderCustomizedLabel}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip formatter={DataFormater} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default ProductUsageChart;
