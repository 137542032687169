import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const DataFormater = (number) => {
  if (number > 1000000000) {
    return (number / 1000000000).toFixed(1).toString() + "B";
  } else if (number > 1000000) {
    return (number / 1000000).toFixed(1).toString() + "M";
  } else if (number > 10000) {
    return (number / 1000).toFixed(1).toString() + "K";
  } else {
    return number.toString();
  }
};

const DisbursementsBarChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid
          strokeDasharray="3 3"
          vertical={false}
          horizontal={false}
        />
        <XAxis dataKey="name" />
        <YAxis tickFormatter={DataFormater} />
        <Tooltip />
        {/* <Legend /> */}
        <Bar dataKey="Total Amount" fill="#B5179E" barSize={10} radius={10} />
        <Bar
          dataKey="Total Approved Amount"
          fill="#FFB200"
          barSize={10}
          radius={10}
        />
        <Bar
          dataKey="Total Declined Amount"
          fill="#FF4848"
          barSize={10}
          radius={10}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default DisbursementsBarChart;
