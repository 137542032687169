import React from "react";
import { ISubscriptionPlanInfo } from "../../../../Types";
import PrimaryButton from "../../../../components/buttons/primary-button/PrimaryButton";
import tickIcon from "../../../../images/tick-icon-gray.svg";
import { useNavigate } from "react-router-dom";

// Interfaces
interface IProps {
  plan: ISubscriptionPlanInfo;
  index: number;
  currentActivePlanIndex: number;
  setCurrentActivePlanIndex: React.Dispatch<React.SetStateAction<number>>;
}
function SubscriptionPlanCard({
  plan,
  index,
  currentActivePlanIndex,
  setCurrentActivePlanIndex,
}: IProps) {
  // Functions, States and Variables
  const navigate = useNavigate();
  // States

  //   Functions
  const handleFocusOnPlan = function (index: number) {
    setCurrentActivePlanIndex(index);
  };

  const handleGenerateArrOfListsFromListHTMLString = function (
    HTMLString: string
  ) {
    const regex = /<li>(.*?)<\/li>/g;
    const listMatches = HTMLString.match(regex);
    const listArray = listMatches?.map((match) =>
      match.replace(/<\/?li>/g, "")
    );
    if (listArray) return listArray;
  };

  //   useEffect(() => {}, [acc])
  return (
    <div
      onClick={() => handleFocusOnPlan(index)}
      className={`subscription-plan-choice-wrapper ${
        index === currentActivePlanIndex && "focused-plan"
      }`}
    >
      {/* {plan.isRecommended && (
        <div className="recommended-wrapper">Recommended</div>
      )} */}
      {plan?.isRecommended && (
        <div className="recommended-wrapper">Recommended</div>
      )}

      {/* Title */}
      <div className="plan-title">{plan.name}</div>
      {/* Price */}
      <div className="plan-price">
        ₦{plan.cost.toLocaleString()}/employee/month
      </div>

      {/* Subscribe Button Wrapper */}
      <PrimaryButton
        className="subscribe-btn-wrapper"
        placeholder="Subscribe to Plan"
        onClick={(e) => {
          navigate("/employer/subscription-details", {
            state: {
              subscriptionPlan: plan,
            },
          });
        }}
      />

      {/* Plan Perks */}
      <div className="plan-perks-wrapper">
        {handleGenerateArrOfListsFromListHTMLString(plan.details)?.map(
          (perk) => (
            <div className="plan-perk">
              <img src={tickIcon} alt="" />
              {perk}
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default SubscriptionPlanCard;
