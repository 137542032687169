import React, { MouseEvent, useEffect, useState } from "react";
import PrimaryButton from "../../../../../../components/buttons/primary-button/PrimaryButton";
import TextInput from "../../../../../../components/input-components/text-input/TextInput";
import LoadingSpinner from "../../../../../../components/loading-spinner/LoadingSpinner";
import { IDropdownOption } from "../../../../../../Types";
import { ISingleEmployee } from "../../../../../employer-settlements/Settlements";
import SelectEmployees from "../../comps/select-employees/SelectEmployees";
import { IFormInputStates } from "../CreditWallet";

// Interfaces
interface IProps {
  allStaff: [] | ISingleEmployee[];
  allStaffDropdown: IDropdownOption[] | [];
  formInputStates: IFormInputStates;
  isForAllStaff: boolean;
  isSendingLoading: boolean;
  setFormInputStates: React.Dispatch<React.SetStateAction<IFormInputStates>>;
  handleSendCreditToStaff: (e: MouseEvent<HTMLButtonElement>) => void;
}

function CreditWalletForm({
  allStaff,
  allStaffDropdown,
  formInputStates,
  isForAllStaff,
  isSendingLoading,
  setFormInputStates,
  handleSendCreditToStaff,
}: IProps) {
  // Function, States and Variables
  // States
  const [selectedStaff, setSelectedStaff] = useState<string[]>([]);

  // UseEffects
  useEffect(() => {
    // Update Form Input states as selected staff changes
    setFormInputStates({
      ...formInputStates,
      staff: selectedStaff,
    });
  }, [selectedStaff]);
  return (
    <div className="credit-wallet-form-wrapper">
      {/* Selected Staff */}
      {!isForAllStaff && (
        <div className="celebartion--select-employees-form-section">
          <SelectEmployees
            allStaffDropdown={allStaffDropdown}
            selectedStaff={selectedStaff}
            setSelectedStaff={setSelectedStaff}
          />
        </div>
      )}

      <form>
        {/* Credit Amount */}
        <div className="form-group">
          <TextInput
            type={"text"}
            id={"credit-amount-text-input"}
            className={"credit-amount-input"}
            placeholder={""}
            value={formInputStates.amount}
            onChange={(e) =>
              setFormInputStates((prevInpuStates) => {
                if (!prevInpuStates) return prevInpuStates;
                const updatedInputStates: IFormInputStates = {
                  ...formInputStates,
                  amount: e.target.value,
                };
                console.log(updatedInputStates);
                return updatedInputStates;
              })
            }
            required={true}
            label={"Credit Amount"}
          />
        </div>

        {/* Celebratory Text (Optional) */}
        <div className="form-group">
          <TextInput
            type={"text"}
            id={"celebratory-text-input"}
            className={"celebratory-input"}
            placeholder={""}
            value={formInputStates.message}
            onChange={(e) =>
              setFormInputStates((prevInpuStates) => {
                if (!prevInpuStates) return prevInpuStates;
                const updatedInputStates: IFormInputStates = {
                  ...formInputStates,
                  message: e.target.value,
                };
                console.log(updatedInputStates);
                return updatedInputStates;
              })
            }
            required={true}
            label={"Celebratory Text"}
          />
        </div>

        <div className="primary-button-container">
          <PrimaryButton
            className="send-celebration-btn-wrapper"
            placeholder="Send Message"
            onClick={(e) => handleSendCreditToStaff(e)}
          />
          {isSendingLoading && <LoadingSpinner />}
        </div>
      </form>
    </div>
  );
}

export default CreditWalletForm;
