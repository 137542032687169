import axios from "axios";
import {
  GET_ALL_CREDIT_TRANSACTIONS,
  GET_ALL_DEBIT_TRANSACTIONS,
  GET_ALL_TRANSACTIONS,
} from "../Constants";
import { api } from "../../api/config";
import { SetErrorHandlerType, SetIsLoadingType } from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";

// Interfaces
interface IFundWalletData {
  reference: string;
}
const url = `${api}/api/v1/company`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get all Transactions
export const getAllTransactions =
  (
    pageNumberIndex: number = 1,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/transactions?page=${pageNumberIndex}`)
      .then((res) => {
        dispatch({ type: GET_ALL_TRANSACTIONS, payload: res.data });
      })
      .catch((err) => {
        handleAPIError(err, dispatch, setErrorHandlerObj);
      })
      .finally(() => setIsLoading(false));
  };

// Get all credit Transactions
export const getAllCreditTransactions =
  (
    pageNumberIndex: number = 1,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/transactions/credit?page=${pageNumberIndex}`)
      .then((res) => {
        dispatch({ type: GET_ALL_CREDIT_TRANSACTIONS, payload: res.data });
        dispatch({ type: GET_ALL_TRANSACTIONS, payload: res.data });
      })
      .catch((err) => {
        handleAPIError(err, dispatch, setErrorHandlerObj);
      })
      .finally(() => setIsLoading(false));
  };

// Get all debit Transactions
export const getAllDebitTransactions =
  (
    pageNumberIndex: number = 1,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/transactions/debit?page=${pageNumberIndex}`)
      .then((res) => {
        dispatch({ type: GET_ALL_DEBIT_TRANSACTIONS, payload: res.data });
        dispatch({ type: GET_ALL_TRANSACTIONS, payload: res.data });
      })
      .catch((err) => {
        handleAPIError(err, dispatch, setErrorHandlerObj);
      })
      .finally(() => setIsLoading(false));
  };

//   Fund Wallet
export const fundWallet =
  (
    data: IFundWalletData,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    handleTriggerPaymentGateway: () => void
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/fund-wallet`, data)
      .then((res) => {
        handleTriggerPaymentGateway();
      })
      .catch((err) => {
        handleAPIError(err, dispatch, setErrorHandlerObj);
      })
      .finally(() => setIsLoading(false));
  };
