import React, { useEffect, useState } from "react";
import "./RequestsPreview.css";
import angleLeftIcon from "../../../../images/angle-left.svg";
import closeIcon from "../../../../images/close-icon-white.svg";
import tickIcon from "../../../../images/tick-icon-white.svg";
// import starIcon from "../../../images/star-icon.svg";
import moment from "moment";
import { IAllRequests } from "../../../../Types";
import { IMerchantFromEmployeeRequest } from "../../../employer-requests/Requests";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import isEmpty from "../../../../validation/isEmpty";
import { numberWithCommas } from "../../../../utils/numberWithCommas";

interface IProps {
  selectedEmployeeRequest: IAllRequests | null;
  merchantFromEmployeeRequest: IMerchantFromEmployeeRequest | null;
  isApproved: boolean;
  setIsApproved: React.Dispatch<React.SetStateAction<boolean>>;
  isDeclined: boolean;
  setIsDeclined: React.Dispatch<React.SetStateAction<boolean>>;
  setWantsToApproveRequest: React.Dispatch<React.SetStateAction<boolean>>;
  setWantsToDeclineRequest: React.Dispatch<React.SetStateAction<boolean>>;
  setIsConfirmApproveDeclineRequestModalOpened: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setWelfareDisplayContent: React.Dispatch<React.SetStateAction<string>>;
}

const RequestsPreview: React.FC<IProps> = function ({
  selectedEmployeeRequest,
  merchantFromEmployeeRequest,
  isApproved,
  isDeclined,
  setIsApproved,
  setIsDeclined,
  setWantsToApproveRequest,
  setWantsToDeclineRequest,
  setIsConfirmApproveDeclineRequestModalOpened,
  setWelfareDisplayContent,
}) {
  // Functions and States
  // States
  const [isLoading, setIsLoading] = useState(false);
  //   Functions
  // handle Open Modal To Confirm Approve Salary Advance or BNPL Request
  const handleOpenModalToConfirmApproveRequest = function () {
    setWantsToDeclineRequest(false);
    setWantsToApproveRequest(true);
    // Open Confirmation Modal
    setIsConfirmApproveDeclineRequestModalOpened(true);
  };

  // handle Open Modal To Confirm Decline Salary Advance or BNPL Request
  const handleOpenModalToConfirmDeclineRequest = function () {
    setWantsToApproveRequest(false);
    setWantsToDeclineRequest(true);
    // Open Confirmation Modal
    setIsConfirmApproveDeclineRequestModalOpened(true);
  };

  useEffect(() => {
    setWantsToApproveRequest(false);
    setWantsToDeclineRequest(false);
  }, []);

  return (
    <div className="requests-preview-container">
      <div className="requests-preview-container--inner">
        {/* Requests preview Top */}
        <div
          className={`dashboard-setup-top-container requests-preview-top-container`}
        >
          <div className="requests-preview-top--left-wrapper">
            {/* Back button Wrapper */}
            <div className="back-button-wrapper">
              <button
                onClick={() => {
                  setWelfareDisplayContent("main");
                  //
                  setIsApproved(false);
                  setIsDeclined(false);
                }}
              >
                <img src={angleLeftIcon} alt="" />
              </button>
            </div>
            {/* Reference Number */}
            <div className="dashboard-setup-top-title">
              #{selectedEmployeeRequest?.staffId._id.substring(18)}
            </div>
          </div>

          {/* Top Right Wrapper */}
          <div className="onboarding-preview-top-action-buttons-container">
            {selectedEmployeeRequest?.status === 1 ? (
              <>
                {isApproved || isDeclined ? (
                  <div
                    className={`request-status-message-wrapper ${
                      isApproved
                        ? "request-approved-status-wrapper"
                        : "request-declined-status-wrapper"
                    }`}
                  >
                    {isApproved ? "Request Approved" : "Request Declined"}
                  </div>
                ) : (
                  <>
                    <div className="decline-request-button-wrapper">
                      <button
                        onClick={() => handleOpenModalToConfirmDeclineRequest()}
                        disabled={isLoading}
                      >
                        <img src={closeIcon} alt="" />
                        Decline Request
                      </button>
                    </div>
                    <div className="approve-request-button-wrapper">
                      <button
                        onClick={() => handleOpenModalToConfirmApproveRequest()}
                        disabled={isLoading}
                      >
                        <img src={tickIcon} alt="" />
                        Approve Request
                      </button>
                    </div>
                  </>
                )}
              </>
            ) : selectedEmployeeRequest?.status === 2 ? (
              // If Approved by Credley
              <div className="request-completed-msg-display-wrapper">
                Completed
              </div>
            ) : selectedEmployeeRequest?.status === 3 ? (
              // If Rejected
              <div className="request-pending-msg-display-wrapper">
                Rejected
              </div>
            ) : selectedEmployeeRequest?.status === 8 ? (
              // If Request is Disbursed
              <div className="request-completed-msg-display-wrapper">
                Disbursed
              </div>
            ) : (
              ""
            )}
            <div className="requests-loader-wrapper">
              {isLoading && <LoadingSpinner />}
            </div>
          </div>
        </div>

        {/* Main section wrapper */}
        {selectedEmployeeRequest && !isEmpty(selectedEmployeeRequest) ? (
          <div className={`requests-preview-main-section-container`}>
            {/* Main Left Container */}
            <div className="requests-preview-main-section--left-container">
              {/* Employee details container */}
              <div className="requests-preview--employee-details-container">
                {/* Title */}
                <div className="requests-preview--employee-details--title">
                  EMPLOYEE DETAILS
                </div>

                {/* Row One */}
                <div className="requests-preview--employee-details--row-one">
                  {/* Profile Img */}
                  {/* <div className="requests-preview--employee-profile-img">
                  <img src={selectedEmployeeForPreview?.imgURL} alt="" />
                </div> */}
                  {/* Employee name */}
                  <div className="requests-preview--employee-name-email">
                    {selectedEmployeeRequest.staffId.firstName}{" "}
                    {selectedEmployeeRequest.staffId.lastName}
                    {/* Employee email */}
                    <div className="requests-preview--employee-email">
                      <div className="requests-preview--employee-detail--title">
                        Email
                      </div>
                      <div className="requests-preview--employee-detail--value">
                        {selectedEmployeeRequest.staffId.email}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Row Two */}
                <div className="requests-preview--employee-details--row-two">
                  {/* Employee department */}
                  <div className="requests-preview--employee-department requests-preview--employe-detail-wrapper">
                    <div className="requests-preview--employee-detail--title">
                      Department
                    </div>
                    <div className="requests-preview--employee-detail--value">
                      {selectedEmployeeRequest.staffId.department}
                    </div>
                  </div>
                  {/* Employee Phone Number */}
                  <div className="requests-preview--employee-phone-number requests-preview--employe-detail-wrapper">
                    <div className="requests-preview--employee-detail--title">
                      Phone Number
                    </div>
                    <div className="requests-preview--employee-detail--value">
                      {selectedEmployeeRequest.staffId.phoneNumber}
                    </div>
                  </div>
                  {/* Employee Role */}
                  <div className="requests-preview--employee-role requests-preview--employe-detail-wrapper">
                    <div className="requests-preview--employee-detail--title">
                      Role
                    </div>
                    <div className="requests-preview--employee-detail--value">
                      {selectedEmployeeRequest.staffId.role}
                    </div>
                  </div>
                  {/* Employee Salary */}
                  <div className="requests-preview--employee-salary requests-preview--employe-detail-wrapper">
                    <div className="requests-preview--employee-detail--title">
                      Salary
                    </div>
                    <div className="requests-preview--employee-detail--value">
                      &#8358;
                      {numberWithCommas(selectedEmployeeRequest.staffId.salary)}
                    </div>
                  </div>
                  {/* Employee Address  */}
                  <div className="requests-preview--employee-address requests-preview--employe-detail-wrapper">
                    <div className="requests-preview--employee-detail--title">
                      Address
                    </div>
                    <div className="requests-preview--employee-detail--value">
                      {selectedEmployeeRequest.staffId.address}
                    </div>
                  </div>
                  {/* Employee Level  */}
                  <div className="requests-preview--employee-level requests-preview--employe-detail-wrapper">
                    <div className="requests-preview--employee-detail--title">
                      Level
                    </div>
                    <div className="requests-preview--employee-detail--value">
                      {selectedEmployeeRequest.staffId.level}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Main Right Container */}
            <div className="requests-preview-main-section--right-container">
              {/* Right Top Wrapper */}
              <div className="requests-preview-main-section--right--top-wrapper">
                {/* Header Wrapper */}
                <div className="requests-preview-main--right--top-header-wrapper">
                  {/* Title */}
                  <div className="requests-preview--employee-details--title">
                    {selectedEmployeeRequest?.type === "salaryAdvance"
                      ? "SALARY ADVANCE DETAILS"
                      : selectedEmployeeRequest.type === "onDemand"
                      ? "ON DEMAND DETAILS"
                      : "BNPL DETAILS"}
                  </div>
                  {/* Request Date */}
                  <div className="requests-preview--employee-detail--title">
                    {moment(selectedEmployeeRequest.createdAt).format(
                      "Do MMM, YY"
                    )}
                  </div>
                </div>

                {/* Reason of declination */}
                {(selectedEmployeeRequest.status === 6 ||
                  selectedEmployeeRequest.status === 3) && (
                  <div className="request-amount-wrapper">
                    <div className="request-amount-title requests-preview--employee-detail--title">
                      Declination Reason:
                    </div>
                    <div className="request-amount-value declination-reason-value">
                      {selectedEmployeeRequest.adminRejectionReason ||
                        selectedEmployeeRequest.hrRejectionReason}
                    </div>
                  </div>
                )}

                <div className="request-amount-wrapper">
                  <div className="request-amount-title requests-preview--employee-detail--title">
                    Request amount:
                  </div>
                  <div className="request-amount-value">
                    &#8358;{numberWithCommas(selectedEmployeeRequest.amount)}
                  </div>
                </div>

                {/* Other Request Finanace values Wrapper */}
                {(selectedEmployeeRequest?.type !== "salaryAdvance" ||
                  selectedEmployeeRequest?.status === 1) && (
                  <div className="other-request-finance-values-wrapper">
                    {/* Tenure(BNPL) || Request Amount(Salary Advance) Wrapper */}
                    <div className="requests-preview--employee-level requests-preview--employe-detail-wrapper">
                      <div className="requests-preview--employee-detail--title">
                        {selectedEmployeeRequest.type === "Bnpl"
                          ? "Tenure"
                          : "Request Amount"}
                      </div>
                      <div className="request-amount-value other-request-finance--value">
                        {selectedEmployeeRequest.type === "Bnpl" ? (
                          selectedEmployeeRequest.tenure
                        ) : (
                          <>
                            &#8358;
                            {numberWithCommas(selectedEmployeeRequest.amount)}
                          </>
                        )}
                      </div>
                    </div>

                    {/* Initial Payment(BNPL) || Salary(Salary Advance) Wrapper */}
                    <div className="requests-preview--employee-level requests-preview--employe-detail-wrapper">
                      <div className="requests-preview--employee-detail--title">
                        {selectedEmployeeRequest.type === "Bnpl"
                          ? "Initial Payment"
                          : "Salary"}
                      </div>
                      <div className="request-amount-value other-request-finance--value">
                        {selectedEmployeeRequest.type === "Bnpl" ? (
                          // FIX amount should be initial Payment for BNPL
                          //   <>
                          //     {selectedEmployeeRequest.initialPayment && (
                          //       <>
                          //         &#8358;
                          //         {numberWithCommas(
                          //           selectedEmployeeRequest.initialPayment
                          //         )}
                          //       </>
                          //     )}
                          //   </>
                          // ) : (
                          //   <>
                          //     {selectedEmployeeRequest.salary && (
                          //       <>
                          //         &#8358;
                          //         {numberWithCommas(selectedEmployeeRequest.salary)}
                          //       </>
                          //     )}
                          //   </>
                          <>
                            {selectedEmployeeRequest.amount && (
                              <>
                                &#8358;
                                {numberWithCommas(
                                  selectedEmployeeRequest.amount
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {selectedEmployeeRequest.staffId.salary && (
                              <>
                                &#8358;
                                {numberWithCommas(
                                  selectedEmployeeRequest.staffId.salary
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </div>
  );
};

export default RequestsPreview;
