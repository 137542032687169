import React, { useContext, useEffect, useState } from "react";
import {
  ISearchContextType,
  SearchContext,
} from "../../../../components/contexts/SearchContext";
import DropdownInput from "../../../../components/input-components/dropdown-input/DropdownInput";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { getAllCompanyRequests } from "../../../../redux/actions/requestActions";
import { IAllRequests, SetErrorHandlerType } from "../../../../Types";
import { handleFilterRequestsTableByStatus } from "../../../../utils/handleFilterRequestsByStatus";
import { requestFilterDropdown } from "../../../../utils/requestFilterDropdown";
import SalaryAdvanceTable from "./salary-advance-table/SalaryAdvanceTable";

// Interfaces
interface IProps {
  pageContent: string;
  isApproved: boolean;
  isDeclined: boolean;
  setSelectedEmployeeRequest: React.Dispatch<
    React.SetStateAction<IAllRequests | null>
  >;
  setErrorHandlerObj: SetErrorHandlerType;
  setWelfareDisplayContent: React.Dispatch<React.SetStateAction<string>>;
}

function SalaryAdvance({
  pageContent,
  isApproved,
  isDeclined,
  setSelectedEmployeeRequest,
  setErrorHandlerObj,
  setWelfareDisplayContent,
}: IProps) {
  // Functions, States and Variables
  const { searchedValue }: ISearchContextType | any = useContext(SearchContext);
  const allRequests: IAllRequests[] | [] = useAppSelector(
    (state) => state.requests.requests
  );
  const dispatch = useAppDispatch();
  //   States
  const [allSalaryAdvanceRequests, setAllSalaryAdvanceRequests] = useState<
    IAllRequests[] | null
  >(null);
  const [currentSalaryAdvancePosts, setCurrentSalaryAdvancePosts] = useState<
    IAllRequests[] | null
  >(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;
  const [salaryAdvanceDateFilter, setSalaryAdvanceDateFilter] = useState("");
  const [salaryAdvanceStatusFilter, setSalaryAdvanceStatusFilter] =
    useState("");
  const [isLoading, setIsLoading] = useState(false);
  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //   Handle Call Filter Table By Status
  const handleCallFilterTableByStatus = function (statusFilter: string) {
    const requestType = "salaryAdvance";
    // Handle Filter Salary Advance Table on change of search value
    handleFilterRequestsTableByStatus(
      statusFilter,
      requestType,
      allRequests,
      allSalaryAdvanceRequests,
      setAllSalaryAdvanceRequests,
      setCurrentSalaryAdvancePosts,
      searchedValue,
      indexOfFirstPost,
      indexOfLastPost
    );
  };

  //   UseEffects
  useEffect(() => {
    dispatch(getAllCompanyRequests(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    console.log(allRequests);
    if (allRequests) {
      // Salary Advance
      const salaryAdvanceData = allRequests.filter((request) => {
        return request.type === "salaryAdvance";
      });
      setAllSalaryAdvanceRequests(salaryAdvanceData);
    }
  }, [allRequests]);

  useEffect(() => {
    // For Salary Advance Requests
    if (allSalaryAdvanceRequests) {
      const currentRows = allSalaryAdvanceRequests?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setCurrentSalaryAdvancePosts(currentRows);
    }
  }, [allSalaryAdvanceRequests, currentPage]);

  useEffect(() => {
    if (isApproved || isDeclined)
      dispatch(getAllCompanyRequests(setIsLoading, setErrorHandlerObj));
  }, [isApproved, isDeclined]);

  useEffect(() => {
    // Set Current Page to 1 to avoid empty screen on search match bug
    setCurrentPage(1);
    const requestType = "salaryAdvance";
    // Handle Filter Salary Advance Table on change of search value
    handleFilterRequestsTableByStatus(
      salaryAdvanceStatusFilter || "All",
      requestType,
      allRequests,
      allSalaryAdvanceRequests,
      setAllSalaryAdvanceRequests,
      setCurrentSalaryAdvancePosts,
      searchedValue,
      indexOfFirstPost,
      indexOfLastPost
    );
  }, [searchedValue]);

  return (
    <section
      className={`welfare-table-section ${
        pageContent === "salary-advance" ? "" : "none"
      }`}
    >
      <div className="welfare-container--inner">
        {/* Salary Advance */}
        <div className="welfare-table-filter-option-wrapper">
          <div className="welfare-table-filter-option-wrapper">Filter:</div>
          {/* Filter by salary advance request status dropdown */}
          <div className="form-group">
            <DropdownInput
              className={"salary-advance-date-filter-dropdown-input"}
              id={"salary-advance-date-filter-input"}
              label={""}
              required={true}
              optionArray={requestFilterDropdown}
              value={salaryAdvanceStatusFilter}
              onChange={(e) => {
                setSalaryAdvanceStatusFilter(e.target.value);
                handleCallFilterTableByStatus(e.target.value);
              }}
            />
          </div>
          {/* Filter by salary advance request application date dropdown */}
          <div className="form-group">
            <DropdownInput
              className={"salary-advance-date-filter-dropdown-input"}
              id={"salary-advance-date-filter-input"}
              label={""}
              required={true}
              optionArray={[
                { key: "", value: "Date", default: "Default" },
                {
                  key: "yesterday",
                  value: "Yesterday",
                },
              ]}
              value={salaryAdvanceDateFilter}
              onChange={(e) => setSalaryAdvanceDateFilter(e.target.value)}
            />
          </div>
        </div>

        {/* Salary Advance Table */}
        <SalaryAdvanceTable
          isLoading={isLoading}
          allSalaryAdvanceRequests={allSalaryAdvanceRequests}
          currentSalaryAdvancePosts={currentSalaryAdvancePosts}
          setSelectedEmployeeRequest={setSelectedEmployeeRequest}
          setWelfareDisplayContent={setWelfareDisplayContent}
          paginate={paginate}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
        />
      </div>
    </section>
  );
}

export default SalaryAdvance;
