import {
  GET_COMPANY_SUBSCRIPTION,
  GET_COMPANY_SUBSCRIPTION_STATUS,
  GET_CURRENT_USER,
  SET_CURRENT_USER,
} from "../Constants";
import isEmpty from "../../validation/isEmpty";

const initialState = {
  isAuthenticated: false,
  user: {},
  companySubscription: null,
  subscriptionStatus: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state = initialState,
  action: { type?: string; payload?: any }
) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case GET_CURRENT_USER:
      return {
        ...state,
        user: action.payload,
      };
    case GET_COMPANY_SUBSCRIPTION:
      return {
        ...state,
        companySubscription: action.payload,
      };
    case GET_COMPANY_SUBSCRIPTION_STATUS:
      return {
        ...state,
        subscriptionStatus: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
