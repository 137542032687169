import React, { MouseEvent, useEffect, useState } from "react";
import "./Modals.css";
import attentionIcon from "../../../images/attention-icon.svg";
import { ISingleEmployee } from "../../employer-settlements/Settlements";
import DropdownInput from "../../../components/input-components/dropdown-input/DropdownInput";
import TextInput from "../../../components/input-components/text-input/TextInput";
import { ICompanyGroups } from "../../../components/onboard-employees/single-upload/SingleUpload";

interface IProps {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessHandlerObj: React.Dispatch<
    React.SetStateAction<{
      isSuccess: boolean;
      message: string;
    }>
  >;
  setErrorHandlerObj: React.Dispatch<
    React.SetStateAction<{
      hasError: boolean;
      message: string;
    }>
  >;
  setIsApproveRequestModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isApproveRequestModalOpened: boolean;
  selectedEmployeeForPreview: ISingleEmployee | null;
}

const ApproveRequestModal: React.FC<IProps> = function ({
  selectedEmployeeForPreview,
  isApproveRequestModalOpened,
  setIsApproveRequestModalOpened,
  setErrorHandlerObj,
  setSuccessHandlerObj,
  setIsLoading,
  isLoading,
}) {
  // Functions, states and variables
  //   States
  const [salary, setSalary] = useState("");
  const [groupName, setGroupName] = useState("");
  const [companyGroups, setCompanyGroups] = useState<[] | ICompanyGroups[]>([]);

  // Functions
  const handleConfirmApproveRequest = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    if (salary !== "" && groupName !== "") {
      e.preventDefault();

      const data = {
        salary,
        groupName,
      };
      //TODO Call the dispatch fxn to approve employee
      setSalary("");
      setGroupName("");

      setIsApproveRequestModalOpened(false);
      setSuccessHandlerObj({
        isSuccess: true,
        message: "Employee successfully onboarded!",
      });
    }
  };

  useEffect(() => {
    setCompanyGroups([
      {
        key: "Senior Management",
        value: "Senior Management",
      },
      {
        key: "Management",
        value: "Management",
      },
      {
        key: "Mid-Level",
        value: "Mid-Level",
      },
      {
        key: "Analyst",
        value: "Analyst",
      },
      {
        key: "Junior Staff",
        value: "Junior Staff",
      },
      {
        key: "Clerical Staff",
        value: "Clerical Staff",
      },
    ]);
  }, []);
  return (
    <div
      className={`approve-request-modal-container modal-container  ${
        isApproveRequestModalOpened ? "" : "none"
      }`}
    >
      <div className="approve-request-modal-container--inner modal-container--inner">
        <div className="attention-img-wrapper">
          <img src={attentionIcon} alt="" />
        </div>

        <div className="approve-request-modal-header-text modal-header-text">
          Confirm Employee
        </div>
        <div className="approve-request-modal-sub-text modal-sub-text">
          To complete the confirmation of{" "}
          <span>
            {selectedEmployeeForPreview?.firstName}{" "}
            {selectedEmployeeForPreview?.lastName}
          </span>
          , kindly input the following info
        </div>

        <form>
          <div className="modal--salary-and-group-form-wrapper">
            {/* Salary form group */}
            <div className="form-group">
              <TextInput
                type={"text"}
                id={`employee-salary-text-input`}
                className={"employee-salary-input"}
                placeholder={""}
                value={salary}
                onChange={(e) => {
                  // const re = /^[0-9\b]+$/;
                  // if value is not blank, then test the regex
                  // if (e.target.value === "" || re.test(e.target.value)) {
                  // }
                  setSalary(e.target.value);
                }}
                required={true}
                label={"Salary (N)"}
              />
            </div>

            {/* Group Name dropdown */}
            <div className="form-group">
              <DropdownInput
                className={"group-name-dropdown-input"}
                id={"group-name-input"}
                label={"Add to group"}
                required={true}
                optionArray={[
                  { key: "", value: "", default: "default" },
                  ...companyGroups,
                ]}
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
              />
            </div>
          </div>

          <div className="modal-action-button-wrapper">
            {/* Cancel Modal Button */}
            <div className="cancel-modal-button-wrapper">
              <button onClick={() => setIsApproveRequestModalOpened(false)}>
                Cancel
              </button>
            </div>
            {/* Conffirm Action Button */}
            <div className="confirm-modal-action-button">
              <button onClick={(e) => handleConfirmApproveRequest(e)}>
                Confirm
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ApproveRequestModal;
