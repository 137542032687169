import React, { MouseEvent, useContext, useEffect, useState } from "react";
import "./CreditWallet.css";
import Sidebar from "../../../../../components/app-sidebar/Sidebar";
import Top from "../../../../../components/app-top/Top";
import ErrorHandler from "../../../../../components/error/ErrorHandler";
import Main from "../../../../../components/main-container/Main";
import SuccessHandler from "../../../../../components/success/SuccessHandler";
import useDisplayMessage from "../../../../../hooks/useDisplayMessage";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { useLocation, useNavigate } from "react-router-dom";
import {
  IDropdownOption,
  IPaystackReference,
  ITemplateObj,
} from "../../../../../Types";
import { getAllCompanyStaffs } from "../../../../../redux/actions/staffActions";
import PrimaryBackButton from "../../../../../components/primary-back-button/PrimaryBackButton";
import CreditWalletForm from "./form/CreditWalletForm";
import { ISingleEmployee } from "../../../../employer-settlements/Settlements";
import SelectTemplate from "../comps/select-template/SelectTemplate";
import SelectNotificationViewers from "../comps/select-notification-viewers/SelectNotificationViewers";
import isEmpty from "../../../../../validation/isEmpty";
import PaymentMethodModal from "../../../../employer-subscription/subscription-details/modals/PaymentMethodModal";
import { UserContext } from "../../../../../components/contexts/UserContext";
import { usePaystackPayment } from "react-paystack";
import { onPaystackClose } from "../../../../../utils/handleOnPaystackClose";
import {
  celebrateAllEmployees,
  celebrateManyStaff,
} from "../../../../../redux/actions/celebrationsActions";
import SubstituteLoadingSpinner from "../../../../../components/substitute-loading-spinner/SubstituteLoadingSpinner";

// Interfaces
export interface IFormInputStates {
  staff: string[];
  amount: string;
  message: string;
}

function CreditWallet() {
  // Functions, States and Variables
  const allStaff: ISingleEmployee[] | [] = useAppSelector(
    (state) => state.staff.staffs
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { user }: any = useContext(UserContext);

  //   States
  const [isForAllStaff, setIsForAllStaff] = useState(false);
  const [formInputStates, setFormInputStates] = useState<IFormInputStates>({
    staff: [],
    amount: "",
    message: "",
  });
  const [allStaffDropdown, setAllStaffDropDown] = useState<
    IDropdownOption[] | []
  >([]);
  // Selecting Templates
  const [selectedTemplate, setSelectedTemplate] = useState<ITemplateObj | null>(
    null
  );
  // Selecting Notification Viewes -- Values - Single, All
  const [notificationViewers, setNotificationViewers] = useState("Single");
  const [isLoading, setIsLoading] = useState(false);
  const [isSendingLoading, setIsSendingLoading] = useState(false);
  const [isPaymentOptionModalOpened, setIsPaymentOptionModalOpened] =
    useState(false);
  // Paystack Configuration and Initialization
  const totalPaystackAmount = isForAllStaff
    ? (user?.staff?.length || 0) * parseInt(formInputStates.amount)
    : parseInt(formInputStates.amount) * formInputStates.staff.length;
  const config = {
    reference: new Date().getTime().toString(),
    email: user?.email || "",
    amount: totalPaystackAmount * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY ?? "",
    // publicKey: "pk_test_cc4c2259b62bc75842e99e60a2e4ac12a883a840",
  };
  const initializePayment: any = usePaystackPayment(config);

  //   Functions
  const handleSendCreditToStaff = function (e: MouseEvent<HTMLButtonElement>) {
    console.log(formInputStates);
    const isFormEmpty = Object.keys(formInputStates).some(
      (inputKeys: string) =>
        formInputStates[inputKeys as keyof typeof formInputStates] === ""
    );

    if (!isFormEmpty) {
      e.preventDefault();
      // If a Template image was not choosen
      // if (!selectedTemplate) {
      //   setErrorHandlerObj({
      //     hasError: true,
      //     message: "You need to select a template image to proceed!",
      //   });
      // } else {
      // If type is celebrate selected staff and no staff was selected
      if (isEmpty(formInputStates.staff)) {
        setErrorHandlerObj({
          hasError: true,
          message: "You have to select at least an employee to proceed!",
        });
      } else {
        // If all necessary input values are filled, open payment choice modal
        setIsPaymentOptionModalOpened(true);
      }
    }
  };

  // Handle Call API To Celebrate Staff
  const handleCallAPIToCelebratetStaff = function (
    paymentMethod: string,
    reference?: IPaystackReference | null
  ) {
    // Paystack reference if it exists(if paystack was used instead of ther payment method(s))
    const { reference: referenceValue } = reference || {};
    if (isForAllStaff) {
      // For All staff
      const data = {
        type: "credit", //text,credit
        totalAmount:
          (user?.staff?.length || 0) * parseInt(formInputStates.amount),
        amountPerStaff: parseInt(formInputStates.amount),
        message: formInputStates.message,
        paymentMethod,
        reference: referenceValue || "",
        paymentGatewayResponse:
          reference && !isEmpty(reference) ? reference : {},
        // celebrationTemplateId: "644423d45b3a972777110f58",
        shouldAllStaffSee: notificationViewers === "All" && true,
      };
      // Call API to celebrate Many Staff
      dispatch(
        celebrateAllEmployees(
          data,
          setIsSendingLoading,
          setErrorHandlerObj,
          navigate
        )
      );
    } else {
      // For Dynamic number of staff
      const staffIdAndAmountArr = formInputStates.staff.map((staff) => {
        return {
          staff,
          amount: parseInt(formInputStates.amount),
        };
      });
      const staffCount = formInputStates.staff.length;

      const data = {
        staffIds: staffIdAndAmountArr,
        type: "credit", //text,credit
        amount: parseInt(formInputStates.amount) * staffCount,
        message: formInputStates.message,
        paymentMethod,
        reference: referenceValue || "",
        paymentGatewayResponse:
          reference && !isEmpty(reference) ? reference : {},
        // celebrationTemplateId: selectedTemplate?._id || "",
        shouldAllStaffSee: notificationViewers === "All" && true,
      };
      // Call API to celebrate Many Staff
      dispatch(
        celebrateManyStaff(
          data,
          setIsSendingLoading,
          setErrorHandlerObj,
          navigate
        )
      );
    }
  };

  // Handles on Sucess of payment using paystack gateway (calls the credit selected/single staff API)
  const onPaystackSuccess = (reference: IPaystackReference) => {
    // Call the API to credit selected staff based on reference generated on success of payment gateway
    const paymentMethod = "paystack";
    handleCallAPIToCelebratetStaff(paymentMethod, reference);
  };

  // handle Initialize Payment To Credit Selected  WITH PAYSTACK
  const handleInitializePaymentWithPaystack = function () {
    // Set Error and Success message to default false
    setErrorHandlerObj({ hasError: false, message: "" });

    // Close the payment method modal
    setIsPaymentOptionModalOpened(false);

    // Initialize payment through paystack gateway || Trigger Paystack API to completed payment
    initializePayment(onPaystackSuccess, onPaystackClose);
  };

  // handle Initialize Payment To Credit Selected  WITH WALLET
  const handleInitializePaymentWithWallet = function () {
    // Set Error and Success message to default false
    setErrorHandlerObj({ hasError: false, message: "" });

    // Close the payment method modal
    setIsPaymentOptionModalOpened(false);

    // Call the API to credit selected staff based on reference generated on success of payment gateway
    const paymentMethod = "wallet";
    handleCallAPIToCelebratetStaff(paymentMethod);
  };

  // Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    // setSuccessHandlerObj,
  } = useDisplayMessage();

  //   UseEffects
  useEffect(() => {
    dispatch(getAllCompanyStaffs(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    if (allStaff) {
      const allStaffDropdownArr = allStaff?.map((staff: ISingleEmployee) => {
        return {
          key: staff._id,
          value: `${staff.firstName} ${staff.lastName}`,
        };
      });

      let sortedAllStaffDropdownArr: IDropdownOption[] =
        allStaffDropdownArr.sort((a: any, b: any) => {
          let splittedFirstnameA = a.value.split("")[0];
          let splittedFirstnameB = b.value.split("")[0];
          let Ca = splittedFirstnameA.toLowerCase();
          let Cb = splittedFirstnameB.toLowerCase();
          if (Ca < Cb) return -1;
          if (Ca > Cb) return 1;
          return 0;
        });
      setAllStaffDropDown(sortedAllStaffDropdownArr);
    }
  }, [allStaff]);

  useEffect(() => {
    if (location.state) {
      const { isCelebrationForAllStaff }: any = location.state;
      if (isCelebrationForAllStaff) {
        // Set Is For All Staff state to true
        setIsForAllStaff(true);
        // Set Employee Name Value to All (for validation sake)
        setFormInputStates((prevInpuStates) => {
          if (!prevInpuStates) return prevInpuStates;
          const updatedInputStates: IFormInputStates = {
            ...formInputStates,
            staff: ["all"],
          };
          console.log(updatedInputStates);
          return updatedInputStates;
        });
      }
    }
  }, [location]);

  return (
    <div
      className={`celebration-type-container ${
        isPaymentOptionModalOpened ? "overflow-hidden" : ""
      }`}
    >
      <Top />
      <Sidebar activeName={"Welfare"} />

      {/* Gray Overlay */}
      <div
        onClick={() => {
          setIsPaymentOptionModalOpened(false);
        }}
        className={`gray-overlay ${isPaymentOptionModalOpened ? "" : "none"}`}
      ></div>

      {/* Select Payment Method Modal */}
      <PaymentMethodModal
        isPaymentOptionModalOpened={isPaymentOptionModalOpened}
        setIsPaymentOptionModalOpened={setIsPaymentOptionModalOpened}
        amount={
          isForAllStaff
            ? (user?.staff?.length || 0) * parseInt(formInputStates.amount)
            : parseInt(formInputStates.amount) * formInputStates.staff.length
        }
        handleInitializePaymentWithPaystack={
          handleInitializePaymentWithPaystack
        }
        handleInitializePaymentWithWallet={handleInitializePaymentWithWallet}
      />

      <Main>
        <div className="celebration-type-container--inner">
          {/* Error and Success Handlers */}
          <div className="employees--actions-handler-wrapper">
            {/* Error handler component */}
            <ErrorHandler errorHandlerObj={errorHandlerObj} />
            {/* Success handler component */}
            <SuccessHandler successHandlerObj={successHandlerObj} />
          </div>

          {/* Main Section */}
          <section className={`celebration-type-main-section`}>
            <div className="primary-back-button-section">
              <div className="primary-back-button-container">
                {/* Back button Wrapper */}
                <PrimaryBackButton
                  onClick={() =>
                    navigate("/employer/welfare", {
                      state: {
                        currentPageContent: "celebrations",
                      },
                    })
                  }
                />
                {/* Top Title */}
                <div className="primary-back-button-title">
                  Credit Wallet {isForAllStaff && <span>(all staff)</span>}
                </div>
              </div>

              {/* Payment Loader */}
              {isSendingLoading && (
                <div className="payment-loader-wrapper">
                  <SubstituteLoadingSpinner />
                </div>
              )}
            </div>

            {/* Form Container */}
            <div className="celebration-type-form-container">
              {/* Select Template Option */}
              {/* <SelectTemplate
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                setErrorHandlerObj={setErrorHandlerObj}
              /> */}

              {/* Select Notification Viewers */}
              <SelectNotificationViewers
                notificationViewers={notificationViewers}
                setNotificationViewers={setNotificationViewers}
              />

              <CreditWalletForm
                allStaff={allStaff}
                allStaffDropdown={allStaffDropdown}
                formInputStates={formInputStates}
                isForAllStaff={isForAllStaff}
                isSendingLoading={isSendingLoading}
                setFormInputStates={setFormInputStates}
                handleSendCreditToStaff={handleSendCreditToStaff}
              />
            </div>
          </section>
        </div>
      </Main>
    </div>
  );
}

export default CreditWallet;
