import moment from "moment";
import React from "react";
import { Table } from "react-bootstrap";
import LoadingSpinner from "../../../../../components/loading-spinner/LoadingSpinner";
import { IAPIPaginatedResponse, ISingleBenefit } from "../../../../../Types";
import isEmpty from "../../../../../validation/isEmpty";
import BEPagination from "../../../../../components/pagination/be-pagination/BEPagination";

// Interfaces
interface IProps {
  isLoading: boolean;
  benefits: IAPIPaginatedResponse<ISingleBenefit[]> | null;
  currentBenefitsPosts: ISingleBenefit[] | null;
  setPageNumberIndex: React.Dispatch<React.SetStateAction<number>>;
}

function BenefitsTable({
  isLoading,
  benefits,
  currentBenefitsPosts,
  setPageNumberIndex,
}: IProps) {
  // Functions, States and Variables

  //   Functions
  return (
    <>
      <div className="primary-table-container">
        <h3>All Staff Credits</h3>
        <Table
          responsive="lg"
          className="primary-table all-benefits-request-table"
        >
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "NAME",
                "EMAIL",
                "SALARY",
                // "WALLET BALANCE",
                "CREDIT AMOUNT",
                "PAYMENT DATE",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentBenefitsPosts?.map((benefit, i) => (
              <tr key={i + 1}>
                {/* Employee Name Column */}
                <td className="td-employee-name">
                  <div className="td-employee-name--wrapper">
                    {benefit.staffId?.firstName} {benefit.staffId?.lastName}
                  </div>
                </td>

                {/*  Employee Email Column */}
                <td className="td-email">
                  <div className="td-email--wrappe">
                    {benefit.staffId?.email}
                  </div>
                </td>

                {/* Salary Column */}
                <td className="td-salary">
                  <div className="td-salary--wrapper">
                    &#8358;{benefit.staffId?.salary?.toLocaleString()}
                  </div>
                </td>

                {/* Wallet Column */}
                {/* <td className="td-wallet-balance">
                  <div className="td-wallet-balance--wrapper">
                    &#8358;{benefit.staffId?.wallet?.toLocaleString()}
                  </div>
                </td> */}

                {/*  Amount Sent Column */}
                <td className="td-amount-sent">
                  <div className="td-amount-sent--wrapper">
                    &#8358;{benefit?.amount?.toLocaleString() || ""}
                  </div>
                </td>

                {/* Date Column*/}
                <td className="td-date">
                  <div className="td-date--wrapper">
                    {moment(benefit?.createdAt || "").format("Do MMM, YY")}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {!isEmpty(currentBenefitsPosts) &&
        !(isLoading && isEmpty(currentBenefitsPosts)) && (
          <BEPagination
            currentPage={benefits?.currentPage || 1}
            lastPage={benefits?.totalPages || 1}
            currentPosts={currentBenefitsPosts}
            setPageNumberIndex={setPageNumberIndex}
          />
        )}
    </>
  );
}

export default BenefitsTable;
