import React, { useEffect, useState } from "react";
import "../../../../../../components/voucher-input-comps/select-voucher-countries/SelectVoucherCountries.css";
import angleDown from "../../../../../../images/angle-down-secondary.svg";
import cancelIcon from "../../../../../../images/cancel-secondary.svg";
import searchIcon from "../../../../../../images/search-icon-secondary.svg";
import { IDropdownOption } from "../../../../../../Types";

// Interfaces
interface IProps {
  allStaffDropdown: IDropdownOption[] | [];
  selectedStaff: string[];
  setSelectedStaff: React.Dispatch<React.SetStateAction<string[]>>;
}

function SelectEmployees({
  allStaffDropdown,
  selectedStaff,
  setSelectedStaff,
}: IProps) {
  // Function, States and Variables
  // States
  const [isFormContainerOpened, setIsFormContainerOpened] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [filteredStaff, setFilteredStaff] = useState(allStaffDropdown);
  const [staffCheckedState, setStaffCheckedState] = useState<boolean[]>([]);

  // Functions
  // Handle Check or Uncheck on Staff Checkbox
  const handleChangeOnCheckbox = (staffId: string) => {
    // If Staff is already selected || is  among selected staff
    if (selectedStaff.includes(staffId)) {
      const index = selectedStaff?.findIndex(
        (currentStaff) => currentStaff === staffId
      );
      if (index > -1) {
        const selectedStaffDuplicate = [...selectedStaff];
        // Remove Staff from Selected Staff Array and Update Array
        selectedStaffDuplicate.splice(index, 1);
        setSelectedStaff(selectedStaffDuplicate);
      }
    } else {
      // If country is not selected || is not among selected countries
      setSelectedStaff([...selectedStaff, staffId]);
    }
  };

  const handleGetStaffName = function (staffId: string) {
    const currentStaff = allStaffDropdown?.filter(
      (staff) => staff.key === staffId
    );

    return currentStaff[0]?.value || "";
  };

  // useEffect
  useEffect(() => {
    // This UseEffect handles update of staff check state when selected staff and filtered staff should change
    const updatedStaffCheckState: boolean[] = filteredStaff?.map((staffObj) => {
      return selectedStaff.includes(staffObj.key);
    });
    setStaffCheckedState(updatedStaffCheckState);
  }, [selectedStaff, filteredStaff]);

  useEffect(() => {
    // This useeffect handles update of filtered staff when search values should change, handles staff filter
    const updatedFilteredStaff = allStaffDropdown?.filter((staffObj) =>
      staffObj.value.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredStaff(updatedFilteredStaff);
  }, [searchValue, allStaffDropdown]);

  return (
    <div className="select-voucher-countries-container">
      {/* Toggle Form Wrapper */}
      <div className="toggle-form-section-button-wrapper">
        <button
          onClick={(e) => {
            e.preventDefault();
            setIsFormContainerOpened(!isFormContainerOpened);
          }}
        >
          Select Employees
          <img
            src={angleDown}
            className={isFormContainerOpened ? "icon-rotated-up" : ""}
            alt=""
          />
        </button>
      </div>

      {/* Selected Staff Overview Wrapper */}
      <div className="selected-countries-overview-wrapper">
        {/* Selected Staff Wrapper */}
        {selectedStaff?.map((staffId, index) => (
          <div key={index + 1} className="primary--selected-object-wrapper">
            <div className="left-col">
              <div className="object-main-text">
                {handleGetStaffName(staffId)}
              </div>
            </div>
            <div className="right-col">
              <button
                onClick={(e) => {
                  // Since country already exists in selected country, it will be removed
                  e.preventDefault();
                  handleChangeOnCheckbox(staffId);
                }}
              >
                <img src={cancelIcon} alt="" />
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Select Staff Form Section */}
      <div
        className={`select-voucher-countries-form-section ${
          isFormContainerOpened ? "" : "collapsed"
        }`}
      >
        <div className="select-voucher-countries-form-section--inner">
          <div className="form-search-wrapper">
            <img src={searchIcon} alt="" />
            <input
              type="search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>

        <div className="countries-checkboxes-wrapper">
          {staffCheckedState?.map((checkedValue, index) => (
            <div key={index + 1} className="country-checkbox">
              <input
                type="checkbox"
                id={`countries-checkbox-${index}`}
                checked={staffCheckedState[index]}
                onChange={() => {
                  handleChangeOnCheckbox(filteredStaff[index]?.key);
                }}
              />
              <label htmlFor={`countries-checkbox-${index}`}>
                {filteredStaff[index]?.value}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SelectEmployees;
