export function handleShortenNumber(number: number) {
  if (number === 0) return number;
  const symbols = ["", "K", "M", "B", "T"];

  const tier = Math.floor(Math.log10(number) / 3);

  const scaledNumber = number / Math.pow(1000, tier);

  const formattedNumber = scaledNumber.toFixed(0);

  return formattedNumber + symbols[tier];
}
