import {
  GET_ALL_CELEBRATION_TEMPLATES,
  GET_ALL_COMPANY_CELEBRATIONS,
} from "../Constants";

const initialState = {
  celebarations: [],
  imgTemplates: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state = initialState,
  action: { type?: string; payload?: any }
) {
  switch (action.type) {
    case GET_ALL_COMPANY_CELEBRATIONS:
      return {
        ...state,
        celebarations: action.payload,
      };
    case GET_ALL_CELEBRATION_TEMPLATES:
      return {
        ...state,
        imgTemplates: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
