import React from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../../../../components/loading-spinner/LoadingSpinner";
import isEmpty from "../../../../../../validation/isEmpty";
import { ISingleEmployee } from "../../../../../employer-settlements/Settlements";
import FEPagination from "../../../../../../components/pagination/fe-pagination/FEPagination";

// Interfaces
interface IProps {
  isLoading: boolean;
  allStaff: ISingleEmployee[] | null;
  currentStaffPosts: ISingleEmployee[] | null;
  paginate: (pageNumber: number) => void;
  currentPage: number;
  rowsPerPage: number;
}

function AllStaffTable({
  isLoading,
  allStaff,
  currentStaffPosts,
  paginate,
  currentPage,
  rowsPerPage,
}: IProps) {
  // Functions, States and Variables
  const navigate = useNavigate();
  return (
    <>
      <div className="primary-table-container">
        <Table
          responsive="lg"
          className="primary-table all-benefits-request-table"
        >
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "NAME",
                "EMAIL",
                "PHONE NUMBER",
                "GROUP",
                "SALARY",
                // "WALLET BALANCE",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentStaffPosts?.map((staff, i) => (
              <tr
                key={i + 1}
                onClick={() => {
                  navigate(
                    "/employer/welfare/benefits/credit-new-wallet/employee-profile",
                    { state: { selectedStaff: staff } }
                  );
                }}
              >
                {/* Employee Name Column */}
                <td className="td-employee-name">
                  <div className="td-employee-name--wrapper">
                    {staff?.firstName} {staff?.lastName}
                  </div>
                </td>

                {/*  Employee Email Column */}
                <td className="td-email">
                  <div className="td-email--wrapper">{staff?.email}</div>
                </td>

                {/*  Employee Phone Column */}
                <td className="td-phone">
                  <div className="td-phone--wrapper">{staff?.phoneNumber}</div>
                </td>

                {/*  Employee Group Column */}
                <td className="td-group">
                  <div className="td-group--wrapper">{staff?.group}</div>
                </td>

                {/* Salary Column */}
                <td className="td-salary">
                  <div className="td-salary--wrapper">
                    &#8358;{staff?.salary?.toLocaleString()}
                  </div>
                </td>

                {/* Wallet Column */}
                {/* <td className="td-wallet-balance">
                  <div className="td-wallet-balance--wrapper">
                    {staff?.wallet?.toLocaleString()}
                  </div>
                </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {allStaff && !isEmpty(currentStaffPosts) && (
        <FEPagination
          paginate={paginate}
          rowsPerPage={rowsPerPage}
          totalPosts={allStaff.length}
          currentPage={currentPage}
        />
      )}
    </>
  );
}

export default AllStaffTable;
