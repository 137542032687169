import React from "react";
import { ISingleEmployee } from "../../../../../../employer-settlements/Settlements";
import "./EmployeeInfo.css";

// Interface
interface IProps {
  currentStaff: ISingleEmployee;
}

function EmployeeInfo({ currentStaff }: IProps) {
  return (
    <div className="employee-info-container">
      {/* Employee details container */}
      <div className="secondary--employee-details-container">
        {/* Title */}
        <div className="requests-preview--employee-details--title">
          EMPLOYEE DETAILS
        </div>

        {/* Inner */}
        <div className="secondary--employee-details-container--inner">
          {/* Employee email */}
          <div className="employee-detail-wrapper">
            <div className="requests-preview--employee-detail--title">
              Email
            </div>
            <div className="requests-preview--employee-detail--value">
              {currentStaff.email}
            </div>
          </div>
          {/* Employee Phone Number */}
          <div className="employee-detail-wrapper">
            <div className="requests-preview--employee-detail--title">
              Phone Number
            </div>
            <div className="requests-preview--employee-detail--value">
              {currentStaff.phoneNumber}
            </div>
          </div>
          {/* Employee Salary */}
          <div className="employee-detail-wrapper">
            <div className="requests-preview--employee-detail--title">
              Salary
            </div>
            <div className="requests-preview--employee-detail--value">
              &#8358;
              {currentStaff.salary?.toLocaleString()}
            </div>
          </div>
          {/* Employee department */}
          <div className="employee-detail-wrapper">
            <div className="requests-preview--employee-detail--title">
              Department
            </div>
            <div className="requests-preview--employee-detail--value">
              {currentStaff.department}
            </div>
          </div>

          {/* Employee Role */}
          <div className="employee-detail-wrapper">
            <div className="requests-preview--employee-detail--title">Role</div>
            <div className="requests-preview--employee-detail--value">
              {currentStaff.role}
            </div>
          </div>

          {/* Employee Level  */}
          <div className="employee-detail-wrapper">
            <div className="requests-preview--employee-detail--title">
              Level
            </div>
            <div className="requests-preview--employee-detail--value">
              {currentStaff.level}
            </div>
          </div>

          {/* Employee Address  */}
          <div className="employee-detail-wrapper">
            <div className="requests-preview--employee-detail--title">
              Address
            </div>
            <div className="requests-preview--employee-detail--value">
              {currentStaff.address}
            </div>
          </div>
        </div>
      </div>

      {/* Wallet Details */}
      <div className="secondary--employee-wallet-details-wrapper">
        {/* Title */}
        <div className="requests-preview--employee-details--title">
          WALLET DETAILS
        </div>

        {/* Wallet Detail Wrapper */}
        <div className="employee-wallet-detail-col">
          {/* Balance */}
          {/* <div className="wallet-detail-row-wrapper">
            <div className="requests-preview--employee-detail--title">
              Wallet Balance
            </div>
            <div className="wallet-detail-title">
              &#8358;{currentStaff.wallet?.toLocaleString()}
            </div>
          </div> */}
          {/* Salary */}
          <div className="wallet-detail-row-wrapper">
            {/* Title */}
            <div className="requests-preview--employee-detail--title">
              Salary
            </div>
            {/* Value */}
            <div className="wallet-detail-title">
              {" "}
              &#8358;{currentStaff.salary?.toLocaleString()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeInfo;
