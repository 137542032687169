import React, { useEffect, useState } from "react";
import "./EmployerDashboard.css";
import Navbar from "../../components/app-navbar/Navbar";
import Top from "../../components/app-top/Top";
import ProductUsageChart from "../../components/pie-charts/ProductUsageChart";
import DisbursementsBarChart from "../../components/bar-charts/DisbursementsBarChart";
import isEmpty from "../../validation/isEmpty";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { numberWithCommas } from "../../utils/numberWithCommas";
import ErrorHandler from "../../components/error/ErrorHandler";
import {
  getDisbursedApprovedDeclinedAmount,
  getDisbursedApprovedDeclinedAmountPerWeek,
  getLastTwoPayouts,
  getPercentageApprovedDeclinedRequests,
  getPercentageStaffUsage,
  getTotalDebt,
} from "../../redux/actions/companyDataSummaryActions";
import moment from "moment";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Main from "../../components/main-container/Main";

interface IPercentStaffUsage {
  totalStaff: number;
  totalStaffUsing: number;
  totalStaffNotUsing: number;
}

interface IPercentApprovedDeclinedReqs {
  totalRequest: number;
  totalRequestApproved: number;
  totalRequestDeclined: number;
}

interface IDisbursedApprovedDeclinedAmount {
  totalDisbursedAmount: number;
  totalApprovedAmount: number;
  totalDeclinedAmount: number;
}

interface IDisbursedApprovedDeclinedAmountPerWeek {
  week_one: {
    totalDisbursedAmount: number;
    totalApprovedAmount: number;
    totalDeclinedAmount: number;
  };
  week_two: {
    totalDisbursedAmount: number;
    totalApprovedAmount: number;
    totalDeclinedAmount: number;
  };
  week_three: {
    totalDisbursedAmount: number;
    totalApprovedAmount: number;
    totalDeclinedAmount: number;
  };
  week_four: {
    totalDisbursedAmount: number;
    totalApprovedAmount: number;
    totalDeclinedAmount: number;
  };
}

interface IDisbursementGraphData {
  name: string;
  "Total Amount": number;
  "Total Approved Amount": number;
  "Total Declined Amount": number;
}

interface IRecentPayouts {
  account: {
    accountName: string;
    accountNumber: string;
    bank: string;
    bankCode: string;
  };
  amount: number;
  companyId: string;
  createdAt: string;
  isApproved: boolean;
  isApprovedByAdmin: boolean;
  isOTPProvided: boolean;
  isPaid: boolean;
  isRejected: boolean;
  isSettled: boolean;
  staffId: string;
  status: number;
  type: string;
  updatedAt: string;
  userId: string;
  _id: string;
}

function EmployerDashboard() {
  // Functions, States and Variables
  const totalCompanyDebt = useAppSelector(
    (state) => state.dataSummary.totalDebt
  );
  const lastTwoPayouts: IRecentPayouts[] | [] = useAppSelector(
    (state) => state.dataSummary.lastTwoPayouts
  );
  const percentageStaffUsage: IPercentStaffUsage = useAppSelector(
    (state) => state.dataSummary.percentStaffUsage
  );
  const percentApprovedDeclinedReqs: IPercentApprovedDeclinedReqs =
    useAppSelector((state) => state.dataSummary.percentApprovedDeclinedReq);
  const disbursedApprovedDeclinedAmount: IDisbursedApprovedDeclinedAmount =
    useAppSelector(
      (state) => state.dataSummary.disbursedApprovedDeclinedAmount
    );
  const disbursedApprovedDeclinedAmountPerWeek: IDisbursedApprovedDeclinedAmountPerWeek =
    useAppSelector(
      (state) => state.dataSummary.disbursedApprovedDeclinedAmount
    );
  const dispatch = useAppDispatch();
  // States
  const [totalDebt, setTotalDebt] = useState(0);
  const [recentPayouts, setRecentPayouts] = useState<IRecentPayouts[] | null>(
    null
  );
  const [percentCompanyStaffUsage, setPercentCompanyStaffUsage] =
    useState<IPercentStaffUsage>({
      totalStaff: 0,
      totalStaffUsing: 0,
      totalStaffNotUsing: 0,
    });
  const [
    percentCompanyApprovedDeclinedReqs,
    setPercentCompanyApprovedDeclinedReqs,
  ] = useState<IPercentApprovedDeclinedReqs>({
    totalRequest: 0,
    totalRequestApproved: 0,
    totalRequestDeclined: 0,
  });
  const [
    companyDisbursedApprovedDeclinedAmount,
    setCompanyDisbursedApprovedDeclinedAmount,
  ] = useState<IDisbursedApprovedDeclinedAmount>({
    totalDisbursedAmount: 0,
    totalApprovedAmount: 0,
    totalDeclinedAmount: 0,
  });
  const [
    companyDisbursedApprovedDeclinedAmountPerWeek,
    setCompanyDisbursedApprovedDeclinedAmountPerWeek,
  ] = useState<IDisbursementGraphData[] | null>(null);

  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  // Functions

  // UseEffects
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      //scroll to page top
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [errorHandlerObj]);

  useEffect(() => {
    dispatch(getTotalDebt(setIsLoading, setErrorHandlerObj));
    dispatch(getLastTwoPayouts(setIsLoading, setErrorHandlerObj));
    dispatch(getPercentageStaffUsage(setIsLoading, setErrorHandlerObj));
    dispatch(
      getPercentageApprovedDeclinedRequests(setIsLoading, setErrorHandlerObj)
    );
    dispatch(
      getDisbursedApprovedDeclinedAmount(setIsLoading, setErrorHandlerObj)
    );
    dispatch(
      getDisbursedApprovedDeclinedAmountPerWeek(
        setIsLoading,
        setErrorHandlerObj
      )
    );
  }, []);

  useEffect(() => {
    setTotalDebt(totalCompanyDebt);
  }, [totalCompanyDebt]);

  useEffect(() => {
    setRecentPayouts(lastTwoPayouts);
  }, [lastTwoPayouts]);

  useEffect(() => {
    setPercentCompanyStaffUsage(percentageStaffUsage);
  }, [percentageStaffUsage]);

  useEffect(() => {
    setPercentCompanyApprovedDeclinedReqs(percentApprovedDeclinedReqs);
  }, [percentApprovedDeclinedReqs]);

  useEffect(() => {
    setCompanyDisbursedApprovedDeclinedAmount(disbursedApprovedDeclinedAmount);
  }, [disbursedApprovedDeclinedAmount]);

  useEffect(() => {
    if (disbursedApprovedDeclinedAmountPerWeek) {
      const disbursedApprovedDeclinedGraphData = [
        {
          name: "Week 1",
          "Total Amount":
            disbursedApprovedDeclinedAmountPerWeek.week_one
              ?.totalDisbursedAmount,
          "Total Approved Amount":
            disbursedApprovedDeclinedAmountPerWeek.week_one
              ?.totalApprovedAmount,
          "Total Declined Amount":
            disbursedApprovedDeclinedAmountPerWeek.week_one
              ?.totalDeclinedAmount,
        },
        {
          name: "Week 2",
          "Total Amount":
            disbursedApprovedDeclinedAmountPerWeek.week_one
              ?.totalDisbursedAmount,
          "Total Approved Amount":
            disbursedApprovedDeclinedAmountPerWeek.week_two
              ?.totalApprovedAmount,
          "Total Declined Amount":
            disbursedApprovedDeclinedAmountPerWeek.week_three
              ?.totalDeclinedAmount,
        },
        {
          name: "Week 3",
          "Total Amount":
            disbursedApprovedDeclinedAmountPerWeek.week_one
              ?.totalDisbursedAmount,
          "Total Approved Amount":
            disbursedApprovedDeclinedAmountPerWeek.week_two
              ?.totalApprovedAmount,
          "Total Declined Amount":
            disbursedApprovedDeclinedAmountPerWeek.week_three
              ?.totalDeclinedAmount,
        },
        {
          name: "Week 4",
          "Total Amount":
            disbursedApprovedDeclinedAmountPerWeek.week_one
              ?.totalDisbursedAmount,
          "Total Approved Amount":
            disbursedApprovedDeclinedAmountPerWeek.week_two
              ?.totalApprovedAmount,
          "Total Declined Amount":
            disbursedApprovedDeclinedAmountPerWeek.week_three
              ?.totalDeclinedAmount,
        },
      ];

      setCompanyDisbursedApprovedDeclinedAmountPerWeek(
        disbursedApprovedDeclinedGraphData
      );
    }
  }, [disbursedApprovedDeclinedAmountPerWeek]);

  return (
    <div className="employer-dashboard-container">
      <Top />
      <Sidebar activeName={"Dashboard"} />

      <Main>
        <div className="employer-dashboard-container--inner">
          {/* Error and Success Handlers */}
          <div className="employees--actions-handler-wrapper">
            {/* Error handler component */}
            <ErrorHandler errorHandlerObj={errorHandlerObj} />
          </div>

          {/* ROW ONE SECTION */}
          <section className="employer-container-row--one employer-dashboard-row-section">
            {/* TOTAL DEBT container / Row one Left */}
            <div className="row-one--left-container total-debt-container">
              {/* Top Wrapper */}
              <div className="row-one--left--top-wrapper">
                <div className="row-one--box-title total-debt-box-title">
                  TOTAL DEBT
                </div>
              </div>

              {/* Middle Wrapper */}
              <div className="row-one--left--middle-wrapper">
                {/* Total debt value wrapper */}
                <div className="total-debt-percentage-wrapper">
                  &#8358;
                  {numberWithCommas(totalDebt.toLocaleString())}
                </div>
              </div>

              {/* Bottom Wrapper */}
              <div className="row-one--left--bottom-wrapper">
                <div className="recent-payouts-title-wrapper">
                  Recent Payout
                </div>
                <div className="recent-payouts-values-container">
                  {!isEmpty(recentPayouts) &&
                    recentPayouts?.map((payout: IRecentPayouts, i) => (
                      <div key={i + 1} className="recent-payouts-value-wrapper">
                        <div className="recent-payout-value">
                          N{numberWithCommas(payout?.amount)}
                        </div>
                        <div className="recent-payout-date">
                          {moment(payout?.createdAt || "").format("L")}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            {/* Product usage container / Row one Middle */}
            <div className="row-one--middle-container product-usage-container">
              {/* Top Wrapper */}
              <div className="row-one--middle--top-wrapper">
                <div className="row-one--box-title product-usage-box-title">
                  PRODUCT USAGE
                </div>
              </div>

              {/* Bottom Wrapper */}
              <div className="row-one--middle--bottom-wrapper">
                <div className="chart-container product-usage-chart-container">
                  <ProductUsageChart
                    data={[
                      {
                        name: "Staff using the product",
                        value: Math.round(
                          (percentCompanyStaffUsage.totalStaffUsing /
                            percentCompanyStaffUsage.totalStaff) *
                            100
                        ),
                      },
                      {
                        name: "Staff not using product",
                        value: Math.round(
                          (percentCompanyStaffUsage?.totalStaffNotUsing /
                            percentCompanyStaffUsage?.totalStaff) *
                            100
                        ),
                      },
                    ]}
                  />
                </div>
                {/* Chart Keys */}
                <div className="chart-keys-container">
                  {/* First Chart Key */}
                  <div className="first-chart-key-wrapper">
                    <div className="chart-key-color staff-using-product-key"></div>
                    <div className="chart-key-title">
                      Staff using the product
                    </div>
                    <div className="chart-key-value">
                      {Math.round(
                        (percentCompanyStaffUsage.totalStaffUsing /
                          percentCompanyStaffUsage.totalStaff) *
                          100
                      ) || 0}
                      %
                    </div>
                  </div>

                  {/* Second Chart Key */}
                  <div className="second-chart-key-wrapper">
                    <div className="chart-key-color staff-amount-product-key"></div>
                    <div className="chart-key-title">
                      Staff not using the product
                    </div>
                    <div className="chart-key-value">
                      {Math.round(
                        (percentCompanyStaffUsage.totalStaffNotUsing /
                          percentCompanyStaffUsage.totalStaff) *
                          100
                      ) || 0}
                      %
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Stock level container / Row one Right */}
            <div className="row-one--right-container all-requests-container">
              {/* Top Wrapper */}
              <div className="row-one--right--top-wrapper">
                <div className="row-one--box-title all-requests-box-title">
                  REQUESTS
                </div>
              </div>

              {/* Bottom Wrapper */}
              <div className="row-one--right--bottom-wrapper">
                {/* Chart Container */}
                <div className="chart-container requests-chart-container">
                  <ProductUsageChart
                    data={[
                      {
                        name: "Total Declined Requests",
                        value:
                          percentCompanyApprovedDeclinedReqs.totalRequestDeclined,
                      },
                      {
                        name: "Total Approved Requests",
                        value:
                          percentCompanyApprovedDeclinedReqs.totalRequestApproved,
                      },
                    ]}
                  />
                </div>
                {/* Chart Keys */}
                <div className="chart-keys-container">
                  {/* First Chart Key */}
                  <div className="first-chart-key-wrapper">
                    <div className="chart-key-color approved-requests-product-key"></div>
                    <div className="chart-key-title">
                      Total Approved Requests
                    </div>
                    <div className="chart-key-value">
                      {percentCompanyApprovedDeclinedReqs.totalRequestApproved}%
                    </div>
                  </div>

                  {/* Second Chart Key */}
                  <div className="second-chart-key-wrapper">
                    <div className="chart-key-color declined-requests-product-key"></div>
                    <div className="chart-key-title">
                      Total Declined Requests
                    </div>
                    <div className="chart-key-value">
                      {percentCompanyApprovedDeclinedReqs.totalRequestDeclined}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* ROW THREE SECTION */}
          <section className="employer-container-row--three employer-dashboard-row-section">
            {/* Row Two Left Container */}
            <div className="row-two--left-container row-two-main-container">
              {/* Top Wrapper */}
              <div className="row-two--left--top-wrapper">
                <div className="row-two--box-title disbursements-box-title">
                  DISBURSEMENTS
                </div>
              </div>

              {/* Bottom Wrapper */}
              <div className="row-two--left--bottom-wrapper">
                {/* Disbursements Values Display Wrapper */}
                <div className="disbursements-values-display-wrapper">
                  {/* Total disbursements */}
                  <div className="total-disbursements-amount-wrapper">
                    <div className="total-disbursements-amount-title disbursements-amount-title">
                      Total Amount
                    </div>
                    <div className="total-disbursements-amount-value disbursements-amount-value">
                      {numberWithCommas(
                        companyDisbursedApprovedDeclinedAmount.totalDisbursedAmount
                      )}
                    </div>
                  </div>

                  {/* Total Approved Amount*/}
                  <div className="total-approved-amount-wrapper">
                    <div className="total-approved-amount-title disbursements-amount-title">
                      Total Approved Amount
                    </div>
                    <div className="total-approved-amount-value disbursements-amount-value">
                      {numberWithCommas(
                        companyDisbursedApprovedDeclinedAmount.totalApprovedAmount
                      )}
                    </div>
                  </div>

                  {/* Total Declined Amount */}
                  <div className="total-declined-amount-wrapper">
                    <div className="total-declined-amount-title disbursements-amount-title">
                      Total Declined Amount
                    </div>
                    <div className="total-declined-amount-value disbursements-amount-value">
                      {numberWithCommas(
                        companyDisbursedApprovedDeclinedAmount?.totalDeclinedAmount
                      )}
                    </div>
                  </div>
                </div>

                <div className="disbursements-bar-chart-container">
                  <DisbursementsBarChart
                    data={companyDisbursedApprovedDeclinedAmountPerWeek}
                  />
                </div>

                {/* Disbursements Keys Display Wrapper */}
                <div className="disbursements-keys-display-wrapper">
                  {/* disbursements keys */}
                  <div className="total-disbursements-keys-wrapper">
                    <div className="total-disbursements-keys-box disbursements-keys-box"></div>
                    <div className="total-disbursements-keys-title disbursements-amount-title">
                      Total Amount
                    </div>
                  </div>

                  {/* Total Approved Amount*/}
                  <div className="total-approved-keys-wrapper">
                    <div className="total-approved-keys-box disbursements-keys-box"></div>
                    <div className="total-approved-keys-title disbursements-amount-title">
                      Total Approved Amount
                    </div>
                  </div>

                  {/* Total Declined Amount */}
                  <div className="total-declined-keys-wrapper">
                    <div className="total-declined-keys-box disbursements-keys-box"></div>
                    <div className="total-declined-keys-title disbursements-amount-title">
                      Total Declined Amount
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Main>
    </div>
  );
}

export default EmployerDashboard;
