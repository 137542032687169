export const GET_ERRORS = "GET_ERRORS";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_COMPANY_SUBSCRIPTION = "GET_COMPANY_SUBSCRIPTION";
export const GET_COMPANY_SUBSCRIPTION_STATUS =
  "GET_COMPANY_SUBSCRIPTION_STATUS";
// Staff
export const GET_ALL_COMPANY_STAFF = "GET_ALL_COMPANY_STAFF";
// Groups
export const GET_ALL_COMPANY_GROUPS = "GET_ALL_COMPANY_GROUPS";
// Requests
export const GET_ALL_REQUESTS = "GET_ALL_REQUESTS";
// Dashboard Summary Data
export const GET_TOTAL_DEBT = "GET_TOTAL_DEBT";
export const GET_LAST_TWO_PAYOUTS = "GET_LAST_TWO_PAYOUTS";
export const GET_PERCENTAGE_STAFF_USAGE = "GET_PERCENTAGE_STAFF_USAGE";
export const GET_PERCENTAGE_APPROVED_DECLINED_REQUESTS =
  "GET_PERCENTAGE_APPROVED_DECLINED_REQUESTS";
export const GET_DISBURSED_APPROVED_DECLINED_AMOUNT =
  "GET_DISBURSED_APPROVED_DECLINED_AMOUNT";
export const GET_DISBURSED_APPROVED_DECLINED_AMOUNT_PER_WEEK =
  "GET_DISBURSED_APPROVED_DECLINED_AMOUNT_PER_WEEK";
// Settlements
export const GET_ALL_SETTLEMENTS = "GET_ALL_SETTLEMENTS";
// API Configs
export const GET_API_DETAILS = "GET_API_DETAILS";
// Company Subscriptions
export const GET_ALL_SUBSCRIPTION_PLANS = "GET_ALL_SUBSCRIPTION_PLANS";
// Benefits
export const GET_ALL_CREDITS_FOR_STAFF = "GET_ALL_CREDITS_FOR_STAFF";
export const GET_ALL_CREDITS_FOR_COMPANY = "GET_ALL_CREDITS_FOR_COMPANY";
// Celebrations
export const GET_ALL_COMPANY_CELEBRATIONS = "GET_ALL_COMPANY_CELEBRATIONS";
export const GET_ALL_CELEBRATION_TEMPLATES = "GET_ALL_CELEBRATION_TEMPLATES";
// WellNess
export const GET_ALL_COURSES = "GET_ALL_COURSES";
export const GET_COURSE_PROGRESS = "GET_COURSE_PROGRESS";
export const GET_COURSE_CURRICULUM = "GET_COURSE_CURRICULUM";
export const GET_COURSE_ASSESSMENT_FOR_COURSE =
  "GET_COURSE_ASSESSMENT_FOR_COURSE";
// Transactions
export const GET_ALL_TRANSACTIONS = "GET_ALL_TRANSACTIONS";
export const GET_ALL_CREDIT_TRANSACTIONS = "GET_ALL_CREDIT_TRANSACTIONS";
export const GET_ALL_DEBIT_TRANSACTIONS = "GET_ALL_DEBIT_TRANSACTIONS";
