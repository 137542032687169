import axios from "axios";
import { api } from "../../api/config";
import {
  IPaystackReference,
  ISingleCelebration,
  IUnstructuredCelebration,
  SetErrorHandlerType,
} from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";
import { NavigateFunction } from "react-router-dom";
import {
  GET_ALL_CELEBRATION_TEMPLATES,
  GET_ALL_COMPANY_CELEBRATIONS,
} from "../Constants";
import isEmpty from "../../validation/isEmpty";

// Interfaces
interface ICelebrateSingleStaffData {
  staffId: string;
  type: string;
  amount: number;
  message: string;
  paymentMethod: string;
  reference: string;
  paymentGatewayResponse: IPaystackReference | {};
  celebrationTemplateId: string;
  shouldAllStaffSee: boolean;
}

interface ICelebrateAllEmployeesData {
  type: string;
  totalAmount: number;
  amountPerStaff: number;
  message: string;
  paymentMethod: string;
  reference: string;
  paymentGatewayResponse: IPaystackReference | {};
  // celebrationTemplateId: string;
  shouldAllStaffSee: boolean;
}

interface ICelebrateManyStaff {
  staffIds: {
    staff: string;
    amount: number;
  }[];
  type: string;
  amount: number;
  message: string;
  paymentMethod: string;
  reference: string;
  paymentGatewayResponse: IPaystackReference | {};
  // celebrationTemplateId: string;
  shouldAllStaffSee: boolean;
}

const url = `${api}/api/v1/company`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get All Company Celebrations
export const getCompanyCelebrations =
  (
    pageNumberIndex: number,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/celebration?page=${pageNumberIndex}`)
      .then((res) => {
        // const companyCelebrations: IUnstructuredCelebration[] | [] =
        //   res.data.data?.reverse();

        // let structuredCompanyCelebrations: ISingleCelebration[] = [];
        // if (companyCelebrations) {
        //   companyCelebrations?.map((celebration, celebrationIndex) => {
        //     let staffCelebrationArr = celebration.staffIds?.map((staff) => {
        //       structuredCompanyCelebrations.push({
        //         staffId: celebration?.staffId || staff.staff,
        //         amount: staff.amount,
        //         _id: celebration._id,
        //         type: celebration.type,
        //         staffCreditId: celebration.staffCreditId,
        //         celebrationType: celebration.celebrationType,
        //         createdAt: celebration.createdAt,
        //       });
        //     });
        //     return staffCelebrationArr;
        //   });
        // console.log(structuredCompanyCelebrations);
        // }
        dispatch({
          type: GET_ALL_COMPANY_CELEBRATIONS,
          payload: res.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get All Celebration Image Templates
export const getCelebrationImgTemplates =
  (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/celebration-template`)
      .then((res) => {
        dispatch({
          type: GET_ALL_CELEBRATION_TEMPLATES,
          payload: res.data.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Celebrate A Single Staff
export const celebrateSingleStaff =
  (
    data: ICelebrateSingleStaffData,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/celebrate-a-staff`, data)
      .then((response) => {
        navigate("/employer/welfare", {
          state: {
            currentPageContent: "celebrations",
            successMessage: "Celebration sent to staff successfully!",
          },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Celebrate Many Staff
export const celebrateManyStaff =
  (
    data: ICelebrateManyStaff,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/celebrate-many-staff`, data)
      .then((response) => {
        navigate("/employer/welfare", {
          state: {
            currentPageContent: "celebrations",
            successMessage: "Celebration sent to staff successfully!",
          },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Celebrate All Employees
export const celebrateAllEmployees =
  (
    data: ICelebrateAllEmployeesData,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/celebrate-all-staff`, data)
      .then((response) => {
        navigate("/employer/welfare", {
          state: {
            currentPageContent: "celebrations",
            successMessage: "Celebration sent to staff successfully!!",
          },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };
