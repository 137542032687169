import React, { useContext, useEffect, useState } from "react";
import {
  ISearchContextType,
  SearchContext,
} from "../../../../components/contexts/SearchContext";
import DropdownInput from "../../../../components/input-components/dropdown-input/DropdownInput";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { getAllCompanyRequests } from "../../../../redux/actions/requestActions";
import { IAllRequests, SetErrorHandlerType } from "../../../../Types";
import { handleFilterRequestsTableByStatus } from "../../../../utils/handleFilterRequestsByStatus";
import { requestFilterDropdown } from "../../../../utils/requestFilterDropdown";
import OnDemandTable from "./on-demand-table/OnDemandTable";

// Interfaces
interface IProps {
  pageContent: string;
  isApproved: boolean;
  isDeclined: boolean;
  setSelectedEmployeeRequest: React.Dispatch<
    React.SetStateAction<IAllRequests | null>
  >;
  setErrorHandlerObj: SetErrorHandlerType;
  setWelfareDisplayContent: React.Dispatch<React.SetStateAction<string>>;
}

function OnDemand({
  pageContent,
  isApproved,
  isDeclined,
  setSelectedEmployeeRequest,
  setErrorHandlerObj,
  setWelfareDisplayContent,
}: IProps) {
  // Functions, States and Variables
  const { searchedValue }: ISearchContextType | any = useContext(SearchContext);
  const allRequests: IAllRequests[] | [] = useAppSelector(
    (state) => state.requests.requests
  );
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  //   States
  const [allOnDemandRequests, setAllOnDemandRequests] = useState<
    IAllRequests[] | null
  >(null);
  const [currentOnDemandPosts, setCurrentOnDemandPosts] = useState<
    IAllRequests[] | null
  >(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;
  const [onDemandDateFilter, setOnDemandDateFilter] = useState("");
  const [onDemandStatusFilter, setOnDemandStatusFilter] = useState("");
  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //   Handle Call Filter Table By Status
  const handleCallFilterTableByStatus = function (statusFilter: string) {
    const requestType = "onDemand";
    // Handle Filter On Demand Table on change of search value
    handleFilterRequestsTableByStatus(
      statusFilter,
      requestType,
      allRequests,
      allOnDemandRequests,
      setAllOnDemandRequests,
      setCurrentOnDemandPosts,
      searchedValue,
      indexOfFirstPost,
      indexOfLastPost
    );
  };

  //   UseEffects
  useEffect(() => {
    dispatch(getAllCompanyRequests(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    console.log(allRequests);
    if (allRequests) {
      // Salary Advance
      const onDemandData = allRequests.filter((request) => {
        return request.type === "onDemand";
      });
      setAllOnDemandRequests(onDemandData);
    }
  }, [allRequests]);

  useEffect(() => {
    // For Salary Advance Requests
    if (allOnDemandRequests) {
      const currentRows = allOnDemandRequests?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setCurrentOnDemandPosts(currentRows);
    }
  }, [allOnDemandRequests, currentPage]);

  useEffect(() => {
    if (isApproved || isDeclined)
      dispatch(getAllCompanyRequests(setIsLoading, setErrorHandlerObj));
  }, [isApproved, isDeclined]);

  useEffect(() => {
    // Set Current Page to 1 to avoid empty screen on search match bug
    setCurrentPage(1);
    const requestType = "onDemand";
    // Handle Filter On Demand Table on change of search value
    handleFilterRequestsTableByStatus(
      onDemandStatusFilter || "All",
      requestType,
      allRequests,
      allOnDemandRequests,
      setAllOnDemandRequests,
      setCurrentOnDemandPosts,
      searchedValue,
      indexOfFirstPost,
      indexOfLastPost
    );
  }, [searchedValue]);

  return (
    <section
      className={`welfare-table-section ${
        pageContent === "on-demand" ? "" : "none"
      }`}
    >
      <div className="welfare-container--inner">
        {/* On Demand */}
        <div className="welfare-table-filter-option-wrapper">
          <div className="welfare-table-filter-option-wrapper">Filter:</div>
          {/* Filter by on demand request status dropdown */}
          <div className="form-group">
            <DropdownInput
              className={"on-demand-date-filter-dropdown-input"}
              id={"on-demand-date-filter-input"}
              label={""}
              required={true}
              optionArray={requestFilterDropdown}
              value={onDemandStatusFilter}
              onChange={(e) => {
                setOnDemandStatusFilter(e.target.value);
                handleCallFilterTableByStatus(e.target.value);
              }}
            />
          </div>
          {/* Filter by on demand request application date dropdown */}
          <div className="form-group">
            <DropdownInput
              className={"on-demand-date-filter-dropdown-input"}
              id={"on-demand-date-filter-input"}
              label={""}
              required={true}
              optionArray={[
                { key: "", value: "Date", default: "Default" },
                {
                  key: "yesterday",
                  value: "Yesterday",
                },
              ]}
              value={onDemandDateFilter}
              onChange={(e) => setOnDemandDateFilter(e.target.value)}
            />
          </div>
        </div>

        {/* On Demand Table */}
        <OnDemandTable
          isLoading={isLoading}
          allOnDemandRequests={allOnDemandRequests}
          currentOnDemandPosts={currentOnDemandPosts}
          setSelectedEmployeeRequest={setSelectedEmployeeRequest}
          setWelfareDisplayContent={setWelfareDisplayContent}
          paginate={paginate}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
        />
      </div>
    </section>
  );
}

export default OnDemand;
