import React from "react";
import "./App.css";
import "./styles/Table.css";
import "./styles/SecondaryModal.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Signin from "./pages/signin/Signin";
import EmployerSignup from "./pages/employer-signup/EmployerSignup";
import AccountAwaitingApproval from "./components/account-awaiting-approval/AccountAwaitingApproval";
import Employees from "./pages/employer-employees/Employees";
import EmployerDashboard from "./pages/employer-dashboard/EmployerDashboard";
import Onboarding from "./pages/employer-onboarding/Onboarding";
import Requests from "./pages/employer-requests/Requests";
import Settlements from "./pages/employer-settlements/Settlements";
import DashboardSetup from "./components/dashboard-comps/dashboard-setup/DashboardSetup";
import DashboardAwaiting from "./components/dashboard-comps/dashboard-awaiting/DashboardAwaiting";
import OnboardEmployees from "./components/onboard-employees/OnboardEmployees";
import SingleUpload from "./components/onboard-employees/single-upload/SingleUpload";
import BulkUpload from "./components/onboard-employees/bulk-upload/BulkUpload";
import OnboardSuccessful from "./components/onboard-employees/onboard-successful/OnboardSuccessful";
import AccountSettings from "./pages/settings/AccountSettings";
import GroupedEmployees from "./pages/employer-employees/grouped-employees/GroupedEmployees";
import { Provider } from "react-redux";
import store from "./store";
import SearchContextProvider from "./components/contexts/SearchContext";
import AccountVerification from "./components/account-verification/AccountVerification";
import AwaitingVerification from "./components/account-verification/awaiting-verification/AwaitingVerification";
import EmployerMou from "./pages/employer-mou/EmployerMou";
import Welfare from "./pages/employer-welfare/Welfare";
import Invoices from "./pages/employer-invoices/Invoices";
import CreditNewWallet from "./pages/employer-welfare/sub-pages/benefits/credit-new-wallet/CreditNewWallet";
import CreditAllEmployees from "./pages/employer-welfare/sub-pages/benefits/credit-all-employees/CreditAllEmployees";
import SendMessage from "./pages/employer-welfare/sub-pages/celebrations/send-message/SendMessage";
import CreditWallet from "./pages/employer-welfare/sub-pages/celebrations/credit-wallet/CreditWallet";
import ChooseSubscriptionPlan from "./pages/employer-subscription/choose-subscription-plan/ChooseSubscriptionPlan";
import SubscriptionDetails from "./pages/employer-subscription/subscription-details/SubscriptionDetails";
import EmployeeProfile from "./pages/employer-welfare/sub-pages/benefits/credit-new-wallet/employee-profile/EmployeeProfile";
import CreditSelectedEmployee from "./pages/employer-welfare/sub-pages/benefits/credit-new-wallet/credit-selected-employee/CreditSelectedEmployee";
import UserContextProvider from "./components/contexts/UserContext";
import ForgotPassword from "./pages/forgot-password/ForgotPassword";
import ResetPassword from "./pages/reset-password/ResetPassword";
import Wallet from "./pages/employer-wallet/Wallet";

function App() {
  return (
    <div className="app">
      <SearchContextProvider>
        <UserContextProvider>
          <Provider store={store}>
            <Router>
              <Routes>
                <Route path="/" element={<Signin />} />
                <Route path="/employer-mou" element={<EmployerMou />} />
                <Route path="/signin" element={<Signin />} />
                <Route path="/employer/signup" element={<EmployerSignup />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                {/* Account Awaiting Approval */}
                <Route
                  path="employer/awaiting-approval"
                  element={<AccountAwaitingApproval />}
                />
                {/* Account Awaiting Verification */}
                <Route
                  path="employer/verification"
                  element={<AwaitingVerification />}
                />

                {/* Verification Page */}
                <Route
                  path="/employer/verification/:token/:id"
                  element={<AccountVerification />}
                />
                {/* Dashboard */}
                <Route
                  path="employer/dashboard"
                  element={<EmployerDashboard />}
                />
                <Route
                  path="employer/dashboard/setup"
                  element={<DashboardSetup />}
                />
                <Route
                  path="employer/dashboard/setup-done"
                  element={<DashboardAwaiting />}
                />
                {/* Employees */}
                <Route path="employer/employees" element={<Employees />} />
                <Route
                  path="employer/employees/group"
                  element={<GroupedEmployees />}
                />
                <Route
                  path="employer/employees/onboard-employees"
                  element={<OnboardEmployees />}
                />
                <Route
                  path="employer/employees/onboard-employees/single"
                  element={<SingleUpload />}
                />
                <Route
                  path="employer/employees/onboard-employees/bulk"
                  element={<BulkUpload />}
                />
                <Route
                  path="employer/employees/onboard-successful"
                  element={<OnboardSuccessful />}
                />
                {/* Onboarding */}
                <Route path="employer/onboarding" element={<Onboarding />} />
                {/* Requests */}
                <Route path="employer/requests" element={<Requests />} />
                {/* Settlements */}
                <Route path="employer/settlements" element={<Settlements />} />
                {/* Settings */}
                <Route path="settings" element={<AccountSettings />} />
                {/* Invoices */}
                <Route path="employer/invoices" element={<Invoices />} />
                {/* New Pages */}
                {/* Welfare */}
                <Route path="employer/welfare" element={<Welfare />} />
                {/* Credit New Wallet -- Benefits */}
                <Route
                  path="employer/welfare/benefits/credit-new-wallet/employee-list"
                  element={<CreditNewWallet />}
                />
                <Route
                  path="employer/welfare/benefits/credit-new-wallet/employee-profile"
                  element={<EmployeeProfile />}
                />
                <Route
                  path="employer/welfare/benefits/credit-new-wallet"
                  element={<CreditSelectedEmployee />}
                />
                {/* Credit All Employees -- Benefits */}
                <Route
                  path="employer/welfare/benefits/credit-all-employees"
                  element={<CreditAllEmployees />}
                />
                {/* Send Message -- Celebrations */}
                <Route
                  path="employer/welfare/celebrations/send-messsage"
                  element={<SendMessage />}
                />
                {/* Credit Wallet -- Celebrations */}
                <Route
                  path="employer/welfare/celebrations/credit-wallet"
                  element={<CreditWallet />}
                />
                {/* Choose Susbscription Plan */}
                <Route
                  path="employer/choose-subscription-plan"
                  element={<ChooseSubscriptionPlan />}
                />
                {/* Subscription Details */}
                <Route
                  path="employer/subscription-details"
                  element={<SubscriptionDetails />}
                />
                {/* Subscription Details */}
                <Route path="employer/wallet" element={<Wallet />} />
              </Routes>
            </Router>
          </Provider>
        </UserContextProvider>
      </SearchContextProvider>
    </div>
  );
}

export default App;
