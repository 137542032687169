import { useEffect, useState } from "react";
import "./TransactionsHistoryTable.css";
import moment from "moment";
import { Table } from "react-bootstrap";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import isEmpty from "../../../../validation/isEmpty";
import BEPagination from "../../../../components/pagination/be-pagination/BEPagination";
import {
  IAPIPaginatedResponse,
  ITransactionsHistory,
  SetErrorHandlerType,
} from "../../../../Types";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  getAllCreditTransactions,
  getAllDebitTransactions,
  getAllTransactions,
} from "../../../../redux/actions/transactionsActions";
import { truncateString } from "../../../../utils/handleTruncateString";

const tableFilterOptions = [
  { key: "all", value: "All" },
  { key: "credit", value: "Credits" },
  { key: "debit", value: "Debits" },
];

// Interfaces
interface IProps {
  historyFilterOption: string;
  setHistoryFilterOption: React.Dispatch<React.SetStateAction<string>>;
  setErrorHandlerObj: SetErrorHandlerType;
}

function TransactionsHistoryTable({
  historyFilterOption,
  setHistoryFilterOption,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const transactionsHistory: IAPIPaginatedResponse<ITransactionsHistory[]> =
    useAppSelector((state) => state.transactions.transactions);
  // States
  const [transactionsHistoryPosts, setTransactionsHistoryPosts] = useState<
    ITransactionsHistory[] | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumberIndex, setPageNumberIndex] = useState(1);

  // Functions
  //   Filter History By Transactions History
  const handleFilterTransactionsHistory = function () {
    setTransactionsHistoryPosts(transactionsHistory?.data);
  };

  const handleFetchTransactionsHistory = function () {
    if (historyFilterOption === "all") {
      dispatch(
        getAllTransactions(pageNumberIndex, setIsLoading, setErrorHandlerObj)
      );
    } else if (historyFilterOption === "debit") {
      dispatch(
        getAllDebitTransactions(
          pageNumberIndex,
          setIsLoading,
          setErrorHandlerObj
        )
      );
    } else {
      dispatch(
        getAllCreditTransactions(
          pageNumberIndex,
          setIsLoading,
          setErrorHandlerObj
        )
      );
    }
  };
  //   UseEffects
  useEffect(() => {
    setPageNumberIndex(1);
    handleFetchTransactionsHistory();
  }, [historyFilterOption]);

  // useEffect(() => {
  // }, [pageNumberIndex]);

  useEffect(() => {
    handleFilterTransactionsHistory();
  }, [transactionsHistory]);

  return (
    <section className="transactions-history-section">
      {/* <div className="section-title">RECENT WALLET TRANSACTIONS</div> */}
      <h3>Transactions History</h3>

      <div className="secondary--table-filter-options-container">
        {tableFilterOptions?.map((filterObj, index) => (
          <div
            key={index + 1}
            className={`secondary--table-filter-option ${
              historyFilterOption === filterObj.key
                ? "secondary--active-filter-option"
                : ""
            }`}
            onClick={() => {
              setHistoryFilterOption(filterObj.key);
            }}
          >
            {filterObj.value}
          </div>
        ))}
      </div>
      <div className="primary-table-container">
        <Table responsive="lg" className="primary-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {["TYPE", "DESCRIPTION", "AMOUNT", "DATE"].map(
                (COLUMN, index) => (
                  <th key={index + 1}>{COLUMN}</th>
                )
              )}
            </tr>
          </thead>

          <tbody>
            {!isLoading &&
              transactionsHistoryPosts?.map((history, i) => (
                <tr key={i + 1}>
                  {/*  Type Column */}
                  <td className="td-type">
                    <div
                      className={`td-type--wrapper ${
                        history.transactionType === "credit"
                          ? "credit-type-wrapper"
                          : "debit-type-wrapper"
                      }`}
                    >
                      {history.transactionType === "credit"
                        ? "Credit"
                        : "Debit"}
                    </div>
                  </td>

                  {/* Description Column */}
                  <td className="td-action">
                    <div className={`td-action--wrapper`}>
                      {truncateString(history.description, 35)}
                    </div>
                  </td>

                  {/* Amount Column */}
                  <td className="td-amount">
                    <div className="td-amount--wrapper">
                      &#8358;{history.amount.toLocaleString()}
                    </div>
                  </td>

                  {/* Date Column*/}
                  <td className="td-date">
                    <div className="td-date--wrapper">
                      {moment(history.createdAt).format("Do MMM, YYYY")}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {!isLoading &&
        !isEmpty(transactionsHistoryPosts) &&
        !(isLoading && isEmpty(transactionsHistoryPosts)) && (
          <BEPagination
            currentPage={transactionsHistory?.currentPage || 1}
            lastPage={transactionsHistory.totalPages}
            currentPosts={transactionsHistoryPosts}
            setPageNumberIndex={setPageNumberIndex}
          />
        )}
    </section>
  );
}

export default TransactionsHistoryTable;
