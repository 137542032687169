import React, { useContext, useEffect, useState } from "react";
import "./DashboardAwaiting.css";
import whiteStarIcon from "../../../images/white-star.svg";
import arrowRight from "../../../images/arrow-long-right.svg";
import aboutOurTeamIcon from "../../../images/about-our-team.jpg";
import { useNavigate } from "react-router-dom";
import Navbar from "../../app-navbar/Navbar";
import Top from "../../app-top/Top";
import { UserContext } from "../../contexts/UserContext";
import isEmpty from "../../../validation/isEmpty";
import ErrorHandler from "../../error/ErrorHandler";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getCompanySubscriptionStatus } from "../../../redux/actions/userActions";
import { ICompanySubscriptionStatus } from "../../../Types";

function DashboardAwaiting() {
  // Functions, States and Variables
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user }: any = useContext(UserContext);
  const companySubscriptionStatus: ICompanySubscriptionStatus | null =
    useAppSelector((state) => state.user.subscriptionStatus);

  // States
  // Functions, States and Variables
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });

  // functions
  const handleNavigateToUploadEmployees = function () {
    // If Company has a current active subscription, either free trial or paid subscription, navigate to onboard
    if (
      companySubscriptionStatus?.isFreeTrail ||
      companySubscriptionStatus?.isActiveSubscription
    ) {
      navigate("/employer/employees/onboard-employees");
    } else {
      setErrorHandlerObj({
        hasError: true,
        message: "Kindly subscribe to a plan before onboarding employees!",
      });
    }
  };

  // UseEffects
  useEffect(() => {
    if (errorHandlerObj.hasError)
      window.scrollTo({ top: 0, behavior: "smooth" });
  }, [errorHandlerObj]);

  useEffect(() => {
    dispatch(getCompanySubscriptionStatus(setErrorHandlerObj));
  }, []);

  return (
    <div className="data-awaiting-page-wrapper">
      <Top />
      <Navbar />

      <div className="verification-message-wrapper">
        {/* Error handler component */}
        <ErrorHandler errorHandlerObj={errorHandlerObj} />
      </div>

      <section className="dashboard-awaiting-data-section">
        {/* Container Header  */}
        <div className="dashboard-awaiting-data-section-header">
          <div className="dashboard-awaiting-data-section-header-hero-img-wrapper">
            <img src={aboutOurTeamIcon} alt="" />
          </div>

          <div className="dashboard-awaiting-data-section-header-title">
            We know you are ready to supercharge your team with this Goody
          </div>

          <div className="dashboard-awaiting-data-section-header-sub-title">
            We are preparing your data 🚀
          </div>
        </div>

        {/* Dashboard Setup Onboarding Section Main Container */}
        <div className="dashboard-awaiting-data-section-main-container">
          {/* Go to Wrapper Container */}
          <div className="go-to-setup-links-wrapper-container">
            {/* Stock setup link wrapper */}
            {!isEmpty(companySubscriptionStatus) && (
              <div className="go-to-setup-link-wrapper go-to-stock-setup-wrapper">
                <button onClick={() => handleNavigateToUploadEmployees()}>
                  Upload/Onboard Employees
                  {/* Arrow Image Wrapper */}
                  <div className="arrow-img-wrapper">
                    <img src={arrowRight} alt="" />
                  </div>
                </button>

                {/* Star Image Icon */}
                <div className="star-image-wrapper">
                  <img src={whiteStarIcon} alt="" />
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default DashboardAwaiting;
