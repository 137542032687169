import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import {
  ISearchContextType,
  SearchContext,
} from "../../../components/contexts/SearchContext";
import DropdownInput from "../../../components/input-components/dropdown-input/DropdownInput";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import { numberWithCommas } from "../../../utils/numberWithCommas";
import isEmpty from "../../../validation/isEmpty";
import { IAllRequests, IMerchantFromEmployeeRequest } from "../Requests";
import "./BNPLTable.css";
import FEPagination from "../../../components/pagination/fe-pagination/FEPagination";

interface IProps {
  allBNPLRequests: IAllRequests[] | null;
  currentBNPLPosts: IAllRequests[] | null;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setSelectedEmployeeRequest: React.Dispatch<
    React.SetStateAction<IAllRequests | null>
  >;
  allRequests: IAllRequests[] | null;
  setAllBNPLRequests: React.Dispatch<
    React.SetStateAction<IAllRequests[] | null>
  >;
  setCurrentBNPLPosts: React.Dispatch<
    React.SetStateAction<IAllRequests[] | null>
  >;
  setMerchantFromEmployeeRequest: React.Dispatch<
    React.SetStateAction<IMerchantFromEmployeeRequest | null>
  >;
  currentPage: number;
  rowsPerPage: number;
  indexOfLastPost: number;
  indexOfFirstPost: number;
  isLoading: boolean;
}

// const dummyMerchant: IMerchantFromEmployeeRequest = {
//   _id: "11",
//   name: "Meghan Tech Stores",
//   imgURL:
//     "https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=",
//   ratings: 4,
//   category: "Phones & Accesories",
// };

const BNPLTable: React.FC<IProps> = function ({
  allBNPLRequests,
  currentBNPLPosts,
  setPageContent,
  currentPage,
  rowsPerPage,
  indexOfFirstPost,
  indexOfLastPost,
  setCurrentPage,
  setSelectedEmployeeRequest,
  allRequests,
  setCurrentBNPLPosts,
  setAllBNPLRequests,
  setMerchantFromEmployeeRequest,
  isLoading,
}) {
  // Functions and States
  const { searchedValue }: ISearchContextType | any = useContext(SearchContext);
  // States
  const [bnplDateFilter, setBnplDateFilter] = useState("");
  const [bnplStatusFilter, setBnplStatusFilter] = useState("");
  // Functions
  const paginateForBNPL = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleGetCurrentEmployee = function (
    id: string,
    employee: IAllRequests
  ) {
    // Set the selected employee request state
    setSelectedEmployeeRequest(employee);
  };

  //   handle Filter Table By Status
  const handleFilterTableByStatus = function (value: string) {
    if (!allRequests) return false;
    if (value === "All") {
      const bnplData = allBNPLRequests
        ?.filter((request) => {
          return request.type === "bnpl";
        })
        ?.filter(
          (request) =>
            request.staffId?.firstName
              .toLowerCase()
              .includes(searchedValue.toLowerCase()) ||
            request.staffId?.lastName
              .toLowerCase()
              .includes(searchedValue.toLowerCase())
        );
      // setAllBNPLRequests(bnplData);
      // Update table state to all BNPL Req rows
      if (!allBNPLRequests || !bnplData) return false;
      const currentRows = bnplData.slice(indexOfFirstPost, indexOfLastPost);

      setCurrentBNPLPosts(currentRows);
    } else if (value === "Awaiting Approval") {
      // Update the set BNPL req state to accomodate only awaiting approv.
      setAllBNPLRequests((prevRequests) => {
        if (!prevRequests || !allRequests) return prevRequests;

        let filteredRequests: IAllRequests[] | null;
        filteredRequests = allRequests
          .filter((request) => {
            return request.type === "bnpl" && request.status === 1;
          })
          ?.filter(
            (request) =>
              request.staffId?.firstName
                .toLowerCase()
                .includes(searchedValue.toLowerCase()) ||
              request.staffId?.lastName
                .toLowerCase()
                .includes(searchedValue.toLowerCase())
          );

        // Update table state to BNPL rows to Awaiting Approval only
        const currentRows = filteredRequests.slice(
          indexOfFirstPost,
          indexOfLastPost
        );
        setCurrentBNPLPosts(currentRows);

        return filteredRequests;
      });
    } else if (value === "Completed") {
      // Update the set BNPL req state to accomodate only Completed
      setAllBNPLRequests((prevRequests) => {
        if (!prevRequests || !allRequests) return prevRequests;

        let filteredRequests: IAllRequests[] | null;
        filteredRequests = allRequests
          .filter((request) => {
            return request.type === "bnpl" && request.status === 2;
          })
          ?.filter(
            (request) =>
              request.staffId?.firstName
                .toLowerCase()
                .includes(searchedValue.toLowerCase()) ||
              request.staffId?.lastName
                .toLowerCase()
                .includes(searchedValue.toLowerCase())
          );

        // Update table state to BNPL rows to Completed only
        const currentRows = filteredRequests.slice(
          indexOfFirstPost,
          indexOfLastPost
        );
        setCurrentBNPLPosts(currentRows);

        return filteredRequests;
      });
    } else if (value === "Completed by Credley") {
      // Update the set BNPL req state to accomodate only Approved by CREDLEY
      setAllBNPLRequests((prevRequests) => {
        if (!prevRequests || !allRequests) return prevRequests;

        let filteredRequests: IAllRequests[] | null;
        filteredRequests = allRequests
          .filter((request) => {
            return request.type === "bnpl" && request.status === 4;
          })
          ?.filter(
            (request) =>
              request.staffId?.firstName
                .toLowerCase()
                .includes(searchedValue.toLowerCase()) ||
              request.staffId?.lastName
                .toLowerCase()
                .includes(searchedValue.toLowerCase())
          );

        // Update table state to BNPL rows to Completed only
        const currentRows = filteredRequests.slice(
          indexOfFirstPost,
          indexOfLastPost
        );
        setCurrentBNPLPosts(currentRows);

        return filteredRequests;
      });
    } else if (value === "Rejected by Credley") {
      // Update the set BNPL req state to accomodate only Rejected by CREDLEY
      setAllBNPLRequests((prevRequests) => {
        if (!prevRequests || !allRequests) return prevRequests;

        let filteredRequests: IAllRequests[] | null;
        filteredRequests = allRequests
          .filter((request) => {
            return request.type === "bnpl" && request.status === 6;
          })
          ?.filter(
            (request) =>
              request.staffId?.firstName
                .toLowerCase()
                .includes(searchedValue.toLowerCase()) ||
              request.staffId?.lastName
                .toLowerCase()
                .includes(searchedValue.toLowerCase())
          );

        // Update table state to BNPL rows to Completed only
        const currentRows = filteredRequests.slice(
          indexOfFirstPost,
          indexOfLastPost
        );
        setCurrentBNPLPosts(currentRows);

        return filteredRequests;
      });
    } else if (value === "Rejected") {
      // Update the set salary advance req state to accomodate only Rejected by HR
      setAllBNPLRequests((prevRequests) => {
        if (!prevRequests || !allRequests) return prevRequests;

        let filteredRequests: IAllRequests[] | null;
        filteredRequests = allRequests
          .filter((request) => {
            return request.type === "bnpl" && request.status === 3;
          })
          ?.filter(
            (request) =>
              request.staffId?.firstName
                .toLowerCase()
                .includes(searchedValue.toLowerCase()) ||
              request.staffId?.lastName
                .toLowerCase()
                .includes(searchedValue.toLowerCase())
          );

        // Update table state to salary advance rows to Rejected by HR only
        const currentRows = filteredRequests.slice(
          indexOfFirstPost,
          indexOfLastPost
        );
        setCurrentBNPLPosts(currentRows);

        return filteredRequests;
      });
    } else if (value === "Disbursed") {
      // Update the set on demand req state to accomodate only Disbursed
      setAllBNPLRequests((prevRequests) => {
        if (!prevRequests || !allRequests) return prevRequests;

        let filteredRequests: IAllRequests[] | null;
        filteredRequests = allRequests
          .filter((request) => {
            return (
              request.type === "bnpl" &&
              (request.status === 7 || request.status === 8)
            );
          })
          ?.filter(
            (request) =>
              request.staffId?.firstName
                .toLowerCase()
                .includes(searchedValue.toLowerCase()) ||
              request.staffId?.lastName
                .toLowerCase()
                .includes(searchedValue.toLowerCase())
          );

        // Update table state to salary advance rows to Disbursed only
        const currentRows = filteredRequests.slice(
          indexOfFirstPost,
          indexOfLastPost
        );
        setCurrentBNPLPosts(currentRows);

        return filteredRequests;
      });
    }
  };

  // useEffect(() => {
  //   setMerchantFromEmployeeRequest(dummyMerchant);
  // }, [dummyMerchant]);

  useEffect(() => {
    handleFilterTableByStatus(bnplStatusFilter || "All");
  }, [searchedValue]);
  return (
    <div className="bnpl-table-section--inner">
      {" "}
      {/* Salary Advance */}
      <div className="onboarding-table-filter-option-wrapper bnpl-table-filter-option-wrapper">
        <div className="onboarding-table-filter-row-title">Filter:</div>
        {/* Filter by salary advance request status dropdown */}
        <div className="onboarding-date-filter-form-group form-group">
          <DropdownInput
            className={"salary-advance-date-filter-dropdown-input"}
            id={"salary-advance-date-filter-input"}
            label={""}
            required={true}
            optionArray={[
              { key: "", value: "Status", default: "Default" },
              {
                key: "All",
                value: "All",
              },
              {
                key: "Awaiting Approval",
                value: "Awaiting Approval",
              },
              {
                key: "Completed",
                value: "Completed by HR",
              },
              {
                key: "Rejected",
                value: "Rejected by HR",
              },
              {
                key: "Completed by Credley",
                value: "Completed by Credley",
              },
              {
                key: "Rejected by Credley",
                value: "Rejected by Credley",
              },
              {
                key: "Disbursed",
                value: "Disbursed",
              },
            ]}
            value={bnplStatusFilter}
            onChange={(e) => {
              setBnplStatusFilter(e.target.value);
              handleFilterTableByStatus(e.target.value);
            }}
          />
        </div>
        {/* Filter by BNPL request application date dropdown */}
        <div className="onboarding-date-filter-form-group form-group">
          <DropdownInput
            className={"bnpl-date-filter-dropdown-input"}
            id={"bnpl-date-filter-input"}
            label={""}
            required={true}
            optionArray={[
              { key: "", value: "Date", default: "Default" },
              {
                key: "yesterday",
                value: "Yesterday",
              },
            ]}
            value={bnplDateFilter}
            onChange={(e) => setBnplDateFilter(e.target.value)}
          />
        </div>
      </div>
      {/* All Salary Advance Request Table Container */}
      <div className="all-onboarding-table-container all-bnpl-request-table-container">
        <Table
          responsive="lg"
          className="all-onboarding-table all-bnpl-request-table"
        >
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "NAME",
                "EMAIL",
                "INITIAL PAYMENT",
                "TENURE",
                "REQUEST AMOUNT",
                "DATE",
                "STATUS",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentBNPLPosts?.map((employee, i) => (
              <tr
                key={i + 1}
                onClick={() => {
                  setPageContent("preview");
                  if (employee._id) {
                    handleGetCurrentEmployee(employee._id, employee);
                  }
                }}
              >
                {/* Employee Name Column */}
                <td className="td-employee-name">
                  <div className="td-employee-name--wrapper">
                    {employee.staffId?.firstName} {employee.staffId?.lastName}
                  </div>
                </td>

                {/*  Employee Email Column */}
                <td className="td-email">
                  <div className="td-email--wrapper">
                    {employee.staffId?.email}
                  </div>
                </td>

                {/*  Initial Payment Column */}
                <td className="td-salary">
                  <div className="td-salary--wrapper">
                    {/* FIX This Should be initial Payment for BNPL  */}
                    {/* &#8358;{numberWithCommas(employee.initialPayment)} */}
                    &#8358;{numberWithCommas(employee?.amount || "")}
                  </div>
                </td>

                {/* Tenure Column */}
                <td className="td-tenure">
                  <div className="td-tenure-wrapper">{employee?.tenure}</div>
                </td>

                {/*  Request amount Column */}
                <td className="td-request-amount">
                  <div className="td-request-amount--wrapper">
                    &#8358;{numberWithCommas(employee?.amount || "")}
                  </div>
                </td>

                {/* Date Column*/}
                <td className="td-date">
                  <div className="td-date--wrapper">
                    {moment(employee?.createdAt || "").format("Do MMM, YY")}
                  </div>
                </td>

                {/* Reequest Status Column*/}
                <td className="td-request-status ">
                  <div
                    className={`td-request-status--wrapper ${
                      employee.status === 8 || employee.status === 7
                        ? "request-completed-wrapper"
                        : employee.status === 6
                        ? "request-rejected-wrapper"
                        : employee.status === 5
                        ? "request-awaiting-approval-wrapper"
                        : employee.status === 4
                        ? "request-completed-wrapper"
                        : employee.status === 3
                        ? "request-rejected-wrapper"
                        : employee.status === 2
                        ? "request-completed-wrapper"
                        : "request-awaiting-approval-wrapper"
                    }`}
                  >
                    {employee.status === 8 || employee.status === 7
                      ? "Disbursed"
                      : employee.status === 6
                      ? "Rejected by Credley"
                      : employee.status === 5
                      ? " Awaiting Credly approval"
                      : employee.status === 4
                      ? "Approved by Credley"
                      : employee.status === 3
                      ? "Rejected by HR"
                      : employee.status === 2
                      ? "Approved by HR"
                      : "Awaiting HR approval"}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>
      {allBNPLRequests && !isEmpty(currentBNPLPosts) && (
        <FEPagination
          paginate={paginateForBNPL}
          rowsPerPage={rowsPerPage}
          totalPosts={allBNPLRequests.length}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export default BNPLTable;
