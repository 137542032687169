import React, { useContext, useEffect, useState } from "react";
import styles from "./WalletBalanceBox.module.css";
import PrimaryButton from "../../../../components/buttons/primary-button/PrimaryButton";
import { useAppDispatch } from "../../../../hooks";
import FundWalletModal from "./fund-wallet-modal/FundWalletModal";
import { SetErrorHandlerType, SetSuccessHandlerType } from "../../../../Types";
import { UserContext } from "../../../../components/contexts/UserContext";
import { usePaystackPayment } from "react-paystack";
import {
  fundWallet,
  getAllTransactions,
} from "../../../../redux/actions/transactionsActions";

// Interfaces
interface IProps {
  historyFilterOption: string;
  setHistoryFilterOption: React.Dispatch<React.SetStateAction<string>>;
  setErrorHandlerObj: SetErrorHandlerType;
  setSuccessHandlerObj: SetSuccessHandlerType;
}

function WalletBalanceBox({
  historyFilterOption,
  setHistoryFilterOption,
  setErrorHandlerObj,
  setSuccessHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const { user: userInfo, fetchUser }: any = useContext(UserContext);
  // Functions
  // States
  const [fundAmount, setFundAmount] = useState("");
  const [paymentReference, setPaymentReference] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFundWalletModalOpened, setIsFundWalletModalOpened] = useState(false);

  // Paystack Configuration and Initialization
  const config = {
    reference: paymentReference,
    email: userInfo?.email || "",
    amount: Number(fundAmount) * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY ?? "",
  };

  const initializePayment: any = usePaystackPayment(config);

  // Functions
  const handleGetWalletFundingAmount = function () {
    setPaymentReference("");
    setIsFundWalletModalOpened(true);
  };

  const handleTriggerFundWallet = function () {
    // Handle Clear Messages
    setErrorHandlerObj({ hasError: false, message: "" });
    setSuccessHandlerObj({ isSuccess: false, message: "" });

    const referenceData = new Date().getTime().toString();
    setPaymentReference(referenceData);
  };

  // Handles on Sucess of payment using paystack gateway (calls the credit all employees API)
  const onPaystackSuccess = () => {
    setSuccessHandlerObj({
      isSuccess: true,
      message: "Wallet fund has been initiated!",
    });
    // Close Modal and reset payment reference to default empty
    setIsFundWalletModalOpened(false);
    setPaymentReference("");

    // Handle refetches on paystack success
    handleOnWalletFundSuccess();
  };

  const handleOnWalletFundSuccess = function () {
    // Load up the refetches after 300ms
    setTimeout(() => {
      // Refetch user data to update wallet balance
      fetchUser();
      // Refetch transaction history and set transaction history filter to all
      if (historyFilterOption === "all")
        dispatch(getAllTransactions(1, setIsLoading, setErrorHandlerObj));
      setHistoryFilterOption("all");
      //
    }, 300);
  };

  // Handles Error or Close of Paystack gateway modal
  const onPaystackClose = function () {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    setErrorHandlerObj({ hasError: true, message: "Payment failed!" });
    setPaymentReference("");
    // Refetch user data to update wallet balance
    fetchUser();
  };

  const handleTriggerPaymentGateway = function () {
    //  Initialize payment through paystack gateway || Trigger Paystack API to completed payment
    initializePayment(onPaystackSuccess, onPaystackClose);
  };

  useEffect(() => {
    // Trigger Payment gateway after sending in the payment reference
    if (paymentReference) {
      const data = { reference: paymentReference };
      //  Call API to initiate wallet funding
      dispatch(
        fundWallet(
          data,
          setIsLoading,
          setErrorHandlerObj,
          handleTriggerPaymentGateway
        )
      );
    }
  }, [paymentReference]);

  return (
    <>
      {/* Modals */}
      <FundWalletModal
        isModalOpened={isFundWalletModalOpened}
        fundAmount={fundAmount}
        isLoading={isLoading}
        setFundAmount={setFundAmount}
        setIsModalOpened={setIsFundWalletModalOpened}
        handleTriggerFundWallet={handleTriggerFundWallet}
      />

      <section className={styles.wallet_balance_box_section}>
        {/* Wallet Balance container / Row one Left */}
        <div className={styles.wallet_balance_box_container}>
          {/* Top Wrapper */}
          <div className={styles.box__row_top_wrapper}>
            <div className={styles.box__row_title_wrapper}>WALLET BALANCE</div>

            {/* Fund Wallet button */}
            <PrimaryButton
              placeholder="Fund Wallet"
              onClick={() => handleGetWalletFundingAmount()}
              className={styles.make_withdrawal_button_wrapper}
            />
          </div>

          {/* Bottom Wrapper */}
          <div className={styles.box__row_bottom_wrapper}>
            {/* Wallet balancee value wrapper */}
            <div className={styles.wallet_balance_value_wrapper}>
              &#8358;
              {userInfo?.walletBalance?.toLocaleString() || 0}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WalletBalanceBox;
