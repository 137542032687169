export const celebrationsTypeDropdown = [
  { key: "", value: "Type", default: "Default" },
  {
    key: "All",
    value: "All",
  },
  {
    key: "Text",
    value: "Text",
  },
  {
    key: "Credit",
    value: "Credit",
  },
];
