import { IAllRequests } from "../Types";

// Interfaces
export type SetRequestsStateType = React.Dispatch<
  React.SetStateAction<IAllRequests[] | null>
>;
//   handle Filter Table By Status
export const handleFilterRequestsTableByStatus = function (
  filterValue: string,
  requestType: string,
  allRequests: IAllRequests[] | null,
  allCurrentRequests: IAllRequests[] | null, //e.g all salary advance or all ondemand requests
  setAllCurrentRequests: SetRequestsStateType,
  setCurrentRequestsPosts: SetRequestsStateType,
  searchedValue: string,
  indexOfFirstPost: number,
  indexOfLastPost: number
) {
  if (!allRequests) return false;
  if (filterValue === "All") {
    const salaryAdvanceData = allRequests
      ?.filter((request) => {
        return request.type === requestType;
      })
      ?.filter(
        (request) =>
          request.staffId?.firstName
            .toLowerCase()
            .includes(searchedValue.toLowerCase()) ||
          request.staffId?.lastName
            .toLowerCase()
            .includes(searchedValue.toLowerCase())
      );

    // Update table state to ALL REQUESTS for he filter section
    if (!allCurrentRequests || !salaryAdvanceData) return false;
    const currentRows = salaryAdvanceData.slice(
      indexOfFirstPost,
      indexOfLastPost
    );

    setCurrentRequestsPosts(currentRows);
  } else if (filterValue === "Awaiting Approval") {
    // Update the set current request state to accomodate only AWAITING APPROVAL.
    setAllCurrentRequests((prevRequests) => {
      if (!prevRequests || !allRequests) return prevRequests;

      let filteredRequests: IAllRequests[] | null;
      filteredRequests = allRequests
        .filter((request) => {
          return request.type === requestType && request.status === 1;
        })
        ?.filter(
          (request) =>
            request.staffId?.firstName
              .toLowerCase()
              .includes(searchedValue.toLowerCase()) ||
            request.staffId?.lastName
              .toLowerCase()
              .includes(searchedValue.toLowerCase())
        );

      // Update table state OF current request state rows to AWAITING APPROVAL only
      const currentRows = filteredRequests.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setCurrentRequestsPosts(currentRows);

      return filteredRequests;
    });
  } else if (filterValue === "Completed") {
    // Update the set current request state to accomodate only COMPLETED.
    setAllCurrentRequests((prevRequests) => {
      if (!prevRequests || !allRequests) return prevRequests;

      let filteredRequests: IAllRequests[] | null;
      filteredRequests = allRequests
        .filter((request) => {
          return request.type === requestType && request.status === 2;
        })
        ?.filter(
          (request) =>
            request.staffId?.firstName
              .toLowerCase()
              .includes(searchedValue.toLowerCase()) ||
            request.staffId?.lastName
              .toLowerCase()
              .includes(searchedValue.toLowerCase())
        );

      // Update table state OF current request state rows to COMPLETED only
      const currentRows = filteredRequests.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setCurrentRequestsPosts(currentRows);

      return filteredRequests;
    });
  } else if (filterValue === "Rejected") {
    // Update the set current request state to accomodate only REJECTED.
    setAllCurrentRequests((prevRequests) => {
      if (!prevRequests || !allRequests) return prevRequests;

      let filteredRequests: IAllRequests[] | null;
      filteredRequests = allRequests
        .filter((request) => {
          return request.type === requestType && request.status === 3;
        })
        ?.filter(
          (request) =>
            request.staffId?.firstName
              .toLowerCase()
              .includes(searchedValue.toLowerCase()) ||
            request.staffId?.lastName
              .toLowerCase()
              .includes(searchedValue.toLowerCase())
        );

      // Update table state OF current request state rows to REJECTED only
      const currentRows = filteredRequests.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setCurrentRequestsPosts(currentRows);

      return filteredRequests;
    });
  } else if (filterValue === "Completed by Credley") {
    // Update the set current request state to accomodate only COMPLETED BY CREDLEY.
    setAllCurrentRequests((prevRequests) => {
      if (!prevRequests || !allRequests) return prevRequests;

      let filteredRequests: IAllRequests[] | null;
      filteredRequests = allRequests
        .filter((request) => {
          return request.type === requestType && request.status === 4;
        })
        ?.filter(
          (request) =>
            request.staffId?.firstName
              .toLowerCase()
              .includes(searchedValue.toLowerCase()) ||
            request.staffId?.lastName
              .toLowerCase()
              .includes(searchedValue.toLowerCase())
        );

      // Update table state OF current request state rows to COMPLETED BY CREDLEY only
      const currentRows = filteredRequests.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setCurrentRequestsPosts(currentRows);

      return filteredRequests;
    });
  } else if (filterValue === "Rejected by Credley") {
    // Update the set current request state to accomodate only REJECTED BY CREDLEY.
    setAllCurrentRequests((prevRequests) => {
      if (!prevRequests || !allRequests) return prevRequests;

      let filteredRequests: IAllRequests[] | null;
      filteredRequests = allRequests
        .filter((request) => {
          return request.type === requestType && request.status === 6;
        })
        ?.filter(
          (request) =>
            request.staffId?.firstName
              .toLowerCase()
              .includes(searchedValue.toLowerCase()) ||
            request.staffId?.lastName
              .toLowerCase()
              .includes(searchedValue.toLowerCase())
        );

      // Update table state OF current request state rows to REJECTED BY CREDLEY only
      const currentRows = filteredRequests.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setCurrentRequestsPosts(currentRows);

      return filteredRequests;
    });
  } else if (filterValue === "Disbursed") {
    // Update the set current request state to accomodate only DISBURSED.
    setAllCurrentRequests((prevRequests) => {
      if (!prevRequests || !allRequests) return prevRequests;

      let filteredRequests: IAllRequests[] | null;
      filteredRequests = allRequests
        .filter((request) => {
          return (
            request.type === requestType &&
            (request.status === 7 || request.status === 8)
          );
        })
        ?.filter(
          (request) =>
            request.staffId?.firstName
              .toLowerCase()
              .includes(searchedValue.toLowerCase()) ||
            request.staffId?.lastName
              .toLowerCase()
              .includes(searchedValue.toLowerCase())
        );
      // Update table state OF current request state rows to DISBURSED only
      const currentRows = filteredRequests.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setCurrentRequestsPosts(currentRows);

      return filteredRequests;
    });
  }
};
