import React, { MouseEvent, useEffect, useState } from "react";
import "./EmployerSignup.css";
import { Link } from "react-router-dom";
import eyeOpenIcon from "../../images/eye-open-icon.svg";
import eyeCloseIcon from "../../images/eye-close-icon.svg";
import AuthNavbar from "../../components/auth-navbar/AuthNavbar";
import authWaveImage from "../../images/signin-wave-image.svg";
import TextInput from "../../components/input-components/text-input/TextInput";
import RegisterSuccessful from "../../components/register-successful-comp/RegisterSuccessful";
import ErrorHandler from "../../components/error/ErrorHandler";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import { useAppDispatch } from "../../hooks";
import { registerCompany } from "../../redux/actions/userActions";
import PhoneInput from "../../components/input-components/phone-input/PhoneInput";

function EmployerSignup() {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  // States
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] =
    useState(false);
  //
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const [isPasswordMatching, setIsPasswordMatching] = useState(true);
  const [registerSuccessful, setRegisterSuccessful] = useState(false);

  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message:
      "lorem ipsum dolir dolor, orem ipsum dolir dolo, orem ipsum dolir dolo",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: true,
    message: "Product successfully added!",
  });
  const [isLoading, setIsLoading] = useState(false);

  //   Functions
  // handle submit signup form
  const handleSubmitEmployerSignupDetails = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    setIsPasswordMatching(true);
    setErrorHandlerObj({ hasError: false, message: "" });

    if (
      email !== "" &&
      companyName !== "" &&
      phoneNumber !== "" &&
      password !== "" &&
      confirmPassword !== "" &&
      isTermsAndConditionsChecked
    ) {
      e.preventDefault();
      if (password !== confirmPassword) {
        e.preventDefault();
        setIsPasswordMatching(false);
      } else {
        const userData = {
          email,
          companyName,
          phoneNumber,
          password,
        };

        dispatch(
          registerCompany(
            userData,
            setErrorHandlerObj,
            setIsLoading,
            setRegisterSuccessful
          )
        );
      }
      setTimeout(() => {
        setIsPasswordMatching(true);
      }, 10000);
    }
  };

  useEffect(() => {
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess) {
      let xCoord = 0,
        yCoord = 0;

      //scroll to page top
      window.scrollTo(xCoord, yCoord);
    }
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
      console.log(errorHandlerObj);
    }
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });
    }
  }, [successHandlerObj]);

  return (
    <div className="employer-signup-container">
      <AuthNavbar />
      {/* Successful Register Form Submission Container */}
      <div
        className={`successful-form-submission-container ${
          !registerSuccessful && "none"
        }`}
      >
        <RegisterSuccessful />
      </div>

      <div
        className={`employer-signup-container--inner ${
          registerSuccessful && "none"
        }`}
      >
        {/* Employer Signup Left section  / Auth Left Section*/}
        <section className="auth-left-section employer-signup-left-section">
          {/* Employer Signup left section text section */}
          <div className="employer-signup-left-section-text-wrapper">
            Improve the quality of life of your employees
          </div>

          {/* Auth Left Image Wrapper */}
          <div className="left-auth-section-image-wrapper">
            <img src={authWaveImage} alt="" />
          </div>
        </section>

        {/* Employer Signup Right section  / Auth Right Section*/}
        <section className="auth-right-section employer-signup-right-section">
          <div className="auth-message-wrapper">
            {/* Error handler component */}
            <ErrorHandler errorHandlerObj={errorHandlerObj} />
          </div>
          <div className="employer-signup-right-section--inner">
            {/* Employer Signup right section header */}
            <div className="employer-signup-right-section-header">
              {/* Employer Signup right section header title */}
              <div className="employer-signup-right-section-header-title">
                Employer Sign up
              </div>

              <div className="employer-signup-right-section-header-sub-title">
                Already have an account,{" "}
                <span>
                  <Link to={"/signin"}>Sign in</Link>
                </span>
              </div>
            </div>

            {/* Employer Signup main form section */}
            <div className="employer-signup-main-form-section">
              <form>
                {/* Company Name form group */}
                <div className="employer-company-name-form-group form-group">
                  <TextInput
                    type={"text"}
                    id={"employer-company-name-text-input"}
                    className={"employer-company-name-input"}
                    placeholder={""}
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    required={true}
                    label={"Company Name"}
                  />
                </div>

                {/*  Email form group */}
                <div className="employer-email-form-group form-group">
                  <TextInput
                    type={"email"}
                    id={"employer-email-text-input"}
                    className={"employer-email-input"}
                    placeholder={""}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required={true}
                    label={"Email"}
                    subLabel={"Preferably Company Mail"}
                  />
                </div>

                {/* Phone Number form group */}
                <div className="employer-phone-number-form-group form-group">
                  <PhoneInput
                    type={"tel"}
                    id={"signup-employer-phone-number-text-input"}
                    className={"merchant-phone-number-input"}
                    placeholder={""}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required={true}
                    label={"Phone Number"}
                    maxLength={11}
                  />
                </div>

                {/* Password form group */}
                <div className="employer-signup-password-form-group form-group">
                  <img
                    src={isPasswordVisible ? eyeOpenIcon : eyeCloseIcon}
                    alt=""
                    className="password-icon-wrapper"
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  />

                  <label htmlFor={"employer-signup-password-input"}>
                    Password
                    <span>(Min 1 upper-cased, 1 number and 1 symbol)</span>
                  </label>
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    id={"employer-signup-password-input"}
                    className={"employer-signup-password-text-input"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required={true}
                    placeholder="At least 8 characters"
                  />
                </div>

                {/* Password form group */}
                <div className="employer-signup-confirm-password-form-group form-group">
                  <img
                    src={isConfirmPasswordVisible ? eyeOpenIcon : eyeCloseIcon}
                    alt=""
                    className="password-icon-wrapper"
                    onClick={() =>
                      setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
                    }
                  />

                  <label htmlFor={"employer-signup-confirm-password-input"}>
                    Retype Password
                  </label>
                  <input
                    type={isConfirmPasswordVisible ? "text" : "password"}
                    id={"employer-signup-confirm-password-input"}
                    className={"employer-signup-confirm-password-text-input"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required={true}
                  />
                </div>

                {/* Password not matching error message */}
                <div
                  className={`password-not-matching-error-message-wrapper ${
                    !isPasswordMatching ? "" : "none"
                  }`}
                >
                  Passwords do not match!
                </div>

                {/* Terms and Conditions Form Group */}
                <div className="terms-conditions-form-group">
                  <input
                    type="checkbox"
                    name="terms-conditions-checkbox"
                    checked={isTermsAndConditionsChecked}
                    onChange={(e) =>
                      setIsTermsAndConditionsChecked(
                        !isTermsAndConditionsChecked
                      )
                    }
                    id="terms-and-conditions-checkbox"
                    required
                  />
                  <label htmlFor="terms-and-conditions-checkbox">
                    I agree to the{" "}
                    <Link to="/employer-mou" target="_blank">
                      Terms and Conditions
                    </Link>{" "}
                    and{" "}
                    <a
                      href={`${process.env.REACT_APP_LANDING_URL}/privacy`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </label>
                </div>

                {/* Submit form button wrapper */}
                <div className="employer-signup-button-wrapper">
                  {isLoading && <LoadingSpinner />}
                  <button onClick={handleSubmitEmployerSignupDetails}>
                    Submit
                  </button>
                </div>

                {/* Not an employer wrapper wrapper  */}
                <div className="not-an-employer-wrapper">
                  <div className="not-an-employer-wrapper--inner">
                    <div className="row-one">
                      Not an Employer,
                      <span>
                        <a
                          href={`${process.env.REACT_APP_MERCHANT_URL}/merchant/signup`}
                        >
                          Sign up as Merchant
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default EmployerSignup;
