import React, { MouseEvent, useEffect, useState } from "react";
import "./SingleUpload.css";
import { useNavigate } from "react-router-dom";
import Top from "../../app-top/Top";
import angleLeftIcon from "../../../images/angle-left.svg";
import plusIcon from "../../../images/plus-icon.svg";
import TextInput from "../../input-components/text-input/TextInput";
import { getAllCompanyGroups } from "../../../redux/actions/groupActions";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import DropdownInput from "../../input-components/dropdown-input/DropdownInput";
import isEmpty from "../../../validation/isEmpty";
import { onboardManyStaff } from "../../../redux/actions/staffActions";
import LoadingSpinner from "../../loading-spinner/LoadingSpinner";
import ErrorHandler from "../../error/ErrorHandler";
import Sidebar from "../../app-sidebar/Sidebar";
import Main from "../../main-container/Main";

interface IAllEmployees {
  firstName: string;
  lastName: string;
  email: string;
  department: string;
  phoneNumber: string;
  role: string;
  salary: string;
  address: string;
  level: string;
  group: string;
  employeeView: string;
}

export interface ICompanyGroups {
  key: string;
  value: string;
}
function SingleUpload() {
  // Functions, States and Variables
  const allGroups = useAppSelector((state) => state.group.groups);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  //   States
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [role, setRole] = useState("");
  const [salary, setSalary] = useState("");
  const [address, setAddress] = useState("");
  const [level, setLevel] = useState("");
  const [group, setGroup] = useState("");

  const [companyGroupsArr, setCompanyGroupsArr] = useState<
    [] | ICompanyGroups[]
  >([]);
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });

  const [isGroupsLoading, setIsGroupsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [allEmployees, setAllEmployees] = useState<IAllEmployees[]>([
    {
      firstName: "",
      lastName: "",
      email: "",
      department: "",
      phoneNumber: "",
      role: "",
      salary: "",
      group: "",
      address: "",
      level: "",
      employeeView: "form-view",
    },
  ]);
  // Functions
  // handle Add New Employee
  const handleAddNewEmployee = function (e: MouseEvent<HTMLButtonElement>) {
    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      phoneNumber !== "" &&
      salary !== "" &&
      group !== "" &&
      address !== ""
      // department !== "" &&
      // role !== "" &&
      // level !== ""
    ) {
      e.preventDefault();

      setFirstName("");
      setLastName("");
      setEmail("");
      setDepartment("");
      setPhoneNumber("");
      setRole("");
      setSalary("");
      setGroup("");
      setAddress("");
      setLevel("");

      // Update and create a new employee form
      setAllEmployees((prevEmployees) => {
        if (!prevEmployees) return prevEmployees;

        let newEmployee = {
          firstName: "",
          lastName: "",
          email: "",
          department: "",
          phoneNumber: "",
          role: "",
          salary: "",
          group: "",
          address: "",
          level: "",
          employeeView: "form-view",
        };

        let newPrevEmployees = prevEmployees
          .map((employee) => {
            return {
              ...employee,
              employeeView: "preview-view",
            };
          })
          .concat(newEmployee);
        // console.log(newPrevEmployees, "all employees");
        return newPrevEmployees;
      });
    }
  };

  // handle Edit Current Employee
  const handleEditCurrentEmployee = function (
    e: MouseEvent<HTMLButtonElement>,
    currentEmployeeIndex: number,
    currentEmployee: IAllEmployees
  ) {
    const employeeObjectWithFormView = allEmployees?.find(
      (employeeObject) => employeeObject.employeeView === "form-view"
    );
    let isActiveEmployeeObjectEmpty = false;
    if (employeeObjectWithFormView) {
      isActiveEmployeeObjectEmpty = Object.keys(
        employeeObjectWithFormView
      )?.every((inputKeys) => {
        if (inputKeys === "employeeView") return true;
        if (
          employeeObjectWithFormView[
            inputKeys as keyof typeof employeeObjectWithFormView
          ] === ""
        )
          return true;
        return false;
      });
    }

    // Check if all the required form field has been filled
    let isEmployeeFormEmpty = false;
    allEmployees?.map((employeeObject) => {
      isEmployeeFormEmpty = Object.keys(employeeObject)?.some(
        (inputKeys) =>
          employeeObject[inputKeys as keyof typeof employeeObject] === "" &&
          inputKeys !== "department" &&
          inputKeys !== "role" &&
          inputKeys !== "level"
      );
    });

    if (isActiveEmployeeObjectEmpty) {
      // Check if the object with form view is empty, then clear the object and move on to edit selected object
      e.preventDefault();
      let filteredAllEmployees = allEmployees?.filter(
        (singleEmployee) =>
          singleEmployee.firstName === "" &&
          singleEmployee.lastName === "" &&
          singleEmployee.email === "" &&
          singleEmployee.phoneNumber === "" &&
          singleEmployee.salary === "" &&
          singleEmployee.group === "" &&
          singleEmployee.address === ""
      );
      handleChangeEmployeeViewToFormForEdit(
        filteredAllEmployees,
        currentEmployeeIndex,
        currentEmployee
      );
    } else if (!isEmployeeFormEmpty) {
      // If object with form view is not empty, trigger form validations until it is fully filled or empty
      e.preventDefault();
      handleChangeEmployeeViewToFormForEdit(
        allEmployees,
        currentEmployeeIndex,
        currentEmployee
      );
    }
  };

  const handleChangeEmployeeViewToFormForEdit = function (
    allEmployeesArr: IAllEmployees[],
    currentEmployeeIndex: number,
    currentEmployee: IAllEmployees
  ) {
    setAllEmployees((prevEmployees) => {
      if (!prevEmployees || !allEmployeesArr) return prevEmployees;

      let newPrevEmployees = allEmployeesArr.map((prevPresentEmployees) => {
        return {
          ...prevPresentEmployees,
          employeeView: "preview-view",
        };
      });

      let updatedEmployees = {
        ...currentEmployee,
        employeeView: "form-view",
      };

      setFirstName(currentEmployee.firstName);
      setLastName(currentEmployee.lastName);
      setEmail(currentEmployee.email);
      setDepartment(currentEmployee.department);
      setPhoneNumber(currentEmployee.phoneNumber);
      setRole(currentEmployee.role);
      setSalary(currentEmployee.salary);
      setGroup(currentEmployee.group);
      setAddress(currentEmployee.address);
      setLevel(currentEmployee.level);
      newPrevEmployees.splice(currentEmployeeIndex, 1, updatedEmployees);
      // console.log("first", newPrevEmployees);
      return newPrevEmployees;
    });
  };

  //   handle Submit Employee Form
  const handleSubmitEmployeeForm = function (e: MouseEvent<HTMLButtonElement>) {
    // Check if all the required form field has been filled
    let isFormEmpty = false;
    allEmployees?.map((employeeObject) => {
      isFormEmpty = Object.keys(employeeObject)?.some(
        (inputKeys) =>
          employeeObject[inputKeys as keyof typeof employeeObject] === "" &&
          inputKeys !== "department" &&
          inputKeys !== "role" &&
          inputKeys !== "level"
      );
    });

    if (!isFormEmpty) {
      e.preventDefault();
      const allEmplyeesArr = allEmployees
        ?.filter(
          (singleEmployee) =>
            singleEmployee.firstName !== "" &&
            singleEmployee.lastName !== "" &&
            singleEmployee.email !== "" &&
            singleEmployee.phoneNumber !== "" &&
            singleEmployee.salary !== "" &&
            singleEmployee.group !== "" &&
            singleEmployee.address !== ""
          // singleEmployee.department !== "" &&
          // singleEmployee.role !== "" &&
          // singleEmployee.level !== ""
        )
        ?.map((employee) => {
          return {
            firstName: employee.firstName,
            lastName: employee.lastName,
            email: employee.email,
            department: employee.department,
            phoneNumber: employee.phoneNumber,
            role: employee.role,
            salary: parseInt(employee.salary),
            group: employee.group,
            address: employee.address,
            level: employee.level,
          };
        });

      const data = { staff: allEmplyeesArr };
      // TODO Call the onboard employees API
      if (!isEmpty(allEmplyeesArr)) {
        dispatch(
          onboardManyStaff(data, setIsLoading, setErrorHandlerObj, navigate)
        );
      }
    }
  };

  useEffect(() => {
    dispatch(getAllCompanyGroups(setIsGroupsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    if (!isEmpty(allGroups)) {
      const groupsArr = allGroups?.groups?.map((group: any) => {
        return {
          key: group.name,
          value: group.name,
        };
      });

      groupsArr.unshift({ key: "", value: "", default: "default" });

      setCompanyGroupsArr(groupsArr);
    }
  }, [allGroups]);

  useEffect(() => {
    if (errorHandlerObj.hasError) {
      //scroll to page top
      window.scrollTo({ top: 0 });
    }
  }, [errorHandlerObj]);

  return (
    <div className="single-upload-container">
      <Top />
      <Sidebar activeName={"Employees"} />

      <Main>
        <div className="single-upload-container--inner">
          {/* Error and Success Handlers */}
          <div className="staff-onboarding-handler-wrapper">
            {/* Error handler component */}
            <ErrorHandler errorHandlerObj={errorHandlerObj} />
          </div>
          {/* Onboard Employees Top */}
          <div className="onboard-employees-top-container">
            {/* Back button Wrapper */}
            <div className="back-button-wrapper">
              <button onClick={() => navigate(-1)}>
                <img src={angleLeftIcon} alt="" />
              </button>
            </div>

            {/* Onboard Employees Title */}
            <div className="dashboard-setup-top-title">
              Onboard Staff Members
            </div>
          </div>

          <div className="single-upload-sub-header">Single Upload &gt;</div>

          {/* single-upload-container Main section */}
          <section className="single-upload-container-main-section">
            {/* Single Upload Form */}

            <form>
              {allEmployees?.map((employee, index) => (
                <div key={index + 1}>
                  <div
                    className={`single-employee-form-row single-employee-form-row-${
                      index + 1
                    } ${employee.employeeView !== "form-view" ? "none" : ""}`}
                  >
                    <div className="form-header-wrapper">
                      Employee {index + 1}
                    </div>

                    <div className="form-body-wrapper">
                      {/* First Name form group */}
                      <div className="form-group">
                        <TextInput
                          type={"text"}
                          id={`employee-${index + 1}-firstname-text-input`}
                          className={`employee-firstname-input`}
                          placeholder={""}
                          value={firstName}
                          onChange={(e) => {
                            setFirstName(e.target.value);

                            setAllEmployees((prevEmployees) => {
                              if (!prevEmployees) return prevEmployees;

                              const updatedEmployees = {
                                ...employee,
                                firstName: e.target.value,
                              };

                              prevEmployees.splice(index, 1, updatedEmployees);

                              // console.log(prevEmployees, "allEmployees");
                              return prevEmployees;
                            });
                          }}
                          required={true}
                          label={"First Name"}
                        />
                      </div>
                      {/* Last Name form group */}
                      <div className="form-group">
                        <TextInput
                          type={"text"}
                          id={`employee-${index + 1}-lastname-text-input`}
                          className={`employee-lastname-input`}
                          placeholder={""}
                          value={lastName}
                          onChange={(e) => {
                            setLastName(e.target.value);

                            setAllEmployees((prevEmployees) => {
                              if (!prevEmployees) return prevEmployees;

                              const updatedEmployees = {
                                ...employee,
                                lastName: e.target.value,
                              };

                              prevEmployees.splice(index, 1, updatedEmployees);

                              // console.log(prevEmployees, "allEmployees");
                              return prevEmployees;
                            });
                          }}
                          required={true}
                          label={"Last Name"}
                        />
                      </div>
                      {/* Email form group */}
                      <div className="form-group">
                        <TextInput
                          type={"email"}
                          id={`employee-${index + 1}-email-text-input`}
                          className={"employee-email-input"}
                          placeholder={""}
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);

                            setAllEmployees((prevEmployees) => {
                              if (!prevEmployees) return prevEmployees;

                              const updatedEmployees = {
                                ...employee,
                                email: e.target.value,
                              };

                              prevEmployees.splice(index, 1, updatedEmployees);

                              // console.log(prevEmployees, "allEmployees");
                              return prevEmployees;
                            });
                          }}
                          required={true}
                          label={"Email"}
                          subLabel={""}
                        />
                      </div>

                      {/* Phone Number form group */}
                      <div className="form-group">
                        <TextInput
                          type={"tel"}
                          id={`employee-${index + 1}-phone-number-text-input`}
                          className={"employee-phone-number-input"}
                          placeholder={""}
                          value={phoneNumber}
                          onChange={(e) => {
                            setPhoneNumber(e.target.value);

                            setAllEmployees((prevEmployees) => {
                              if (!prevEmployees) return prevEmployees;

                              const updatedEmployees = {
                                ...employee,
                                phoneNumber: e.target.value,
                              };

                              prevEmployees.splice(index, 1, updatedEmployees);

                              // console.log(prevEmployees, "allEmployees");
                              return prevEmployees;
                            });
                          }}
                          required={true}
                          label={"Phone Number"}
                        />
                      </div>

                      {/* Department form group */}
                      <div className="form-group">
                        <TextInput
                          type={"text"}
                          id={`employee-${index + 1}-department-text-input`}
                          className={`employee-department-input`}
                          placeholder={""}
                          value={department}
                          onChange={(e) => {
                            setDepartment(e.target.value);

                            setAllEmployees((prevEmployees) => {
                              if (!prevEmployees) return prevEmployees;

                              const updatedEmployees = {
                                ...employee,
                                department: e.target.value,
                              };

                              prevEmployees.splice(index, 1, updatedEmployees);

                              // console.log(prevEmployees, "allEmployees");
                              return prevEmployees;
                            });
                          }}
                          label={"Department"}
                          subLabel={"(optional)"}
                        />
                      </div>
                      {/* Role form group */}
                      <div className="form-group">
                        <TextInput
                          type={"text"}
                          id={`employee-${index + 1}-role-text-input`}
                          className={`employee-role-input`}
                          placeholder={""}
                          value={role}
                          onChange={(e) => {
                            setRole(e.target.value);

                            setAllEmployees((prevEmployees) => {
                              if (!prevEmployees) return prevEmployees;

                              const updatedEmployees = {
                                ...employee,
                                role: e.target.value,
                              };
                              prevEmployees.splice(index, 1, updatedEmployees);
                              return prevEmployees;
                            });
                          }}
                          label={"Role"}
                          subLabel={"(optional)"}
                        />
                      </div>
                      {/* Salary form group */}
                      <div className="form-group">
                        <TextInput
                          type={"number"}
                          id={`employee-${index + 1}-salary-text-input`}
                          className={"employee-salary-input"}
                          placeholder={""}
                          value={salary}
                          onChange={(e) => {
                            setSalary(e.target.value);

                            setAllEmployees((prevEmployees) => {
                              if (!prevEmployees) return prevEmployees;

                              const updatedEmployees = {
                                ...employee,
                                salary: e.target.value,
                              };

                              prevEmployees.splice(index, 1, updatedEmployees);

                              // console.log(prevEmployees, "allEmployees");
                              return prevEmployees;
                            });
                          }}
                          required={true}
                          label={"Salary (₦)"}
                        />
                      </div>
                      {/* Level form group */}
                      <div className="form-group">
                        <TextInput
                          type={"text"}
                          id={`employee-${index + 1}-level-text-input`}
                          className={`employee-level-input`}
                          placeholder={""}
                          value={level}
                          onChange={(e) => {
                            setLevel(e.target.value);

                            setAllEmployees((prevEmployees) => {
                              if (!prevEmployees) return prevEmployees;

                              const updatedEmployees = {
                                ...employee,
                                level: e.target.value,
                              };

                              prevEmployees.splice(index, 1, updatedEmployees);

                              // console.log(prevEmployees, "allEmployees");
                              return prevEmployees;
                            });
                          }}
                          label={"Level"}
                          subLabel={"(optional)"}
                        />
                      </div>

                      {/* Employee Group dropdown */}
                      <div className="group-name-form-group form-group">
                        <DropdownInput
                          className={"group-name-dropdown-input"}
                          id={"group-name-input"}
                          label={"Group"}
                          required={true}
                          optionArray={[...companyGroupsArr]}
                          value={group}
                          onChange={(e) => {
                            setGroup(e.target.value);

                            setAllEmployees((prevEmployees) => {
                              if (!prevEmployees) return prevEmployees;

                              const updatedEmployees = {
                                ...employee,
                                group: e.target.value,
                              };

                              prevEmployees.splice(index, 1, updatedEmployees);

                              // console.log(prevEmployees, "allEmployees");
                              return prevEmployees;
                            });
                          }}
                        />
                      </div>

                      {/* Address form group */}
                      <div className="employee-address-form-group form-group">
                        <label
                          htmlFor={`employee-${
                            index + 1
                          }-address-textarea-input`}
                        >
                          Address
                        </label>
                        <textarea
                          id={`employee-${1}-address-textarea-input`}
                          className={"employee-address-input"}
                          placeholder={""}
                          value={address}
                          onChange={(e) => {
                            setAddress(e.target.value);

                            setAllEmployees((prevEmployees) => {
                              if (!prevEmployees) return prevEmployees;

                              const updatedEmployees = {
                                ...employee,
                                address: e.target.value,
                              };

                              prevEmployees.splice(index, 1, updatedEmployees);

                              // console.log(prevEmployees, "allEmployees");
                              return prevEmployees;
                            });
                          }}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className={`single-employee-preview-row single-employee-preview-row-${
                      index + 1
                    } ${employee.employeeView === "form-view" ? "none" : ""}`}
                  >
                    {/* Top Section */}
                    <div className="single-employee-preview-row--top-section">
                      <div className="single-employee-preview-title-wrapper">
                        Employee {index + 1}
                      </div>

                      <div className="single-employee-preview--edit-employee-btn-wrapper">
                        <button
                          onClick={(e) =>
                            handleEditCurrentEmployee(e, index, employee)
                          }
                        >
                          edit
                        </button>
                      </div>
                    </div>

                    {/* Main Section */}
                    <div className="single-employee-preview-row--main-section">
                      {/* First Name */}
                      <div className="single-employee-preview--info">
                        <div className="employee-info-title-wrapper">
                          First name
                        </div>
                        <div className="employee-info-value-wrapper">
                          {employee.firstName}
                        </div>
                      </div>
                      {/* Last Name */}
                      <div className="single-employee-preview--info">
                        <div className="employee-info-title-wrapper">
                          Last name
                        </div>
                        <div className="employee-info-value-wrapper">
                          {employee.lastName}
                        </div>
                      </div>
                      {/* Email */}
                      <div className="single-employee-preview--info">
                        <div className="employee-info-title-wrapper">Email</div>
                        <div className="employee-info-value-wrapper">
                          {employee.email}
                        </div>
                      </div>
                      {/* Department */}
                      <div className="single-employee-preview--info">
                        <div className="employee-info-title-wrapper">
                          Department
                        </div>
                        <div className="employee-info-value-wrapper">
                          {employee.department}
                        </div>
                      </div>
                      {/* Phone Number */}
                      <div className="single-employee-preview--info">
                        <div className="employee-info-title-wrapper">
                          Phone Number
                        </div>
                        <div className="employee-info-value-wrapper">
                          {employee.phoneNumber}
                        </div>
                      </div>
                      {/* Role*/}
                      <div className="single-employee-preview--info">
                        <div className="employee-info-title-wrapper">Role</div>
                        <div className="employee-info-value-wrapper">
                          {employee.role}
                        </div>
                      </div>
                      {/* Salary */}
                      <div className="single-employee-preview--info">
                        <div className="employee-info-title-wrapper">
                          Salary
                        </div>
                        <div className="employee-info-value-wrapper">
                          N{Number(employee.salary)?.toLocaleString()}
                        </div>
                      </div>
                      {/* Group */}
                      <div className="single-employee-preview--info">
                        <div className="employee-info-title-wrapper">Group</div>
                        <div className="employee-info-value-wrapper">
                          {employee.group}
                        </div>
                      </div>
                      {/* Address */}
                      <div className="single-employee-preview--info">
                        <div className="employee-info-title-wrapper">
                          Address
                        </div>
                        <div className="employee-info-value-wrapper">
                          {employee.address}
                        </div>
                      </div>
                      {/* Level */}
                      <div className="single-employee-preview--info">
                        <div className="employee-info-title-wrapper">Level</div>
                        <div className="employee-info-value-wrapper">
                          {employee.level}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <div className="add-new-ranges-button-wrapper add-new-employee-plus-btn-wrapper">
                <button onClick={(e) => handleAddNewEmployee(e)}>
                  <img src={plusIcon} alt="" /> Add New Employee
                </button>
              </div>

              {/* Submit form button */}
              <div className="submit-setup-form-button-wrapper">
                {isLoading && <LoadingSpinner />}
                <button onClick={handleSubmitEmployeeForm}>Submit</button>
              </div>
            </form>
          </section>
        </div>
      </Main>
    </div>
  );
}

export default SingleUpload;
