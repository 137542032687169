import React from "react";
import cancelIcon from "../../../../images/cancel-secondary.svg";
import angleRightIcon from "../../../../images/angle-right-secondary.svg";

// Interfaces
interface IProps {
  isPaymentOptionModalOpened: boolean;
  setIsPaymentOptionModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  amount: number;
  handleInitializePaymentWithPaystack: () => void;
  handleInitializePaymentWithWallet?: () => void;
}

function PaymentMethodModal({
  isPaymentOptionModalOpened,
  setIsPaymentOptionModalOpened,
  handleInitializePaymentWithPaystack,
  amount,
  handleInitializePaymentWithWallet,
}: IProps) {
  // Functions, States and Variables
  return (
    <div
      className={`secondary-modal-container payment-method-modal-container ${
        isPaymentOptionModalOpened ? "" : "none"
      }`}
    >
      <div className="secondary-modal-container--inner">
        {/* Top Wrapper */}
        <div className="secondary-modal--top-wrapper">
          {/* Title */}
          <div className="secondary-modal--title">Choose Payment Method</div>
          {/* Cancel Button */}
          <div className="secondary-modal--cancel-btn-wrapper">
            <button
              onClick={() => {
                setIsPaymentOptionModalOpened(false);
              }}
            >
              <img src={cancelIcon} alt="" />
            </button>
          </div>
        </div>

        {/* Bottom Layer */}
        <div className="secondary-modal--bottom-wrapper">
          <div className="secondary-modal--sub-title">
            Amount to pay: ₦{amount.toLocaleString()}
          </div>

          {/* Modal Options */}
          <div className="modal-options-container">
            {/* Wallet Option */}
            {handleInitializePaymentWithWallet && (
              <div className="modal-option-wrapper">
                <button
                  onClick={() => {
                    // Initialze Payment With Wallet
                    handleInitializePaymentWithWallet();
                  }}
                >
                  {/* Left Wrapper */}
                  <div className="modal-option--left-wrapper">
                    <div className="modal-option--texts-wrapper">
                      <div className="modal-option--main-text">Wallet</div>
                    </div>
                  </div>

                  {/* Right Wrapper */}
                  <div className="modal-option--right-wrapper">
                    <div className="modal-option--angle-right-icon">
                      <img src={angleRightIcon} alt="" />
                    </div>
                  </div>
                </button>
              </div>
            )}

            {/* Paystack Option */}
            <div className="modal-option-wrapper">
              <button
                onClick={() => {
                  // Initialze Payment With Paystack
                  handleInitializePaymentWithPaystack();
                }}
              >
                {/* Left Wrapper */}
                <div className="modal-option--left-wrapper">
                  <div className="modal-option--texts-wrapper">
                    <div className="modal-option--main-text">Paystack</div>
                  </div>
                </div>

                {/* Right Wrapper */}
                <div className="modal-option--right-wrapper">
                  <div className="modal-option--angle-right-icon">
                    <img src={angleRightIcon} alt="" />
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentMethodModal;
