import React, { useContext, useEffect, useState } from "react";
import "./OnDemandTable.css";
import { Table } from "react-bootstrap";
import DropdownInput from "../../../components/input-components/dropdown-input/DropdownInput";
import { numberWithCommas } from "../../../utils/numberWithCommas";
import { IAllRequests } from "../Requests";
import moment from "moment";
import isEmpty from "../../../validation/isEmpty";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import {
  ISearchContextType,
  SearchContext,
} from "../../../components/contexts/SearchContext";
import FEPagination from "../../../components/pagination/fe-pagination/FEPagination";

interface IProps {
  allOnDemandRequests: IAllRequests[] | null;
  currentOnDemandPosts: IAllRequests[] | null;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setSelectedEmployeeRequest: React.Dispatch<
    React.SetStateAction<IAllRequests | null>
  >;
  setCurrentOnDemandPosts: (
    value: React.SetStateAction<IAllRequests[] | null>
  ) => void;
  allRequests: IAllRequests[] | null;
  setAllOnDemandRequests: React.Dispatch<
    React.SetStateAction<IAllRequests[] | null>
  >;
  currentPage: number;
  rowsPerPage: number;
  indexOfLastPost: number;
  indexOfFirstPost: number;
  isLoading: boolean;
}

const OnDemandTable: React.FC<IProps> = function ({
  allOnDemandRequests,
  currentOnDemandPosts,
  setPageContent,
  setCurrentPage,
  setSelectedEmployeeRequest,
  setCurrentOnDemandPosts,
  allRequests,
  setAllOnDemandRequests,
  currentPage,
  rowsPerPage,
  indexOfFirstPost,
  indexOfLastPost,
  isLoading,
}) {
  // Functions and States
  const { searchedValue }: ISearchContextType | any = useContext(SearchContext);
  // States
  const [onDemandDateFilter, setOnDemandDateFilter] = useState("");
  const [onDemandStatusFilter, setOnDemandStatusFilter] = useState("");
  // Functions
  const paginateForOnDemand = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleGetCurrentEmployee = function (
    id: string,
    employee: IAllRequests
  ) {
    // Set the selected employee request state
    setSelectedEmployeeRequest(employee);
  };

  //   handle Filter Table By Status
  const handleFilterTableByStatus = function (value: string) {
    if (!allRequests) return false;
    if (value === "All") {
      const onDemandData = allRequests
        ?.filter((request) => {
          return request.type === "onDemand";
        })
        ?.filter(
          (request) =>
            request.staffId?.firstName
              .toLowerCase()
              .includes(searchedValue.toLowerCase()) ||
            request.staffId?.lastName
              .toLowerCase()
              .includes(searchedValue.toLowerCase())
        );

      // Update table state to salary advance rows
      if (!allOnDemandRequests || !onDemandData) return false;
      const currentRows = onDemandData.slice(indexOfFirstPost, indexOfLastPost);

      setCurrentOnDemandPosts(currentRows);
    } else if (value === "Awaiting Approval") {
      // Update the set on demand req state to accomodate only awaiting approv.
      setAllOnDemandRequests((prevRequests) => {
        if (!prevRequests || !allRequests) return prevRequests;

        let filteredRequests: IAllRequests[] | null;
        filteredRequests = allRequests
          .filter((request) => {
            return request.type === "onDemand" && request.status === 1;
          })
          ?.filter(
            (request) =>
              request.staffId?.firstName
                .toLowerCase()
                .includes(searchedValue.toLowerCase()) ||
              request.staffId?.lastName
                .toLowerCase()
                .includes(searchedValue.toLowerCase())
          );

        // Update table state to salary advance rows to Awaiting Approval only
        const currentRows = filteredRequests.slice(
          indexOfFirstPost,
          indexOfLastPost
        );
        setCurrentOnDemandPosts(currentRows);

        return filteredRequests;
      });
    } else if (value === "Completed") {
      // Update the set on demand req state to accomodate only Completed
      setAllOnDemandRequests((prevRequests) => {
        if (!prevRequests || !allRequests) return prevRequests;

        let filteredRequests: IAllRequests[] | null;
        filteredRequests = allRequests
          .filter((request) => {
            return request.type === "onDemand" && request.status === 2;
          })
          ?.filter(
            (request) =>
              request.staffId?.firstName
                .toLowerCase()
                .includes(searchedValue.toLowerCase()) ||
              request.staffId?.lastName
                .toLowerCase()
                .includes(searchedValue.toLowerCase())
          );

        // Update table state to on demand rows to Completed only
        const currentRows = filteredRequests.slice(
          indexOfFirstPost,
          indexOfLastPost
        );
        setCurrentOnDemandPosts(currentRows);

        return filteredRequests;
      });
    } else if (value === "Completed by Credley") {
      // Update the set on demand req state to accomodate only Completed by CREDLEY
      setAllOnDemandRequests((prevRequests) => {
        if (!prevRequests || !allRequests) return prevRequests;

        let filteredRequests: IAllRequests[] | null;
        filteredRequests = allRequests
          .filter((request) => {
            return request.type === "onDemand" && request.status === 4;
          })
          ?.filter(
            (request) =>
              request.staffId?.firstName
                .toLowerCase()
                .includes(searchedValue.toLowerCase()) ||
              request.staffId?.lastName
                .toLowerCase()
                .includes(searchedValue.toLowerCase())
          );

        // Update table state to on demand rows to Completed only
        const currentRows = filteredRequests.slice(
          indexOfFirstPost,
          indexOfLastPost
        );
        setCurrentOnDemandPosts(currentRows);

        return filteredRequests;
      });
    } else if (value === "Rejected by Credley") {
      // Update the set on demand req state to accomodate only Rejected By CREDLEY
      setAllOnDemandRequests((prevRequests) => {
        if (!prevRequests || !allRequests) return prevRequests;

        let filteredRequests: IAllRequests[] | null;
        filteredRequests = allRequests
          .filter((request) => {
            return request.type === "onDemand" && request.status === 6;
          })
          ?.filter(
            (request) =>
              request.staffId?.firstName
                .toLowerCase()
                .includes(searchedValue.toLowerCase()) ||
              request.staffId?.lastName
                .toLowerCase()
                .includes(searchedValue.toLowerCase())
          );

        // Update table state to on demand rows to Completed only
        const currentRows = filteredRequests.slice(
          indexOfFirstPost,
          indexOfLastPost
        );
        setCurrentOnDemandPosts(currentRows);

        return filteredRequests;
      });
    } else if (value === "Rejected") {
      // Update the set on demand req state to accomodate only Rejected by HR
      setAllOnDemandRequests((prevRequests) => {
        if (!prevRequests || !allRequests) return prevRequests;

        let filteredRequests: IAllRequests[] | null;
        filteredRequests = allRequests
          .filter((request) => {
            return request.type === "onDemand" && request.status === 3;
          })
          ?.filter(
            (request) =>
              request.staffId?.firstName
                .toLowerCase()
                .includes(searchedValue.toLowerCase()) ||
              request.staffId?.lastName
                .toLowerCase()
                .includes(searchedValue.toLowerCase())
          );

        // Update table state to salary advance rows to Rejected by HR only
        const currentRows = filteredRequests.slice(
          indexOfFirstPost,
          indexOfLastPost
        );
        setCurrentOnDemandPosts(currentRows);

        return filteredRequests;
      });
    } else if (value === "Disbursed") {
      // Update the set on demand req state to accomodate only Disbursed
      setAllOnDemandRequests((prevRequests) => {
        if (!prevRequests || !allRequests) return prevRequests;

        let filteredRequests: IAllRequests[] | null;
        filteredRequests = allRequests
          .filter((request) => {
            return (
              request.type === "onDemand" &&
              (request.status === 7 || request.status === 8)
            );
          })
          ?.filter(
            (request) =>
              request.staffId?.firstName
                .toLowerCase()
                .includes(searchedValue.toLowerCase()) ||
              request.staffId?.lastName
                .toLowerCase()
                .includes(searchedValue.toLowerCase())
          );

        // Update table state to salary advance rows to Disbursed only
        const currentRows = filteredRequests.slice(
          indexOfFirstPost,
          indexOfLastPost
        );
        setCurrentOnDemandPosts(currentRows);

        return filteredRequests;
      });
    }
  };

  useEffect(() => {
    handleFilterTableByStatus(onDemandStatusFilter || "All");
  }, [searchedValue]);
  return (
    <div className="on-demand-table-section--inner">
      {/* Salary Advance */}
      <div className="onboarding-table-filter-option-wrapper salary-advance-table-filter-option-wrapper">
        <div className="onboarding-table-filter-row-title">Filter:</div>
        {/* Filter by salary advance request status dropdown */}
        <div className="onboarding-date-filter-form-group form-group">
          <DropdownInput
            className={"on-demand-date-filter-dropdown-input"}
            id={"on-demand-date-filter-input"}
            label={""}
            required={true}
            optionArray={[
              { key: "", value: "Status", default: "Default" },
              {
                key: "All",
                value: "All",
              },
              {
                key: "Awaiting Approval",
                value: "Awaiting Approval",
              },
              {
                key: "Completed",
                value: "Completed by HR",
              },
              {
                key: "Rejected",
                value: "Rejected by HR",
              },
              {
                key: "Completed by Credley",
                value: "Completed by Credley",
              },
              {
                key: "Rejected by Credley",
                value: "Rejected by Credley",
              },
              {
                key: "Disbursed",
                value: "Disbursed",
              },
            ]}
            value={onDemandStatusFilter}
            onChange={(e) => {
              setOnDemandStatusFilter(e.target.value);
              handleFilterTableByStatus(e.target.value);
            }}
          />
        </div>
        {/* Filter by on demand request application date dropdown */}
        <div className="onboarding-date-filter-form-group form-group">
          <DropdownInput
            className={"on-demand-date-filter-dropdown-input"}
            id={"on-demand-date-filter-input"}
            label={""}
            required={true}
            optionArray={[
              { key: "", value: "Date", default: "Default" },
              {
                key: "yesterday",
                value: "Yesterday",
              },
            ]}
            value={onDemandDateFilter}
            onChange={(e) => setOnDemandDateFilter(e.target.value)}
          />
        </div>
      </div>

      {/* All On Demand Request Table Container */}
      <div className="all-onboarding-table-container all-on-demand-request-table-container">
        <Table
          responsive="lg"
          className="all-onboarding-table all-on-demand-request-table"
        >
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "NAME",
                "EMAIL",
                "PHONE NUMBER",
                "SALARY (N)",
                "REQUEST AMOUNT",
                "DATE",
                "STATUS",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentOnDemandPosts?.map((employee, i) => (
              <tr
                key={i + 1}
                onClick={() => {
                  setPageContent("preview");
                  if (employee._id) {
                    handleGetCurrentEmployee(employee._id, employee);
                  }
                }}
              >
                {/* Employee Name Column */}
                <td className="td-employee-name">
                  <div className="td-employee-name--wrapper">
                    {employee.staffId?.firstName} {employee.staffId?.lastName}
                  </div>
                </td>

                {/*  Employee Email Column */}
                <td className="td-email">
                  <div className="td-email--wrapper">
                    {employee.staffId?.email}
                  </div>
                </td>

                {/* Phone Column */}
                <td className="td-phone-no">
                  <div className="td-phone-no--wrapper">
                    {employee.staffId?.phoneNumber}
                  </div>
                </td>

                {/*  Salary Column */}
                <td className="td-salary">
                  <div className="td-salary--wrapper">
                    &#8358;{numberWithCommas(employee.staffId?.salary || "")}
                  </div>
                </td>

                {/*  Request amount Column */}
                <td className="td-request-amount">
                  <div className="td-request-amount--wrapper">
                    &#8358;{numberWithCommas(employee?.amount || "")}
                  </div>
                </td>

                {/* Date Column*/}
                <td className="td-date">
                  <div className="td-date--wrapper">
                    {moment(employee?.createdAt || "").format("Do MMM, YY")}
                  </div>
                </td>

                {/* Reequest Status Column*/}
                <td className="td-request-status ">
                  <div
                    className={`td-request-status--wrapper ${
                      employee.status === 8 || employee.status === 7
                        ? "request-completed-wrapper"
                        : employee.status === 6
                        ? "request-rejected-wrapper"
                        : employee.status === 5
                        ? "request-awaiting-approval-wrapper"
                        : employee.status === 4
                        ? "request-completed-wrapper"
                        : employee.status === 3
                        ? "request-rejected-wrapper"
                        : employee.status === 2
                        ? "request-completed-wrapper"
                        : "request-awaiting-approval-wrapper"
                    }`}
                  >
                    {employee.status === 8 || employee.status === 7
                      ? "Disbursed"
                      : employee.status === 6
                      ? "Rejected by Credley"
                      : employee.status === 5
                      ? " Awaiting Credly approval"
                      : employee.status === 4
                      ? "Approved by Credley"
                      : employee.status === 3
                      ? "Rejected by HR"
                      : employee.status === 2
                      ? "Approved by HR"
                      : "Awaiting HR approval"}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {allOnDemandRequests && !isEmpty(currentOnDemandPosts) && (
        <FEPagination
          paginate={paginateForOnDemand}
          rowsPerPage={rowsPerPage}
          totalPosts={allOnDemandRequests.length}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export default OnDemandTable;
