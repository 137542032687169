import React from "react";
import moment from "moment";
import { Table } from "react-bootstrap";
import LoadingSpinner from "../../../../../components/loading-spinner/LoadingSpinner";
import {
  IAPIPaginatedResponse,
  ISingleCelebration,
} from "../../../../../Types";
import isEmpty from "../../../../../validation/isEmpty";
import BEPagination from "../../../../../components/pagination/be-pagination/BEPagination";

// Interfaces
interface IProps {
  isLoading: boolean;
  celebrations: IAPIPaginatedResponse<ISingleCelebration[]> | null;
  currentCelebrationsPosts: ISingleCelebration[] | null;
  setPageNumberIndex: React.Dispatch<React.SetStateAction<number>>;
}

function CelebrationsTable({
  isLoading,
  celebrations,
  currentCelebrationsPosts,
  setPageNumberIndex,
}: IProps) {
  // Functions, States and Variables

  //   Functions
  return (
    <>
      <div className="primary-table-container">
        <h3>All Staff Celebrations</h3>
        <Table
          responsive="lg"
          className="primary-table all-celebrations-request-table"
        >
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "NAME",
                "EMAIL",
                // "WALLET BALANCE",
                "TYPE",
                "AMOUNT SENT",
                "DATE SENT",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentCelebrationsPosts?.map((celebration, i) => (
              <tr key={i + 1}>
                {/* Employee Name Column */}
                <td className="td-employee-name">
                  <div className="td-employee-name--wrapper">
                    {celebration.staffId?.firstName}{" "}
                    {celebration.staffId?.lastName}
                  </div>
                </td>

                {/*  Employee Email Column */}
                <td className="td-email">
                  <div className="td-email--wrapper">
                    {celebration.staffId?.email}
                  </div>
                </td>

                {/* Wallet Column */}
                {/* <td className="td-wallet-balance">
                  <div className="td-wallet-balance--wrapper">
                    ₦{celebration.staffId?.staff?.wallet?.toLocaleString()}
                  </div>
                </td> */}

                {/*  Type Column */}
                <td className="td-type">
                  <div className="td-type--wrapper">
                    {celebration.type === "text" ? "Text" : "Credit"}
                  </div>
                </td>

                {/*  Amount Sent Column */}
                <td className="td-amount-sent">
                  <div className="td-amount-sent--wrapper">
                    {celebration.type === "credit"
                      ? "₦" + celebration?.amount?.toLocaleString() || ""
                      : ""}
                  </div>
                </td>

                {/* Date Column*/}
                <td className="td-date">
                  <div className="td-date--wrapper">
                    {moment(celebration?.createdAt || "").format("Do MMM, YY")}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {!isEmpty(currentCelebrationsPosts) &&
        !(isLoading && isEmpty(currentCelebrationsPosts)) && (
          <BEPagination
            currentPage={celebrations?.currentPage || 1}
            lastPage={celebrations?.totalPages || 1}
            currentPosts={currentCelebrationsPosts}
            setPageNumberIndex={setPageNumberIndex}
          />
        )}
    </>
  );
}

export default CelebrationsTable;
