import React, { MouseEvent, useEffect, useState } from "react";
import "./OnboardingPreview.css";
import { ISingleEmployee } from "../../employer-settlements/Settlements";
import angleLeftIcon from "../../../images/angle-left.svg";
import closeIcon from "../../../images/close-icon-white.svg";
import tickIcon from "../../../images/tick-icon-white.svg";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import TextInput from "../../../components/input-components/text-input/TextInput";
import DropdownInput from "../../../components/input-components/dropdown-input/DropdownInput";
import { ICompanyGroups } from "../../../components/onboard-employees/single-upload/SingleUpload";

interface IProps {
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  selectedEmployeeForPreview: ISingleEmployee | null;
}
const OnboardingPreview: React.FC<IProps> = function ({
  setPageContent,
  selectedEmployeeForPreview,
}) {
  // Functions, States and Variables
  //   States
  const [salary, setSalary] = useState("");
  const [groupName, setGroupName] = useState("");
  const [companyGroups, setCompanyGroups] = useState<[] | ICompanyGroups[]>([]);
  const [isApproved, setIsApproved] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false);

  useEffect(() => {
    setCompanyGroups([
      {
        key: "Senior Management",
        value: "Senior Management",
      },
      {
        key: "Management",
        value: "Management",
      },
      {
        key: "Mid-Level",
        value: "Mid-Level",
      },
      {
        key: "Analyst",
        value: "Analyst",
      },
      {
        key: "Junior Staff",
        value: "Junior Staff",
      },
      {
        key: "Clerical Staff",
        value: "Clerical Staff",
      },
    ]);
  }, []);

  //   Functions
  // handle Approve Onboarding Request
  const handleApproveOnboardingRequest = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    if (salary !== "" && groupName !== "") {
      e.preventDefault();

      const data = {
        salary,
        groupName,
      };
      //TODO Call the dispatch fxn to approve employee
      setIsApproved(true);
      setSalary("");
      setGroupName("");
    }
  };

  // handle Decline Onboarding Request
  const handleDeclineOnboardingRequest = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    e.preventDefault();

    //TODO Call the dispatch fxn to decline employee
    setIsDeclined(true);
  };

  return (
    <div className="onboarding-preview-container--inner">
      <form>
        {/* Settlement preview Top */}
        <div
          className={`dashboard-setup-top-container onboarding-preview-top-container`}
        >
          {/* Top Left Wrapper */}
          <div className="onboarding-preview-top--left-wrapper">
            {/* Back button Wrapper */}
            <div className="back-button-wrapper">
              <button
                onClick={() => {
                  setPageContent("main");

                  //
                  setIsApproved(false);
                  setIsDeclined(false);

                  setSalary("");
                  setGroupName("");
                }}
              >
                <img src={angleLeftIcon} alt="" />
              </button>
            </div>
            {/* Reference Number */}
            <div className="dashboard-setup-top-title">
              {selectedEmployeeForPreview?.referenceId}
            </div>
          </div>

          {/* Top Right Wrapper */}
          <div className="onboarding-preview-top-action-buttons-container">
            {isApproved || isDeclined ? (
              <div
                className={`request-status-message-wrapper ${
                  isApproved
                    ? "request-approved-status-wrapper"
                    : "request-declined-status-wrapper"
                }`}
              >
                {isApproved ? "Request Approved" : "Request Declined"}
              </div>
            ) : (
              <>
                <div className="decline-request-button-wrapper">
                  <button onClick={(e) => handleDeclineOnboardingRequest(e)}>
                    <img src={closeIcon} alt="" />
                    Decline Request
                  </button>
                </div>
                <div className="approve-request-button-wrapper">
                  <button onClick={(e) => handleApproveOnboardingRequest(e)}>
                    <img src={tickIcon} alt="" />
                    Approve Request
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        {/* Main section wrapper */}
        {selectedEmployeeForPreview ? (
          <div className="onboarding-preview-main-container">
            <div className="settlement-preview--employee-details-container onboarding-preview-main-container--inner">
              {/* Title */}
              <div className="settlement-preview--employee-details--title">
                EMPLOYEE DETAILS
              </div>

              {/* Row One */}

              <div className="onboarding-preview--employee-details--row-one">
                <div className="settlement-preview--employee-details--row-one">
                  {/* Profile Img */}
                  <div className="settlement-preview--employee-profile-img">
                    <img src={selectedEmployeeForPreview?.imgURL} alt="" />
                  </div>
                  {/* Employee name */}
                  <div className="settlement-preview--employee-name">
                    {selectedEmployeeForPreview?.firstName}{" "}
                    {selectedEmployeeForPreview?.lastName}
                  </div>
                </div>

                {/* Salary and group form wrapper */}
                <div className="salary-and-group-form-group-wrapper">
                  {/* Salary form group */}
                  <div className="form-group">
                    <TextInput
                      type={"text"}
                      id={`employee-salary-text-input`}
                      className={"employee-salary-input"}
                      placeholder={""}
                      value={salary}
                      onChange={(e) => {
                        setSalary(e.target.value);
                      }}
                      required={true}
                      label={"Salary (N)"}
                    />
                  </div>

                  {/* Group Name dropdown */}
                  <div className="form-group">
                    <DropdownInput
                      className={"group-name-dropdown-input"}
                      id={"group-name-input"}
                      label={"Add to group"}
                      required={true}
                      optionArray={[
                        { key: "", value: "", default: "default" },
                        ...companyGroups,
                      ]}
                      value={groupName}
                      onChange={(e) => setGroupName(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              {/* Row Two */}
              <div className="settlement-preview--employee-details--row-two onboarding-preview--employee-details--row-two">
                {/* Employee email */}
                <div className="settlement-preview--employee-email settlement-preview--employe-detail-wrapper">
                  <div className="settlement-preview--employee-detail--title">
                    Email
                  </div>
                  <div className="settlement-preview--employee-detail--value">
                    {selectedEmployeeForPreview?.email}
                  </div>
                </div>
                {/* Employee department */}
                <div className="settlement-preview--employee-department settlement-preview--employe-detail-wrapper">
                  <div className="settlement-preview--employee-detail--title">
                    Department
                  </div>
                  <div className="settlement-preview--employee-detail--value">
                    {selectedEmployeeForPreview?.department}
                  </div>
                </div>
                {/* Employee Phone Number */}
                <div className="settlement-preview--employee-phone-number settlement-preview--employe-detail-wrapper">
                  <div className="settlement-preview--employee-detail--title">
                    Phone Number
                  </div>
                  <div className="settlement-preview--employee-detail--value">
                    {selectedEmployeeForPreview?.phoneNumber}
                  </div>
                </div>
                {/* Employee Role */}
                <div className="settlement-preview--employee-role settlement-preview--employe-detail-wrapper">
                  <div className="settlement-preview--employee-detail--title">
                    Role
                  </div>
                  <div className="settlement-preview--employee-detail--value">
                    {selectedEmployeeForPreview?.role}
                  </div>
                </div>
                {/* Employee Level  */}
                <div className="settlement-preview--employee-level settlement-preview--employe-detail-wrapper">
                  <div className="settlement-preview--employee-detail--title">
                    Level
                  </div>
                  <div className="settlement-preview--employee-detail--value">
                    {selectedEmployeeForPreview?.level}
                  </div>
                </div>
                {/* Employee Address  */}
                <div className="settlement-preview--employee-address settlement-preview--employe-detail-wrapper">
                  <div className="settlement-preview--employee-detail--title">
                    Address
                  </div>
                  <div className="settlement-preview--employee-detail--value">
                    {selectedEmployeeForPreview?.address}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </form>
    </div>
  );
};

export default OnboardingPreview;
