import React, { MouseEvent, useEffect, useState, useContext } from "react";
import "./SubscriptionDetails.css";
import Sidebar from "../../../components/app-sidebar/Sidebar";
import Top from "../../../components/app-top/Top";
import PrimaryButton from "../../../components/buttons/primary-button/PrimaryButton";
import ErrorHandler from "../../../components/error/ErrorHandler";
import Main from "../../../components/main-container/Main";
import PrimaryBackButton from "../../../components/primary-back-button/PrimaryBackButton";
import SuccessHandler from "../../../components/success/SuccessHandler";
import useDisplayMessage from "../../../hooks/useDisplayMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { ISubscriptionPlanInfo } from "../../../Types";
import BillingFormatForm from "./billing-format-form/BillingFormatForm";
import SubscriptionSummary from "./subscription-summary/SubscriptionSummary";
import moment from "moment";
import PaymentMethodModal from "./modals/PaymentMethodModal";
import SubstituteLoadingSpinner from "../../../components/substitute-loading-spinner/SubstituteLoadingSpinner";
import { UserContext } from "../../../components/contexts/UserContext";
import { usePaystackPayment } from "react-paystack";
import { useAppDispatch } from "../../../hooks";
import { addCompanySubscription } from "../../../redux/actions/subscriptionActions";
import { onPaystackClose } from "../../../utils/handleOnPaystackClose";

// Interfaces
export interface IFormInputStates {
  billingDateFormat: string;
  numberOfEmployees: string;
}

export interface ISummaryData {
  subscriptionPricePerEmployee: number;
  totalAmount: number;
}

function SubscriptionDetails() {
  // Functions, States and Variables
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { user }: any = useContext(UserContext);

  //   States
  const [selectedPlan, setSelectedPlan] =
    useState<ISubscriptionPlanInfo | null>(null);
  const [formInputStates, setFormInputStates] = useState<IFormInputStates>({
    billingDateFormat: "Monthly",
    numberOfEmployees: "500",
  });
  const [summaryData, setSummaryData] = useState<ISummaryData>({
    subscriptionPricePerEmployee: 1,
    totalAmount: 1,
  });
  const [nextBillingDate, setNextBillingDate] = useState("");
  const [isPaymentOptionModalOpened, setIsPaymentOptionModalOpened] =
    useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  const config = {
    reference: new Date().getTime().toString(),
    email: user?.email || "",
    // FIX Remove current amount and use the one below
    // amount: summaryData.totalAmount,
    amount: summaryData.totalAmount * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY ?? "",
    // publicKey: "pk_test_cc4c2259b62bc75842e99e60a2e4ac12a883a840",
  };
  const initializePayment: any = usePaystackPayment(config);

  //   Functions
  const handleUpdateSummaryData = function (
    billingDateMultiplicator: number,
    numberOfEmployees?: string | undefined
  ) {
    // Billing Date Multiplier is 1 for monthly, 4 for quarterly and 12 for annually
    const updatedSummaryData: ISummaryData = {
      subscriptionPricePerEmployee:
        (selectedPlan?.cost ?? 1) * billingDateMultiplicator,
      totalAmount:
        (selectedPlan?.cost ?? 1) *
        billingDateMultiplicator *
        parseInt(numberOfEmployees || formInputStates.numberOfEmployees),
    };
    setSummaryData(updatedSummaryData);
  };

  // Set Newt billing Date
  const handleSetNextBillingDate = function (monthsCount: number) {
    const date = new Date();
    const nextDate = new Date(date.setMonth(date.getMonth() + monthsCount));
    setNextBillingDate(moment(nextDate).format("D, MMM YYYY"));
  };

  // OnCLick of the continue to payment button
  const handleContinueToPayment = function (e: MouseEvent<HTMLButtonElement>) {
    if (formInputStates.numberOfEmployees !== "") {
      e.preventDefault();
      //   Open Selected Payment Method Modal
      setIsPaymentOptionModalOpened(true);
    }
  };

  // Handle Initialize Payment (onClick of payment method)
  const handleInitializeSubscriptionPaymentWithPaystack = function () {
    // Set Error and Success message to default false
    setErrorHandlerObj({ hasError: false, message: "" });

    //  Call the API to add company subscription(which on success, triggers the Paystack API)
    const data = {
      planId: selectedPlan?._id || "",
      billingFrequency: formInputStates.billingDateFormat.toLowerCase(),
      noOfEmployees: parseInt(formInputStates.numberOfEmployees),
    };

    // Close the payment method modal
    setIsPaymentOptionModalOpened(false);

    // Call API
    dispatch(
      addCompanySubscription(
        data,
        setIsPaymentLoading,
        setErrorHandlerObj,
        initializePayment,
        summaryData.totalAmount,
        onPaystackClose,
        navigate
      )
    );
  };

  // Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    // setSuccessHandlerObj,
  } = useDisplayMessage();

  //   UseEffects
  useEffect(() => {
    if (location.state) {
      const { subscriptionPlan }: any = location.state;

      //   Set Selected Plan
      setSelectedPlan(subscriptionPlan);
      //   Set Default Summary Data
      const updatedSummaryData: ISummaryData = {
        subscriptionPricePerEmployee: subscriptionPlan?.cost ?? 1,
        totalAmount:
          (subscriptionPlan?.cost ?? 1) *
          parseInt(formInputStates.numberOfEmployees),
      };
      setSummaryData(updatedSummaryData);
      //   Set Next Billing Date
      handleSetNextBillingDate(1);
    } else {
      // If there is no subscription state
      navigate("/employer/choose-subscription-plan");
    }
  }, [location]);

  useEffect(() => {
    console.log(user, "----------------------------------------------");
  }, [user]);
  return (
    <div
      className={`subscription-plan-container ${
        isPaymentOptionModalOpened ? "overflow-hidden" : ""
      }`}
    >
      <Top />
      <Sidebar activeName={"Dashboard"} />

      {/* Gray Overlay */}
      <div
        onClick={() => {
          setIsPaymentOptionModalOpened(false);
        }}
        className={`gray-overlay ${isPaymentOptionModalOpened ? "" : "none"}`}
      ></div>

      {/* Select Payment Method Modal */}
      <PaymentMethodModal
        isPaymentOptionModalOpened={isPaymentOptionModalOpened}
        setIsPaymentOptionModalOpened={setIsPaymentOptionModalOpened}
        amount={summaryData.totalAmount}
        handleInitializePaymentWithPaystack={
          handleInitializeSubscriptionPaymentWithPaystack
        }
      />

      <Main>
        <div className="subscription-plan-container--inner">
          {/* Error and Success Handlers */}
          <div className="employees--actions-handler-wrapper">
            {/* Error handler component */}
            <ErrorHandler errorHandlerObj={errorHandlerObj} />
            {/* Success handler component */}
            <SuccessHandler successHandlerObj={successHandlerObj} />
          </div>

          {/* Main Section */}
          <section className={`subscription-plan-main-section`}>
            <div className="primary-back-button-section">
              {/* Back Button wrapper */}
              <div className="primary-back-button-container">
                {/* Back button Wrapper */}
                <PrimaryBackButton onClick={() => navigate(-1)} />
                {/* Top Title */}
                <div className="primary-back-button-title ">
                  Subscription Details
                </div>
              </div>

              {/* Payment Loader */}
              {isPaymentLoading && (
                <div className="payment-loader-wrapper">
                  <SubstituteLoadingSpinner />
                </div>
              )}
            </div>

            {/* Selcted Plan Display */}
            <div className="selected-plan-display-wrapper">
              <div className="subscription-detail-title">Choose Plan</div>

              <div className="plan-display-box-wrapper">
                {/* Left Col*/}
                <div className="left-col">
                  {/* Plan Name */}
                  <div className="selected-plan-name">{selectedPlan?.name}</div>
                  {/* Plan Price */}
                  <div className="selected-plan-price">
                    ₦{selectedPlan?.cost.toLocaleString()}
                    /employee/month
                  </div>
                </div>

                {/* Right Col */}
                <div className="right-col">
                  {/* Change Plan Button Wrapper */}
                  <PrimaryButton
                    className="change-plan-btn-wrapper"
                    placeholder="Change Plan"
                    onClick={(e) => {
                      navigate(-1);
                    }}
                  />
                </div>
              </div>
            </div>

            <form>
              {/* Billing Form Container */}
              <BillingFormatForm
                formInputStates={formInputStates}
                setFormInputStates={setFormInputStates}
                handleUpdateSummaryData={handleUpdateSummaryData}
                handleSetNextBillingDate={handleSetNextBillingDate}
              />

              {/* Subscription Details */}
              <SubscriptionSummary
                formInputStates={formInputStates}
                summaryData={summaryData}
                nextBillingDate={nextBillingDate}
              />

              {/* Continue to Payment  Button Wrapper*/}
              <PrimaryButton
                className="continue-to-payment-btn-wrapper"
                placeholder="Continue to Payment"
                onClick={(e) => {
                  handleContinueToPayment(e);
                }}
              />
            </form>
          </section>
        </div>
      </Main>
    </div>
  );
}

export default SubscriptionDetails;
