import { GET_ALL_SUBSCRIPTION_PLANS } from "../Constants";

const initialState = {
  subscriptionPlans: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state = initialState,
  action: { type?: string; payload?: any }
) {
  switch (action.type) {
    case GET_ALL_SUBSCRIPTION_PLANS:
      return {
        ...state,
        subscriptionPlans: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
