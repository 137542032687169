import React, { MouseEvent, useState } from "react";
import TextInput from "../../../../input-components/text-input/TextInput";
import LoadingSpinner from "../../../../loading-spinner/LoadingSpinner";
import plusIcon from "../../../../../images/plus-icon.svg";
import {
  IInstrumentsRanges,
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../../../../Types";
import isEmpty from "../../../../../validation/isEmpty";
import { useAppDispatch } from "../../../../../hooks";
import { numberWithCommas } from "../../../../../utils/numberWithCommas";
import {
  addNewCompanyInstruments,
  updateManyCompanyInstrument,
} from "../../../../../redux/actions/instrumentActions";
import { useNavigate } from "react-router-dom";

// Interfaces
interface IProps {
  pageType: string;
  instrumentTitle: string;
  fromPriceValue: string;
  toPriceValue: string;
  amountLoanable: string;
  instrumentsRangesObj: IInstrumentsRanges[] | null;
  setInstrumentTitle: React.Dispatch<React.SetStateAction<string>>;
  setFromPriceValue: React.Dispatch<React.SetStateAction<string>>;
  setToPriceValue: React.Dispatch<React.SetStateAction<string>>;
  setAmountLoanable: React.Dispatch<React.SetStateAction<string>>;
  setInstrumentsRangesObj: React.Dispatch<
    React.SetStateAction<IInstrumentsRanges[] | null>
  >;
  setIsInstrumentsFormFilled: React.Dispatch<React.SetStateAction<boolean>>;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  setErrorHandlerObj: SetErrorHandlerType;
  setSuccessHandlerObj: SetSuccessHandlerType;
}

function InstrumentsSetupForm({
  pageType,
  instrumentTitle,
  fromPriceValue,
  toPriceValue,
  amountLoanable,
  instrumentsRangesObj,
  setInstrumentTitle,
  setFromPriceValue,
  setToPriceValue,
  setAmountLoanable,
  setInstrumentsRangesObj,
  setIsInstrumentsFormFilled,
  setPageContent,
  setErrorHandlerObj,
  setSuccessHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // States
  const [isLoading, setIsLoading] = useState(false);

  //   Functions
  // Handle Submit / Update Account and Instruments Form
  const handleSubmitAccountAndInstrumentsForm = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    if (instrumentsRangesObj) {
      e.preventDefault();

      const allInstrumentsArr = instrumentsRangesObj.filter(
        (instruments) =>
          instruments.title !== "" &&
          instruments.from !== "" &&
          instruments.to !== "" &&
          instruments.amountLoanable !== ""
      );
      const newInstrumentsData = {
        instruments: allInstrumentsArr
          .filter((instrument) => instrument._id.includes("new") === true)
          ?.map((instruments) => {
            return {
              title: instruments.title,
              from: parseInt(instruments.from),
              to: parseInt(instruments.to),
              loanAmount: parseInt(instruments.amountLoanable),
            };
          }),
      };
      const edittedInstrumentsData = {
        instruments: allInstrumentsArr
          .filter((instrument) => instrument._id.includes("edit") === true)
          ?.map((instruments) => {
            return {
              id: instruments._id.split("-")[1],
              title: instruments.title,
              from: parseInt(instruments.from),
              to: parseInt(instruments.to),
              loanAmount: parseInt(instruments.amountLoanable),
            };
          }),
      };

      // console.log("new-submit", newInstrumentsData);
      // console.log("edit-submit", edittedInstrumentsData);

      let newInstrumentsExists = false;
      if (!isEmpty(newInstrumentsData.instruments)) {
        newInstrumentsExists = true;
      }

      // Calling the Edit Instruments API first before calling the Add New API
      if (!isEmpty(edittedInstrumentsData.instruments)) {
        // Checking if editted instruments exists
        //  Call the Edit instruments API
        // console.log(
        //   "call the edit instruments API and new instruments is",
        //   newInstrumentsExists
        // );

        dispatch(
          updateManyCompanyInstrument(
            edittedInstrumentsData,
            setIsLoading,
            setErrorHandlerObj,
            setSuccessHandlerObj,
            pageType,
            setPageContent,
            setIsInstrumentsFormFilled,
            navigate,
            newInstrumentsExists
          )
        );
      }

      if (newInstrumentsExists) {
        // Checking if new instruments exists
        //  Call the Create / Add New instrument  API
        // console.log("call the new instruments API", newInstrumentsExists);

        dispatch(
          addNewCompanyInstruments(
            newInstrumentsData,
            setIsLoading,
            setErrorHandlerObj,
            setSuccessHandlerObj,
            pageType,
            setPageContent,
            setIsInstrumentsFormFilled,
            navigate
          )
        );
      }
    }
  };

  // handle Add NewcRange
  const handleAddNewRange = function (e: MouseEvent<HTMLButtonElement>) {
    if (
      instrumentTitle !== "" &&
      fromPriceValue !== "" &&
      toPriceValue !== "" &&
      amountLoanable !== ""
    ) {
      e.preventDefault();

      setInstrumentTitle("");
      setFromPriceValue("");
      setToPriceValue("");
      setAmountLoanable("");

      // Update and create a new instrument form
      setInstrumentsRangesObj((prevInstruments) => {
        if (!prevInstruments) return prevInstruments;

        let newInstrument = {
          _id: `new-${Math.floor(Math.random() * 10000)}`,
          title: "",
          from: "",
          to: "",
          amountLoanable: "",
          instrumentView: "form-view",
        };

        let newPrevInstruments = prevInstruments
          .map((instrument) => {
            return {
              ...instrument,
              instrumentView: "preview-view",
            };
          })
          .concat(newInstrument);
        return newPrevInstruments;
      });
    }
  };

  // handle Edit Current Instrument
  const handleEditCurrentInstrument = function (
    e: MouseEvent<HTMLButtonElement>,
    id: string,
    instrument: IInstrumentsRanges
  ) {
    // Update All Instruments State
    // Filter out any instrument obj whose form state is empty, then set current obj to preview based on index gotten from Id
    e.preventDefault();

    let filteredInstrumentsRangesObj = instrumentsRangesObj?.filter(
      (singleInstrument) =>
        singleInstrument.title !== "" &&
        singleInstrument.from !== "" &&
        singleInstrument.to !== "" &&
        singleInstrument.amountLoanable !== ""
    );

    // console.log("is not empty", filteredInstrumentsRangesObj);
    setInstrumentsRangesObj((prevInstruments) => {
      if (!prevInstruments || !filteredInstrumentsRangesObj)
        return prevInstruments;

      // Get the instrument index
      const index = filteredInstrumentsRangesObj.findIndex(
        (singleInstrument: IInstrumentsRanges) => singleInstrument._id === id
      );

      let newPrevInstruments = filteredInstrumentsRangesObj.map(
        (currentInstruments) => {
          return {
            ...currentInstruments,
            instrumentView: "preview-view",
          };
        }
      );

      let updatedInstrument = {
        ...instrument,
        _id:
          instrument._id.includes("new") || instrument._id.includes("edit")
            ? instrument._id
            : `edit-${instrument._id}`,
        instrumentView: "form-view",
      };

      setInstrumentTitle(instrument.title);
      setFromPriceValue(instrument.from);
      setToPriceValue(instrument.to);
      setAmountLoanable(instrument.amountLoanable);
      newPrevInstruments.splice(index, 1, updatedInstrument);
      return newPrevInstruments;
    });
  };
  return (
    <form>
      {/* Form Row One / Instrument Range One */}
      {instrumentsRangesObj?.map((instrument, index) => (
        <div key={index + 1}>
          <div
            className={`ranges-form-wrapper ${
              instrument.instrumentView !== "form-view" ? "none" : ""
            }`}
          >
            <div className="form-header-wrapper">Range {index + 1}</div>
            {/* Instrument Title*/}
            <div className="form-body-wrapper form-group">
              <TextInput
                type={"text"}
                id={`employer-instrument-title-${index + 1}-text-input`}
                className={`employer-instrument-title-input`}
                placeholder={"Name this Instument"}
                value={instrumentTitle}
                onChange={(e) => {
                  setInstrumentTitle(e.target.value);

                  setInstrumentsRangesObj((prevInstruments) => {
                    if (!prevInstruments) return prevInstruments;

                    const updatedInstrument = {
                      ...instrument,
                      title: e.target.value,
                    };

                    prevInstruments.splice(index, 1, updatedInstrument);

                    console.log(prevInstruments, "allInstrumnts");
                    return prevInstruments;
                  });
                }}
                required={true}
                label={"Instrument Title"}
              />
            </div>

            {/* Flex form wrapper */}
            <div className="form-body-wrapper instrument-range-from-to-value-form-group-wrapper">
              {/* From Value */}
              <div className="form-group">
                <TextInput
                  type={"text"}
                  id={`from-value-${index + 1}-text-input`}
                  className={`from-value-input`}
                  placeholder={"Input Price (N)"}
                  value={fromPriceValue}
                  onChange={(e) => {
                    setFromPriceValue(e.target.value);

                    setInstrumentsRangesObj((prevInstruments) => {
                      if (!prevInstruments) return prevInstruments;

                      const updatedInstrument = {
                        ...instrument,
                        from: e.target.value,
                      };

                      prevInstruments.splice(index, 1, updatedInstrument);

                      console.log(prevInstruments, "allInstrumnts");
                      return prevInstruments;
                    });
                  }}
                  required={true}
                  label={"From"}
                />
              </div>

              {/* To Value */}
              <div className="form-group">
                <TextInput
                  type={"text"}
                  id={`to-value-${index + 1}-text-input`}
                  className={`to-value-input`}
                  placeholder={"Input Price (N)"}
                  value={toPriceValue}
                  onChange={(e) => {
                    setToPriceValue(e.target.value);

                    setInstrumentsRangesObj((prevInstruments) => {
                      if (!prevInstruments) return prevInstruments;

                      const updatedInstrument = {
                        ...instrument,
                        to: e.target.value,
                      };

                      prevInstruments.splice(index, 1, updatedInstrument);

                      console.log(prevInstruments, "allInstrumnts");
                      return prevInstruments;
                    });
                  }}
                  required={true}
                  label={"To"}
                />
              </div>
            </div>

            {/* Amount Loanable Value */}
            <div className="form-group amount-loanable-form-group">
              <TextInput
                type={"text"}
                id={`amount-loanable-${index + 1}-text-input`}
                className={`amount-loanable-input`}
                placeholder={""}
                value={amountLoanable}
                onChange={(e) => {
                  setAmountLoanable(e.target.value);

                  setInstrumentsRangesObj((prevInstruments) => {
                    if (!prevInstruments) return prevInstruments;

                    const updatedInstrument = {
                      ...instrument,
                      amountLoanable: e.target.value,
                    };

                    prevInstruments.splice(index, 1, updatedInstrument);

                    console.log(prevInstruments, "allInstrumnts");
                    return prevInstruments;
                  });
                }}
                required={true}
                label={"Total Loanable Amount"}
              />
            </div>
          </div>

          {/*Instrument Ranges Preview view wrapper */}
          <div
            className={`instrument-ranges-preview-view-wrapper ${
              instrument.instrumentView === "form-view" ? "none" : ""
            }`}
          >
            {/* Top Wrapper*/}
            <div className="ranges-preview-top-wrapper">
              <div className="range-value-wrapper">Range {index + 1}</div>
              <div className="edit-range-button">
                <button
                  onClick={(e) =>
                    handleEditCurrentInstrument(e, instrument._id, instrument)
                  }
                >
                  edit
                </button>
              </div>
            </div>

            <div className="ranges-preview-info--inner">
              <div className="preview--instrument-title-wrapper">
                {instrument.title}
              </div>
              <div className="preview--instrument-range-wrapper">
                &#8358;{numberWithCommas(instrument.from)} - &#8358;
                {numberWithCommas(instrument.to)}
              </div>
              <div className="preview--loanable-amount-title-wrapper">
                Total Loanable Amount
              </div>
              <div className="preview--loanable-amount-value-wrapper">
                &#8358;{numberWithCommas(instrument.amountLoanable)}
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="add-new-ranges-button-wrapper">
        <button onClick={(e) => handleAddNewRange(e)}>
          {" "}
          <img src={plusIcon} alt="" /> Add New Range
        </button>
      </div>

      {/* Submit form button */}
      <div className="submit-setup-form-button-wrapper">
        {isLoading && <LoadingSpinner />}
        <button onClick={handleSubmitAccountAndInstrumentsForm}>Submit</button>
      </div>
    </form>
  );
}

export default InstrumentsSetupForm;
