import React from "react";
import "./EmployerMou.css";
import AuthNavbar from "../../components/auth-navbar/AuthNavbar";

function EmployerMou() {
  return (
    <div className="employer-mou-container">
      <AuthNavbar />
      <div className="employer-mou-container-main-section">
        <div>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              This Agreement is made this ________________ day of
              _______________________ 2022,&nbsp;
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              BETWEEN&nbsp;
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              CREDLEY GLOBAL LIMITED,
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;a limited liability company incorporated and registered
              under Part A of the Companies and Allied Matters Act 2020 with RC
              No: 1904745 whose office is at 29/31, 1
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              <span style={{ fontSize: "0.6em", verticalAlign: "super" }}>
                st
              </span>
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;Floor, Awolowo Way, Ikeja, Lagos State, Nigeria (hereinafter
              referred to as
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;"Credley"
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              )
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              which expression shall where the context so admits, include its
              agents, assigns and successors–in-title) of the one part.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              AND
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              _____________________________,
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;a ___________________ company incorporated and registered
              under Part ___ of the Companies and Allied Matters Act 2020 with
              RC No: __________ whose office is at_________________________
              (hereinafter referred to as
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;the "Company"
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              )
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              which expression shall where the context so admits, include its
              agents, assigns and successors–in-title) of the other part.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Credley and the Company are hereinafter individually referred to
              as “
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              the Party
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ” and collectively referred to as “
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              the Parties
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ”.&nbsp;
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              BACKGROUND
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              <br />
              <br />
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "3pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Credley is a company engaged in providing pay day loan and Buy
                  Now-Pay Later services/solutions.&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "3pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Company is a _______________
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "3pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Credley and Company are desirous of entering this MOU in order
                  for Credley to provide a Buy Now Pay Later Solution and
                  customer loans via its online application (“
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Credley app
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ”) to the members of staff of Company.&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "3pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.28",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The Parties are now entering into this Agreement for the
                  purpose of documenting all terms agreed upon between them.
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              marginRight: "-16.5pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              NOW, THEREFORE,
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;in consideration of the promises and the covenants,
              conditions, and agreements contained herein, and for other good
              and valuable consideration, the receipt and sufficiency of which
              are hereby acknowledged, the Parties hereby agree as follows:
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "12pt",
                  marginBottom: "12pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Interpretation
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: "48px",
                }}
              >
                <li
                  aria-level={2}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-54pt",
                    paddingLeft: "17.450000000000003pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.295",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Definitions:&nbsp;
                    </span>
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Affiliate
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  : includes, in relation to either party, each and any
                  subsidiary or holding company of that party and each and any
                  subsidiary of a holding company of that party&nbsp;
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  OR
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  &nbsp;either party or any business entity from time to time
                  Controlling, Controlled by, or under common Control with,
                  either party.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Business
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  : the business of Credley or any of its Affiliates.&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Business Day:
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  &nbsp; a day other than a Saturday, Sunday or public holiday
                  in Nigeria, when banks in Nigeria are open for business.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Confidential Information
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  : information of commercial value, in whatever form or medium,
                  disclosed by the party (or any of its Affiliates) to the other
                  party (or any of its Affiliates) including commercial or
                  technical know-how, technology, information pertaining to
                  business operations and strategies, and information pertaining
                  to customers, pricing and marketing and, for clarity,
                  including (in the case of the Company's information)
                  information relating to the Application, the Company Software
                  or any of its constituent parts, the Source Code relating to
                  the Company Software or any such parts.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Credley application
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  : the software program developed by Credley through which it
                  will provide consumer loans and Buy Now Pay Later solutions to
                  the members of staff of the Company and to the general
                  public.&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={6}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Credley Representative
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  : a person duly authorised by Credley to act on its behalf for
                  the purposes of this Agreement and identified to Company by
                  written notice from the Customer.
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Deliverables
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              : &nbsp;all documents, products and materials developed by the
              Company or its agents, subcontractors and personnel as part of or
              in relation to the Services in any form, including without
              limitation computer programs, data, reports and specifications
              (including drafts), and the Key Deliverables set out in this
              Agreement.&nbsp;
            </span>
          </p>
          <ol
            start={7}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Data Protection Legislation
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  : the Nigerian Data Protection Legislation and any other
                  applicable international convention or treaty relating to
                  personal data and all other legislation and regulatory
                  requirements in force from time to time which apply to a party
                  relating to the use of personal data (including, without
                  limitation, the privacy of electronic communications) [and the
                  guidance and codes of practice issued by the relevant data
                  protection or supervisory authority and applicable to a party.
                  &nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Documentation
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  : the operating manuals, user instruction manuals, technical
                  literature and all other related materials in human-readable
                  and/or machine-readable forms supplied by the Company.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Good Industry Practice
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  : the exercise of that degree of skill, care, prudence,
                  efficiency, foresight and timeliness as would be expected from
                  a leading company within the relevant industry or business
                  sector.
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Intellectual Property Rights
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              : &nbsp;patents, rights to inventions, copyright and neighbouring
              and related rights, trade marks, business names and domain names,
              rights in get-up, goodwill and the right to sue for passing off,
              rights in designs, database rights, rights to use, and protect the
              confidentiality of, confidential information (including know-how)
              and all other intellectual property rights, in each case whether
              registered or unregistered and including all applications and
              rights to apply for and be granted, renewals or extensions of, and
              rights to claim priority from, such rights and all similar or
              equivalent rights or forms of protection which subsist or will
              subsist now or in the future in any part of the world.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Services:&nbsp;
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              the consumer loan and Buy Now Pay Later services provided by the
              Credley via the Credley app.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textIndent: "-36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
              padding: "0pt 0pt 0pt 36pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tools
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              : any tools and know-how developed and methods invented by the
              Company in the course of, or as a result of, carrying out the
              Work, whether or not developed or invented specifically or used
              exclusively to carry out the Work.
            </span>
          </p>
          <ol
            start={10}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Virus
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  : any program which contains malicious code or infiltrates or
                  damages a computer system without the owner's informed consent
                  or is designed to do so or which is hostile, intrusive or
                  annoying to the owner or user and has no legitimate purpose.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Work
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  : all the works, duties and obligations to be carried out by
                  the Company under this agreement.
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={2}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Interpretation
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={3}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Unless expressly provided otherwise in this Agreement, a
                  reference to legislation or a legislative provision:
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={4}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-27pt",
                paddingLeft: "27pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  is a reference to it as [amended, extended or re-enacted from
                  time to time OR it is in force as at the date of this
                  Agreement; and
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={2}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={4}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-27pt",
                paddingLeft: "27pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  shall include all subordinate legislation made from time to
                  time under that legislation or legislative provision.
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={2}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={3}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Any words following the terms including, include, in
                  particular, for example or any similar expression shall be
                  construed as illustrative and shall not limit the sense of the
                  words, description, definition, phrase or term preceding those
                  terms.
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={3}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={3}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  A reference to writing or written includes email.
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={2}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "12pt",
                  marginBottom: "12pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Commencement and Term
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              This Agreement shall commence on the date when it has been signed
              by both parties and shall continue until it is terminated in
              accordance with its provisions.&nbsp;
            </span>
          </p>
          <ol
            start={3}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "12pt",
                  marginBottom: "12pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Obligations
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: "48px",
                }}
              >
                <li
                  aria-level={2}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-54pt",
                    paddingLeft: "18pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.295",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Credley shall:
                    </span>
                  </p>
                  <ol
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      paddingInlineStart: "48px",
                    }}
                  >
                    <li
                      aria-level={3}
                      dir="ltr"
                      style={{
                        listStyleType: "decimal",
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-90pt",
                        paddingLeft: "18pt",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.295",
                          textAlign: "justify",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "12pt",
                            fontFamily: "Georgia",
                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          provide the Credley application through which it shall
                          provide the following services to the staff of the
                          Company:
                        </span>
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "lower-roman",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "36pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Buy Now Pay Later services. &nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "lower-roman",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "36pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Consumer loans.&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "lower-roman",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "36pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  On-demand salary i.e., salary for the period worked within a
                  month.&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <ol
            start={2}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={3}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  grant the staff of the Company, adequate and unrestricted
                  access to the Credley app.&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={3}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ensure that the data provided by the Company and the staff of
                  the Company during the process of signing up and the
                  consequent use of the Credley app, are adequately protected.
                  &nbsp;&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={3}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ensure that the loans are adequately paid upon request, by the
                  staff of the Company.&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={2}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The Company shall:
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: "48px",
                }}
              >
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-54pt",
                    paddingLeft: "18pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.295",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      ensure that only staff that are willing to sign up to the
                      Credley application are allowed to sign up.&nbsp;
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-54pt",
                    paddingLeft: "18pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.295",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      ensure that it notifies and adequately communicates to its
                      staff, the terms and conditions for obtaining the loan and
                      the Buy Now Pay Later solution/services of Credley.&nbsp;
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-54pt",
                    paddingLeft: "18pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.295",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      ensure that on a monthly basis, it remits all sums and
                      interests (as may be agreed upon by each staff and
                      Credley) to Credley before the disbursement of salaries to
                      its staff that have either obtained a loan or purchased an
                      item via the Credley app, on a Buy Now Pay Later
                      basis.&nbsp;
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-54pt",
                    paddingLeft: "18pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.295",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      ensure that for all on-demand salary, it make refunds
                      (with interests) as at when due, to Credley.&nbsp;
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-54pt",
                    paddingLeft: "18pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.295",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      &nbsp;ensure that it provides all necessary information
                      pertaining to its staff that sign up to Credley app.&nbsp;
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-54pt",
                    paddingLeft: "18pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.295",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      promptly notify Credley where a staff that has signed up
                      to the Credley app and has either obtained a loan or
                      purchased an item or items on a Buy Now Pay Later basis,
                      resigns.&nbsp;
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-54pt",
                    paddingLeft: "18pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.295",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      in furtherance of clause 3.2.5. above, it shall make all
                      necessary outstanding payment owed by the staff to Credley
                      within a month from the date in which it notifies Credley
                      of the staff’s resignation.&nbsp;
                    </span>
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol
            start={4}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "12pt",
                  marginBottom: "12pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  &nbsp;Data Protection
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: "48px",
                }}
              >
                <li
                  aria-level={2}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-54pt",
                    paddingLeft: "24.549999999999997pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.295",
                      textAlign: "justify",
                      marginTop: "14pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      During the term of this Agreement, and for a period
                      of&nbsp;
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "#ffff00",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      two (2) years
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      &nbsp;thereafter, all confidential and/or proprietary
                      information, whether oral, written or contained in any
                      medium whatsoever, that is made available to either party
                      (the “Recipient Party”) shall be kept strictly
                      confidential and such information shall not be divulged to
                      any third party without the prior written consent of the
                      other party (the “
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Disclosing Party
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      ”) except to legal, accounting and financial advisers of
                      either party or third Parties providing services with
                      respect to any of the activities herein, and provided that
                      such advisers or other third Parties agree in writing to
                      maintain the confidentiality of such information. The
                      Parties shall comply with the requirements of all
                      legislation and regulatory requirements in force from time
                      to time relating to the use of personal data, including
                      (without limitation) the Data Protection Legislation.
                    </span>
                  </p>
                </li>
                <li
                  aria-level={2}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-54pt",
                    paddingLeft: "24.549999999999997pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.295",
                      textAlign: "justify",
                      marginTop: "14pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Each party shall, at its own expense, ensure that it
                      complies with and assists the other party to comply with
                      the requirements of all legislation and regulatory
                      requirements in force from time to time relating to the
                      use of personal data, including (without limitation) the
                      Data Protection Legislation. This clause is in addition
                      to, and does not reduce, remove or replace, a party's
                      obligations arising from such requirements.
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "12pt",
                  marginBottom: "12pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Intellectual Property&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textIndent: "-36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
              padding: "0pt 0pt 0pt 36pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The Parties agree that this Agreement shall not transfer or grant
              title to any of the respective intellectual property rights as
              defined under Clause 1 of this Agreement unless otherwise agreed
              upon by the Parties.&nbsp;
            </span>
          </p>
          <ol
            start={6}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "12pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Warranties and Indemnity
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: "48px",
                }}
              >
                <li
                  aria-level={2}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-54pt",
                    paddingLeft: "17.450000000000003pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.295",
                      textAlign: "justify",
                      marginTop: "12pt",
                      marginBottom: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      The Parties warrants that they have obtained all relevant
                      authorizations, permits and licenses from their respective
                      Board of Directors to enter into this Agreement;
                    </span>
                  </p>
                </li>
                <li
                  aria-level={2}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-54pt",
                    paddingLeft: "17.450000000000003pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.295",
                      textAlign: "justify",
                      marginTop: "14pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Credley warrants that any requisite regulatory license
                      needed before and during the subsistence of this Agreement
                      has either been obtained or will be obtained at its own
                      expense;&nbsp;
                    </span>
                  </p>
                </li>
                <li
                  aria-level={2}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-54pt",
                    paddingLeft: "17.450000000000003pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.295",
                      textAlign: "justify",
                      marginTop: "14pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      The Parties warrants that they are not aware or cannot
                      reasonably foresee any internal or regulatory requirement
                      arising from prior Agreement or incidental issues capable
                      of threatening the performance of their respective
                      obligations under this Agreement AND that should they
                      become aware after the execution of this Agreement, they
                      shall take all necessary and reasonable steps to
                      facilitate, address resolve the hindrance. This shall not
                      apply to Force Majeure Events;
                    </span>
                  </p>
                </li>
                <li
                  aria-level={2}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-54pt",
                    paddingLeft: "17.450000000000003pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.295",
                      textAlign: "justify",
                      marginTop: "14pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      The Parties warrant that they shall comply fully with
                      their obligations under this Agreement and shall be liable
                      in breach to each other in the event of a breach arising
                      from a failure (which is not a Force Majeure Event),
                      neglect or refusal to comply or adhere to the provisions
                      of this Agreement;
                    </span>
                  </p>
                </li>
                <li
                  aria-level={2}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-54pt",
                    paddingLeft: "17.450000000000003pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.295",
                      textAlign: "justify",
                      marginTop: "14pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      The Company warrants that its staff that would sign up to
                      the Credley app in order to have access to the loan and
                      Buy Now Pay Later solutions are or shall be under no
                      restriction that would prevent them from obtaining the
                      services and performing their payment obligations;&nbsp;
                    </span>
                  </p>
                </li>
                <li
                  aria-level={2}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-54pt",
                    paddingLeft: "17.450000000000003pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.295",
                      textAlign: "justify",
                      marginTop: "14pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      The Parties indemnify each other against all liabilities,
                      costs, expenses, damages and losses (including legal fees)
                      that either of the Parties may suffer as a result of the
                      breach of any warranty contained in this Agreement.
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "12pt",
                  marginBottom: "12pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Termination
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: "48px",
                }}
              >
                <li
                  aria-level={2}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-54pt",
                    paddingLeft: "18pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.295",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Without affecting any other right or remedy available to
                      it, Credley may terminate this Agreement with immediate
                      effect by giving written notice to the Company if:
                    </span>
                  </p>
                  <ol
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      paddingInlineStart: "48px",
                    }}
                  >
                    <li
                      aria-level={3}
                      dir="ltr"
                      style={{
                        listStyleType: "decimal",
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-90pt",
                        paddingLeft: "18pt",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.295",
                          textAlign: "justify",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "12pt",
                            fontFamily: "Georgia",
                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          the Company's financial position deteriorates to such
                          an extent that in Credley's (reasonable) opinion the
                          Company's capability to adequately fulfil its
                          obligations under this Agreement has been placed in
                          jeopardy; or
                        </span>
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={2}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={3}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  the Company commits a breach of this Agreement
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={2}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Without affecting any other right or remedy available to it,
                  either party may terminate this Agreement with immediate
                  effect by giving written notice to the other party if:
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={3}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  the other party commits a material breach of any term of this
                  Agreement which breach is irremediable or (if such breach is
                  remediable) fails to remedy that breach within a period of
                  five (5) Business Days after being notified to do so;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={2}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={3}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  the other party suspends, or threatens to suspend, or ceases
                  or threatens to cease to carry on all or a substantial part of
                  its business.
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={3}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Any provision of this Agreement that expressly or by
                  implication is intended to come into or continue in force on
                  or after termination or expiry of this Agreement shall remain
                  in full force and effect.
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={4}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "8pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Termination or expiry of this Agreement shall not affect any
                  of the rights, remedies, obligations or liabilities of the
                  parties that have accrued up to the date of termination or
                  expiry, including the right to claim damages in respect of any
                  breach of this Agreement which existed at or before the date
                  of termination or expiry.
                </span>
              </p>
            </li>
          </ol>
          <ol
            start={8}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "12pt",
                  marginBottom: "12pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  General&nbsp;
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: "48px",
                }}
              >
                <li
                  aria-level={2}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-54pt",
                    paddingLeft: "18pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.295",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Force majeure
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      : Neither party shall be in breach of this Agreement nor
                      liable for delay in performing, or failure to perform, any
                      of its obligations under this Agreement if such delay or
                      failure result from events, circumstances or causes beyond
                      its reasonable control. If the period of delay or
                      non-performance continues for two weeks, the party not
                      affected may terminate this Agreement by giving five days
                      written notice to the affected party.
                    </span>
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={2}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Subcontracting
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  : The Company may not subcontract any or all of its rights or
                  obligations under this Agreement without the prior written
                  consent of the Customer. If Credle consents to any
                  subcontracting by the Company, the Company shall remain
                  responsible for all acts and omissions of its subcontractors
                  as if they were its own.
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={3}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Entire Agreement
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  : This Agreement constitutes the entire Agreement between the
                  parties and supersedes and extinguishes all previous
                  Agreements, promises, assurances, warranties, representations
                  and understandings between them, whether written or oral,
                  relating to its subject matter.
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={4}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Variation
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  : No variation of this Agreement shall be effective unless it
                  is in writing and signed by the parties (or their authorised
                  representatives).
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={5}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Waiver
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: "48px",
                }}
              >
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-54pt",
                    paddingLeft: "18pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.295",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      A waiver of any right or remedy under this Agreement or by
                      law is only effective if given in writing and shall not be
                      deemed a waiver of any subsequent right or remedy.
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-54pt",
                    paddingLeft: "18pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.295",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      A failure or delay by a party to exercise any right or
                      remedy provided under this Agreement or by law shall not
                      constitute a waiver of that or any other right or remedy,
                      nor shall it prevent or restrict any further exercise of
                      that or any other right or remedy. No single or partial
                      exercise of any right or remedy provided under this
                      Agreement or by law shall prevent or restrict the further
                      exercise of that or any other right or remedy.
                    </span>
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={6}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Severance:
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  &nbsp;If any provision or part-provision of this Agreement is
                  or becomes invalid, illegal or unenforceable, it shall be
                  deemed modified to the minimum extent necessary to make it
                  valid, legal and enforceable. If such modification is not
                  possible, the relevant provision or part-provision shall be
                  deemed deleted. Any modification to or deletion of a provision
                  or part-provision under this Clause 10.6 shall not affect the
                  validity and enforceability of the rest of this
                  Agreement.&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={7}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Notices
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: "48px",
                }}
              >
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-54pt",
                    paddingLeft: "18pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.295",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Any notice or other communication given to a party under
                      or in connection with this Agreement shall be in writing
                      and shall be sent by email to the address specified below
                    </span>
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "#ffff00",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The Company:&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "#ffff00",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Credley:&nbsp;
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              If no response is received within two days from the transmission
              of the email to the above, kindly send an email to:
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "#ffff00",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The Company: []
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "#ffff00",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Credley: []
            </span>
          </p>
          <p>
            <br />
          </p>
          <ol
            start={2}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={3}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Any notice or communication shall be deemed to have been
                  received at the time of transmission, or, if this time falls
                  outside business hours in the place of receipt, when business
                  hours resume. In this Clause, business hours mean 8.00am to
                  8.00pm Monday to Friday on a day that is not a public holiday
                  in the place of receipt.
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={3}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={3}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  This Clause does not apply to the service of any proceedings
                  or other documents in any legal action or, where applicable,
                  any arbitration or other method of dispute resolution.
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={8}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-10.899999999999999pt",
                paddingLeft: "10.350000000000001pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Third party rights:&nbsp;
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The rights of the parties to rescind or vary this Agreement
                  are not subject to the consent of any other person.
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={9}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Governing law
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  : This Agreement, and any dispute or claim (including
                  non-contractual disputes or claims) arising out of or in
                  connection with it or its subject matter or formation, shall
                  be governed by, and construed in accordance with, the laws of
                  the Federal Republic of Nigeria.
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={10}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Jurisdiction
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  : Each party irrevocably agrees that the courts of Nigeria
                  shall have exclusive jurisdiction to settle any dispute or
                  claim (including non-contractual disputes or claims) arising
                  out of or in connection with this Agreement or its subject
                  matter or formation.
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={11}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "8pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Dispute Resolution
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: "48px",
                }}
              >
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-61.099999999999994pt",
                    paddingLeft: "25.1pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.2",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Any difference, dispute, controversy or claim (“Dispute”)
                      which may arise between the Parties out of or in relation
                      to or in connection with this Agreement, or the breach,
                      termination, effect, validity, interpretation or
                      application of this Agreement or as to their rights,
                      duties or liabilities hereunder, other than a dispute for
                      which provisions specifically made in this Agreement, the
                      parties shall use their best endeavours to settle such
                      dispute by mutual negotiations and agreement.&nbsp;
                    </span>
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={2}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={3}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-25.099999999999994pt",
                paddingLeft: "25.1pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  If for any reason, the Parties cannot resolve such dispute
                  amicably within one month from the commencement of the
                  resolution process, such dispute shall be referred the Lagos
                  State Multidoor Courthouse for mediation. Both Parties shall
                  jointly contribute to the fees of the mediator.&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              In witness whereof, this Agreement is duly executed by the
              parties, or their duly authorised representatives as set forth
              below:
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              CREDLEY GLOBAL LIMITED
            </span>
          </p>
          {/* <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Name:
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Signature:
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Date:
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              In the Presence of&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Name:
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Address:
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Occupation:&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Signature:
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Name:
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Address:
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Occupation:&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Signature:
            </span>
          </p> */}
          <p>
            <br />
          </p>
          {/* <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              THE COMPANY&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Name:
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Signature:
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Date:
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              In the Presence of&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Name:
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Address:
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Occupation:&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Signature:
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Name:
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Address:
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Occupation:&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Signature:
            </span>
          </p> */}
        </div>
      </div>
    </div>
  );
}

export default EmployerMou;
