import moment from "moment";
import "./CoursePreview.css";
import React, { useEffect, useState } from "react";
import PrimaryBackButton from "../../../../../components/primary-back-button/PrimaryBackButton";
import {
  ICourseCurriculum,
  IWellnessCourse,
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../../../../Types";
import profileUserIcon from "../../../../../images/profile-user-icon.svg";
import moneyIcon from "../../../../../images/money-icon.svg";
import timerIcon from "../../../../../images/timer-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import {
  getCourseAssessmentForCourse,
  getCourseCurriculum,
} from "../../../../../redux/actions/wellnessActions";
import isEmpty from "../../../../../validation/isEmpty";
import LoadingSpinner from "../../../../../components/loading-spinner/LoadingSpinner";
import videoCircleIcon from "../../../../../images/video-circle-icon.svg";
import documentIcon from "../../../..../../../../images/document-icon.svg";
import CourseParticipants from "./course-participants/CourseParticipants";

// Interfaces
interface IProps {
  selectedCourseForPreview: IWellnessCourse | null;
  setSelectedCourseForPreview: React.Dispatch<
    React.SetStateAction<IWellnessCourse | null>
  >;
  setPageView: React.Dispatch<React.SetStateAction<string>>;
  setErrorHandlerObj: SetErrorHandlerType;
  setSuccessHandlerObj: SetSuccessHandlerType;
}

function CoursePreview({
  selectedCourseForPreview,
  setSelectedCourseForPreview,
  setPageView,
  setErrorHandlerObj,
  setSuccessHandlerObj,
}: IProps) {
  // Funtions, States and Variables
  const dispatch = useAppDispatch();
  const courseCurriculum: ICourseCurriculum[] | [] = useAppSelector(
    (state) => state.wellness.courseCurriculum
  );
  // States
  const [isSecondaryLoading, setIsSecondaryLoading] = useState(false);

  // useeffects
  useEffect(() => {
    if (selectedCourseForPreview) {
      dispatch(
        getCourseCurriculum(
          selectedCourseForPreview._id,
          setIsSecondaryLoading,
          setErrorHandlerObj
        )
      );
    }
  }, [selectedCourseForPreview]);

  return (
    <>
      {selectedCourseForPreview && (
        <div className="course-preview-container--inner">
          {/* Top Wrapper */}
          <div className="course-preview--top-wrapper">
            <div className="primary-back-button-container">
              {/* Back button Wrapper */}
              <PrimaryBackButton
                onClick={() => {
                  setPageView("main");
                }}
              />
              {/* Top Title */}
              <div className="primary-back-button-title">
                {selectedCourseForPreview.name}
              </div>
            </div>
          </div>
          <div className="preview-course--image-wrapper">
            <img src={selectedCourseForPreview.image} alt="" />
          </div>
          {/* Course Info */}
          <div className="preview-course--course-info">
            <div className="course-info--row">
              {/* Title */}
              <div className="course-info--course-title">
                {selectedCourseForPreview.name}
              </div>
              {/* Upload Date */}
              <div className="course-info--course-upload">
                Uploaded by Admin on{" "}
                {moment(selectedCourseForPreview.createdAt).format(
                  "Do MMM, YYYY"
                )}
              </div>
            </div>

            {/* Participants */}
            <div className="course-info--course-detail">
              <img src={profileUserIcon} alt="" />{" "}
              {selectedCourseForPreview.participants?.toLocaleString()}{" "}
              {selectedCourseForPreview.participants > 1
                ? "participants"
                : "participant"}
            </div>
            {/* Price */}
            <div className="course-info--course-detail">
              <img src={moneyIcon} alt="" /> &#8358;
              {selectedCourseForPreview.price?.toLocaleString()}
            </div>
            {/* Course Length */}
            <div className="course-info--course-detail">
              <img src={timerIcon} alt="" /> {selectedCourseForPreview.duration}
            </div>
          </div>

          {/* Description */}
          <div className="course-preview--course-description">
            <div className="course-description--title">Course Description</div>
            <div className="course-description--value">
              {selectedCourseForPreview.description}
            </div>
          </div>

          {/* Contents */}
          <div className="course-preview--course-description">
            <div className="course-description--title">Course Contents</div>
            <div className="course-description--sub-title">
              This course includes:
            </div>
            <div className="course-description--value course-contents--value">
              {!isSecondaryLoading &&
                courseCurriculum?.map((content, index) => (
                  <div key={index + 1} className="course-contents--value-inner">
                    <img src={videoCircleIcon} alt="" />
                    {content.title}
                  </div>
                ))}
              {!isSecondaryLoading &&
                selectedCourseForPreview.hasAssessment && (
                  <div className="course-contents--value-inner">
                    <img src={documentIcon} alt="" />
                    Course Assessment
                  </div>
                )}

              {isSecondaryLoading && (
                <div className="below-table-loader-wrapper">
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </div>

          {/* Participants */}
          <CourseParticipants
            selectedCourseForPreview={selectedCourseForPreview}
            setErrorHandlerObj={setErrorHandlerObj}
          />
        </div>
      )}
    </>
  );
}

export default CoursePreview;
