import React, { MouseEvent } from "react";
import styles from "./FundWalletModal.module.css";
import TextInput from "../../../../../components/input-components/text-input/TextInput";
import SubstituteLoadingSpinner from "../../../../../components/substitute-loading-spinner/SubstituteLoadingSpinner";
import { handleFormatNumberInputs } from "../../../../../utils/handleFormatNumberInputs";
import PrimaryButton from "../../../../../components/buttons/primary-button/PrimaryButton";

interface IProps {
  isModalOpened: boolean;
  fundAmount: string;
  isLoading: boolean;
  setFundAmount: React.Dispatch<React.SetStateAction<string>>;
  setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  handleTriggerFundWallet: () => void;
}

function FundWalletModal({
  isModalOpened,
  fundAmount,
  isLoading,
  setFundAmount,
  setIsModalOpened,
  handleTriggerFundWallet,
}: IProps) {
  // Functions and states
  // States

  //   Functions;
  const handleConfirmTriggerPaymentGateway = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    if (fundAmount !== "") {
      e.preventDefault();
      // Call API to send reference and on success, trigger payment gateway
      handleTriggerFundWallet();
    }
  };

  return (
    <section
      className={`${styles.modal_section}  ${isModalOpened ? styles.show : ""}`}
    >
      {/* Modal Overlay */}
      <div className={styles.modal_container}>
        <div className={`${styles.modal_content_container}`}>
          <div className={styles.fund_wallet_modal_container__inner}>
            <div className={styles.fund_wallet_modal_header}>
              Fund Wallet {isLoading && <SubstituteLoadingSpinner />}
            </div>

            {/*  form wrapper */}
            <div className={styles.form_container}>
              <form>
                {/* Amount */}
                <div className="form-group">
                  <TextInput
                    type={"text"}
                    id={"wallet-amount-text-input"}
                    className={"wallet-amount-input"}
                    placeholder={""}
                    value={handleFormatNumberInputs(fundAmount)}
                    onChange={(e) => {
                      // Remove all the commas from the number value
                      let value = e.target.value;
                      let valueWithoutCommas = value.split(",").join("");
                      setFundAmount(valueWithoutCommas);
                    }}
                    required={true}
                    label={"Enter the amount you want to fund into your wallet"}
                  />
                </div>

                <div className={styles.cta_container}>
                  {/* Fund Wallet Button */}
                  <PrimaryButton
                    placeholder="Fund Wallet"
                    onClick={(e) => handleConfirmTriggerPaymentGateway(e)}
                    className={styles.make_withdrawal_button_wrapper}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className={styles.overlay_wrapper}
          onClick={() => setIsModalOpened(false)}
        ></div>
      </div>
    </section>
  );
}

export default FundWalletModal;
