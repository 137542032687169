import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import { Link } from "react-router-dom";
import { allNavLinks, IAllNavLinks } from "../../utils/allNavLinks";

interface IProps {
  activeName: string;
}

const Sidebar: React.FC<IProps> = function ({ activeName }) {
  // Function and variables
  const [navLinksArr, setNavLinksArr] = useState<IAllNavLinks[] | null>(null);

  useEffect(() => {
    // const token = localStorage.getItem("jwtToken");
    // if (!token) {
    //   navigate("/");
    // }
  }, []);
  //
  useEffect(() => {
    setNavLinksArr((prevNavLinks) => {
      if (!allNavLinks) return null;

      const index = allNavLinks?.findIndex(
        (links) => links.name === activeName
      );

      let newAllNavLinks = [...allNavLinks];
      let currentActiveLink = allNavLinks
        ?.filter((links) => links.name === allNavLinks[index].name)
        ?.map((links) => {
          return {
            ...links,
            active: true,
          };
        });

      newAllNavLinks.splice(index, 1, currentActiveLink[0]);

      return newAllNavLinks;
    });
  }, []);

  return (
    <div className="sidebar-container">
      <div className="sidebar-container--inner">
        {/* Navigation Links Container */}
        <nav className="navigation-links-container">
          {/* Dashboard Link Wrapper */}
          {navLinksArr?.map((link, index) => (
            <div
              key={index + 1}
              className={`nav-link-container ${link.name.toLowerCase()}-link-wrapper  ${
                link.active ? "active-link-wrapper" : ""
              }`}
            >
              <Link to={link.link}>
                <img src={link.active ? link.activeIcon : link.icon} alt="" />
                {link.name}
              </Link>
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
