import React, { useContext, useEffect, useState } from "react";
import "./CreditAllEmployees.css";
import Sidebar from "../../../../../components/app-sidebar/Sidebar";
import Top from "../../../../../components/app-top/Top";
import ErrorHandler from "../../../../../components/error/ErrorHandler";
import Main from "../../../../../components/main-container/Main";
import SuccessHandler from "../../../../../components/success/SuccessHandler";
import useDisplayMessage from "../../../../../hooks/useDisplayMessage";
import PrimaryBackButton from "../../../../../components/primary-back-button/PrimaryBackButton";
import { useNavigate } from "react-router-dom";
import PaymentMethodModal from "../../../../employer-subscription/subscription-details/modals/PaymentMethodModal";
import PaymentSummary from "./payment-summary/PaymentSummary";
import { UserContext } from "../../../../../components/contexts/UserContext";
import { usePaystackPayment } from "react-paystack";
import { onPaystackClose } from "../../../../../utils/handleOnPaystackClose";
import {
  IAmountPerGroupObj,
  IGroupObjInUser,
  IPaystackReference,
} from "../../../../../Types";
import { useAppDispatch } from "../../../../../hooks";
import { creditAllEmployees } from "../../../../../redux/actions/benefitsActions";
import SubstituteLoadingSpinner from "../../../../../components/substitute-loading-spinner/SubstituteLoadingSpinner";
import isEmpty from "../../../../../validation/isEmpty";

function CreditAllEmployees() {
  // Functions, States and Variables
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user }: any = useContext(UserContext);

  // States
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [selectedVoucherCategories, setSelectedVoucherCategories] = useState<
    string[]
  >([]);
  const [isBenefitsAmountFixed, setIsBenefitsAmountFixed] = useState(false);
  const [isPaymentOptionModalOpened, setIsPaymentOptionModalOpened] =
    useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  // Paystack Configuration and Initialization
  const config = {
    reference: new Date().getTime().toString(),
    email: user?.email || "",
    amount: totalAmount * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY ?? "",
    // publicKey: "pk_test_cc4c2259b62bc75842e99e60a2e4ac12a883a840",
  };
  const initializePayment: any = usePaystackPayment(config);

  // Functions
  // handle Call API To Credit All Staff
  const handleCallAPIToCreditAllStaff = function (
    paymentMethod: string,
    reference?: IPaystackReference | null
  ) {
    // Paystack reference if it exists(if paystack was used instead of ther payment method(s))
    const { reference: referenceValue } = reference || {};
    const data = {
      amount: totalAmount,
      paymentMethod,
      reference: referenceValue || "",
      creditType: "everyOne",
      paymentGatewayResponse: reference && !isEmpty(reference) ? reference : {},
    };

    // console.log(reference, data);
    // Call API
    dispatch(
      creditAllEmployees(
        data,
        setIsPaymentLoading,
        setErrorHandlerObj,
        navigate
      )
    );
  };

  // Handles on Sucess of payment using paystack gateway (calls the credit all employees API)
  const onPaystackSuccess = (reference: IPaystackReference) => {
    // Call the API to credit all employees based on reference generated on success of payment gateway
    const paymentMethod = "paystack";
    handleCallAPIToCreditAllStaff(paymentMethod, reference);
  };

  // handle Initialize Payment To Credit ALL Staff WITH PAYSTACK
  const handleInitializePaymentWithPaystack = function () {
    // Set Error and Success message to default false
    setErrorHandlerObj({ hasError: false, message: "" });

    // Close the payment method modal
    setIsPaymentOptionModalOpened(false);

    // Initialize payment through paystack gateway || Trigger Paystack API to completed payment
    initializePayment(onPaystackSuccess, onPaystackClose);
  };

  // handle Initialize Payment To Credit ALL Staff With WALLET
  const handleInitializePaymentWithWallet = function () {
    // Set Error and Success message to default false
    setErrorHandlerObj({ hasError: false, message: "" });

    // Close the payment method modal
    setIsPaymentOptionModalOpened(false);

    // Call the API to credit selected staff based on reference generated on success of payment gateway
    const paymentMethod = "wallet";
    handleCallAPIToCreditAllStaff(paymentMethod);
  };

  //   UseEffects
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    // setSuccessHandlerObj,
  } = useDisplayMessage();

  useEffect(() => {
    // console.log(user, "POPPP");
    // Set Is Benefits Amount fixed State
    const isFixed = user?.benefits?.paymentType === "flatAmount" ? true : false;
    setIsBenefitsAmountFixed(isFixed);
    // Set Voucher Input States to values already set in settings
    setSelectedCountries(user?.benefits.countries || []);
    setSelectedVoucherCategories(user?.benefits.categories || []);

    // Calculate and Set total Amount
    if (isFixed) {
      // If payment type is Flat Amount
      const total = (user?.staff?.length || 0) * user?.benefits?.amount;
      setTotalAmount(total);
    } else {
      // Calculate the total amount by multiplying the amount by the staff count in each group and getting the sum
      const groupsAmountArr: number[] = [];
      user?.groups?.forEach((group: IGroupObjInUser) => {
        let currentStaffCount = group?.noOfStaff ?? 0;
        const groupBenefitAmount = user?.benefits.amountPerGroup?.find(
          (benefitGroup: IAmountPerGroupObj) => {
            return group._id === benefitGroup.groupId;
          }
        );
        const totalAmountForCurrentGroup =
          currentStaffCount * (groupBenefitAmount?.amount ?? 0);
        groupsAmountArr.push(totalAmountForCurrentGroup);
      });
      // Add all the total amounts per group in the array together
      const totalBenefitAmountForGroups = groupsAmountArr?.reduce(
        (acc, amount) => acc + amount,
        0
      );
      // console.log(totalBenefitAmountForGroups, "POPPPPP");
      setTotalAmount(totalBenefitAmountForGroups);
    }
  }, [user]);

  return (
    <div
      className={`credit-all-employees-container ${
        isPaymentOptionModalOpened ? "overflow-hidden" : ""
      }`}
    >
      <Top />
      <Sidebar activeName={"Welfare"} />

      {/* Gray Overlay */}
      <div
        onClick={() => {
          setIsPaymentOptionModalOpened(false);
        }}
        className={`gray-overlay ${isPaymentOptionModalOpened ? "" : "none"}`}
      ></div>

      {/* Select Payment Method Modal */}
      <PaymentMethodModal
        isPaymentOptionModalOpened={isPaymentOptionModalOpened}
        setIsPaymentOptionModalOpened={setIsPaymentOptionModalOpened}
        amount={totalAmount}
        handleInitializePaymentWithPaystack={
          handleInitializePaymentWithPaystack
        }
        handleInitializePaymentWithWallet={handleInitializePaymentWithWallet}
      />

      <Main>
        <div className="credit-all-employees-container--inner">
          {/* Error and Success Handlers */}
          <div className="employees--actions-handler-wrapper">
            <ErrorHandler errorHandlerObj={errorHandlerObj} />
            <SuccessHandler successHandlerObj={successHandlerObj} />
          </div>

          {/* Main Section */}
          <section className={`credit-all-employees-main-section`}>
            {/* Top Wrapper */}
            <div className="primary-back-button-section">
              <div className="primary-back-button-container">
                {/* Back button Wrapper */}
                <PrimaryBackButton
                  onClick={() =>
                    navigate("/employer/welfare", {
                      state: { currentPageContent: "benefits" },
                    })
                  }
                />
                {/* Top Title */}
                <div className="primary-back-button-title">
                  Credit All Employees
                </div>
              </div>

              {/* Payment Loader */}
              {isPaymentLoading && (
                <div className="payment-loader-wrapper">
                  <SubstituteLoadingSpinner />
                </div>
              )}
            </div>

            {/* Payment Summary Section || Only to be shown if payment summary is summary-page */}
            <PaymentSummary
              totalAmount={totalAmount}
              selectedCountries={selectedCountries}
              selectedVoucherCategories={selectedVoucherCategories}
              isBenefitsAmountFixed={isBenefitsAmountFixed}
              setIsPaymentOptionModalOpened={setIsPaymentOptionModalOpened}
            />

            {/*  Form Section || Only to be shown when page content is form-page*/}
            {/* {pageContent === "form-page" && (
              <section className="credit-selected-employee-form-container">
                <form>
                  Credits Amount || Groups and Fixed Amounts--COMMENTS
                  {isBenefitsAmountFixed ? (
                    <>
                      <div className="primary--selected-object-wrapper">
                        <div className="left-col">
                          <div className="object-main-text">Flat Amount </div>
                          <div className="object-sub-text">
                            ${(10000).toLocaleString()}
                          </div>
                        </div>
                      </div>
                      Credit Amount Form--COMMENTS
                      <CreditAmountForm
                        creditAmount={creditAmount}
                        setCreditAmount={setCreditAmount}
                      />
                    </>
                  ) : (
                    <>
                      Groups and Fixed Amounts --COMMENTS
                      <GroupsAndCreditAmounts
                        allGroupsAndRespectiveAmounts={
                          user?.groupsAndBenefitAmount || []
                        }
                      />
                    </>
                  )}

                  Voucher Countries and Categories Container--COMMENTS
                  <div className="voucher-countries-and-categories-container">
                    <div className="section-title">Voucher Details</div>

                    Select Voucher Countries--COMMENTS
                    <SelectVoucherCountries
                      selectedCountries={selectedCountries}
                      setSelectedCountries={setSelectedCountries}
                    />

                    Select Voucher Categories--COMMENTS
                    <SelectVoucherCategories
                      selectedVoucherCategories={selectedVoucherCategories}
                      setSelectedVoucherCategories={
                        setSelectedVoucherCategories
                      }
                    />
                  </div>

                  Credit Wallet Button--COMMENTS
                  <PrimaryButton
                    className="credit-staff-wallets-btn-wrapper"
                    placeholder="Go to Payment Summary"
                    onClick={(e) => {
                      handleProceedToSummaryPage(e);
                    }}
                  />
                </form>
              </section>
            )} */}
          </section>
        </div>
      </Main>
    </div>
  );
}

export default CreditAllEmployees;
