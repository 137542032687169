import axios from "axios";
import { GET_ALL_COMPANY_GROUPS, GET_ERRORS } from "../Constants";
import isEmpty from "../../validation/isEmpty";
import { api } from "../../api/config";
import { NavigateFunction } from "react-router-dom";
import {
  SetErrorHandlerType,
  SetIsLoadingType,
  SetSuccessHandlerType,
} from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";

interface IGroupTypes {
  name: string;
}

interface IManyGroupTypes {
  groups: { name: string }[];
}

interface IUpdateManyGroups {
  groups: { name: string; id: string }[];
}

const url = `${api}/api/v1/company`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get All Company Groups
export const getAllCompanyGroups =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/groups`)
      .then((res) => {
        dispatch({
          type: GET_ALL_COMPANY_GROUPS,
          payload: res.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Add New Company Group
export const addNewGroupToCompany =
  (
    data: IGroupTypes,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    isAddingEmployeesAfter: boolean,
    navigate: NavigateFunction,
    setIsCreateNewGroupModalOpened: React.Dispatch<
      React.SetStateAction<boolean>
    >
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/groups`, data)
      .then((res) => {
        if (isAddingEmployeesAfter) {
          navigate("/employer/employees/onboard-employees");
        } else {
          // Set success message
          setSuccessHandlerObj({
            isSuccess: true,
            message: "Group created successfully!",
          });
        }
        setIsLoading(false);
        setIsCreateNewGroupModalOpened(false);
      })
      .catch((err) => {
        setIsCreateNewGroupModalOpened(false);
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Add Many New Company Group
export const addManyNewGroupsToCompany =
  (
    data: IManyGroupTypes,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    pageType: string,
    setPageContent: React.Dispatch<React.SetStateAction<string>>,
    setIsGroupsFormFilled: SetIsLoadingType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/add-many-groups`, data)
      .then((res) => {
        // Set the form page to filled
        setIsGroupsFormFilled(true);
        // Set Page Content to awaiting dashboard data / setup done
        if (pageType === "settings") {
          setSuccessHandlerObj({
            isSuccess: true,
            message: "Company Groups updated successfully!",
          });
        } else {
          // Navigate to setup don page
          // navigate("/employer/dashboard/setup-done");
          navigate("/employer/choose-subscription-plan");
        }

        setIsLoading(false);
      })
      .catch((err) => {
        setIsGroupsFormFilled(false);
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Update Many Company Group
export const updateManyCompanyGroups =
  (
    data: IUpdateManyGroups,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    pageType: string,
    setPageContent: React.Dispatch<React.SetStateAction<string>>,
    newCompanyGroupsExists: boolean,
    setIsGroupsFormFilled: SetIsLoadingType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/update-many-groups`, data)
      .then((res) => {
        // If NO New instruments exists
        if (!newCompanyGroupsExists) {
          // Set the form page to filled
          setIsGroupsFormFilled(true);
          if (pageType === "settings") {
            setSuccessHandlerObj({
              isSuccess: true,
              message: "Company Groups updated successfully!",
            });
          } else {
            // Navigate to setup don page
            // navigate("/employer/dashboard/setup-done");
            navigate("/employer/choose-subscription-plan");
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsGroupsFormFilled(false);
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Remove Company Group
export const removeGroupFromCompany =
  (
    data: IGroupTypes,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/groups/delete`, data)
      .then((res) => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };
