import { IDropdownOption } from "../Types";

// Payment Interval Dropdown Options
export const paymentIntervalDropDown: IDropdownOption[] = [
  {
    key: "monthly",
    value: "Monthly",
  },
  {
    key: "quarterly",
    value: "Quarterly",
  },
  {
    key: "annually",
    value: "Annually",
  },
];

// Adding credits to Wallet Dropdown Options
export const addingCreditsToWalletDropdown: IDropdownOption[] = [
  { key: "rollOver", value: "Roll Over" },
  { key: "topUp", value: "Top Up" },
];

// Adding credits to Wallet Dropdown Options
export const paymentTypeDropdown: IDropdownOption[] = [
  { key: "flatAmount", value: "Flat Amount" },
  { key: "perStaffGroup", value: "Per Staff Group" },
];
