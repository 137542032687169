import moment from "moment";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import { useAppDispatch } from "../../../hooks";
import { dowloadInvoiceForSettlement } from "../../../redux/actions/settlementsActions";
import { SetErrorHandlerType } from "../../../Types";
import { numberWithCommas } from "../../../utils/numberWithCommas";
import { ISingleSettlement } from "../Settlements";

interface IProps {
  currentPosts: ISingleSettlement[] | null;
  isLoading: boolean;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  setSelectedSettlementsForPreview: React.Dispatch<
    React.SetStateAction<ISingleSettlement | null>
  >;
  setIsMarkSettlementsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorHandlerObj: SetErrorHandlerType;
}
function SettlementTable({
  currentPosts,
  isLoading,
  setPageContent,
  setSelectedSettlementsForPreview,
  setIsMarkSettlementsLoading,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  // States
  const [currentInvoiceBuffer, setCurrentInvoiceBuffer] = useState<
    string | ArrayBuffer | null
  >("");
  const [
    selectedCompanyToDownloadInvoice,
    setSelectedCompanyToDownloadInvoice,
  ] = useState("");
  //   Functions
  // handle Preview Selected Settlement
  const handlePreviewSelectedSettlement = function (
    settlement: ISingleSettlement
  ) {
    setPageContent("preview");
    setSelectedSettlementsForPreview(settlement);
  };

  //   handle View Invoice
  const handleViewInvoice = function (settlementId: string) {
    dispatch(
      dowloadInvoiceForSettlement(
        settlementId,
        setIsMarkSettlementsLoading,
        setErrorHandlerObj,
        setCurrentInvoiceBuffer
      )
    );
  };

  // Handle Download Invoice from Buffer
  const handleDownloadInvoiceFromBuffer = function (
    name: string,
    invoiceBuffer: any
  ) {
    const url = window.URL.createObjectURL(
      new Blob([new Uint8Array(invoiceBuffer).buffer])
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // UseEffects
  useEffect(() => {
    if (currentInvoiceBuffer) {
      handleDownloadInvoiceFromBuffer(
        `${selectedCompanyToDownloadInvoice}_invoice.pdf`,
        currentInvoiceBuffer
      );
    }
  }, [currentInvoiceBuffer]);
  return (
    <div className="all-settlements-table-container">
      {/* All Settlements Table Container */}
      <Table responsive="lg" className="all-settlements-table">
        <thead
          style={{
            textAlign: "left",
          }}
        >
          <tr>
            {[
              // "Date",
              "INVOICE NO.",
              "AMOUNT",
              "DUE DATE",
              "STATUS",
              "",
              "",
            ].map((COLUMN, index) => (
              <th key={index + 1}>{COLUMN}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {currentPosts?.map((settlement, i) => (
            <tr key={i + 1}>
              {/* Reference Column */}
              <td
                className="td-reference"
                onClick={() => {
                  handlePreviewSelectedSettlement(settlement);
                }}
              >
                <div className="td-reference--wrapper">
                  {settlement.invoiceNumber}
                </div>
              </td>

              {/* Amount Column*/}
              <td
                className="td-amount"
                onClick={() => {
                  handlePreviewSelectedSettlement(settlement);
                }}
              >
                <div className="td-amount--wrapper">
                  &#8358;{numberWithCommas(settlement.amount)}
                </div>
              </td>

              {/* Next due date Column*/}
              <td
                className="td-next-due-date"
                onClick={() => {
                  handlePreviewSelectedSettlement(settlement);
                }}
              >
                <div className="td-next-due-date--wrapper">
                  {moment(settlement.createdAt).format("Do MMM, YY")}
                </div>
              </td>

              {/* Settlement Status Column*/}
              <td
                className="td-settlements-status"
                onClick={() => {
                  handlePreviewSelectedSettlement(settlement);
                }}
              >
                <div
                  className={`td-settlements-status--wrapper  ${
                    settlement.status === 2
                      ? "request-completed-wrapper"
                      : settlement.status === 1
                      ? "request-rejected-wrapper"
                      : ""
                  }`}
                >
                  {settlement.status === 2
                    ? "Completed"
                    : settlement.status === 1
                    ? "Pending"
                    : ""}
                </div>
              </td>

              {/* Pay Button Column*/}
              <td className="td-pay-settlement">
                <div className="td-pay-settlement--wrapper">
                  {settlement.status !== 2 ? (
                    <a
                      href={settlement.paystackResponse.authorization_url}
                      target="_blank"
                      rel={"noreferrer"}
                    >
                      Pay
                    </a>
                  ) : (
                    <span>Paid</span>
                  )}
                </div>
              </td>

              {/* Pay Button Column*/}
              <td className="td-view-invoice">
                <div className="td-view-invoice--wrapper">
                  <button
                    onClick={() => {
                      setSelectedCompanyToDownloadInvoice(
                        settlement.invoiceData.client.company
                      );
                      handleViewInvoice(settlement._id);
                    }}
                  >
                    View invoice
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="below-table-loader-wrapper">
        {isLoading && <LoadingSpinner />}
      </div>
    </div>
  );
}

export default SettlementTable;
