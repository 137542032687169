import React, { useEffect, useState } from "react";
import "./OnboardEmployees.css";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../app-navbar/Navbar";
import Top from "../app-top/Top";
import angleLeftIcon from "../../images/angle-left.svg";
import Sidebar from "../app-sidebar/Sidebar";
import Main from "../main-container/Main";
import ErrorHandler from "../error/ErrorHandler";
import SuccessHandler from "../success/SuccessHandler";
import useDisplayMessage from "../../hooks/useDisplayMessage";

function OnboardEmployees() {
  // Functions, States and Variables
  const navigate = useNavigate();
  const location = useLocation();

  // UseEffects
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  useEffect(() => {
    if (location.state) {
      const { message }: any = location.state;
      if (message) setSuccessHandlerObj({ isSuccess: true, message });
    }

    // Cleanup fxn that clears the location state
    return () => {
      window.history.replaceState({}, document.title);
    };
  }, [location]);

  return (
    <div className="onboard-employees-container">
      <Top />
      <Sidebar activeName={"Employees"} />

      <Main>
        <div className="onboard-employees-container--inner">
          {/* Error and Success Handlers */}
          <div className="employees--actions-handler-wrapper">
            {/* Error handler component */}
            <ErrorHandler errorHandlerObj={errorHandlerObj} />
            {/* Success handler component */}
            <SuccessHandler successHandlerObj={successHandlerObj} />
          </div>

          {/* Onboard Employees Top */}
          <div className="onboard-employees-top-container">
            {/* Back button Wrapper */}
            <div className="back-button-wrapper">
              <button onClick={() => navigate("/employer/employees")}>
                <img src={angleLeftIcon} alt="" />
              </button>
            </div>

            {/* Onboard Employees Title */}
            <div className="dashboard-setup-top-title">Onboard Employees</div>
          </div>

          <section className="onboard-employees-container-main-section">
            <div className="onboard-employees-action-buttons">
              {/* Single Upload Button Wrapper */}
              <div className="single-upload-button-wrapper onboard-employees-action-button-wrapper">
                <button
                  onClick={() =>
                    navigate("/employer/employees/onboard-employees/single")
                  }
                >
                  Single Upload
                </button>
              </div>
              {/* Bulk Upload Button Wrapper */}
              <div className="bulk-upload-button-wrapper onboard-employees-action-button-wrapper">
                <button
                  onClick={() =>
                    navigate("/employer/employees/onboard-employees/bulk")
                  }
                >
                  Bulk Upload
                </button>
              </div>
            </div>
          </section>
        </div>
      </Main>
    </div>
  );
}

export default OnboardEmployees;
