import axios from "axios";
import {
  GET_DISBURSED_APPROVED_DECLINED_AMOUNT,
  GET_DISBURSED_APPROVED_DECLINED_AMOUNT_PER_WEEK,
  GET_LAST_TWO_PAYOUTS,
  GET_PERCENTAGE_APPROVED_DECLINED_REQUESTS,
  GET_PERCENTAGE_STAFF_USAGE,
  GET_TOTAL_DEBT,
} from "../Constants";
import { api } from "../../api/config";
import { SetErrorHandlerType, SetIsLoadingType } from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";

const url = `${api}/api/v1/company`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get Total Debt
export const getTotalDebt =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/total-debt`)
      .then((res) => {
        dispatch({
          type: GET_TOTAL_DEBT,
          payload: res.data.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get Last Two Payouts
export const getLastTwoPayouts =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/last-two-payouts`)
      .then((res) => {
        dispatch({
          type: GET_LAST_TWO_PAYOUTS,
          payload: res.data.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get Percentage Staff Usage
export const getPercentageStaffUsage =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/percentage-of-staff-usage`)
      .then((res) => {
        dispatch({
          type: GET_PERCENTAGE_STAFF_USAGE,
          payload: res.data.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get Percentage Approved, Declined Requests
export const getPercentageApprovedDeclinedRequests =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/percentage-of-approve-declined-requests`)
      .then((res) => {
        dispatch({
          type: GET_PERCENTAGE_APPROVED_DECLINED_REQUESTS,
          payload: res.data.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get Disbursed, Approved, and Declined Amount
export const getDisbursedApprovedDeclinedAmount =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/disbursed-approve-and-declined-amount`)
      .then((res) => {
        dispatch({
          type: GET_DISBURSED_APPROVED_DECLINED_AMOUNT,
          payload: res.data.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get Disbursed, Approved, and Declined Amount Per Week
export const getDisbursedApprovedDeclinedAmountPerWeek =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/disbursed-approve-and-declined-amount-per-week`)
      .then((res) => {
        dispatch({
          type: GET_DISBURSED_APPROVED_DECLINED_AMOUNT_PER_WEEK,
          payload: res.data.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };
