import React, { createContext, useState } from "react";

interface ISearchContextProviderProps {
  children: React.ReactNode;
}

export interface ISearchContextType {
  searchedValue: string;
  setSearchedValue: React.Dispatch<React.SetStateAction<string>>;
}

export const SearchContext = createContext<ISearchContextType | null>(null);
function SearchContextProvider({ children }: ISearchContextProviderProps) {
  const [searchedValue, setSearchedValue] = useState("");

  return (
    <SearchContext.Provider value={{ searchedValue, setSearchedValue }}>
      {children}
    </SearchContext.Provider>
  );
}

export default SearchContextProvider;
