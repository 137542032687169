import React from "react";
import "./PrimaryBackButton.css";
import angleLeftIcon from "../../images/angle-left.svg";

// Interfaces
interface IProps {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

function PrimaryBackButton({ onClick }: IProps) {
  return (
    <div className="primary-back-button-wrapper">
      <button onClick={onClick}>
        <img src={angleLeftIcon} alt="" />
      </button>
    </div>
  );
}

export default PrimaryBackButton;
