import React, { MouseEvent, useEffect, useState } from "react";
import "./SendMessage.css";
import Sidebar from "../../../../../components/app-sidebar/Sidebar";
import Top from "../../../../../components/app-top/Top";
import ErrorHandler from "../../../../../components/error/ErrorHandler";
import Main from "../../../../../components/main-container/Main";
import SuccessHandler from "../../../../../components/success/SuccessHandler";
import useDisplayMessage from "../../../../../hooks/useDisplayMessage";
import PrimaryBackButton from "../../../../../components/primary-back-button/PrimaryBackButton";
import { useLocation, useNavigate } from "react-router-dom";
import SendMessageForm from "./form/SendMessageForm";
import { IDropdownOption, ITemplateObj } from "../../../../../Types";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { getAllCompanyStaffs } from "../../../../../redux/actions/staffActions";
import { ISingleEmployee } from "../../../../employer-settlements/Settlements";
import SelectTemplate from "../comps/select-template/SelectTemplate";
import SelectNotificationViewers from "../comps/select-notification-viewers/SelectNotificationViewers";
import isEmpty from "../../../../../validation/isEmpty";
import {
  celebrateAllEmployees,
  celebrateManyStaff,
} from "../../../../../redux/actions/celebrationsActions";

// Interfaces
export interface IFormInputStates {
  staff: string[];
  message: string;
}

function SendMessage() {
  // Functions, States and Variables
  const allStaff: ISingleEmployee[] | [] = useAppSelector(
    (state) => state.staff.staffs
  );
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  //   States
  const [isForAllStaff, setIsForAllStaff] = useState(false);
  const [formInputStates, setFormInputStates] = useState<IFormInputStates>({
    staff: [],
    message: "",
  });
  const [allStaffDropdown, setAllStaffDropDown] = useState<
    IDropdownOption[] | []
  >([]);
  // Selecting Templates
  const [selectedTemplate, setSelectedTemplate] = useState<ITemplateObj | null>(
    null
  );
  // Selecting Notification Viewes -- Values - Single, All
  const [notificationViewers, setNotificationViewers] = useState("Single");
  const [isLoading, setIsLoading] = useState(false);
  const [isSendingLoading, setIsSendingLoading] = useState(false);

  //   Functions
  const handleSendMessage = function (e: MouseEvent<HTMLButtonElement>) {
    const isFormEmpty = Object.keys(formInputStates).some(
      (inputKeys: string) =>
        formInputStates[inputKeys as keyof typeof formInputStates] === ""
    );

    if (!isFormEmpty) {
      e.preventDefault();

      // If a Template image was not choosen
      // if (!selectedTemplate) {
      //   setErrorHandlerObj({
      //     hasError: true,
      //     message: "You need to select a template image to proceed!",
      //   });
      // } else {
      // If type is celebrate selected staff and no staff was selected
      if (isEmpty(formInputStates.staff)) {
        setErrorHandlerObj({
          hasError: true,
          message: "You have to select at least an employee to proceed!",
        });
      } else {
        if (isForAllStaff) {
          // For All staff
          const data = {
            type: "text", //text,credit
            totalAmount: 0,
            amountPerStaff: 0,
            message: formInputStates.message,
            paymentMethod: "paystack",
            reference: "",
            paymentGatewayResponse: {},
            celebrationTemplateId: selectedTemplate?._id || "",
            shouldAllStaffSee: true,
          };
          // Call API to celebrate Many Staff
          dispatch(
            celebrateAllEmployees(
              data,
              setIsSendingLoading,
              setErrorHandlerObj,
              navigate
            )
          );
        } else {
          // For Dynamic number of staff
          const staffIdAndAmountArr = formInputStates.staff.map((staff) => {
            return {
              staff,
              amount: 0,
            };
          });
          const data = {
            staffIds: staffIdAndAmountArr,
            type: "text", //text,credit
            amount: 0,
            message: formInputStates.message,
            paymentMethod: "paystack",
            reference: "",
            paymentGatewayResponse: {},
            celebrationTemplateId: "644423d45b3a972777110f58",
            shouldAllStaffSee: true,
          };
          // Call API to celebrate Many Staff
          dispatch(
            celebrateManyStaff(
              data,
              setIsSendingLoading,
              setErrorHandlerObj,
              navigate
            )
          );
        }
        // }
      }
    }
  };

  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    // setSuccessHandlerObj,
  } = useDisplayMessage();

  //   UseEffects
  useEffect(() => {
    dispatch(getAllCompanyStaffs(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    if (allStaff) {
      const allStaffDropdownArr = allStaff?.map((staff: ISingleEmployee) => {
        return {
          key: staff._id,
          value: `${staff.firstName} ${staff.lastName}`,
        };
      });
      //   Sort the dropdown values alphabetically by firstname
      let sortedAllStaffDropdownArr: IDropdownOption[] =
        allStaffDropdownArr.sort((a: any, b: any) => {
          let splittedFirstnameA = a.value.split("")[0];
          let splittedFirstnameB = b.value.split("")[0];
          let Ca = splittedFirstnameA.toLowerCase();
          let Cb = splittedFirstnameB.toLowerCase();
          if (Ca < Cb) return -1;
          if (Ca > Cb) return 1;
          return 0;
        });
      setAllStaffDropDown(sortedAllStaffDropdownArr);
    }
  }, [allStaff]);

  useEffect(() => {
    if (location.state) {
      const { isCelebrationForAllStaff }: any = location.state;
      if (isCelebrationForAllStaff) {
        // Set Is For All Staff state to true
        setIsForAllStaff(true);
        // Set Employee Name Value to All (for validation sake)
        setFormInputStates((prevInpuStates) => {
          if (!prevInpuStates) return prevInpuStates;
          const updatedInputStates: IFormInputStates = {
            ...formInputStates,
            staff: ["all"],
          };
          console.log(updatedInputStates);
          return updatedInputStates;
        });
      }
    }
  }, [location]);

  return (
    <div className="celebration-type-container">
      <Top />
      <Sidebar activeName={"Welfare"} />

      <Main>
        <div className="celebration-type-container--inner">
          {/* Error and Success Handlers */}
          <div className="employees--actions-handler-wrapper">
            {/* Error handler component */}
            <ErrorHandler errorHandlerObj={errorHandlerObj} />
            {/* Success handler component */}
            <SuccessHandler successHandlerObj={successHandlerObj} />
          </div>

          {/* Main Section */}
          <section className={`celebration-type-main-section`}>
            <div className="primary-back-button-container celebration-type--top-wrapper">
              {/* Back button Wrapper */}
              <PrimaryBackButton
                onClick={() =>
                  navigate("/employer/welfare", {
                    state: {
                      currentPageContent: "celebrations",
                    },
                  })
                }
              />
              {/* Top Title */}
              <div className="primary-back-button-title">
                Send Message {isForAllStaff && <span>(all staff)</span>}
              </div>
            </div>

            {/* Form Container */}
            <div className="celebration-type-form-container">
              {/* Select Template Option */}
              {/* <SelectTemplate
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                setErrorHandlerObj={setErrorHandlerObj}
              /> */}

              {/* Select Notification Viewers */}
              <SelectNotificationViewers
                notificationViewers={notificationViewers}
                setNotificationViewers={setNotificationViewers}
              />

              <SendMessageForm
                allStaff={allStaff}
                allStaffDropdown={allStaffDropdown}
                formInputStates={formInputStates}
                isForAllStaff={isForAllStaff}
                isSendingLoading={isSendingLoading}
                setFormInputStates={setFormInputStates}
                handleSendMessage={handleSendMessage}
              />
            </div>
          </section>
        </div>
      </Main>
    </div>
  );
}

export default SendMessage;
