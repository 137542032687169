import {
  GET_ALL_COURSES,
  GET_COURSE_ASSESSMENT_FOR_COURSE,
  GET_COURSE_CURRICULUM,
  GET_COURSE_PROGRESS,
} from "../Constants";

const initialState = {
  courses: [],
  courseCurriculum: [],
  courseAssessment: [],
  courseProgress: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state = initialState,
  action: { type?: string; payload?: any }
) {
  switch (action.type) {
    case GET_ALL_COURSES:
      return {
        ...state,
        courses: action.payload,
      };
    case GET_COURSE_CURRICULUM:
      return {
        ...state,
        courseCurriculum: action.payload,
      };
    case GET_COURSE_ASSESSMENT_FOR_COURSE:
      return {
        ...state,
        courseAssessment: action.payload,
      };
    case GET_COURSE_PROGRESS:
      return {
        ...state,
        courseProgress: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
