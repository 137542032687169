import React, { useState } from "react";
import Top from "../../components/app-top/Top";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Main from "../../components/main-container/Main";
import WalletBalanceBox from "./comps/wallet-balance-box/WalletBalanceBox";
import ErrorHandler from "../../components/error/ErrorHandler";
import SuccessHandler from "../../components/success/SuccessHandler";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import TransactionsHistoryTable from "./comps/transactions-history-table/TransactionsHistoryTable";

function Wallet() {
  // Functions, States and Variables
  const [historyFilterOption, setHistoryFilterOption] = useState("all");

  // Hooks
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();
  return (
    <div className="employer-dashboard-container">
      <Top />
      <Sidebar activeName={"Wallet"} />

      <Main>
        <div className="employer-dashboard-container--inner">
          {/* Error and Success Handlers */}
          <div className="employees--actions-handler-wrapper">
            <ErrorHandler errorHandlerObj={errorHandlerObj} />
            <SuccessHandler successHandlerObj={successHandlerObj} />
          </div>

          {/* Wallet Balance Box */}
          <WalletBalanceBox
            historyFilterOption={historyFilterOption}
            setHistoryFilterOption={setHistoryFilterOption}
            setErrorHandlerObj={setErrorHandlerObj}
            setSuccessHandlerObj={setSuccessHandlerObj}
          />

          {/* Transactions History Table */}
          <TransactionsHistoryTable
            historyFilterOption={historyFilterOption}
            setHistoryFilterOption={setHistoryFilterOption}
            setErrorHandlerObj={setErrorHandlerObj}
          />
        </div>
      </Main>
    </div>
  );
}

export default Wallet;
