import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  // Functions, States, Variables
  const location = useLocation();

  //   States
  const [isDashboardActive, setIsDashboardActive] = useState(false);
  const [isEmployeesActive, setIsEmployeesActive] = useState(false);
  const [isOnboardingActive, setIsOnboardingActive] = useState(false);
  const [isRequestsActive, setIsRequestsActive] = useState(false);
  const [isSettlementsActive, setIsSettlementsActive] = useState(false);

  useEffect(() => {
    if (
      location.pathname.includes("dashboard") ||
      location.pathname.includes("awaiting-approval")
    ) {
      setIsDashboardActive(true);
    } else if (location.pathname.includes("employees")) {
      setIsEmployeesActive(true);
    } else if (location.pathname.includes("onboarding")) {
      setIsOnboardingActive(true);
    } else if (location.pathname.includes("requests")) {
      setIsRequestsActive(true);
    } else if (location.pathname.includes("settlements")) {
      setIsSettlementsActive(true);
    }
  }, [location]);
  return (
    <div className="app-navbar-container">
      <div className="app-navbar-container--inner">
        {/* Navbar Links  Wrapper Container */}
        <div className="navbar-links-wrapper-container">
          {/* Dashboard Link Wrapper */}
          <div
            className={`navbar-link-wrapper navbar-dashboard-link-wrrapper ${
              isDashboardActive && "active-navbar-link"
            }`}
          >
            <Link to="/employer/dashboard">Dashboard</Link>
          </div>

          {/* Employees Wrapper */}
          <div
            className={`navbar-link-wrapper navbar-employees-wrrapper ${
              isEmployeesActive && "active-navbar-link"
            }`}
          >
            <Link to="/employer/employees">Employees</Link>
          </div>

          {/* Onboarding Link Wrapper */}
          {/* <div
            className={`navbar-link-wrapper navbar-onboarding-wrrapper  ${
              isOnboardingActive && "active-navbar-link"
            }`}
          >
            <Link to="/employer/onboarding">Onboarding</Link>
          </div> */}

          {/* Requests Link Wrapper */}
          <div
            className={`navbar-link-wrapper navbar-requests-wrrapper  ${
              isRequestsActive && "active-navbar-link"
            }`}
          >
            <Link to="/employer/requests">Requests</Link>
          </div>

          {/* Settlements Link Wrapper */}
          <div
            className={`navbar-link-wrapper navbar-settlements-wrrapper  ${
              isSettlementsActive && "active-navbar-link"
            }`}
          >
            <Link to="/employer/settlements">Settlements</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
