import React from "react";
import TextInput from "../../../../components/input-components/text-input/TextInput";
import { IFormInputStates } from "../SubscriptionDetails";
import "./BillingFormatForm.css";

// Interfaces
interface IProps {
  formInputStates: IFormInputStates;
  setFormInputStates: React.Dispatch<React.SetStateAction<IFormInputStates>>;
  handleUpdateSummaryData: (
    billingDateMultiplicator: number,
    numberOfEmployees?: string | undefined
  ) => void;
  handleSetNextBillingDate: (monthsCount: number) => void;
}

function BillingFormatForm({
  formInputStates,
  setFormInputStates,
  handleUpdateSummaryData,
  handleSetNextBillingDate,
}: IProps) {
  return (
    <div className="billing-format-form-container">
      <div className="subscription-detail-title">Billing Format</div>

      <div className="billing-date-format-form-wrapper">
        {/* Monthly */}
        <div className="form-group">
          <label>
            <input
              type="radio"
              value="Monthly"
              checked={formInputStates.billingDateFormat === "Monthly"}
              onChange={(e) => {
                setFormInputStates((prevInputStates) => {
                  const updatedInputStates: IFormInputStates = {
                    ...prevInputStates,
                    billingDateFormat: e.target.value,
                  };
                  return updatedInputStates;
                });
                handleUpdateSummaryData(1);
                handleSetNextBillingDate(1);
              }}
            />
            Monthly Billing
          </label>
        </div>
        {/* Yearly */}
        <div className="form-group">
          <label>
            <input
              type="radio"
              value="Yearly"
              checked={formInputStates.billingDateFormat === "Yearly"}
              onChange={(e) => {
                setFormInputStates((prevInputStates) => {
                  const updatedInputStates: IFormInputStates = {
                    ...prevInputStates,
                    billingDateFormat: e.target.value,
                  };
                  return updatedInputStates;
                });
                handleUpdateSummaryData(12);
                handleSetNextBillingDate(12);
              }}
            />
            Yearly Billing
          </label>
        </div>
      </div>

      <div className="form-group employees-no-form-group">
        <TextInput
          type={"number"}
          id={"employees-no-text-input"}
          className={"employees-no-input"}
          placeholder={""}
          value={formInputStates.numberOfEmployees}
          onChange={(e) => {
            setFormInputStates((prevInpuStates) => {
              const updatedInputStates: IFormInputStates = {
                ...formInputStates,
                numberOfEmployees: e.target.value,
              };
              return updatedInputStates;
            });
            const currentBillingDateMultiplicator =
              formInputStates.billingDateFormat === "Monthly" ? 1 : 12;
            handleUpdateSummaryData(
              currentBillingDateMultiplicator,
              e.target.value
            );
          }}
          required={true}
          label={"Number of Employees"}
          min={"1"}
        />
      </div>
    </div>
  );
}

export default BillingFormatForm;
