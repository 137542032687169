import React, { useContext, useEffect, useState } from "react";
import "./Wellness.css";
import { SearchContext } from "../../../../components/contexts/SearchContext";
import {
  IWellnessCourse,
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../../../Types";
import profileUserIcon from "../../../../images/profile-user-icon.svg";
import moneyIcon from "../../../../images/money-icon.svg";
import timerIcon from "../../../../images/timer-icon.svg";
import isEmpty from "../../../../validation/isEmpty";
import CoursePreview from "./course-preview/CoursePreview";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { getAllCourses } from "../../../../redux/actions/wellnessActions";
import { handleShortenNumber } from "../../../../utils/handleShortenNumber";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import FEPagination from "../../../../components/pagination/fe-pagination/FEPagination";

// Interfaces
interface IProps {
  pageContent: string;
  setErrorHandlerObj: SetErrorHandlerType;
  setSuccessHandlerObj: SetSuccessHandlerType;
}

function Wellness({
  pageContent,
  setErrorHandlerObj,
  setSuccessHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const { searchedValue }: any = useContext(SearchContext);
  const courses: IWellnessCourse[] | [] = useAppSelector(
    (state) => state.wellness.courses
  );
  //   States
  const [pageView, setPageView] = useState("main");
  const [allCourses, setAllCourses] = useState<IWellnessCourse[] | null>(null);
  const [currentCoursesPosts, setCurrentCoursesPosts] = useState<
    IWellnessCourse[] | null
  >(null);
  const [selectedCourseForPreview, setSelectedCourseForPreview] =
    useState<IWellnessCourse | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;
  const [isLoading, setIsLoading] = useState(false);
  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handlePreviewSelectedCourse = function (course: IWellnessCourse) {
    setPageView("preview");
    setSelectedCourseForPreview(course);
  };

  //   Useeffects
  useEffect(() => {
    if (courses) setAllCourses(courses);
  }, [courses]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (allCourses) {
      const currentRows = allCourses?.slice(indexOfFirstPost, indexOfLastPost);
      setCurrentCoursesPosts(currentRows);
    }
  }, [allCourses, currentPage]);

  useEffect(() => {
    // Set Current Page to 1 to avoid empty screen on search match bug
    setCurrentPage(1);
    // Filter through courses based on search value
    const coursesData = courses?.filter((course) =>
      course.name.toLowerCase().includes(searchedValue.toLowerCase())
    );

    // handle update of courses state based on searched value
    if (coursesData) {
      // const currentRows = coursesData.slice(indexOfFirstPost, indexOfLastPost);
      setAllCourses(coursesData);
    }
  }, [searchedValue]);

  useEffect(() => {
    // console.log(courses);
    if (courses) setAllCourses(courses);
  }, [courses]);

  useEffect(() => {
    dispatch(getAllCourses(setIsLoading, setErrorHandlerObj));
  }, []);

  return (
    <section
      className={`welfare-table-section ${
        pageContent === "wellness" ? "" : "none"
      }`}
    >
      {/* Main Container || Only shows when page view is "main"*/}
      <section
        className={`wellness-main-container ${
          pageView === "main" ? "" : "none"
        }`}
      >
        <div className="welfare--title-wrapper">
          All Courses <span>({allCourses?.length})</span>
        </div>

        {/* Courses Container */}
        <div className="wellness-courses-container">
          {currentCoursesPosts?.map((course, index) => (
            <button
              key={index + 1}
              onClick={() => handlePreviewSelectedCourse(course)}
              className="wellness-courses-wrapper"
            >
              {/* Coin Images */}
              <div className="course-image-wrapper">
                <img src={course.image} alt="" />
              </div>
              {/* Course Details */}
              <div className="course-box--lower">
                <div className="course-box--name">{course.name}</div>
                <div className="course-card-footer">
                  {/* Course Participant Count */}
                  <div className="course-box--footer-count">
                    <img src={profileUserIcon} alt="" />
                    {course.participants?.toLocaleString()}
                  </div>
                  {/* Course Cost  */}
                  <div className="course-box--footer-count">
                    <img src={moneyIcon} alt="" />
                    {handleShortenNumber(course.price)}
                  </div>
                  {/* Course Time Length  */}
                  <div className="course-box--footer-count">
                    <img src={timerIcon} alt="" />
                    {course.duration}
                  </div>
                </div>
              </div>
            </button>
          ))}
        </div>

        {isLoading && (
          <div className="below-table-loader-wrapper">
            <LoadingSpinner />
          </div>
        )}

        {allCourses && !isEmpty(currentCoursesPosts) && (
          <FEPagination
            paginate={paginate}
            rowsPerPage={rowsPerPage}
            totalPosts={allCourses.length}
            currentPage={currentPage}
          />
        )}
      </section>

      {/* Selected Course Preview*/}
      {pageView === "preview" && (
        <section className="course-preview-container">
          <CoursePreview
            selectedCourseForPreview={selectedCourseForPreview}
            setSelectedCourseForPreview={setSelectedCourseForPreview}
            setPageView={setPageView}
            setErrorHandlerObj={setErrorHandlerObj}
            setSuccessHandlerObj={setSuccessHandlerObj}
          />
        </section>
      )}
    </section>
  );
}

export default Wellness;
