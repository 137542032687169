import React from "react";
import Navbar from "../app-navbar/Navbar";
import Top from "../app-top/Top";
import "./AccountAwaitingApproval.css";
import smileEmojiIcon from "../../images/smile-emoji.png";

function AccountAwaitingApproval() {
  return (
    <>
      <Top />
      <Navbar />
      <section className="account-awaiting-approval-section">
        {/* Container Header  */}
        <div className="account-awaiting-approval-section-header">
          <div className="account-awaiting-approval-section-header-title">
            Just give us one sec <img src={smileEmojiIcon} alt="" />
          </div>

          <div className="account-awaiting-approval-section-header-sub-title">
            Your Account is currently undergoing approval
          </div>
        </div>
      </section>
    </>
  );
}

export default AccountAwaitingApproval;
