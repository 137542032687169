import React, { useEffect, useState } from "react";
import "./EmployeeProfile.css";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../../../../../components/app-sidebar/Sidebar";
import Top from "../../../../../../components/app-top/Top";
import ErrorHandler from "../../../../../../components/error/ErrorHandler";
import Main from "../../../../../../components/main-container/Main";
import PrimaryBackButton from "../../../../../../components/primary-back-button/PrimaryBackButton";
import { ISingleEmployee } from "../../../../../employer-settlements/Settlements";
import PrimaryButton from "../../../../../../components/buttons/primary-button/PrimaryButton";
import EmployeeInfo from "./employee-details/EmployeeInfo";
import RecentWalletTrancactions from "./recent-wallet-transactions/RecentWalletTrancactions";

function EmployeeProfile() {
  // Functions, States and variables
  const navigate = useNavigate();
  const location = useLocation();
  //    States
  const [currentStaff, setCurrentStaff] = useState<ISingleEmployee | null>(
    null
  );
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });

  // UseEffects
  useEffect(() => {
    //scroll to page top
    if (errorHandlerObj.hasError)
      window.scrollTo({ top: 0, behavior: "smooth" });
  }, [errorHandlerObj]);

  useEffect(() => {
    if (location.state) {
      const { selectedStaff }: any = location.state;
      console.log(selectedStaff);
      if (selectedStaff) setCurrentStaff(selectedStaff);
    } else {
      // If Location contains no state object
      navigate("/employer/welfare/benefits/credit-new-wallet/employee-list");
    }
    // Scroll to page top
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  return (
    <div className="employee-profile-container">
      <Top />
      <Sidebar activeName={"Welfare"} />

      <Main>
        {currentStaff && (
          <div className="employee-profile-container--inner">
            {/* Error and Success Handlers */}
            <div className="employees--actions-handler-wrapper">
              <ErrorHandler errorHandlerObj={errorHandlerObj} />
            </div>

            {/* Main Section */}
            <section className={`employee-profile-main-section`}>
              {/* Top Wrapper */}
              <div className="employee-profile--top-wrapper">
                <div className="primary-back-button-container">
                  {/* Back button Wrapper */}
                  <PrimaryBackButton onClick={() => navigate(-1)} />
                  {/* Top Title */}
                  <div className="primary-back-button-title">
                    {currentStaff.firstName} {currentStaff.lastName}
                  </div>
                </div>

                <PrimaryButton
                  className="credit-new-wallet-btn-wrapper"
                  placeholder="Credit Wallet"
                  onClick={() => {
                    navigate("/employer/welfare/benefits/credit-new-wallet", {
                      state: { selectedStaff: currentStaff },
                    });
                  }}
                />
              </div>

              {/* Employee Personal and Wallet Details */}
              <EmployeeInfo currentStaff={currentStaff} />

              {/* Recent Wallet Transaction */}
              <RecentWalletTrancactions
                staffId={currentStaff._id}
                setErrorHandlerObj={setErrorHandlerObj}
              />
            </section>
          </div>
        )}
      </Main>
    </div>
  );
}

export default EmployeeProfile;
