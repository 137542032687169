import React from "react";
import moment from "moment";
import { Table } from "react-bootstrap";
import LoadingSpinner from "../../../../../../../../components/loading-spinner/LoadingSpinner";
import { IBenefitWalletHistory } from "../../../../../../../../Types";
import isEmpty from "../../../../../../../../validation/isEmpty";
import FEPagination from "../../../../../../../../components/pagination/fe-pagination/FEPagination";

// Interfaces
interface IProps {
  transactionHistory: IBenefitWalletHistory[] | null;
  currentPosts: IBenefitWalletHistory[] | null;
  isLoading: boolean;
  paginate: (pageNumber: number) => void;
  currentPage: number;
  rowsPerPage: number;
}

function WalletHistoryTable({
  transactionHistory,
  currentPosts,
  isLoading,
  paginate,
  currentPage,
  rowsPerPage,
}: IProps) {
  // Functions, States and Variables
  return (
    <>
      <div className="primary-table-container">
        <Table
          responsive="lg"
          className="primary-table all-benefits-request-table"
        >
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {["TYPE", "DATE", "AMOUNT"].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {!isLoading &&
              currentPosts?.map((history, i) => (
                <tr key={i + 1}>
                  {/*  Type Column */}
                  <td className="td-type">
                    <div
                      className={`td-type--wrapper ${
                        history.transactionType === "credit"
                          ? "credit-type-wrapper"
                          : "debit-type-wrapper"
                      }`}
                    >
                      {history.transactionType === "credit"
                        ? "Credit"
                        : "Debit"}
                    </div>
                  </td>

                  {/* Date Column*/}
                  <td className="td-date">
                    <div className="td-date--wrapper">
                      {moment(history.createdAt).format("Do MMM, YY")}
                    </div>
                  </td>

                  {/* Action Column */}
                  {/* <td className="td-action">
                  <div className={`td-action--wrapper`}>{history.action}</div>
                </td> */}

                  {/* Amount Column */}
                  <td className="td-amount">
                    <div className="td-amount--wrapper">
                      &#8358;{history.amount.toLocaleString()}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {transactionHistory && !isEmpty(currentPosts) && (
        <FEPagination
          paginate={paginate}
          rowsPerPage={rowsPerPage}
          totalPosts={transactionHistory.length}
          currentPage={currentPage}
        />
      )}
    </>
  );
}

export default WalletHistoryTable;
