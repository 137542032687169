import React, { useState, useEffect, MouseEvent, useContext } from "react";
import { useNavigate } from "react-router-dom";
import angleLeftIcon from "../../../images/angle-left.svg";
import TextInput from "../../input-components/text-input/TextInput";
import jwt_decode from "jwt-decode";
import ErrorHandler from "../../error/ErrorHandler";
import SuccessHandler from "../../success/SuccessHandler";
import { IPageStateObj } from "../../dashboard-comps/dashboard-setup/DashboardSetup";
import copyIcon from "../../../images/copy-icon.svg";
import { useAppDispatch } from "../../../hooks";
import { getCurrentUser, logoutUser } from "../../../redux/actions/authActions";
import LoadingSpinner from "../../loading-spinner/LoadingSpinner";
import { addNewFlatSalaryLoanRate } from "../../../redux/actions/salaryLoanRateActions";
import useDisplayMessage from "../../../hooks/useDisplayMessage";
import SetupNavigation from "./navigation/SetupNavigation";
import { ordinal_suffix_of } from "../../../utils/ordinalSuffix";
import BenefitsForm from "./subpages/benefits/BenefitsForm";
import {
  IBenefitsSetupForm,
  ICompanyGroupsObj,
  IInstrumentsRanges,
} from "../../../Types";
import APIConfiguration from "./subpages/api-configuration/APIConfiguration";
import AccountDetailsForm from "./subpages/account-details/AccountDetailsForm";
import InstrumentsSetupForm from "./subpages/instruments-setup/InstrumentsSetupForm";
import GroupsSetupForm from "./subpages/groups-setup/GroupsSetupForm";
import { UserContext } from "../../contexts/UserContext";
import CompanySubscriptionBox from "./subscription-details/CompanySubscriptionBox";

interface IProps {
  pageContent: string;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  pageType: string;
  pageStateObj: IPageStateObj | null;
}

const SetupLayout: React.FC<IProps> = function ({
  pageContent,
  setPageContent,
  pageType,
  pageStateObj,
}) {
  const navigate = useNavigate();
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const { setUser }: any = useContext(UserContext);

  // States
  //   Account details form state
  const [companyName, setCompanyName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [payDay, setPayDay] = useState("");
  const [companyCode, setCompanyCode] = useState("CRED-PSTK");

  //   Instruments setup form states
  const [instrumentsRangesObj, setInstrumentsRangesObj] = useState<
    IInstrumentsRanges[] | null
  >([
    {
      _id: `new-${Math.floor(Math.random() * 10000)}`,
      title: "",
      from: "",
      to: "",
      amountLoanable: "",
      instrumentView: "form-view",
    },
  ]);

  const [instrumentTitle, setInstrumentTitle] = useState("");
  const [fromPriceValue, setFromPriceValue] = useState("");
  const [toPriceValue, setToPriceValue] = useState("");
  const [amountLoanable, setAmountLoanable] = useState("");

  //   Groups setup form states
  const [companyGroupsObj, setCompanyGroupsObj] = useState<
    ICompanyGroupsObj[] | null
  >([
    {
      _id: `new-${Math.floor(Math.random() * 10000)}`,
      groupName: "",
      percent: "",
      isFormActive: false,
    },
  ]);

  const [newGroupName, setNewGroupName] = useState("");

  // Salary Loan Rates setup form states
  // Flat Salary Loan Rate Value
  const [flatSalaryLoanRateValue, setFlatSalaryLoanRateValue] = useState("");

  // Benefits Form
  const [benefitsSetupForm, setBenefitsSetupForm] =
    useState<IBenefitsSetupForm>({
      paymentInterval: "",
      addingCreditsToWallet: "",
      paymentType: "",
      payDay: "",
      flatAmount: "",
      groups: [
        {
          _id: `new-${Math.floor(Math.random() * 10000)}`,
          groupName: "",
          amount: "",
          isFormActive: false,
        },
      ],
    });

  //
  const [isAccountDetailsFormFilled, setIsAccountDetailsFormFilled] =
    useState(false);
  const [isInstrumentsFormFilled, setIsInstrumentsFormFilled] = useState(false);
  const [isFlatSalaryLoanRateFormFilled, setIsFlatSalaryLoanRateFormFilled] =
    useState(false);
  const [isGroupsFormFilled, setIsGroupsFormFilled] = useState(false);
  const [isBenefitsFormFilled, setIsBenefitsFormFilled] = useState(false);
  const [isAPISecretKeyExisting, setIsAPISecretKeyExisting] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState("");
  const [loading, setLoading] = useState(false);

  // handle Log Out User
  const handleLogOutUser = function (e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    dispatch(logoutUser(navigate, setUser));
  };

  // handle Submit Flat Salary Rate
  const handleSubmitFlatSalaryRate = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    if (flatSalaryLoanRateValue !== "") {
      e.preventDefault();

      const data = {
        loanPercent: parseInt(flatSalaryLoanRateValue),
      };

      dispatch(
        addNewFlatSalaryLoanRate(
          data,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          setPageContent,
          setIsFlatSalaryLoanRateFormFilled
        )
      );
    }
  };

  // handle Copy Code To Clipboard
  const handleCopyCodeToClipboard = function () {
    if (companyCode) {
      navigator.clipboard.writeText(companyCode);
      setSuccessHandlerObj({
        isSuccess: true,
        message: "Code copied to clipboard!",
      });
    }
  };

  useEffect(() => {
    if (pageStateObj) {
      setCompanyEmail(pageStateObj.email);
      setCompanyName(pageStateObj.companyName);
      setPhoneNumber(pageStateObj.phoneNumber);
      setPayDay(`${ordinal_suffix_of(parseInt(pageStateObj.payDay))}`);
      setCompanySize(pageStateObj.companySize);
      setProfilePhoto(pageStateObj.profilePicture);

      setInstrumentsRangesObj(pageStateObj.instrumentRanges);
      setInstrumentTitle(pageStateObj.instrumentRanges[0]?.title || "");
      setFromPriceValue(pageStateObj.instrumentRanges[0]?.from || "");
      setToPriceValue(pageStateObj.instrumentRanges[0]?.to || "");
      setAmountLoanable(pageStateObj.instrumentRanges[0]?.amountLoanable || "");
      setCompanyGroupsObj(pageStateObj.allCompanyGroups);
      setFlatSalaryLoanRateValue(pageStateObj.loanPercent.toString());
      setNewGroupName(pageStateObj.allCompanyGroups[0]?.groupName || "");
    }

    //   Set the form page to filled
    if (payDay && companySize) setIsAccountDetailsFormFilled(true);

    if (
      instrumentTitle !== "" &&
      fromPriceValue !== "" &&
      toPriceValue !== "" &&
      amountLoanable !== ""
    )
      setIsInstrumentsFormFilled(true);

    if (newGroupName !== "") setIsGroupsFormFilled(true);

    if (flatSalaryLoanRateValue !== "") setIsFlatSalaryLoanRateFormFilled(true);

    if (
      pageStateObj?.benefits?.paymentInterval &&
      pageStateObj?.benefits?.paymentType
    )
      setIsBenefitsFormFilled(true);

    // console.log(pageStateObj, "pageStateObj");
  }, [pageStateObj]);

  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  useEffect(() => {
    window.scrollTo({ top: 0 });
    // Dummy Call to check internet connection and display error mssage on NOT
    dispatch(getCurrentUser(setLoading, setErrorHandlerObj));
  }, [pageContent]);

  useEffect(() => {
    // Get and set company code
    const token: any = localStorage.getItem("jwtToken");
    // Decode token to get user data
    const decoded: any = jwt_decode(token);
    setCompanyCode(decoded?.companyCode);
  }, []);

  return (
    <div className="dashboard-setup-layout-container">
      <div className="employees--actions-handler-wrapper">
        {/* Error handler component */}
        <ErrorHandler errorHandlerObj={errorHandlerObj} />

        {/* Success handler component */}
        <SuccessHandler successHandlerObj={successHandlerObj} />
      </div>
      {/* Dashboard Setup Top */}
      <div
        className={`dashboard-setup-top-container ${
          successHandlerObj.isSuccess || errorHandlerObj.hasError
            ? "push-wrapper-down"
            : ""
        }`}
      >
        <div className="dashboard-setup-top--left-wrapper">
          {/* Back button Wrapper */}
          <div className="back-button-wrapper settings--back-button-wrapper">
            <button
              onClick={() => {
                if (pageType === "settings") {
                  navigate(-1);
                } else {
                  setPageContent("main");
                }
              }}
            >
              <img src={angleLeftIcon} alt="" />
            </button>
          </div>
          {/* Setup Top Title */}
          <div className="dashboard-setup-top-title">
            {pageType === "settings" ? "Settings" : "Set up"}
          </div>
        </div>

        {/* Subscription Company Code wrapper */}
        {pageType === "settings" && pageContent === "company-info-setup" && (
          <section className="subscription-and-code-section">
            {/* Subscription Info */}
            <CompanySubscriptionBox setErrorHandlerObj={setErrorHandlerObj} />

            {/* Company Code Wrapper */}
            <div className="company-code-copy-wrapper">
              <div className="company-code-copy---text">
                <div className="company-code-copy--title">Company Code:</div>
                <div className="company-code-copy--value">{companyCode}</div>
              </div>

              <div
                className="company-code-copy---icon"
                onClick={handleCopyCodeToClipboard}
              >
                <img src={copyIcon} alt="" />
              </div>
            </div>
          </section>
        )}
      </div>

      {/* Setup Layout main Container */}
      <div className="dashboard-setup-layout-main-container">
        {/* Dasboard setup Left Section / Dashboard setup navigation container */}
        <SetupNavigation
          pageContent={pageContent}
          pageType={pageType}
          isAccountDetailsFormFilled={isAccountDetailsFormFilled}
          isFlatSalaryLoanRateFormFilled={isFlatSalaryLoanRateFormFilled}
          isGroupsFormFilled={isGroupsFormFilled}
          isBenefitsFormFilled={isBenefitsFormFilled}
          isAPISecretKeyExisting={isAPISecretKeyExisting}
          setPageContent={setPageContent}
          handleLogOutUser={handleLogOutUser}
        />

        {/* Dasboard Setup Right Section / Dashboard setup form container */}
        <div className="dashboard-setup-layout--right-section dashboard-setup-layout-form-container">
          {/* ACCOUNT DETAILS Setup Inner Container */}
          <div
            className={`account-details-setup--inner ${
              pageContent !== "company-info-setup" ? "none" : ""
            }`}
          >
            <AccountDetailsForm
              companyName={companyName}
              companyEmail={companyEmail}
              phoneNumber={phoneNumber}
              companySize={companySize}
              payDay={payDay}
              profilePhoto={profilePhoto}
              setPayDay={setPayDay}
              setCompanySize={setCompanySize}
              setProfilePhoto={setProfilePhoto}
              setPageContent={setPageContent}
              setIsAccountDetailsFormFilled={setIsAccountDetailsFormFilled}
              setErrorHandlerObj={setErrorHandlerObj}
              setSuccessHandlerObj={setSuccessHandlerObj}
            />
          </div>

          {/*DELIVERY OPTIONS Setup Inner Container */}
          <div
            className={`instruments-setup--inner ${
              pageContent !== "instruments-setup" ? "none" : ""
            }`}
          >
            <InstrumentsSetupForm
              pageType={pageType}
              instrumentTitle={instrumentTitle}
              fromPriceValue={fromPriceValue}
              toPriceValue={toPriceValue}
              amountLoanable={amountLoanable}
              instrumentsRangesObj={instrumentsRangesObj}
              setInstrumentTitle={setInstrumentTitle}
              setFromPriceValue={setFromPriceValue}
              setToPriceValue={setToPriceValue}
              setAmountLoanable={setAmountLoanable}
              setInstrumentsRangesObj={setInstrumentsRangesObj}
              setIsInstrumentsFormFilled={setIsInstrumentsFormFilled}
              setPageContent={setPageContent}
              setErrorHandlerObj={setErrorHandlerObj}
              setSuccessHandlerObj={setSuccessHandlerObj}
            />
          </div>

          {/* COMPANY GROUPS Setup Inner Container */}
          <div
            className={`groups-setup--inner ${
              pageContent !== "groups-setup" ? "none" : ""
            }`}
          >
            <GroupsSetupForm
              pageType={pageType}
              newGroupName={newGroupName}
              companyGroupsObj={companyGroupsObj}
              setNewGroupName={setNewGroupName}
              setCompanyGroupsObj={setCompanyGroupsObj}
              setIsGroupsFormFilled={setIsGroupsFormFilled}
              setPageContent={setPageContent}
              setErrorHandlerObj={setErrorHandlerObj}
              setSuccessHandlerObj={setSuccessHandlerObj}
            />
          </div>

          {/* Salary Flat Loan Rate Groups Setup Inner Container */}
          <div
            className={`flat-salary-loan-rate-setup--inner ${
              pageContent !== "salary-loan-rate-setup" ? "none" : ""
            }`}
          >
            <form>
              <div className="form-header-wrapper">
                Percentage of salary accessible
              </div>
              {/* Flat Salary Loan Rate form group */}
              <div className="flat-salary-loan-rate-form-group form-group">
                <TextInput
                  type={"number"}
                  id={"flat-salary-loan-rate-text-input"}
                  className={"flat-salary-loan-rate-input"}
                  placeholder={""}
                  value={flatSalaryLoanRateValue}
                  onChange={(e) => {
                    setFlatSalaryLoanRateValue(e.target.value);
                  }}
                  required={true}
                  // label={"Salary Loan Rate (%)"}
                  label={"Percentage (%)"}
                />
              </div>

              {/* Submit form button */}
              <div className="submit-setup-form-button-wrapper">
                {isLoading && <LoadingSpinner />}
                <button onClick={handleSubmitFlatSalaryRate}>Submit</button>
              </div>
            </form>
          </div>

          {/* BENEFITS Setup Inner Container */}
          <div
            className={`benefits-setup--inner ${
              pageContent !== "benefits-setup" ? "none" : ""
            }`}
          >
            <BenefitsForm
              pageType={pageType}
              benefitsSetupForm={benefitsSetupForm}
              pageStateObj={pageStateObj}
              setBenefitsSetupForm={setBenefitsSetupForm}
              setSuccessHandlerObj={setSuccessHandlerObj}
              setErrorHandlerObj={setErrorHandlerObj}
            />
          </div>

          {/* API CONFIGURATION Setup Inner Container */}
          <div
            className={`api-config-setup--inner ${
              pageContent !== "api-config-setup" ? "none" : ""
            }`}
          >
            <APIConfiguration
              pageType={pageType}
              setIsAPISecretKeyExisting={setIsAPISecretKeyExisting}
              setSuccessHandlerObj={setSuccessHandlerObj}
              setErrorHandlerObj={setErrorHandlerObj}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupLayout;
