import React, { MouseEvent, useState } from "react";
import LoadingSpinner from "../../../../loading-spinner/LoadingSpinner";
import { useAppDispatch } from "../../../../../hooks";
import TextInput from "../../../../input-components/text-input/TextInput";
import { useNavigate } from "react-router-dom";
import {
  ICompanyGroupsObj,
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../../../../Types";
import isEmpty from "../../../../../validation/isEmpty";
import {
  addManyNewGroupsToCompany,
  updateManyCompanyGroups,
} from "../../../../../redux/actions/groupActions";
import plusIcon from "../../../../../images/plus-icon.svg";

// Interfaces
interface IProps {
  pageType: string;
  newGroupName: string;
  companyGroupsObj: ICompanyGroupsObj[] | null;
  setNewGroupName: React.Dispatch<React.SetStateAction<string>>;
  setCompanyGroupsObj: React.Dispatch<
    React.SetStateAction<ICompanyGroupsObj[] | null>
  >;
  setIsGroupsFormFilled: React.Dispatch<React.SetStateAction<boolean>>;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  setErrorHandlerObj: SetErrorHandlerType;
  setSuccessHandlerObj: SetSuccessHandlerType;
}

function GroupsSetupForm({
  pageType,
  newGroupName,
  companyGroupsObj,
  setNewGroupName,
  setCompanyGroupsObj,
  setIsGroupsFormFilled,
  setPageContent,
  setErrorHandlerObj,
  setSuccessHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  //   States
  const [isLoading, setIsLoading] = useState(false);
  // Grouped Salary Loan Rate Value
  const [groupedSalaryLoanRateValue, setGroupedSalaryLoanRateValue] =
    useState("");
  //   Functions
  // handle Add NewcRange
  const handleAddNewGroupForm = function (e: MouseEvent<HTMLButtonElement>) {
    if (newGroupName !== "") {
      e.preventDefault();

      setNewGroupName("");
      setGroupedSalaryLoanRateValue("");

      // Update and create a new instrument form
      setCompanyGroupsObj((prevCompanyGroups) => {
        if (!prevCompanyGroups) return prevCompanyGroups;

        let newCompanyGroup: ICompanyGroupsObj = {
          _id: `new-${Math.floor(Math.random() * 10000)}`,
          groupName: "",
          percent: "",
          isFormActive: false,
        };

        let newPrevCompanyGroups = prevCompanyGroups
          .map((group) => {
            return {
              ...group,
              isFormActive: false,
            };
          })
          .concat(newCompanyGroup);
        return newPrevCompanyGroups;
      });
    }
  };

  // Handle Submit / Update Company Groups Form
  const handleSubmitGroupsForm = function (e: MouseEvent<HTMLButtonElement>) {
    if (companyGroupsObj) {
      e.preventDefault();

      const allCompanyGroupsArr = companyGroupsObj.filter(
        (group) => group.groupName !== ""
      );
      const newGroupsData = {
        groups: allCompanyGroupsArr
          .filter((group) => group._id.includes("new") === true)
          ?.map((group) => {
            return {
              name: group.groupName,
              ...(group.percent && {
                percent: parseInt(group.percent.toString()),
              }),
            };
          }),
      };

      const edittedGroupsData = {
        groups: allCompanyGroupsArr
          .filter((group) => group._id.includes("edit") === true)
          ?.map((group) => {
            return {
              id: group._id.split("-")[1],
              name: group.groupName,
              ...(group.percent && {
                percent: parseInt(group.percent.toString()),
              }),
            };
          }),
      };

      // console.log("new-submit", newGroupsData);
      // console.log("edit-submit", edittedGroupsData);

      let newCompanyGroupsExists = false;
      if (!isEmpty(newGroupsData.groups)) {
        newCompanyGroupsExists = true;
      }

      // Calling the Edit Company Groups API first before calling the Add New API
      if (!isEmpty(edittedGroupsData.groups)) {
        // Checking if editted groups exists
        //  Call the Edit Groups API
        console.log(
          "call the edit groups API and new instruments is",
          newCompanyGroupsExists
        );

        const groupNamesArr = allCompanyGroupsArr.map(
          (group) => group.groupName
        );

        // Check if a group name is duplicated
        const isGroupNameDuplicated = groupNamesArr.some((group, index) => {
          return groupNamesArr.indexOf(group) !== index;
        });

        if (isGroupNameDuplicated) {
          // If group name is duplicated
          setErrorHandlerObj({
            hasError: true,
            message: "Two groups cannot have the same name!",
          });
        } else {
          // Check if any
          dispatch(
            updateManyCompanyGroups(
              edittedGroupsData,
              setIsLoading,
              setErrorHandlerObj,
              setSuccessHandlerObj,
              pageType,
              setPageContent,
              newCompanyGroupsExists,
              setIsGroupsFormFilled,
              navigate
            )
          );
        }
      }

      if (newCompanyGroupsExists) {
        // Checking if new instruments exists
        //  Call the Create / Add New instrument  API
        console.log("call the new groups API", newCompanyGroupsExists);
        dispatch(
          addManyNewGroupsToCompany(
            newGroupsData,
            setIsLoading,
            setErrorHandlerObj,
            setSuccessHandlerObj,
            pageType,
            setPageContent,
            setIsGroupsFormFilled,
            navigate
          )
        );
      }

      // If No changes were made to the groups form
      if (isEmpty(edittedGroupsData.groups) && !newCompanyGroupsExists) {
        // Set Page Content to the next page
        // setPageContent("instruments-setup");

        // Check if it isnt settings page and go to main page
        if (pageType !== "settings") {
          // setPageContent("main");
          navigate("/employer/choose-subscription-plan");
        }
      }
    }
  };

  return (
    <form>
      {/* Form Row One / Group One */}
      {companyGroupsObj?.map((group, index) => (
        <div key={index + 1}>
          <div className={`ranges-form-wrapper`}>
            <div className="form-header-wrapper">Group {index + 1}</div>
            {/* Group Name */}
            <div className="form-body-wrapper form-group">
              <TextInput
                type={"text"}
                id={`employer-group-name-${index + 1}-text-input`}
                className={`employer-group-name-input`}
                placeholder={"e.g Junior Staff"}
                value={
                  !group.isFormActive
                    ? companyGroupsObj[index].groupName
                    : newGroupName
                }
                onChange={(e) => {
                  setNewGroupName(e.target.value);

                  setCompanyGroupsObj((prevCompanyGroupsObj) => {
                    if (!prevCompanyGroupsObj) return prevCompanyGroupsObj;
                    const newCompanyGroupsObj = {
                      ...group,
                      _id:
                        group._id.includes("new") || group._id.includes("edit")
                          ? group._id
                          : `edit-${group._id}`,
                      groupName: e.target.value,
                    };

                    prevCompanyGroupsObj.splice(index, 1, newCompanyGroupsObj);

                    console.log(prevCompanyGroupsObj);
                    return prevCompanyGroupsObj;
                  });
                }}
                onFocus={() => {
                  setNewGroupName(group.groupName);
                  // Set the active status of location to true on input focus
                  setCompanyGroupsObj((prevCompanyGroupsObj) => {
                    if (!prevCompanyGroupsObj) return prevCompanyGroupsObj;
                    const newCompanyGroupsObj = {
                      ...group,
                      isFormActive: true,
                    };

                    prevCompanyGroupsObj
                      .map((group) => {
                        return { ...group, isFormActive: false };
                      })
                      .splice(index, 1, newCompanyGroupsObj);

                    return prevCompanyGroupsObj;
                  });
                }}
                required={true}
                label={"Group Name"}
              />
            </div>

            {/* Group Loan Percent */}
            <div className="form-body-wrapper form-group">
              <TextInput
                type={"number"}
                id={`group-salary-loan-rate-${index + 1}-text-input`}
                className={`group-salary-loan-rate-input`}
                placeholder={"e.g 10"}
                value={
                  !group.isFormActive
                    ? companyGroupsObj[index].percent?.toString() || ""
                    : groupedSalaryLoanRateValue
                }
                onChange={(e) => {
                  setGroupedSalaryLoanRateValue(e.target.value);

                  setCompanyGroupsObj((prevCompanyGroupsObj) => {
                    if (!prevCompanyGroupsObj) return prevCompanyGroupsObj;
                    const newCompanyGroupsObj = {
                      ...group,
                      _id:
                        group._id.includes("new") || group._id.includes("edit")
                          ? group._id
                          : `edit-${group._id}`,
                      percent: e.target.value,
                    };

                    prevCompanyGroupsObj.splice(index, 1, newCompanyGroupsObj);

                    console.log(prevCompanyGroupsObj);
                    return prevCompanyGroupsObj;
                  });
                }}
                onFocus={() => {
                  setGroupedSalaryLoanRateValue(group.groupName);
                  // Set the active status of location to true on input focus
                  setCompanyGroupsObj((prevCompanyGroupsObj) => {
                    if (!prevCompanyGroupsObj) return prevCompanyGroupsObj;
                    const newCompanyGroupsObj = {
                      ...group,
                      isFormActive: true,
                    };

                    prevCompanyGroupsObj
                      .map((group) => {
                        return { ...group, isFormActive: false };
                      })
                      .splice(index, 1, newCompanyGroupsObj);

                    return prevCompanyGroupsObj;
                  });
                }}
                required={true}
                label={"Salary Percent Accessible for this Group"}
                subLabel={"(optional)"}
              />
            </div>
          </div>
        </div>
      ))}

      <div className="add-new-ranges-button-wrapper">
        <button onClick={(e) => handleAddNewGroupForm(e)}>
          {" "}
          <img src={plusIcon} alt="" /> Add New Group
        </button>
      </div>

      {/* Submit form button */}
      <div className="submit-setup-form-button-wrapper">
        {isLoading && <LoadingSpinner />}
        <button onClick={handleSubmitGroupsForm}>Submit</button>
      </div>
    </form>
  );
}

export default GroupsSetupForm;
