import axios from "axios";
import { api } from "../../api/config";
import { handleAPIError } from "../../utils/handleAPIError";
import {
  SetErrorHandlerType,
  SetIsLoadingType,
  SetSuccessHandlerType,
} from "../../Types";

interface IPaydayAndSize {
  payDay: number;
  companySize: string;
}
const url = `${api}/api/v1/company/update-payday-and-size`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Update Company Payday And Size
export const updateCompanyPaydayAndSize =
  (
    data: IPaydayAndSize,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setPageContent: React.Dispatch<React.SetStateAction<string>>,
    setIsAccountDetailsFormFilled: (
      value: React.SetStateAction<boolean>
    ) => void
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}`, data)
      .then((res) => {
        // Set Page Content to the next page
        setPageContent("salary-loan-rate-setup");

        setSuccessHandlerObj({
          isSuccess: true,
          message: "Pay day and company size updated successfully!",
        });
        //   Set the form page to filled
        setIsAccountDetailsFormFilled(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };
