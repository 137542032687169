import axios from "axios";
import { GET_ERRORS } from "../Constants";
import isEmpty from "../../validation/isEmpty";
import { api } from "../../api/config";
import { NavigateFunction } from "react-router-dom";

interface INewInstrumentsData {
  instruments: {
    title: string;
    from: number;
    to: number;
    loanAmount: number;
  }[];
}

interface IUpdateSingleInstrumentData {
  title: string;
  from: number;
  to: number;
  loanAmount: number;
}

interface IUpdateMultipleInstrumentData {
  instruments: {
    title: string;
    from: number;
    to: number;
    loanAmount: number;
    id: string;
  }[];
}
const url = `${api}/api/v1/company`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Add New Company Instruments
export const addNewCompanyInstruments =
  (
    data: INewInstrumentsData,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: React.Dispatch<
      React.SetStateAction<{
        hasError: boolean;
        message: string;
      }>
    >,
    setSuccessHandlerObj: React.Dispatch<
      React.SetStateAction<{
        isSuccess: boolean;
        message: string;
      }>
    >,
    pageType: string,
    setPageContent: React.Dispatch<React.SetStateAction<string>>,
    setIsInstrumentsFormFilled: (value: React.SetStateAction<boolean>) => void,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/add-instrument`, data)
      .then((res) => {
        //   Set the form page to filled
        setIsInstrumentsFormFilled(true);

        // Set Page Content to awaiting dashboard data / setup done
        if (pageType === "settings") {
          setSuccessHandlerObj({
            isSuccess: true,
            message: "Company instruments updated successfully!",
          });
        } else {
          // Navigate to setup don page
          navigate("/employer/dashboard/setup-done");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (!isEmpty(err) && !isEmpty(err.response)) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response,
          });
        } else {
          dispatch({
            type: GET_ERRORS,
            payload:
              "Something Went Wrong. Please Check your Connection and try again",
          });
        }

        setIsLoading(false);
        // Set the error handler state
        setErrorHandlerObj({
          hasError: true,
          message:
            err?.response?.data ||
            "Something Went Wrong. Please Check your Connection and try again",
        });
      });
  };

// Update Single Company Instruments
export const updateSingleCompanyInstrument =
  (
    instrumentId: string,
    data: IUpdateSingleInstrumentData,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: React.Dispatch<
      React.SetStateAction<{
        hasError: boolean;
        message: string;
      }>
    >,
    setSuccessHandlerObj: React.Dispatch<
      React.SetStateAction<{
        isSuccess: boolean;
        message: string;
      }>
    >,
    pageType: string,
    setPageContent: React.Dispatch<React.SetStateAction<string>>,
    setIsInstrumentsFormFilled: (value: React.SetStateAction<boolean>) => void,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/update-an-instrument/${instrumentId}`, data)
      .then((res) => {
        // Set the form page to filled
        setIsInstrumentsFormFilled(true);

        // Set Page Content to awaiting dashboard data / setup done
        if (pageType === "settings") {
          setSuccessHandlerObj({
            isSuccess: true,
            message: "Company instruments updated successfully!",
          });
        } else {
          // Navigate to setup don page
          navigate("/employer/dashboard/setup-done");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (!isEmpty(err) && !isEmpty(err.response)) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response,
          });
        } else {
          dispatch({
            type: GET_ERRORS,
            payload:
              "Something Went Wrong. Please Check your Connection and try again",
          });
        }

        setIsLoading(false);
        // Set the error handler state
        setErrorHandlerObj({
          hasError: true,
          message:
            err?.response?.data ||
            "Something Went Wrong. Please Check your Connection and try again",
        });
      });
  };

// Update Many Company Instruments
export const updateManyCompanyInstrument =
  (
    data: IUpdateMultipleInstrumentData,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: React.Dispatch<
      React.SetStateAction<{
        hasError: boolean;
        message: string;
      }>
    >,
    setSuccessHandlerObj: React.Dispatch<
      React.SetStateAction<{
        isSuccess: boolean;
        message: string;
      }>
    >,
    pageType: string,
    setPageContent: React.Dispatch<React.SetStateAction<string>>,
    setIsInstrumentsFormFilled: (value: React.SetStateAction<boolean>) => void,
    navigate: NavigateFunction,
    newInstrumentsExists: boolean
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/update-many-instrument`, data)
      .then((res) => {
        // If NO New instruments exists
        if (!newInstrumentsExists) {
          // Set the form page to filled
          setIsInstrumentsFormFilled(true);

          // Set Page Content to awaiting dashboard data / setup done
          if (pageType === "settings") {
            setSuccessHandlerObj({
              isSuccess: true,
              message: "Company instruments updated successfully!",
            });
          } else {
            // Navigate to setup don page
            navigate("/employer/dashboard/setup-done");
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (!isEmpty(err) && !isEmpty(err.response)) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response,
          });
        } else {
          dispatch({
            type: GET_ERRORS,
            payload:
              "Something Went Wrong. Please Check your Connection and try again",
          });
        }

        setIsLoading(false);
        // Set the error handler state
        setErrorHandlerObj({
          hasError: true,
          message:
            err?.response?.data ||
            "Something Went Wrong. Please Check your Connection and try again",
        });
      });
  };
