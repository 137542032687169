import { useEffect, useState } from "react";
import "./RecentWalletTrancactions.css";
import {
  IBenefitWalletHistory,
  IDropdownOption,
  SetErrorHandlerType,
} from "../../../../../../../Types";
import WalletHistoryTable from "./wallet-history-table/WalletHistoryTable";
import { useAppDispatch, useAppSelector } from "../../../../../../../hooks";
import { getAllCreditsForStaff } from "../../../../../../../redux/actions/benefitsActions";

// Interface
interface IProps {
  staffId: string;
  setErrorHandlerObj: SetErrorHandlerType;
}

const tableFilterOptions: IDropdownOption[] = [
  { key: "all", value: "All" },
  { key: "credit", value: "Credits" },
  { key: "debit", value: "Debits" },
];
function RecentWalletTrancactions({ staffId, setErrorHandlerObj }: IProps) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const walletHistory: IBenefitWalletHistory[] | [] = useAppSelector(
    (state) => state.benefits.getCreditsForStaff
  );
  // States
  const [transactionHistory, setTransactionHistory] = useState<
    IBenefitWalletHistory[] | null
  >(null);
  const [currentTransactionHistory, setCurrentTransactionHistory] = useState<
    IBenefitWalletHistory[] | null
  >(null);
  const [filterOption, setFilterOption] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;
  const [isLoading, setIsLoading] = useState(false);

  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //   Filter History By Wallet History By Type
  const handleFilterWalletHistoryByType = function (filterParam: string) {
    if (filterParam === "all") {
      setTransactionHistory(walletHistory);
    } else {
      // Handle Filter Transaction History Table on change of search value
      const walletHistoryData = walletHistory?.filter(
        (history) => history.transactionType === filterParam
      );
      // Update table state to Filtered Staff for the filter section
      if (walletHistoryData) setTransactionHistory(walletHistoryData);
    }
  };

  useEffect(() => {
    dispatch(getAllCreditsForStaff(staffId, setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    // console.log(walletHistory, "WH");
    setTransactionHistory(walletHistory);
  }, [walletHistory]);

  useEffect(() => {
    if (transactionHistory) {
      const currentRows = transactionHistory?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setCurrentTransactionHistory(currentRows);
    }
  }, [transactionHistory, currentPage]);

  useEffect(() => {
    // Set Current Page to 1 to avoid empty screen on search match bug
    setCurrentPage(1);
  }, [filterOption]);

  return (
    <div className="recent-wallet-transactions-container">
      <div className="section-title">RECENT WALLET TRANSACTIONS</div>

      <div className="secondary--table-filter-options-container">
        {tableFilterOptions?.map((filterObj: IDropdownOption, index) => (
          <div
            key={index + 1}
            className={`secondary--table-filter-option ${
              filterOption === filterObj.key
                ? "secondary--active-filter-option"
                : ""
            }`}
            onClick={() => {
              setFilterOption(filterObj.key);
              handleFilterWalletHistoryByType(filterObj.key);
            }}
          >
            {filterObj.value}
          </div>
        ))}
      </div>

      {/* Wallet transaction History Table */}
      <WalletHistoryTable
        transactionHistory={transactionHistory}
        currentPosts={currentTransactionHistory}
        isLoading={isLoading}
        paginate={paginate}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
      />
    </div>
  );
}

export default RecentWalletTrancactions;
