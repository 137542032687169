import { GET_API_DETAILS } from "../Constants";

const initialState = {
  apiDetails: { _id: "", apikey: "", apisecret: "" },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state = initialState,
  action: { type?: string; payload?: any }
) {
  switch (action.type) {
    case GET_API_DETAILS:
      return {
        ...state,
        apiDetails: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
