import React, { MouseEvent } from "react";
import "./Modals.css";
import attentionIcon from "../../../images/attention-icon.svg";
import { ISingleEmployee } from "../../employer-settlements/Settlements";
import { deleteStaff } from "../../../redux/actions/staffActions";
import { useAppDispatch } from "../../../hooks";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";

interface IProps {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessHandlerObj: React.Dispatch<
    React.SetStateAction<{
      isSuccess: boolean;
      message: string;
    }>
  >;
  setErrorHandlerObj: React.Dispatch<
    React.SetStateAction<{
      hasError: boolean;
      message: string;
    }>
  >;

  setIsDeleteEmployeeModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isDeleteEmployeeModalOpened: boolean;
  selectedEmployeeForPreview: ISingleEmployee | null;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
}

const DeleteEmployeeModal: React.FC<IProps> = function ({
  selectedEmployeeForPreview,
  isDeleteEmployeeModalOpened,
  setIsDeleteEmployeeModalOpened,
  setErrorHandlerObj,
  setSuccessHandlerObj,
  setIsLoading,
  isLoading,
  setPageContent,
}) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  // Functions, States and Variables
  const handleConfirmDeleteRequest = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    e.preventDefault();
    if (selectedEmployeeForPreview) {
      // TODO Call the dispatch fxn to delete employee
      dispatch(
        deleteStaff(
          selectedEmployeeForPreview._id,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          setPageContent,
          setIsDeleteEmployeeModalOpened
        )
      );
    }
  };
  return (
    <div
      className={`decline-request-modal-container modal-container ${
        isDeleteEmployeeModalOpened ? "" : "none"
      }`}
    >
      <div className="approve-request-modal-container--inner modal-container--inner">
        <div className="attention-img-wrapper">
          <img src={attentionIcon} alt="" />
        </div>

        <div className="approve-request-modal-header-text modal-header-text">
          Kindly confirm this action
        </div>
        <div className="approve-request-modal-sub-text modal-sub-text">
          Would you like to delete this staff{" "}
          <span>
            {selectedEmployeeForPreview?.firstName}{" "}
            {selectedEmployeeForPreview?.lastName}
          </span>{" "}
          from your company
        </div>

        <div className="modal-action-button-wrapper">
          {/* Cancel Modal Button */}
          <div className="cancel-modal-button-wrapper">
            <button onClick={() => setIsDeleteEmployeeModalOpened(false)}>
              Cancel
            </button>
          </div>
          {/* Conffirm Action Button */}
          <div className="confirm-modal-action-button">
            <button onClick={(e) => handleConfirmDeleteRequest(e)}>
              Confirm
            </button>
            <div className="confirm-modal-button-loader-wrapper">
              {isLoading && <LoadingSpinner />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteEmployeeModal;
