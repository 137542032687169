import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Top from "../../components/app-top/Top";
import ErrorHandler from "../../components/error/ErrorHandler";
import Main from "../../components/main-container/Main";
import SuccessHandler from "../../components/success/SuccessHandler";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import {
  IAllRequests,
  IMerchantFromEmployeeRequest,
} from "../employer-requests/Requests";
import CelebrationTypeModal from "./components/modals/CelebrationTypeModal";
import ConfirmApproveDeclineRequestModal from "./components/modals/ConfirmApproveDeclineRequestModal";
import RequestsPreview from "./components/requests-preview/RequestsPreview";
import WelfareNav from "./components/welfare-nav/WelfareNav";
import Benefits from "./sub-pages/benefits/Benefits";
import Celebrations from "./sub-pages/celebrations/Celebrations";
import OnDemand from "./sub-pages/on-demand/OnDemand";
import SalaryAdvance from "./sub-pages/salary-advance/SalaryAdvance";
import Wellness from "./sub-pages/wellness/Wellness";
import "./Welfare.css";

function Welfare() {
  // Functions, States and Variables
  const location = useLocation();
  // States
  const [welfareDisplayContent, setWelfareDisplayContent] = useState("main");
  const [pageContent, setPageContent] = useState("");

  // Selected Employee Request (Salary Advance / On Demand)
  const [selectedEmployeeRequest, setSelectedEmployeeRequest] =
    useState<IAllRequests | null>(null);

  // Is Employee's SA or OD Request Approved or Declined
  const [isApproved, setIsApproved] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false);
  const [wantsToApproveRequest, setWantsToApproveRequest] = useState(false);
  const [wantsToDeclineRequest, setWantsToDeclineRequest] = useState(false);
  const [
    isConfirmApproveDeclineRequestModalOpened,
    setIsConfirmApproveDeclineRequestModalOpened,
  ] = useState(false);
  const [merchantFromEmployeeRequest, setMerchantFromEmployeeRequest] =
    useState<IMerchantFromEmployeeRequest | null>(null);

  // Celebrations Modal
  const [isCelebrationTypeModalOpened, setIsCelebrationTypeModalOpened] =
    useState(false);
  const [isCelebrationForAllStaff, setIsCelebrationForAllStaff] =
    useState(false);
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  useEffect(() => {
    // If Location contains state to move to page content, move, else if no state set to default page content
    if (location.state) {
      const { currentPageContent }: any = location.state;
      currentPageContent && setPageContent(currentPageContent);
    } else {
      setPageContent("benefits");
    }

    // If Location contains state to pop up success toast, pop it up
    if (location.state) {
      const { successMessage }: any = location.state;
      successMessage &&
        setSuccessHandlerObj({ isSuccess: true, message: successMessage });
    }

    // Cleanup function to clear location state
    return () => {
      window.history.replaceState({}, document.title);
    };
  }, [location]);

  return (
    <div
      className={`welfare-container ${
        isConfirmApproveDeclineRequestModalOpened ||
        isCelebrationTypeModalOpened
          ? "overflow-hidden"
          : ""
      }`}
    >
      <Top />
      <Sidebar activeName={"Welfare"} />

      {/* Gray Overlay */}
      <div
        onClick={() => {
          setIsConfirmApproveDeclineRequestModalOpened(false);
          setIsCelebrationTypeModalOpened(false);
          setIsCelebrationForAllStaff(false);
        }}
        className={`gray-overlay ${
          isConfirmApproveDeclineRequestModalOpened ||
          isCelebrationTypeModalOpened
            ? ""
            : "none"
        }`}
      ></div>
      {/* MODALS */}
      {/* Confirm Approve Decline Request Modal */}
      <ConfirmApproveDeclineRequestModal
        isConfirmApproveDeclineRequestModalOpened={
          isConfirmApproveDeclineRequestModalOpened
        }
        setIsConfirmApproveDeclineRequestModalOpened={
          setIsConfirmApproveDeclineRequestModalOpened
        }
        selectedEmployeeRequest={selectedEmployeeRequest}
        setSelectedEmployeeRequest={setSelectedEmployeeRequest}
        wantsToApproveRequest={wantsToApproveRequest}
        wantsToDeclineRequest={wantsToDeclineRequest}
        setIsApproved={setIsApproved}
        setIsDeclined={setIsDeclined}
        setErrorHandlerObj={setErrorHandlerObj}
        setSuccessHandlerObj={setSuccessHandlerObj}
      />

      {/* Confirm Approve Decline Request Modal */}
      <CelebrationTypeModal
        isCelebrationTypeModalOpened={isCelebrationTypeModalOpened}
        isCelebrationForAllStaff={isCelebrationForAllStaff}
        setIsCelebrationTypeModalOpened={setIsCelebrationTypeModalOpened}
        setIsCelebrationForAllStaff={setIsCelebrationForAllStaff}
      />

      <Main>
        <div className="welfare-container--inner">
          {/* Error and Success Handlers */}
          <div className="employees--actions-handler-wrapper">
            <ErrorHandler errorHandlerObj={errorHandlerObj} />
            <SuccessHandler successHandlerObj={successHandlerObj} />
          </div>

          {/* Main Section */}
          <section
            className={`welfare-main-section ${
              welfareDisplayContent === "main" ? "" : "none"
            }`}
          >
            {/* Welfare Navigation Section */}
            <WelfareNav
              pageContent={pageContent}
              setPageContent={setPageContent}
            />

            {/* Salary Advance Section ::NB: Muting the validation below makes all sub pages request to get called together*/}
            {pageContent === "salary-advance" && (
              <SalaryAdvance
                pageContent={pageContent}
                isApproved={isApproved}
                isDeclined={isDeclined}
                setSelectedEmployeeRequest={setSelectedEmployeeRequest}
                setErrorHandlerObj={setErrorHandlerObj}
                setWelfareDisplayContent={setWelfareDisplayContent}
              />
            )}

            {/* On Demand Section ::NB: Muting the validation below makes all sub pages request to get called together*/}
            {pageContent === "on-demand" && (
              <OnDemand
                pageContent={pageContent}
                isApproved={isApproved}
                isDeclined={isDeclined}
                setSelectedEmployeeRequest={setSelectedEmployeeRequest}
                setErrorHandlerObj={setErrorHandlerObj}
                setWelfareDisplayContent={setWelfareDisplayContent}
              />
            )}

            {/* Benefits ::NB: Muting the validation below makes all sub pages request to get called together*/}
            {pageContent === "benefits" && (
              <Benefits
                pageContent={pageContent}
                setErrorHandlerObj={setErrorHandlerObj}
              />
            )}

            {/* Celebrations ::NB: Muting the validation below makes all sub pages request to get called together*/}
            {pageContent === "celebrations" && (
              <Celebrations
                pageContent={pageContent}
                setIsCelebrationTypeModalOpened={
                  setIsCelebrationTypeModalOpened
                }
                setIsCelebrationForAllStaff={setIsCelebrationForAllStaff}
                setErrorHandlerObj={setErrorHandlerObj}
              />
            )}

            {/* Benefits ::NB: Muting the validation below makes all sub pages request to get called together*/}
            {pageContent === "wellness" && (
              <Wellness
                pageContent={pageContent}
                setErrorHandlerObj={setErrorHandlerObj}
                setSuccessHandlerObj={setSuccessHandlerObj}
              />
            )}
          </section>

          {/* Requests Preview Section  || SA and OD Requests Preview*/}
          <section
            className={`requests-preview-section ${
              welfareDisplayContent === "requests-preview" ? "" : "none"
            }`}
          >
            <RequestsPreview
              selectedEmployeeRequest={selectedEmployeeRequest}
              merchantFromEmployeeRequest={merchantFromEmployeeRequest}
              isApproved={isApproved}
              isDeclined={isDeclined}
              setIsApproved={setIsApproved}
              setIsDeclined={setIsDeclined}
              setWantsToApproveRequest={setWantsToApproveRequest}
              setWantsToDeclineRequest={setWantsToDeclineRequest}
              setIsConfirmApproveDeclineRequestModalOpened={
                setIsConfirmApproveDeclineRequestModalOpened
              }
              setWelfareDisplayContent={setWelfareDisplayContent}
            />
          </section>
        </div>
      </Main>
    </div>
  );
}

export default Welfare;
