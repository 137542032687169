export const allPayDays = [
  {
    key: "",
    value: "",
    default: "default",
  },
  { key: "1st", value: "1st" },
  { key: "2nd", value: "2nd" },
  { key: "3rd", value: "3rd" },
  { key: "4th", value: "4th" },
  { key: "5th", value: "5th" },
  { key: "6th", value: "6th" },
  { key: "7th", value: "7th" },
  { key: "8th", value: "8th" },
  { key: "9th", value: "9th" },
  { key: "10th", value: "10th" },
  { key: "11th", value: "11th" },
  { key: "12th", value: "12th" },
  { key: "13th", value: "13th" },
  { key: "14th", value: "14th" },
  { key: "15th", value: "15th" },
  { key: "16th", value: "16th" },
  { key: "17th", value: "17th" },
  { key: "18th", value: "18th" },
  { key: "19th", value: "19th" },
  { key: "20th", value: "20th" },
  { key: "21st", value: "21st" },
  { key: "22nd", value: "22nd" },
  { key: "23rd", value: "23rd" },
  { key: "23th", value: "23th" },
  { key: "25th", value: "25th" },
  { key: "26th", value: "26th" },
  { key: "27th", value: "27th" },
  { key: "28th", value: "28th" },
  { key: "29th", value: "29th" },
  { key: "30th", value: "30th" },
  { key: "31st", value: "31st" },
];
