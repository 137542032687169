import {
  GET_ALL_CREDITS_FOR_COMPANY,
  GET_ALL_CREDITS_FOR_STAFF,
} from "../Constants";

const initialState = {
  getCreditsForStaff: [],
  getCreditsForCompany: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state = initialState,
  action: { type?: string; payload?: any }
) {
  switch (action.type) {
    case GET_ALL_CREDITS_FOR_STAFF:
      return {
        ...state,
        getCreditsForStaff: action.payload,
      };
    case GET_ALL_CREDITS_FOR_COMPANY:
      return {
        ...state,
        getCreditsForCompany: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
