import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./AccountSetup.css";
import whiteStarIcon from "../../images/white-star.svg";
import arrowRight from "../../images/arrow-long-right.svg";
import smileEmoji from "../../images/smile-emoji.png";
import SetupLayout from "./setup-layout/SetupLayout";
import DashboardAwaiting from "../dashboard-comps/dashboard-awaiting/DashboardAwaiting";
import Top from "../app-top/Top";
import Navbar from "../app-navbar/Navbar";
import { IPageStateObj } from "../dashboard-comps/dashboard-setup/DashboardSetup";
import ErrorHandler from "../error/ErrorHandler";
import { getCurrentUser } from "../../redux/actions/authActions";
import { useAppDispatch, useAppSelector } from "../../hooks";
import isEmpty from "../../validation/isEmpty";
import SubstituteLoadingSpinner from "../substitute-loading-spinner/SubstituteLoadingSpinner";
import useDisplayMessage from "../../hooks/useDisplayMessage";

interface IProps {
  pageType: string;
  pageStateObj: IPageStateObj | null;
}
const AccountSetup: React.FC<IProps> = function ({ pageType, pageStateObj }) {
  // Since this comp is reusable, it takes a pageType prop specifying the page u the comp
  // Funtion, State and Variables
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.user.user);

  // Hooks
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  // States
  const [pageContent, setPageContent] = useState("main");
  const [isLoading, setIsLoading] = useState(false);

  // is onboard employees button Clicked
  const [isOnboardEmployeesButtonClicked, setIsOnboardEmployeesButtonClicked] =
    useState(false);

  // USEEFFECT
  useEffect(() => {
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess) {
      //scroll to page top
      window.scrollTo({ top: 0 });
    }
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
    }
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });
    }
  }, [successHandlerObj]);

  // Handle Navigate to onboard employees
  const handleNavigateToOnboardEmployees = function () {
    setIsOnboardEmployeesButtonClicked(true);
    setErrorHandlerObj({ hasError: false, message: "" });

    // Check if userInfo is not empty(i.e is gotten), then check and navigate / throw error respectively
    if (!isEmpty(userInfo)) {
      if (userInfo.loanPercent && !isEmpty(userInfo.groups)) {
        // If user is approved
        if (userInfo.isApproved) {
          navigate("/employer/employees/onboard-employees");
          // navigate("/employer/choose-subscription-plan");
        } else {
          navigate("/employer/awaiting-approval");
        }
      } else {
        setErrorHandlerObj({
          hasError: true,
          message: "Kindly complete acount setup before you can proceed!",
        });
      }
    }
  };

  useEffect(() => {
    dispatch(getCurrentUser(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    // If Button is clicked before userInfo is gotten, trigger call on change of user info
    if (isOnboardEmployeesButtonClicked) {
      if (userInfo.loanPercent && !isEmpty(userInfo.groups)) {
        // If user is approved
        if (userInfo.isApproved) {
          // navigate("/employer/employees/onboard-employees");
          navigate("/employer/choose-subscription-plan");
        } else {
          navigate("/employer/awaiting-approval");
        }
      } else {
        setErrorHandlerObj({
          hasError: true,
          message: "Kindly complete acount setup before you can proceed!",
        });
      }
    }
  }, [userInfo]);

  // USEEFFECT
  useEffect(() => {
    //   If page setup is settings setup, set page content to account details straight
    if (pageType !== "dash-setup") setPageContent("company-info-setup");
  }, []);
  return (
    <div className="dashboard-setup-container">
      <Top />
      <Navbar />

      <div className="acount-setup--actions-handler-wrapper">
        {/* Error handler component */}
        {/* <ErrorHandler errorHandlerObj={errorHandlerObj} /> */}
      </div>
      {pageType === "dash-setup" && (
        <div
          className={`dashboard-setup-container--inner ${
            pageContent !== "main" ? "none" : ""
          }`}
        >
          {/* Dashboard Setup Onboarding Section */}
          <section className="dashboard-setup-onboarding-section">
            {/* Container Header  */}
            <div className="dashboard-setup-onboarding-section-header">
              <div className="dashboard-setup-container-onboarding-section-title">
                Your account is almost done{" "}
                <>
                  <img src={smileEmoji} alt="" />
                </>
              </div>
            </div>

            {/* Dashboard Setup Onboarding Section Main Container */}
            <div className="dashboard-setup-onboarding-section-main-container">
              {/* Go to Wrapper Container */}
              <div className="go-to-setup-links-wrapper-container">
                {/* Shop Details setup link wrapper */}
                <div className="go-to-setup-link-wrapper go-to-company-info-setup-wrapper">
                  <button onClick={() => setPageContent("company-info-setup")}>
                    Set up Company Info
                    {/* Arrow Image Wrapper */}
                    <div className="arrow-img-wrapper">
                      <img src={arrowRight} alt="" />
                    </div>
                  </button>

                  {/* Star Image Icon */}
                  <div className="star-image-wrapper">
                    <img src={whiteStarIcon} alt="" />
                  </div>
                </div>

                {/* Company Instruments setup link wrapper */}
                {/* <div className="go-to-setup-link-wrapper go-to-instrument-setup-wrapper">
                  <button onClick={() => setPageContent("instruments-setup")}>
                    Set up Instrument ---Comment Arrow Image Wrapper ---Comment
                    <div className="arrow-img-wrapper">
                      <img src={arrowRight} alt="" />
                    </div>
                  </button>
                  Star Image Icon ---Comment
                  <div className="star-image-wrapper">
                    <img src={whiteStarIcon} alt="" />
                  </div>
                </div> */}

                {/* Choose Subscription Plan link wrapper */}
                <div className="go-to-setup-link-wrapper go-to-subscription-setup-wrapper">
                  <button
                    onClick={() =>
                      navigate("/employer/choose-subscription-plan")
                    }
                  >
                    Choose Subscription Plan
                    {/* Arrow Image Wrapper */}
                    <div className="arrow-img-wrapper">
                      <img src={arrowRight} alt="" />
                    </div>
                  </button>

                  {/* Star Image Icon */}
                  <div className="star-image-wrapper">
                    <img src={whiteStarIcon} alt="" />
                  </div>
                </div>

                {/* Company Groups setup link wrapper */}
                <div className="go-to-setup-link-wrapper go-to-instrument-setup-wrapper">
                  <button onClick={() => setPageContent("groups-setup")}>
                    Set up Groups
                    {/* Arrow Image Wrapper */}
                    <div className="arrow-img-wrapper">
                      <img src={arrowRight} alt="" />
                    </div>
                  </button>

                  {/* Star Image Icon */}
                  <div className="star-image-wrapper">
                    <img src={whiteStarIcon} alt="" />
                  </div>
                </div>

                {/* Stock setup link wrapper */}
                <div className="go-to-setup-link-wrapper go-to-onboard-employees-setup-wrapper">
                  <button onClick={() => handleNavigateToOnboardEmployees()}>
                    Upload/Onboard Employees
                    {/* Arrow Image Wrapper */}
                    {isOnboardEmployeesButtonClicked && isLoading && (
                      <SubstituteLoadingSpinner />
                    )}
                    {/* Arrow Image Wrapper */}
                    <div className="arrow-img-wrapper">
                      <img src={arrowRight} alt="" />
                    </div>
                  </button>

                  {/* Star Image Icon */}
                  <div className="star-image-wrapper">
                    <img src={whiteStarIcon} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}

      {/*Dashboard Setup Section */}
      <section
        className={`dashboard-setup-form-section ${
          pageContent === "main" || pageContent === "setup-done" ? "none" : ""
        }`}
      >
        <SetupLayout
          pageContent={pageContent}
          setPageContent={setPageContent}
          pageType={pageType}
          pageStateObj={pageStateObj}
        />
      </section>

      {/* Setup Done / Awaiting Dashboard Data Section */}
      {/* {pageType === "dash-setup" && (
        <section
          className={`awaiting-dashboard-data-section-container ${
            pageContent !== "setup-done" ? "none" : ""
          }`}
        >
          <DashboardAwaiting />
        </section>
      )} */}
    </div>
  );
};

export default AccountSetup;
