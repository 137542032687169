import React, { useEffect, useState } from "react";
import "./Onboarding.css";
import { Table } from "react-bootstrap";
import Navbar from "../../components/app-navbar/Navbar";
import Top from "../../components/app-top/Top";
import DropdownInput from "../../components/input-components/dropdown-input/DropdownInput";
import { numberWithCommas } from "../../utils/numberWithCommas";
import { ISingleEmployee } from "../employer-settlements/Settlements";
import closeIcon from "../../images/close-icon.svg";
import tickIcon from "../../images/tick-icon.svg";
import OnboardingPreview from "./onboarding-preview/OnboardingPreview";
import ApproveRequestModal from "./modals/ApproveRequestModal";
import ErrorHandler from "../../components/error/ErrorHandler";
import SuccessHandler from "../../components/success/SuccessHandler";
import DeclineRequestModal from "./modals/DeclineRequestModal";
import FEPagination from "../../components/pagination/fe-pagination/FEPagination";

export interface IAllOnboarding {
  _id: string;
  referenceId: string;
  imgURL: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  salary: number;
  date: string;
  department: string;
  role: string;
  group: string;
  level: string;
  address: string;
}

const dummyNames = [
  "Paityn Torff",
  "Skylar Kenter",
  "Cheyenne Siphron",
  "Lydia Septimus",
  "Ashlynn Workman",
  "Makenna Ekstrom Bothman",
  "Allison Rosser",
  "Maren Gouse",
  "Skylar Kenter",
  "Cheyenne Siphron",
  "Lydia Septimus",
  "Ashlynn Workman",
  "Makenna Ekstrom Bothman",
  "Allison Rosser",
  "Maren Gouse",
  "Cheyenne Siphron",
  "Lydia Septimus",
  "Ashlynn Workman",
  "Makenna Ekstrom Bothman",
  "Allison Rosser",
  "Maren Gouse",
  "Skylar Kenter",
  "Cheyenne Siphron",
  "Lydia Septimus",
  "Ashlynn Workman",
  "Makenna Ekstrom Bothman",
  "Allison Rosser",
  "Maren Gouse",
  "Maren Gouse",
  "Cheyenne Siphron",
  "Lydia Septimus",
  "Ashlynn Workman",
  "Makenna Ekstrom Bothman",
  "Allison Rosser",
  "Maren Gouse",
  "Skylar Kenter",
  "Cheyenne Siphron",
  "Lydia Septimus",
  "Ashlynn Workman",
  "Makenna Ekstrom Bothman",
  "Allison Rosser",
  "Maren Gouse",
];

const dummyOnboardings = dummyNames.map((name, i) => {
  return {
    _id: (i + 1).toString(),
    referenceId: "HJD409503",
    imgURL:
      "https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=",
    firstName: name.split(" ")[0],
    lastName: name.split(" ")[1],
    email: "OlatunjO@mail.com",
    phoneNumber: "08027472374",
    salary: 300000,
    date: "12, Jun 2022",
    department: "Accounting",
    role: "Accounting Associate",
    level: "LV2",
    group: "Junior Staff",
    address:
      "This dummy text is suppose to include the address of the user and any other information that proves rthe address",
  };
});

function Onboarding() {
  //Function, States and Variables

  // States
  const [allOnboardings, setAllOnboardings] = useState<IAllOnboarding[] | null>(
    null
  );
  const [pageContent, setPageContent] = useState("main");
  const [onboardingDateFilter, setOnboardingDateFilter] = useState("");
  const [selectedEmployeeForPreview, setSelectedEmployeeForPreview] =
    useState<ISingleEmployee | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [currentPosts, setCurrentPosts] = useState<IAllOnboarding[] | null>(
    null
  );

  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  const [isApproveRequestModalOpened, setIsApproveRequestModalOpened] =
    useState(false);
  const [isDeclineRequestModalOpened, setIsDeclineRequestRModalOpened] =
    useState(false);

  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });

  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pageContent]);

  useEffect(() => {
    setAllOnboardings(dummyOnboardings);
  }, []);

  useEffect(() => {
    if (allOnboardings) {
      const currentRows = allOnboardings?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );

      setCurrentPosts(currentRows);
    }
  }, [allOnboardings, currentPage]);

  useEffect(() => {
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess) {
      let xCoord = 0,
        yCoord = 0;

      //scroll to page top
      window.scrollTo(xCoord, yCoord);
    }
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
    }
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });
    }
  }, [successHandlerObj]);
  return (
    <div
      className={`onboarding-container  ${
        isApproveRequestModalOpened || isDeclineRequestModalOpened
          ? "overflow-hidden"
          : ""
      }`}
    >
      <Top />
      <Navbar />

      {/* Gray Overlay */}
      <div
        onClick={() => {
          setIsApproveRequestModalOpened(false);
          setIsDeclineRequestRModalOpened(false);
        }}
        className={`gray-overlay ${
          isApproveRequestModalOpened || isDeclineRequestModalOpened
            ? ""
            : "none"
        }`}
      ></div>

      {/* Approve Request Modal */}
      <ApproveRequestModal
        isApproveRequestModalOpened={isApproveRequestModalOpened}
        setIsApproveRequestModalOpened={setIsApproveRequestModalOpened}
        setErrorHandlerObj={setErrorHandlerObj}
        setSuccessHandlerObj={setSuccessHandlerObj}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        selectedEmployeeForPreview={selectedEmployeeForPreview}
      />

      {/* Decline Request Modal */}
      <DeclineRequestModal
        isDeclineRequestModalOpened={isDeclineRequestModalOpened}
        setIsDeclineRequestRModalOpened={setIsDeclineRequestRModalOpened}
        setErrorHandlerObj={setErrorHandlerObj}
        setSuccessHandlerObj={setSuccessHandlerObj}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        selectedEmployeeForPreview={selectedEmployeeForPreview}
      />

      <div className="onboarding-container--inner">
        {/* Error handler component */}
        <ErrorHandler errorHandlerObj={errorHandlerObj} />

        {/* Success handler component */}
        <SuccessHandler successHandlerObj={successHandlerObj} />

        {/* Onbooarding table section */}
        <section
          className={`onboarding-table-section ${
            pageContent === "main" ? "" : "none"
          }`}
        >
          <div className="onboarding-table-filter-option-wrapper">
            <div className="onboarding-table-filter-row-title">Filter:</div>
            {/* Filter by onboarding application date dropdown */}
            <div className="onboarding-date-filter-form-group form-group">
              <DropdownInput
                className={"onboarding-date-filter-dropdown-input"}
                id={"onboarding-date-filter-input"}
                label={""}
                required={true}
                optionArray={[
                  { key: "", value: "Date", default: "Default" },
                  {
                    key: "yesterday",
                    value: "Yesterday",
                  },
                ]}
                value={onboardingDateFilter}
                onChange={(e) => setOnboardingDateFilter(e.target.value)}
              />
            </div>
          </div>

          {/* All Orders Table Container */}
          <div className="all-onboarding-table-container">
            <Table responsive="lg" className="all-onboarding-table">
              <thead
                style={{
                  textAlign: "left",
                }}
              >
                <tr>
                  {[
                    "NAME",
                    "EMAIL",
                    "PHONE NUMBER",
                    "SALARY (N)",
                    "DATE",
                    "",
                    "",
                  ].map((COLUMN, index) => (
                    <th key={index + 1}>{COLUMN}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {currentPosts?.map((employee, i) => (
                  <tr key={i + 1}>
                    {/* Employee Name Column */}
                    <td
                      className="td-employee-name"
                      onClick={() => {
                        setPageContent("preview");
                        setSelectedEmployeeForPreview(employee);
                      }}
                    >
                      <div className="td-employee-name--wrapper">
                        {/* profile image wrapper */}
                        {/* <div className="profile-image-wrapper">
                          <img src={employee?.imgURL} alt="" />
                        </div> */}
                        {employee.firstName} {employee.lastName}
                      </div>
                    </td>

                    {/*  Employee Email Column */}
                    <td
                      className="td-email"
                      onClick={() => {
                        setPageContent("preview");
                        setSelectedEmployeeForPreview(employee);
                      }}
                    >
                      <div className="td-email--wrapper">{employee.email}</div>
                    </td>

                    {/* Phone Column */}
                    <td
                      className="td-phone-no"
                      onClick={() => {
                        setPageContent("preview");
                        setSelectedEmployeeForPreview(employee);
                      }}
                    >
                      <div className="td-phone-no--wrapper">
                        {employee.phoneNumber}
                      </div>
                    </td>

                    {/*  Salary Column */}
                    <td
                      className="td-salary"
                      onClick={() => {
                        setPageContent("preview");
                        setSelectedEmployeeForPreview(employee);
                      }}
                    >
                      <div className="td-salary--wrapper">
                        &#8358;{numberWithCommas(employee.salary)}
                      </div>
                    </td>

                    {/* Date Column*/}
                    <td
                      className="td-date"
                      onClick={() => {
                        setPageContent("preview");
                        setSelectedEmployeeForPreview(employee);
                      }}
                    >
                      <div className="td-date--wrapper">{employee.date}</div>
                    </td>

                    {/* Decline Request Btn Column*/}
                    <td className="td-decline-request">
                      <div className="td-decline-request--wrapper">
                        <button
                          onClick={() => {
                            setIsDeclineRequestRModalOpened(true);
                            setSelectedEmployeeForPreview(employee);
                          }}
                        >
                          <img src={closeIcon} alt="" />
                          Decline Request
                        </button>
                      </div>
                    </td>

                    {/* Approve Request Btn Column*/}
                    <td className="td-approve-request">
                      <div className="td-approve-request--wrapper">
                        <button
                          onClick={() => {
                            setIsApproveRequestModalOpened(true);
                            setSelectedEmployeeForPreview(employee);
                          }}
                        >
                          <img src={tickIcon} alt="" />
                          Approve Request
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          {allOnboardings && (
            <FEPagination
              paginate={paginate}
              rowsPerPage={rowsPerPage}
              totalPosts={allOnboardings.length}
              currentPage={currentPage}
            />
          )}
        </section>

        <section
          className={`onboarding-preview-container ${
            pageContent === "preview" ? "" : "none"
          }`}
        >
          <OnboardingPreview
            setPageContent={setPageContent}
            selectedEmployeeForPreview={selectedEmployeeForPreview}
          />
        </section>
      </div>
    </div>
  );
}

export default Onboarding;
