import React from "react";
import TextInput from "../../../../../../../../components/input-components/text-input/TextInput";
import { ISingleEmployee } from "../../../../../../../employer-settlements/Settlements";
import "./CreditAmountForm.css";

// Interfaces
interface IProps {
  currentStaff: ISingleEmployee;
  creditAmount: string;
  setCreditAmount: React.Dispatch<React.SetStateAction<string>>;
}

function CreditAmountForm({
  currentStaff,
  creditAmount,
  setCreditAmount,
}: IProps) {
  return (
    <div className="credit-amount-form-wrapper">
      {/* Staff Name */}
      <div className="form-group">
        <TextInput
          type={"text"}
          id={"staff-name-text-input"}
          className={"staff-name-input"}
          placeholder={""}
          value={`${currentStaff.firstName} ${currentStaff.lastName}`}
          onChange={() => {}}
          label={"Employee Name"}
          disabled={true}
        />
      </div>

      {/* Staff group */}
      <div className="form-group">
        <TextInput
          type={"text"}
          id={"staff-group-text-input"}
          className={"staff-group-input"}
          placeholder={""}
          value={currentStaff.group}
          onChange={() => {}}
          label={"Group"}
          disabled={true}
        />
      </div>

      {/* Staff salary */}
      <div className="form-group">
        <TextInput
          type={"text"}
          id={"staff-salary-text-input"}
          className={"staff-salary-input"}
          placeholder={""}
          value={`₦${currentStaff.salary.toLocaleString()}`}
          onChange={() => {}}
          label={"Salary"}
          disabled={true}
        />
      </div>

      {/* Staff Wallet Balance */}
      {/* <div className="form-group">
        <TextInput
          type={"text"}
          id={"staff-wallet-balance-text-input"}
          className={"staff-wallet-balance-input"}
          placeholder={""}
          value={`₦${currentStaff.wallet?.toLocaleString() || 600}`}
          onChange={() => {}}
          label={"Wallet Balance"}
          disabled={true}
        />
      </div> */}

      {/* Staff Credit Amount */}
      <div className="form-group">
        <TextInput
          type={"number"}
          id={"staff-credit-amount-text-input"}
          className={"staff-credit-amount-input"}
          placeholder={""}
          value={creditAmount}
          onChange={(e) => setCreditAmount(e.target.value)}
          label={"Credit Amount"}
          required={true}
          min={"1"}
        />
      </div>
    </div>
  );
}

export default CreditAmountForm;
