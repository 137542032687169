/* eslint-disable import/no-anonymous-default-export */
import { GET_ERRORS } from "../Constants";

const initialState = {};

export default function (
  state = initialState,
  action: { type?: string; payload?: any }
) {
  switch (action.type) {
    case GET_ERRORS:
      return action.payload;
    default:
      return state;
  }
}
