import axios from "axios";
import { api } from "../../api/config";
import setAuthToken from "../../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { GET_CURRENT_USER, GET_ERRORS, SET_CURRENT_USER } from "../Constants";
import { NavigateFunction } from "react-router-dom";
import isEmpty from "../../validation/isEmpty";
import { handleAPIError } from "../../utils/handleAPIError";
import {
  SetErrorHandlerType,
  SetIsLoadingType,
  SetSuccessHandlerType,
} from "../../Types";

interface IAuth {
  userdata: {
    email: string;
    password: string;
  };

  verificationData: {
    token: string;
    companyId: string;
  };
}

interface IResetPasswordData {
  token: string;
  password: string;
}

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

const url = `${api}/api/v1/company`;
// Login - Get User Token
export const loginUser =
  (
    userData: IAuth["userdata"],
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/login`, userData)
      .then((res) => {
        const token = res.data;
        // Save token on local storage
        localStorage.setItem("jwtToken", token);
        // Set token to all Request Header
        setAuthToken(token);
        // Set current User to decoded token
        dispatch(getCurrentUser(setIsLoading, setErrorHandlerObj));

        axios.defaults.headers.common["authorization"] = token;

        // Get user details and navigate to app
        axios.get(`${url}/one`).then((response) => {
          const userInfo = response.data;
          // console.log(response.data, "authh");
          // If User is verified, check for setup completion
          if (userInfo.isVerified) {
            if (!isEmpty(userInfo.loanPercent && !isEmpty(userInfo.groups))) {
              // Navigate to dashboard
              navigate("/employer/dashboard");
            } else {
              // Navigate to dashboard setup
              navigate("/employer/dashboard/setup");
            }
          } else {
            // If user is not verified
            navigate("/employer/verification");
          }
          setIsLoading(false);
        });
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Set Logged in user
export const setCurrentUser = (decoded: any) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Get Current User
export const getCurrentUser =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/one`)
      .then((res) => {
        dispatch({
          type: GET_CURRENT_USER,
          payload: res.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Verify Current Company
export const verifyCurrentCompany =
  (
    data: IAuth["verificationData"],
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/verify`, data)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Your account has been successfully verified!",
        });

        setTimeout(() => {
          navigate("/");
        }, 10000);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
        setTimeout(() => {
          navigate("/");
        }, 10000);
      });
  };

// Resend Verification link
export const resendVerificationLink =
  (
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/resend-verification-email`)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: `We’ve resent you a verification link. Kindly check your email!`,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Forgot Password
export const forgotPassword =
  (
    data: { email: string },
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsLoading: SetIsLoadingType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/forgot-password`, data)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "A reset token has been sent to your email!",
        });
        setIsLoading(false);
        // Navigate to reset password after 8 seconds
        setTimeout(() => {
          navigate("/reset-password");
        }, 8000);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Reset Password
export const resetPassword =
  (
    data: IResetPasswordData,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsLoading: SetIsLoadingType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/reset-password`, data)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Password updated successfully, Go ahead and Login!",
        });

        setIsLoading(false);
        // Navigate to signin after 8 seconds
        setTimeout(() => {
          navigate("/");
        }, 8000);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Log Out User
export const logoutUser =
  (navigate: NavigateFunction, setUser: any) => (dispatch: any) => {
    // Remove token from localstorage
    localStorage.removeItem("jwtToken");
    // Remove auth header for futere requests
    setAuthToken(false);
    // Set current user to {} which will set is authenticated to false
    dispatch(setCurrentUser({}));
    setUser(null);
    navigate("/");
  };
