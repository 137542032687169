import dashboardIcon from "../images/sidebar-svg/dashboard-icon.svg";
import dashboardIconActive from "../images/sidebar-svg/dashboard-icon-active.svg";
import employeesIcon from "../images/sidebar-svg/employees-icon.svg";
import employeesIconActive from "../images/sidebar-svg/employees-icon-active.svg";
import welfareIcon from "../images/sidebar-svg/welfare-icon.svg";
import welfareIconActive from "../images/sidebar-svg/welfare-icon-active.svg";
import settlementsIcon from "../images/sidebar-svg/settlements-icon.svg";
import settlementsIconActive from "../images/sidebar-svg/settlements-icon-active.svg";
import transactionsIcon from "../images/sidebar-svg/transactions-icon.svg";
import transactionsIconActive from "../images/sidebar-svg/transactions-icon-active.svg";

export interface IAllNavLinks {
  name: string;
  active: boolean;
  icon: string;
  activeIcon: string;
  link: string;
}

export const allNavLinks = [
  {
    name: "Dashboard",
    active: false,
    icon: dashboardIcon,
    activeIcon: dashboardIconActive,
    link: "/employer/dashboard",
  },
  {
    name: "Employees",
    active: false,
    icon: employeesIcon,
    activeIcon: employeesIconActive,
    link: "/employer/employees",
  },
  {
    name: "Welfare",
    active: false,
    icon: welfareIcon,
    activeIcon: welfareIconActive,
    link: "/employer/welfare",
  },
  {
    name: "Settlements",
    active: false,
    icon: settlementsIcon,
    activeIcon: settlementsIconActive,
    link: "/employer/settlements",
  },
  {
    name: "Wallet",
    active: false,
    icon: transactionsIcon,
    activeIcon: transactionsIconActive,
    link: "/employer/wallet",
  },
];
