import React, { useContext, useState } from "react";
import PrimaryButton from "../../../../../../components/buttons/primary-button/PrimaryButton";
import { UserContext } from "../../../../../../components/contexts/UserContext";
import { IAmountPerGroupObj, IGroupObjInUser } from "../../../../../../Types";
import "./PaymentSummary.css";

// Interfaces
interface IProps {
  totalAmount: number;
  selectedCountries: string[];
  selectedVoucherCategories: string[];
  isBenefitsAmountFixed: boolean;
  setIsPaymentOptionModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

function PaymentSummary({
  totalAmount,
  selectedCountries,
  selectedVoucherCategories,
  isBenefitsAmountFixed,
  setIsPaymentOptionModalOpened,
}: IProps) {
  // Functions, States and Variables
  const { user }: any = useContext(UserContext);

  // States
  //   Functions
  // handle Open Payment Method Choice Modal
  const handleOpenPaymentMethodChoiceModal = function () {
    setIsPaymentOptionModalOpened(true);
  };

  // States
  return (
    <section className="credit-employee-summary-container">
      {/* Voucher details containers */}
      <div className="suummary-details-container">
        <div className="summary-details-title">Voucher Details</div>

        <div className="summary-detail-container--inner">
          {/* Country */}
          <div className="summary-detail-wrapper">
            <div className="summary-detail-key">Country</div>
            <div className="summary-detail-value">
              {selectedCountries.join(", ")}
            </div>
          </div>
          {/* Categories */}
          <div className="summary-detail-wrapper">
            <div className="summary-detail-key">Categories</div>
            <div className="summary-detail-value">
              {selectedVoucherCategories.join(", ")}
            </div>
          </div>
        </div>
      </div>

      {/* Payment details containers */}
      <div className="suummary-details-container payment-summary-details-container">
        <div className="summary-details-title">Payment Details</div>

        <div className="summary-detail-container--inner">
          {/* Number of employees */}
          <div className="summary-detail-wrapper">
            <div className="summary-detail-key">Number of employees</div>
            <div className="summary-detail-value">
              {isBenefitsAmountFixed ? (
                user?.staff?.length || 0
              ) : (
                <div className="grouped-payment-detail-container">
                  {user?.groups?.map(
                    (group: IGroupObjInUser, index: number) => (
                      <div
                        key={index + 1}
                        className="grouped-payment-detail-wrapper"
                      >
                        {group.name}: {group?.noOfStaff ?? "Nil"}
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          </div>
          {/* Credit amount/employee */}
          <div className="summary-detail-wrapper">
            <div className="summary-detail-key">Credit amount/employee</div>
            <div className="summary-detail-value">
              {isBenefitsAmountFixed ? (
                <>&#8358;{user?.benefits?.amount?.toLocaleString() || 0}</>
              ) : (
                <div className="grouped-payment-detail-container">
                  {user?.benefits.amountPerGroup?.map(
                    (group: IAmountPerGroupObj, index: number) => (
                      <div
                        key={index + 1}
                        className="grouped-payment-detail-wrapper"
                      >
                        {group.groupName}: &#8358;
                        {group.amount?.toLocaleString()}
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          </div>
          {/* Total Amount to be credited */}
          <div className="summary-detail-wrapper">
            <div className="summary-detail-key">
              Total Amount to be credited
            </div>
            <div className="summary-detail-value">
              &#8358;{totalAmount.toLocaleString()}
            </div>
          </div>
        </div>
      </div>

      {/* Credit Wallet Button */}
      <PrimaryButton
        className="credit-staff-wallets-btn-wrapper"
        placeholder="Credit Wallets"
        onClick={(e) => {
          handleOpenPaymentMethodChoiceModal();
        }}
      />
    </section>
  );
}

export default PaymentSummary;
