import React from "react";
import "./TextInput.css";

interface IProps {
  name?: string;
  type: string;
  id: string;
  className: string;
  placeholder?: string;
  value: string;
  onChange: (e: any) => void;
  required?: boolean;
  min?: string;
  max?: string;
  maxLength?: number;
  label?: string;
  subLabel?: string;
  disabled?: boolean;
  onFocus?: (e: any) => void;
}
// Can be Text, Password, Email, Number or Url Input
const TextInput = ({
  name,
  id,
  type,
  className,
  placeholder,
  value,
  onChange,
  required,
  min,
  max,
  maxLength,
  label,
  subLabel,
  disabled,
  onFocus,
}: IProps) => {
  return (
    <>
      <label htmlFor={id}>
        {label}
        <span>{subLabel}</span>
      </label>
      <input
        type={type}
        id={id}
        className={className}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
        min={min}
        max={max}
        maxLength={maxLength}
        disabled={disabled}
        onFocus={onFocus}
      />
    </>
  );
};

export default TextInput;
