import { ISingleCelebration } from "../Types";

// Interfaces
export type SetCelebrationsStateType = React.Dispatch<
  React.SetStateAction<ISingleCelebration[] | null>
>;
//   handle Filter Table By Type
export const handleFilterCelebrationsByType = function (
  filterValue: string,
  celebrations: ISingleCelebration[] | [], //Straight from API response
  setCurrentCelebrationsPosts: SetCelebrationsStateType,
  searchedValue: string
) {
  if (!celebrations) return false;
  if (filterValue === "All") {
    const celebrationsData = celebrations?.filter(
      (celebration) =>
        celebration.staffId?.firstName
          ?.toLowerCase()
          .includes(searchedValue.toLowerCase()) ||
        celebration.staffId?.lastName
          ?.toLowerCase()
          .includes(searchedValue.toLowerCase())
    );

    // Update table state to ALL CELEBRATIONS for he filter section
    if (!celebrations || !celebrationsData) return false;
    setCurrentCelebrationsPosts(celebrationsData);
  } else if (filterValue === "Text") {
    // Update the set current celebrations state to accomodate only TEXTs.
    let filteredCelebrations: ISingleCelebration[] | null;
    filteredCelebrations = celebrations
      .filter((request) => {
        return request.type === "text";
      })
      ?.filter(
        (request) =>
          request.staffId?.firstName
            ?.toLowerCase()
            .includes(searchedValue.toLowerCase()) ||
          request.staffId?.lastName
            ?.toLowerCase()
            .includes(searchedValue.toLowerCase())
      );
    setCurrentCelebrationsPosts(filteredCelebrations);
  } else {
    // Update the set current celebrations state to accomodate only CREDITs.
    let filteredCelebrations: ISingleCelebration[] | null;
    filteredCelebrations = celebrations
      .filter((request) => {
        return request.type === "credit";
      })
      ?.filter(
        (request) =>
          request.staffId?.firstName
            ?.toLowerCase()
            .includes(searchedValue.toLowerCase()) ||
          request.staffId?.lastName
            ?.toLowerCase()
            .includes(searchedValue.toLowerCase())
      );
    setCurrentCelebrationsPosts(filteredCelebrations);
  }
};
