import React from "react";
import "./Textarea.css";

interface IProps {
  id: string;
  className: string;
  placeholder?: string;
  value: string;
  onChange: (e: any) => void;
  required?: boolean;
  label?: string;
  subLabel?: string;
  disabled?: boolean;
  onFocus?: (e: any) => void;
  resize?: true;
}
const Textarea = ({
  id,
  className,
  placeholder,
  value,
  onChange,
  required,
  label,
  subLabel,
  disabled,
  onFocus,
  resize,
}: IProps) => {
  return (
    <>
      <label htmlFor={id}>
        {label}
        <span>{subLabel}</span>
      </label>
      <textarea
        id={id}
        className={`${className} ${resize ? "resize-textarea" : ""}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
        disabled={disabled}
        onFocus={onFocus}
      />
    </>
  );
};

export default Textarea;
