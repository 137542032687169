import React, { useContext, useEffect, useState } from "react";
import "./Settlements.css";
import Top from "../../components/app-top/Top";
import DropdownInput from "../../components/input-components/dropdown-input/DropdownInput";
import SettlementPreview from "./settlement-preview/SettlementPreview";
import {
  ISearchContextType,
  SearchContext,
} from "../../components/contexts/SearchContext";
import isEmpty from "../../validation/isEmpty";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getAllCompanySettlements } from "../../redux/actions/settlementsActions";
import ErrorHandler from "../../components/error/ErrorHandler";
import SettlementTable from "./settlement-table/SettlementTable";
import SubstituteLoadingSpinner from "../../components/substitute-loading-spinner/SubstituteLoadingSpinner";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Main from "../../components/main-container/Main";
import FEPagination from "../../components/pagination/fe-pagination/FEPagination";

export interface ISingleEmployee {
  _id: string;
  referenceId?: string;
  firstName: string;
  lastName: string;
  salary: number;
  imgURL?: string;
  email: string;
  department: string;
  role: string;
  phoneNumber: string;
  group: string;
  level: string;
  address: string;
  isActive?: boolean;
  staffCode?: string;
  wallet?: number;
}

export interface IItenerary {
  quantity: string;
  description: string;
  "tax-rate": number;
  price: number;
}

export interface IInvoiceData {
  client: {
    company: string;
    address: string;
  };
  sender: {
    company: string;
  };
  images: {
    logo: string;
  };
  information: {
    number: string;
    date: string;
    "due-date": string;
  };
  products: IItenerary[];
}

export interface ISingleSettlement {
  _id: string;
  companyId: string;
  amount: number;
  status: number;
  invoiceData: IInvoiceData;
  invoiceNumber: string;
  paystackReference: string;
  paystackResponse: {
    access_code: string;
    authorization_url: string;
    reference: string;
  };
  isActive: boolean;
  isSettled: boolean;
  withdrawals: {
    withdrawalsId: string;
    staffId: string;
    _id: string;
  }[];
  createdAt: string;
  updatedAt: string;
}

function Settlements() {
  //Function, States and Variables
  const settlements: ISingleSettlement[] | [] = useAppSelector(
    (state) => state.settlements.settlements
  );
  const { searchedValue }: ISearchContextType | any = useContext(SearchContext);
  const dispatch = useAppDispatch();
  // States
  const [tableFilterOption, setTableFilterOption] = useState("all");
  const [allSettlements, setAllSettlements] = useState<
    ISingleSettlement[] | null
  >(null);
  const [selectedSettlementsForPreview, setSelectedSettlementsForPreview] =
    useState<ISingleSettlement | null>(null);
  const [pageContent, setPageContent] = useState("main");
  const [settlementsDateFilter, setSettlementsDateFilter] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPosts, setCurrentPosts] = useState<ISingleSettlement[] | null>(
    null
  );

  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;
  const [isLoading, setIsLoading] = useState(false);
  const [isMarkSettlementsLoading, setIsMarkSettlementsLoading] =
    useState(false);
  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // handle Change Filter Option To All
  const handleChangeFilterOptionToAll = function () {
    setTableFilterOption("all");

    // Update settlement state
    let filteredSettlement = settlements?.filter((settlement) =>
      settlement.invoiceNumber
        .toLowerCase()
        .includes(searchedValue.toLowerCase())
    );
    setAllSettlements(filteredSettlement);
  };
  // handle Change Filter Option To Pending
  const handleChangeFilterOptionToPending = function () {
    setTableFilterOption("pending");

    // Update settlement state
    setAllSettlements((prevSettlements) => {
      if (!prevSettlements) return prevSettlements;

      let filteredSettlement = settlements
        ?.filter((settlement) => settlement.status === 1)
        ?.filter((settlement) =>
          settlement.invoiceNumber
            .toLowerCase()
            .includes(searchedValue.toLowerCase())
        );
      return filteredSettlement;
    });
  };
  // handle Change Filter Option To Completed
  const handleChangeFilterOptionToCompleted = function () {
    setTableFilterOption("completed");

    // Update settlement state
    setAllSettlements((prevSettlements) => {
      if (!prevSettlements) return prevSettlements;

      let filteredSettlement = settlements
        ?.filter((settlement) => settlement.status === 2)
        ?.filter((settlement) =>
          settlement.invoiceNumber
            .toLowerCase()
            .includes(searchedValue.toLowerCase())
        );
      return filteredSettlement;
    });
  };

  // Handles Display the error and success message and scrolls to top
  const { errorHandlerObj, setErrorHandlerObj } = useDisplayMessage();

  useEffect(() => {
    dispatch(getAllCompanySettlements(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    let allNewSettlements = settlements?.filter((settlement) =>
      settlement.invoiceNumber
        .toLowerCase()
        .includes(searchedValue.toLowerCase())
    );
    setAllSettlements(allNewSettlements);
  }, [settlements, searchedValue]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pageContent]);

  useEffect(() => {
    if (allSettlements) {
      const currentRows = allSettlements?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );

      setCurrentPosts(currentRows);
    }
  }, [allSettlements, currentPage]);

  return (
    <div className="settlements-container">
      <Top />
      {/* <Navbar /> */}
      <Sidebar activeName={"Settlements"} />

      <Main>
        <div className="settlements-container--inner">
          {/* Error and Success Handlers */}
          <div className="employees--actions-handler-wrapper">
            {/* Error handler component */}
            <ErrorHandler errorHandlerObj={errorHandlerObj} />
          </div>

          <section
            className={`settlements-main-container-section ${
              pageContent === "main" ? "" : "none"
            }`}
          >
            <div className="settlements-top-wrapper">
              {/* seettlements filter section */}
              <div className="settlements-filter-container">
                {/* All filter option */}
                <div
                  className={`settlements-filter-option filter-option--all ${
                    tableFilterOption === "all" ? "active-filter-option" : ""
                  }`}
                  onClick={() => handleChangeFilterOptionToAll()}
                >
                  All
                </div>
                {/* Pending filter option */}
                <div
                  className={`settlements-filter-option filter-option--pending ${
                    tableFilterOption === "pending"
                      ? "active-filter-option"
                      : ""
                  }`}
                  onClick={() => handleChangeFilterOptionToPending()}
                >
                  Pending
                </div>
                {/* Completed filter option */}
                <div
                  className={`settlements-filter-option filter-option--completed ${
                    tableFilterOption === "completed"
                      ? "active-filter-option"
                      : ""
                  }`}
                  onClick={() => handleChangeFilterOptionToCompleted()}
                >
                  Completed
                </div>
              </div>
            </div>

            {/* Settlements Table Container */}
            <div className="settlements-table-container">
              {/* Section Row One */}
              <div className="settlements-main-container--row-one settlements-top-row-section">
                {/* Table filter option wrapper */}
                <div className="welfare-table-filter-option-wrapper">
                  <div className="settlements-table-filter-row-title">
                    Filter:
                  </div>
                  {/* Filter by order date dropdown */}
                  <div className="settlements-date-filter-form-group form-group">
                    <DropdownInput
                      className={"settlements-date-filter-dropdown-input"}
                      id={"settlements-date-filter-input"}
                      label={""}
                      required={true}
                      optionArray={[
                        { key: "", value: "Date", default: "Default" },
                        {
                          key: "yesterday",
                          value: "Yesterday",
                        },
                      ]}
                      value={settlementsDateFilter}
                      onChange={(e) => setSettlementsDateFilter(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              {/* If Settlements is marked as paid */}
              {isMarkSettlementsLoading && (
                <div className="mark-settlement-loading-spinner-wrapper">
                  {<SubstituteLoadingSpinner />}
                </div>
              )}

              {/* Table Row */}
              <div className="settlements-main-container--row-two settlements-table-row">
                <SettlementTable
                  currentPosts={currentPosts}
                  isLoading={isLoading}
                  setPageContent={setPageContent}
                  setSelectedSettlementsForPreview={
                    setSelectedSettlementsForPreview
                  }
                  setIsMarkSettlementsLoading={setIsMarkSettlementsLoading}
                  setErrorHandlerObj={setErrorHandlerObj}
                />
              </div>
            </div>

            {allSettlements && !isEmpty(allSettlements) && (
              <FEPagination
                paginate={paginate}
                rowsPerPage={rowsPerPage}
                totalPosts={allSettlements.length}
                currentPage={currentPage}
              />
            )}
          </section>

          <section
            className={`settlement-preview-container ${
              pageContent === "preview" ? "" : "none"
            }`}
          >
            {selectedSettlementsForPreview && (
              <SettlementPreview
                selectedSettlementInvoiceNumber={
                  selectedSettlementsForPreview.invoiceNumber
                }
                selectedSettlementItenerary={
                  selectedSettlementsForPreview.invoiceData.products
                }
                setPageContent={setPageContent}
              />
            )}
          </section>
        </div>
      </Main>
    </div>
  );
}

export default Settlements;
