import axios from "axios";
import { GET_ALL_COMPANY_STAFF, GET_ERRORS } from "../Constants";
import isEmpty from "../../validation/isEmpty";
import { api } from "../../api/config";
import { NavigateFunction } from "react-router-dom";
import { handleAPIError } from "../../utils/handleAPIError";
import {
  SetErrorHandlerType,
  SetIsLoadingType,
  SetSuccessHandlerType,
} from "../../Types";

interface IStaffTypes {
  singleStaff: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    department: string;
    email: string;
    role: string;
    salary: number;
    address: string;
    group: string;
    level: string;
  };
}
const url = `${api}/api/v1/company`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get All Company Staffs
export const getAllCompanyStaffs =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/staff`)
      .then((res) => {
        dispatch({
          type: GET_ALL_COMPANY_STAFF,
          payload: res.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//   Onboard Single Staff
export const onboardSingleStaff =
  (
    data: IStaffTypes["singleStaff"],
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/onboard-staff`, data)
      .then((res) => {
        navigate("/employer/employees/onboard-successful", {
          state: { numOfStaff: 1 },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//   Onboard many Staff
export const onboardManyStaff =
  (
    data: { staff: IStaffTypes["singleStaff"][] },
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/onboard-many-staff`, data)
      .then((res) => {
        navigate("/employer/employees/onboard-successful", {
          state: { numOfStaff: res.data.data?.length || 1 },
        });

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//   Onboard Staff Through CSV
export const onboardStaffThroughCSV =
  (
    data: FormData,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/onboard-staff-csv`, data)
      .then((res) => {
        navigate("/employer/employees/onboard-successful", {
          state: { numOfStaff: 1 },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Edit / Update Staff Information
export const editStaffInformation =
  (
    staffId: string,
    data: IStaffTypes["singleStaff"],
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setPageContent: React.Dispatch<React.SetStateAction<string>>
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/edit-staff/${staffId}`, data)
      .then((res) => {
        setPageContent("main");

        // Set success message
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Employee details updated successfully!",
        });
        // TODO Set page content
        setIsLoading(false);
      })
      .catch((err) => {
        setPageContent("main");
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//  Delete Staff
export const deleteStaff =
  (
    staffId: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setPageContent: React.Dispatch<React.SetStateAction<string>>,
    setIsDeleteEmployeeModalOpened: React.Dispatch<
      React.SetStateAction<boolean>
    >
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/delete-staff/${staffId}`)
      .then((res) => {
        // Set success message
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Employee deleted successfully!",
        });
        // TODO Set page content
        setPageContent("main");
        setIsDeleteEmployeeModalOpened(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setPageContent("main");
        setIsDeleteEmployeeModalOpened(false);
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };
