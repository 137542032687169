import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/app-navbar/Navbar";
import Top from "../../components/app-top/Top";
import {
  ISearchContextType,
  SearchContext,
} from "../../components/contexts/SearchContext";
import ErrorHandler from "../../components/error/ErrorHandler";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getAllCompanyRequests } from "../../redux/actions/requestActions";
import { ISingleEmployee } from "../employer-settlements/Settlements";
import BNPLTable from "./bnpl-table/BNPLTable";
import ConfirmApproveDeclineRequestModal from "./modals/ConfirmApproveDeclineRequestModal";
import OnDemandTable from "./on-demand-table/OnDemandTable";
import RequestsPreview from "./requests-preview/RequestsPreview";
import "./Requests.css";
import SalaryAdvanceTable from "./salary-advance-table/SalaryAdvanceTable";

export interface IAllRequests {
  account: {
    accountNumber: string;
    bank: string;
    bankCode: string;
    accountName: string;
  };
  _id: string;
  amount: number;
  type: string;
  status: number;
  isPaid: boolean;
  isRejected: boolean;
  isOTPProvided: boolean;
  isApproved: boolean;
  isApprovedByAdmin: boolean;
  userId: string;
  companyId: string;
  staffId: ISingleEmployee;
  createdAt: string;
  adminRejectionReason?: string;
  hrRejectionReason?: string;
  tenure?: string;
  repayments?: {
    amount: number;
    date: string;
  }[];
}

export interface IMerchantFromEmployeeRequest {
  _id: string;
  name: string;
  imgURL: string;
  ratings: number;
  category: string;
}

function Requests() {
  //Function, States and Variables
  const { searchedValue }: ISearchContextType | any = useContext(SearchContext);
  // States
  const allRequests: IAllRequests[] | [] = useAppSelector(
    (state) => state.requests.requests
  );
  const dispatch = useAppDispatch();
  // States
  const [tableFilterOption, setTableFilterOption] = useState("on-demand");
  const [allOnDemandRequests, setAllOnDemandRequests] = useState<
    IAllRequests[] | null
  >(null);
  const [allSalaryAdvanceRequests, setAllSalaryAdvanceRequests] = useState<
    IAllRequests[] | null
  >(null);
  const [allBNPLRequests, setAllBNPLRequests] = useState<IAllRequests[] | null>(
    null
  );
  const [selectedEmployeeRequest, setSelectedEmployeeRequest] =
    useState<IAllRequests | null>(null);

  const [merchantFromEmployeeRequest, setMerchantFromEmployeeRequest] =
    useState<IMerchantFromEmployeeRequest | null>(null);
  const [pageContent, setPageContent] = useState("main");

  const [currentPageForOnDemand, setCurrentPageForOnDemand] = useState(1);
  const [currentPageForSalaryAdvance, setCurrentPageForSalaryAdvance] =
    useState(1);
  const [currentPageForBNPL, setCurrentPageForBNPL] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentOnDemandPosts, setCurrentOnDemandPosts] = useState<
    IAllRequests[] | null
  >(null);
  const [currentSalaryAdvancePosts, setCurrentSalaryAdvancePosts] = useState<
    IAllRequests[] | null
  >(null);
  const [currentBNPLPosts, setCurrentBNPLPosts] = useState<
    IAllRequests[] | null
  >(null);

  const [isApproved, setIsApproved] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false);
  const [wantsToApproveRequest, setWantsToApproveRequest] = useState(false);
  const [wantsToDeclineRequest, setWantsToDeclineRequest] = useState(false);
  const [
    isConfirmApproveDeclineRequestModalOpened,
    setIsConfirmApproveDeclineRequestModalOpened,
  ] = useState(false);

  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  // On Demand
  const indexOfLastPostForOnDemand = currentPageForOnDemand * rowsPerPage;
  const indexOfFirstPostForOnDemand = indexOfLastPostForOnDemand - rowsPerPage;
  // Salary Advance
  const indexOfLastPostForSalaryAdvance =
    currentPageForSalaryAdvance * rowsPerPage;
  const indexOfFirstPostForSalaryAdvance =
    indexOfLastPostForSalaryAdvance - rowsPerPage;
  // BNPL
  const indexOfLastPostForBNPL = currentPageForBNPL * rowsPerPage;
  const indexOfFirstPostForBNPL = indexOfLastPostForBNPL - rowsPerPage;

  // Functions
  // handle Change Filter Option To On Demand
  const handleChangeFilterOptionToOnDemand = function () {
    setTableFilterOption("on-demand");

    // Update table state to salary advance rows
    if (!allOnDemandRequests) return false;
    const currentRows = allOnDemandRequests.slice(
      indexOfFirstPostForOnDemand,
      indexOfLastPostForOnDemand
    );

    setCurrentOnDemandPosts(currentRows);
  };

  // handle Change Filter Option To Salary Advance
  const handleChangeFilterOptionToSalaryAdvance = function () {
    setTableFilterOption("salary-advance");

    // Update table state to salary advance rows
    if (!allSalaryAdvanceRequests) return false;
    const currentRows = allSalaryAdvanceRequests.slice(
      indexOfFirstPostForSalaryAdvance,
      indexOfLastPostForSalaryAdvance
    );

    setCurrentSalaryAdvancePosts(currentRows);
  };
  // handle Change Filter Option To BNPL
  const handleChangeFilterOptionToBNPL = function () {
    setTableFilterOption("bnpl");

    // Update table state to bnpl rows
    if (!allBNPLRequests) return false;
    const currentRows = allBNPLRequests.slice(
      indexOfFirstPostForBNPL,
      indexOfLastPostForBNPL
    );

    setCurrentBNPLPosts(currentRows);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pageContent]);

  useEffect(() => {
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess) {
      //scroll to page top
      window.scrollTo({ top: 0 });
    }
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
    }
    console.log(errorHandlerObj);
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });
    }
  }, [successHandlerObj]);

  useEffect(() => {
    dispatch(getAllCompanyRequests(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    console.log(allRequests);
    if (allRequests) {
      // On Demand
      const onDemandData = allRequests.filter((request) => {
        return request.type === "onDemand";
      });
      setAllOnDemandRequests(onDemandData);

      // Salary Advance
      const salaryAdvanceData = allRequests.filter((request) => {
        return request.type === "salaryAdvance";
      });
      setAllSalaryAdvanceRequests(salaryAdvanceData);

      // BNPL
      const bnplData = allRequests.filter((request) => {
        return request.type === "bnpl";
      });
      setAllBNPLRequests(bnplData);
    }
  }, [allRequests]);

  useEffect(() => {
    // For On Demand Requests
    if (allOnDemandRequests) {
      const currentRows = allOnDemandRequests?.slice(
        indexOfFirstPostForOnDemand,
        indexOfLastPostForOnDemand
      );

      setCurrentOnDemandPosts(currentRows);
    }
  }, [allOnDemandRequests, currentPageForOnDemand]);

  useEffect(() => {
    // For Salary Advance Requests
    if (allSalaryAdvanceRequests) {
      const currentRows = allSalaryAdvanceRequests?.slice(
        indexOfFirstPostForSalaryAdvance,
        indexOfLastPostForSalaryAdvance
      );

      setCurrentSalaryAdvancePosts(currentRows);
    }
  }, [allSalaryAdvanceRequests, currentPageForSalaryAdvance]);

  useEffect(() => {
    // For BNPL Requests
    if (allBNPLRequests) {
      const currentRows = allBNPLRequests?.slice(
        indexOfFirstPostForBNPL,
        indexOfLastPostForBNPL
      );

      setCurrentBNPLPosts(currentRows);
    }
  }, [allBNPLRequests, currentPageForBNPL]);

  useEffect(() => {
    if (isApproved || isDeclined)
      dispatch(getAllCompanyRequests(setIsLoading, setErrorHandlerObj));
  }, [isApproved, isDeclined]);

  return (
    <div
      className={`requests-container ${
        isConfirmApproveDeclineRequestModalOpened ? "overflow-hidden" : ""
      }`}
    >
      <Top />
      <Navbar />

      {/* Gray Overlay */}
      <div
        onClick={() => {
          setIsConfirmApproveDeclineRequestModalOpened(false);
        }}
        className={`gray-overlay ${
          isConfirmApproveDeclineRequestModalOpened ? "" : "none"
        }`}
      ></div>

      {/* Confirm Approve Decline Request  Modal */}
      <ConfirmApproveDeclineRequestModal
        isConfirmApproveDeclineRequestModalOpened={
          isConfirmApproveDeclineRequestModalOpened
        }
        setIsConfirmApproveDeclineRequestModalOpened={
          setIsConfirmApproveDeclineRequestModalOpened
        }
        selectedEmployeeRequest={selectedEmployeeRequest}
        setSelectedEmployeeRequest={setSelectedEmployeeRequest}
        wantsToApproveRequest={wantsToApproveRequest}
        wantsToDeclineRequest={wantsToDeclineRequest}
        setIsApproved={setIsApproved}
        setIsDeclined={setIsDeclined}
        setErrorHandlerObj={setErrorHandlerObj}
        setSuccessHandlerObj={setSuccessHandlerObj}
      />

      <div className="employees--actions-handler-wrapper">
        {/* Error handler component */}
        <ErrorHandler errorHandlerObj={errorHandlerObj} />
      </div>
      <div className={`requests-container--inner`}>
        {/* Main Container Section */}
        <section
          className={`requests-main-section-comtainer ${
            pageContent === "main" ? "" : "none"
          }`}
        >
          <div className="requests-top-wrapper">
            {/* seettlements filter section */}
            <div className="requests-filter-container">
              {/* On demand filter option */}
              <div
                className={`requests-filter-option filter-option--on-demand ${
                  tableFilterOption === "on-demand"
                    ? "active-filter-option"
                    : ""
                }`}
                onClick={() => handleChangeFilterOptionToOnDemand()}
              >
                On-Demand
              </div>
              {/* Salary Advance filter option */}
              <div
                className={`requests-filter-option filter-option--salary-advance ${
                  tableFilterOption === "salary-advance"
                    ? "active-filter-option"
                    : ""
                }`}
                onClick={() => handleChangeFilterOptionToSalaryAdvance()}
              >
                Salary Advance
              </div>
              {/* BNPL Filter option */}
              <div
                className={`requests-filter-option filter-option--bnpl ${
                  tableFilterOption === "bnpl" ? "active-filter-option" : ""
                }`}
                onClick={() => handleChangeFilterOptionToBNPL()}
              >
                BNPL
              </div>
            </div>
          </div>

          {/* Salary Advance Table Section */}
          <section
            className={`on-demand-table-section ${
              tableFilterOption === "on-demand" ? "" : "none"
            }`}
          >
            <OnDemandTable
              allOnDemandRequests={allOnDemandRequests}
              currentOnDemandPosts={currentOnDemandPosts}
              setPageContent={setPageContent}
              currentPage={currentPageForOnDemand}
              setCurrentPage={setCurrentPageForOnDemand}
              setSelectedEmployeeRequest={setSelectedEmployeeRequest}
              setCurrentOnDemandPosts={setCurrentOnDemandPosts}
              allRequests={allRequests}
              setAllOnDemandRequests={setAllOnDemandRequests}
              rowsPerPage={rowsPerPage}
              indexOfFirstPost={indexOfFirstPostForOnDemand}
              indexOfLastPost={indexOfLastPostForOnDemand}
              isLoading={isLoading}
            />
          </section>

          {/* Salary Advance Table Section */}
          <section
            className={`salary-advance-table-section ${
              tableFilterOption === "salary-advance" ? "" : "none"
            }`}
          >
            <SalaryAdvanceTable
              allSalaryAdvanceRequests={allSalaryAdvanceRequests}
              currentSalaryAdvancePosts={currentSalaryAdvancePosts}
              setPageContent={setPageContent}
              currentPage={currentPageForSalaryAdvance}
              setCurrentPage={setCurrentPageForSalaryAdvance}
              setSelectedEmployeeRequest={setSelectedEmployeeRequest}
              setCurrentSalaryAdvancePosts={setCurrentSalaryAdvancePosts}
              allRequests={allRequests}
              setAllSalaryAdvanceRequests={setAllSalaryAdvanceRequests}
              rowsPerPage={rowsPerPage}
              indexOfFirstPost={indexOfFirstPostForSalaryAdvance}
              indexOfLastPost={indexOfLastPostForSalaryAdvance}
              isLoading={isLoading}
            />
          </section>

          {/* BNPL Table Section */}
          <section
            className={`bnpl-table-section ${
              tableFilterOption === "bnpl" ? "" : "none"
            }`}
          >
            <BNPLTable
              allBNPLRequests={allBNPLRequests}
              currentBNPLPosts={currentBNPLPosts}
              setPageContent={setPageContent}
              currentPage={currentPageForBNPL}
              setCurrentPage={setCurrentPageForBNPL}
              setSelectedEmployeeRequest={setSelectedEmployeeRequest}
              allRequests={allRequests}
              setCurrentBNPLPosts={setCurrentBNPLPosts}
              setAllBNPLRequests={setAllBNPLRequests}
              setMerchantFromEmployeeRequest={setMerchantFromEmployeeRequest}
              rowsPerPage={rowsPerPage}
              indexOfFirstPost={indexOfFirstPostForBNPL}
              indexOfLastPost={indexOfLastPostForBNPL}
              isLoading={isLoading}
            />
          </section>
        </section>

        {/* Preview Container Section */}
        <section
          className={`requests-preview-container ${
            pageContent === "preview" ? "" : "none"
          }`}
        >
          <RequestsPreview
            selectedEmployeeRequest={selectedEmployeeRequest}
            setPageContent={setPageContent}
            merchantFromEmployeeRequest={merchantFromEmployeeRequest}
            isApproved={isApproved}
            isDeclined={isDeclined}
            setIsApproved={setIsApproved}
            setIsDeclined={setIsDeclined}
            setWantsToApproveRequest={setWantsToApproveRequest}
            setWantsToDeclineRequest={setWantsToDeclineRequest}
            setIsConfirmApproveDeclineRequestModalOpened={
              setIsConfirmApproveDeclineRequestModalOpened
            }
          />
        </section>
      </div>
    </div>
  );
}

export default Requests;
