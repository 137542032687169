export const requestFilterDropdown = [
  { key: "", value: "Status", default: "Default" },
  {
    key: "All",
    value: "All",
  },
  {
    key: "Awaiting Approval",
    value: "Awaiting Approval",
  },
  {
    key: "Completed",
    value: "Completed by HR",
  },
  {
    key: "Rejected",
    value: "Rejected by HR",
  },
  {
    key: "Completed by Credley",
    value: "Completed by Credley",
  },
  {
    key: "Rejected by Credley",
    value: "Rejected by Credley",
  },
  {
    key: "Disbursed",
    value: "Disbursed",
  },
];
