import React, { MouseEvent, useEffect, useState } from "react";
import "./BulkUpload.css";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../app-navbar/Navbar";
import Top from "../../app-top/Top";
import angleLeftIcon from "../../../images/angle-left.svg";
import downloadIcon from "../../../images/download-icon.svg";
import LoadingSpinner from "../../loading-spinner/LoadingSpinner";
import ErrorHandler from "../../error/ErrorHandler";
import { useAppDispatch } from "../../../hooks";
import { onboardStaffThroughCSV } from "../../../redux/actions/staffActions";
import { CSVLink } from "react-csv";
import Sidebar from "../../app-sidebar/Sidebar";
import Main from "../../main-container/Main";

function BulkUpload() {
  // Functions, States and Variables
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [cvsFileName, setCvsFileName] = useState("");
  const [cvsFile, setCvsFile] = useState<string | File>("");

  // Functions
  const handleSubmitCVSDocument = function (e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    const selectedFile = cvsFile;
    const formData = new FormData();
    formData.append("file", selectedFile);

    //  Call the onboard employees through CVS FIlE API
    dispatch(
      onboardStaffThroughCSV(
        formData,
        setIsLoading,
        setErrorHandlerObj,
        navigate
      )
    );
  };

  useEffect(() => {
    if (errorHandlerObj.hasError) {
      //scroll to page top
      window.scrollTo({ top: 0 });
    }
  }, [errorHandlerObj]);

  const csvData = [
    [
      "firstName",
      "lastName",
      "phoneNumber",
      "group",
      "email",
      "salary",
      "address",
      "level",
      "role",
      "department",
    ],
    [
      "John",
      "Doe",
      "07035807104",
      "Junior Staff",
      "johndoe@gmail.com",
      "10000000.00",
      "Lagos Nigeria",
      "Grade 1",
      "Software Development",
      "IT",
    ],
    [
      "Mary",
      "Daniel",
      "07035807105",
      "Junior Staff",
      "marydaniel@gmail.com",
      "30000000.00",
      "Lagos Nigeria",
      "Grade 3",
      "Software Development",
      "IT",
    ],
  ];

  return (
    <div className="bulk-upload-container">
      <Top />
      <Sidebar activeName={"Employees"} />

      <Main>
        <div className="staff-onboarding-handler-wrapper">
          {/* Error handler component */}
          <ErrorHandler errorHandlerObj={errorHandlerObj} />
        </div>
        <div className="bulk-upload-container--inner">
          {" "}
          {/* Onboard Employees Top */}
          <div className="onboard-employees-top-container">
            {/* Back button Wrapper */}
            <div className="back-button-wrapper">
              <button onClick={() => navigate(-1)}>
                <img src={angleLeftIcon} alt="" />
              </button>
            </div>

            {/* Onboard Employees Title */}
            <div className="dashboard-setup-top-title">
              Onboard Staff Members
            </div>
          </div>
          <div className="single-upload-sub-header">Bulk Upload &gt;</div>
          {/* Bulk Upload Container Main section */}
          <section className="bulk-upload-container-main-section">
            {/* Upload CVS or XLS file form */}
            <form>
              {/* CVS / XLS Doc form group */}
              <div className="cvs-xls-doc-form-group file-form-group">
                <label htmlFor="cvs-xls-doc-file-input">Document Upload</label>
                <div className="file-form-group--inner">
                  <div className="file-input-wrapper">
                    <div className="file-input-title">
                      {cvsFileName || "Upload CVS or XLS File"}
                    </div>
                    <input
                      type="file"
                      id={"cvs-xls-doc-file-input"}
                      className={"cvs-xls-doc-input"}
                      placeholder={""}
                      value={cvsFileName}
                      onChange={(e) => {
                        if (e.target.files) {
                          setCvsFile(e.target.files[0]);
                          setCvsFileName(e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="cvs-file-format-download-link-wrapper">
                  <CSVLink data={csvData} filename={"sample.csv"}>
                    CSV File Format <img src={downloadIcon} alt="" />
                  </CSVLink>
                </div>
              </div>

              {/* Submit form button */}
              <div className="submit-setup-form-button-wrapper">
                {isLoading && <LoadingSpinner />}
                <button onClick={handleSubmitCVSDocument}>Upload</button>
              </div>
            </form>
          </section>
        </div>
      </Main>
    </div>
  );
}

export default BulkUpload;
