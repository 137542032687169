import React, { MouseEvent, useEffect, useState } from "react";
import "./EmployeePreview.css";
import { ISingleEmployee } from "../../../employer-settlements/Settlements";
import angleLeftIcon from "../../../../images/angle-left.svg";
import isEmpty from "../../../../validation/isEmpty";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import { numberWithCommas } from "../../../../utils/numberWithCommas";
import TextInput from "../../../../components/input-components/text-input/TextInput";
import { ICompanyGroups } from "../../../../components/onboard-employees/single-upload/SingleUpload";
import DropdownInput from "../../../../components/input-components/dropdown-input/DropdownInput";
import { useAppDispatch } from "../../../../hooks";
import { editStaffInformation } from "../../../../redux/actions/staffActions";
import RecentWalletTrancactions from "../../../employer-welfare/sub-pages/benefits/credit-new-wallet/employee-profile/recent-wallet-transactions/RecentWalletTrancactions";

interface IProps {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessHandlerObj: React.Dispatch<
    React.SetStateAction<{
      isSuccess: boolean;
      message: string;
    }>
  >;
  setErrorHandlerObj: React.Dispatch<
    React.SetStateAction<{
      hasError: boolean;
      message: string;
    }>
  >;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  selectedEmployeeForPreview: ISingleEmployee | null;
  setSelectedEmployeeForPreview: React.Dispatch<
    React.SetStateAction<ISingleEmployee | null>
  >;

  setIsDeleteEmployeeModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isDeleteEmployeeModalOpened: boolean;
  allGroups: any;
}

interface ICurrentEmployee {
  _id: string;
  referenceId?: string;
  firstName: string;
  lastName: string;
  salary?: number;
  imgURL?: string;
  email: string;
  department: string;
  role: string;
  phoneNumber: string;
  level: string;
  group: string;
  address: string;
  employeeView: string;
  staffCode?: string;
  wallet?: number;
}
const EmployeePreview: React.FC<IProps> = function ({
  isLoading,
  setIsLoading,
  setSuccessHandlerObj,
  setErrorHandlerObj,
  setPageContent,
  selectedEmployeeForPreview,
  setSelectedEmployeeForPreview,
  isDeleteEmployeeModalOpened,
  setIsDeleteEmployeeModalOpened,
  allGroups,
}) {
  const dispatch = useAppDispatch();
  // Functions and States
  const [currentEmployee, setCurrentEmployee] =
    useState<ICurrentEmployee | null>(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [role, setRole] = useState("");
  const [salary, setSalary] = useState<any>("");
  const [address, setAddress] = useState("");
  const [group, setGroup] = useState("");
  const [level, setLevel] = useState("");

  const [companyGroupsArr, setCompanyGroupsArr] = useState<
    [] | ICompanyGroups[]
  >([]);
  // Functions
  //   handle Set Employee View To Edit
  const handleSetEmployeeViewToEdit = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    e.preventDefault();
    setCurrentEmployee((prevCurrentEmployee) => {
      if (!selectedEmployeeForPreview) return prevCurrentEmployee;
      const updatedCurrentEmployee = {
        ...selectedEmployeeForPreview,
        employeeView: "form-view",
      };

      return updatedCurrentEmployee;
    });
  };

  // handle Open Delete Employee Modal
  const handleOpenDeleteEmployeeModal = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    e.preventDefault();
    setIsDeleteEmployeeModalOpened(true);
  };

  //   handle Update Employee information
  const handleUpdateEmployeeInformation = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      phoneNumber !== "" &&
      salary !== "" &&
      group !== "" &&
      address !== ""
      // department !== "" &&
      // role !== "" &&
      // level !== ""
    ) {
      e.preventDefault();

      const data = {
        firstName,
        lastName,
        phoneNumber,
        department,
        email,
        role,
        group,
        salary: parseInt(salary),
        address,
        level,
      };

      if (selectedEmployeeForPreview) {
        //   Call the dispatch function to update the employee information
        dispatch(
          editStaffInformation(
            selectedEmployeeForPreview._id,
            data,
            setIsLoading,
            setErrorHandlerObj,
            setSuccessHandlerObj,
            setPageContent
          )
        );
        // setSelectedEmployeeForPreview(null);
        // setCurrentEmployee(null);
      }
    }
  };

  useEffect(() => {
    setCurrentEmployee((prevCurrentEmployee) => {
      if (!selectedEmployeeForPreview) return prevCurrentEmployee;
      const newCurrentEmployee = {
        ...selectedEmployeeForPreview,
        employeeView: "preview-view",
      };

      return newCurrentEmployee;
    });
  }, [selectedEmployeeForPreview]);

  useEffect(() => {
    if (currentEmployee) {
      setFirstName(currentEmployee.firstName);
      setLastName(currentEmployee.lastName);
      setEmail(currentEmployee.email);
      setDepartment(currentEmployee.department);
      setPhoneNumber(currentEmployee.phoneNumber);
      setRole(currentEmployee.role);
      setSalary(currentEmployee.salary);
      setAddress(currentEmployee.address);
      setLevel(currentEmployee.level);
      setGroup(currentEmployee.group);
    }
  }, [currentEmployee]);

  useEffect(() => {
    if (!isEmpty(allGroups)) {
      const groupsArr = allGroups?.groups?.map((group: any) => {
        return {
          key: group.name,
          value: group.name,
        };
      });

      groupsArr.unshift({ key: "", value: "", default: "default" });
      console.log(allGroups, groupsArr);

      setCompanyGroupsArr(groupsArr);
    }
  }, [allGroups]);
  return (
    <div className="employee-preview-section--inner">
      {/* Requests preview Top */}
      <form>
        <div
          className={`dashboard-setup-top-container employee-preview-top-container`}
        >
          <div className="employee-preview-top--left-wrapper">
            {/* Back button Wrapper */}
            <div className="back-button-wrapper">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setPageContent("main");

                  setSelectedEmployeeForPreview(null);

                  //
                }}
              >
                <img src={angleLeftIcon} alt="" />
              </button>
            </div>
            {/* Reference Number */}
            <div className="dashboard-setup-top-title">
              {/* {currentEmployee?.referenceId} */}#
              {currentEmployee?.staffCode || ""}
            </div>
          </div>

          {/* Top Right Wrapper */}
          {currentEmployee && (
            <div className="employee-preview-top-action-buttons-container">
              {currentEmployee.employeeView === "preview-view" ? (
                <div className="edit-employee-info-button-wrapper">
                  <button
                    onClick={(e) => handleSetEmployeeViewToEdit(e)}
                    disabled={isLoading}
                  >
                    Edit Information
                  </button>
                </div>
              ) : (
                <div className="update-employee-info-button-wrapper">
                  {isLoading && <LoadingSpinner />}
                  <button
                    onClick={(e) => handleUpdateEmployeeInformation(e)}
                    disabled={isLoading}
                  >
                    Update Information
                  </button>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Main section container */}
        {currentEmployee && !isEmpty(currentEmployee) ? (
          <>
            <div className={`employee-preview-main-section-container`}>
              {/* Left Container */}
              <div className="employee-preview-main--left-container">
                {/* Title */}
                <div className="requests-preview--employee-details--title">
                  EMPLOYEE
                </div>

                {/* Profile Img */}
                {/* <div className="employee-preview--employee-profile-img">
                  <img src={currentEmployee?.imgURL} alt="" />
                </div> */}

                {/* Employee name */}
                <div className="employee-preview--employee-name">
                  {currentEmployee?.firstName} {currentEmployee?.lastName}
                </div>
                <div className="employee-preview--employee-role--top-left">
                  {currentEmployee?.role}
                </div>

                {/* Employee Loan Cap */}
                <div className="employee-preview--employee-loan-cap">
                  <div className="requests-preview--employee-detail--title">
                    Loan Cap
                  </div>
                  <div className="employee-preview--employee-loan-cap--value">
                    &#8358;
                    {numberWithCommas(0)}
                  </div>
                </div>
              </div>

              {/* Right Container */}
              <div
                className={`employee-preview-main--right-container ${
                  currentEmployee.employeeView === "preview-view" ? "" : "none"
                }`}
              >
                <div className="employee-details--preview-wrapper">
                  {/* Title */}
                  <div className="requests-preview--employee-details--title">
                    DETAILS
                  </div>

                  <div className="employee-preview--employee-details--row-one">
                    {/* Employee name */}
                    <div className="employee-preview--employee-name-wrapper employee-preview--employee-detail-wrapper">
                      <div className="requests-preview--employee-detail--title">
                        Name
                      </div>
                      <div className="requests-preview--employee-detail--value">
                        {currentEmployee?.firstName} {currentEmployee?.lastName}
                      </div>
                    </div>
                    {/* Employee email */}
                    <div className="employee-preview--employee-email employee-preview--employee-detail-wrapper">
                      <div className="requests-preview--employee-detail--title">
                        Email
                      </div>
                      <div className="requests-preview--employee-detail--value">
                        {currentEmployee?.email}
                      </div>
                    </div>
                    {/* Employee department */}
                    <div className="employee-preview--employee-department employee-preview--employee-detail-wrapper">
                      <div className="requests-preview--employee-detail--title">
                        Department
                      </div>
                      <div className="requests-preview--employee-detail--value">
                        {currentEmployee?.department}
                      </div>
                    </div>
                    {/* Employee Phone Number */}
                    <div className="employee-preview--employee-phone-number employee-preview--employee-detail-wrapper">
                      <div className="requests-preview--employee-detail--title">
                        Phone Number
                      </div>
                      <div className="requests-preview--employee-detail--value">
                        {currentEmployee?.phoneNumber}
                      </div>
                    </div>
                    {/* Employee Role */}
                    <div className="employee-preview--employee-role employee-preview--employee-detail-wrapper">
                      <div className="requests-preview--employee-detail--title">
                        Role
                      </div>
                      <div className="requests-preview--employee-detail--value">
                        {currentEmployee?.role}
                      </div>
                    </div>
                    {/* Employee Group */}
                    <div className="employee-preview--employee-role employee-preview--employee-detail-wrapper">
                      <div className="requests-preview--employee-detail--title">
                        Group
                      </div>
                      <div className="requests-preview--employee-detail--value">
                        {currentEmployee?.group}
                      </div>
                    </div>
                    {/* Employee Salary */}
                    <div className="employee-preview--employee-salary employee-preview--employee-detail-wrapper">
                      <div className="requests-preview--employee-detail--title">
                        Salary
                      </div>
                      <div className="requests-preview--employee-detail--value">
                        {currentEmployee?.salary ? (
                          <>
                            &#8358;
                            {numberWithCommas(currentEmployee.salary)}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* Employee Level  */}
                    <div className="requests-preview--employee-level employee-preview--employee-detail-wrapper">
                      <div className="requests-preview--employee-detail--title">
                        Level
                      </div>
                      <div className="requests-preview--employee-detail--value">
                        {currentEmployee?.level}
                      </div>
                    </div>
                    {/* Employee Address  */}
                    <div className="requests-preview--employee-address employee-preview--employee-detail-wrapper">
                      <div className="requests-preview--employee-detail--title">
                        Address
                      </div>
                      <div className="requests-preview--employee-detail--value">
                        {currentEmployee?.address}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`employee-edit-form-main-section-container ${
                  currentEmployee?.employeeView === "form-view" ? "" : "none"
                }`}
              >
                <div className="form-body-wrapper">
                  {/* First Name form group */}
                  <div className="form-group">
                    <TextInput
                      type={"text"}
                      id={`employee-firstname-text-input`}
                      className={`employee-name-input`}
                      placeholder={""}
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      required={true}
                      label={"First Name"}
                    />
                  </div>

                  {/* Last Name form group */}
                  <div className="form-group">
                    <TextInput
                      type={"text"}
                      id={`employee-lastname-text-input`}
                      className={`employee-name-input`}
                      placeholder={""}
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                      required={true}
                      label={"Last Name"}
                    />
                  </div>

                  {/* Email form group */}
                  <div className="form-group">
                    <TextInput
                      type={"email"}
                      id={`employee-email-text-input`}
                      className={"employee-email-input"}
                      placeholder={""}
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required={true}
                      label={"Email"}
                      subLabel={""}
                    />
                  </div>

                  {/* Department form group */}
                  <div className="form-group">
                    <TextInput
                      type={"text"}
                      id={`employee-department-text-input`}
                      className={`employee-department-input`}
                      placeholder={""}
                      value={department}
                      onChange={(e) => {
                        setDepartment(e.target.value);
                      }}
                      required={true}
                      label={"Department"}
                    />
                  </div>

                  {/* Phone Number form group */}
                  <div className="form-group">
                    <TextInput
                      type={"tel"}
                      id={`employee-phone-number-text-input`}
                      className={"employee-phone-number-input"}
                      placeholder={""}
                      value={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                      required={true}
                      label={"Phone Number"}
                    />
                  </div>

                  {/* Role form group */}
                  <div className="form-group">
                    <TextInput
                      type={"text"}
                      id={`employee-role-text-input`}
                      className={`employee-role-input`}
                      placeholder={""}
                      value={role}
                      onChange={(e) => {
                        setRole(e.target.value);
                      }}
                      required={true}
                      label={"Role"}
                    />
                  </div>

                  {/* Salary form group */}
                  <div className="form-group">
                    <TextInput
                      type={"text"}
                      id={`employee-salary-text-input`}
                      className={"employee-salary-input"}
                      placeholder={""}
                      value={salary}
                      onChange={(e) => {
                        setSalary(e.target.value);
                      }}
                      required={true}
                      label={"Salary (N)"}
                    />
                  </div>

                  {/* Level form group */}
                  <div className="form-group">
                    <TextInput
                      type={"text"}
                      id={`employee-level-text-input`}
                      className={`employee-level-input`}
                      placeholder={""}
                      value={level}
                      onChange={(e) => {
                        setLevel(e.target.value);
                      }}
                      required={true}
                      label={"Level"}
                    />
                  </div>

                  {/* Employee Group dropdown */}
                  <div className="group-name-form-group form-group">
                    <DropdownInput
                      className={"group-name-dropdown-input"}
                      id={"group-name-input"}
                      label={"Group"}
                      required={true}
                      optionArray={[...companyGroupsArr]}
                      value={group}
                      onChange={(e) => {
                        setGroup(e.target.value);
                      }}
                    />
                  </div>

                  {/* Address form group */}
                  <div className="employee-address-form-group form-group">
                    <label htmlFor={`employee-address-textarea-input`}>
                      Address
                    </label>
                    <textarea
                      id={`employee-${1}-address-textarea-input`}
                      className={"employee-address-input"}
                      placeholder={""}
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                      required={true}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Recent Wallet Transaction */}
            <RecentWalletTrancactions
              staffId={currentEmployee._id}
              setErrorHandlerObj={setErrorHandlerObj}
            />

            {/* Delete employee btn wrapper */}
            {currentEmployee?.employeeView === "preview-view" && (
              <div className="delete-employee-btn-wrapper">
                <button onClick={(e) => handleOpenDeleteEmployeeModal(e)}>
                  Delete Employee
                </button>
              </div>
            )}
          </>
        ) : (
          <LoadingSpinner />
        )}
      </form>
    </div>
  );
};

export default EmployeePreview;
