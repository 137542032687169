import React, { MouseEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TextInput from "../../../components/input-components/text-input/TextInput";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import SubstituteLoadingSpinner from "../../../components/substitute-loading-spinner/SubstituteLoadingSpinner";
import { useAppDispatch } from "../../../hooks";
import { addNewGroupToCompany } from "../../../redux/actions/groupActions";
import "./Modals.css";

interface IProps {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessHandlerObj: React.Dispatch<
    React.SetStateAction<{
      isSuccess: boolean;
      message: string;
    }>
  >;
  setErrorHandlerObj: React.Dispatch<
    React.SetStateAction<{
      hasError: boolean;
      message: string;
    }>
  >;

  setIsCreateNewGroupModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isCreateNewGroupModalOpened: boolean;
}
const CreateNewGroupModal: React.FC<IProps> = function ({
  isCreateNewGroupModalOpened,
  setIsCreateNewGroupModalOpened,
  setErrorHandlerObj,
  setSuccessHandlerObj,
  setIsLoading,
  isLoading,
}) {
  // Functions and states
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // States
  const [groupName, setGroupName] = useState("");
  const [isAddingEmployeesAfter, setIsAddingEmployeesAfter] = useState(false);

  //   Functions
  const handleAddEmployees = function (e: MouseEvent<HTMLButtonElement>) {
    setIsAddingEmployeesAfter(true);
    if (groupName !== "") {
      e.preventDefault();

      const data = {
        name: groupName,
      };

      dispatch(
        addNewGroupToCompany(
          data,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          isAddingEmployeesAfter,
          navigate,
          setIsCreateNewGroupModalOpened
        )
      );
    }
    // navigate("/employer/employees/onboard-employees");
  };

  const handleSubmitGroupName = function (e: MouseEvent<HTMLButtonElement>) {
    setErrorHandlerObj({ hasError: false, message: "" });
    setSuccessHandlerObj({ isSuccess: false, message: "" });
    setIsAddingEmployeesAfter(false);
    if (groupName !== "") {
      e.preventDefault();

      const data = {
        name: groupName,
      };

      dispatch(
        addNewGroupToCompany(
          data,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          isAddingEmployeesAfter,
          navigate,
          setIsCreateNewGroupModalOpened
        )
      );
    }
  };

  useEffect(() => {
    // when modal closes, set group name to default empty
    if (!isCreateNewGroupModalOpened) setGroupName("");
  }, [isCreateNewGroupModalOpened]);

  return (
    <div
      className={`create-new-group-modal-container ${
        isCreateNewGroupModalOpened ? "" : "none"
      }`}
    >
      <div className="create-new-group-modal-container--inner">
        <div className="create-new-group-modal-header">
          Create New Group {isLoading && <SubstituteLoadingSpinner />}
        </div>

        {/* Group name form wrapper */}
        <div className="group-name-form-wrapper">
          <form>
            {/* Salary form group */}
            <div className="form-group">
              <TextInput
                type={"text"}
                id={`employee-salary-text-input`}
                className={"employee-salary-input"}
                placeholder={""}
                value={groupName}
                onChange={(e) => {
                  setGroupName(e.target.value);
                }}
                required={true}
                label={"Group Name"}
              />
            </div>

            <div className="create-new-group-modal-action-buttons-wrapper">
              {/* Add employees btn wrapper */}
              {/* <div className="add-employees-btn-wrapper">
                <button onClick={(e) => handleAddEmployees(e)}>
                  Add Employees
                </button>
              </div> */}

              {/* Submit Create Group Btn Wrapper */}
              <div className="submit-create-group-btn-wrapper">
                <button onClick={(e) => handleSubmitGroupName(e)}>Done</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateNewGroupModal;
