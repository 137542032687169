import {
  GET_ALL_CREDIT_TRANSACTIONS,
  GET_ALL_DEBIT_TRANSACTIONS,
  GET_ALL_TRANSACTIONS,
} from "../Constants";

const initialState = {
  transactions: { data: [], totalPages: 1, currentPage: 1 },
  creditTransactions: { data: [], totalPages: 1, currentPage: 1 },
  debitTransactions: { data: [], totalPages: 1, currentPage: 1 },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state = initialState,
  action: { type?: string; payload?: any }
) {
  switch (action.type) {
    case GET_ALL_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
      };
    case GET_ALL_CREDIT_TRANSACTIONS:
      return {
        ...state,
        creditTransactions: action.payload,
      };
    case GET_ALL_DEBIT_TRANSACTIONS:
      return {
        ...state,
        debitTransactions: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
