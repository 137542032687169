import React, { useEffect, useState } from "react";
import "./SettlementPreview.css";
import { IItenerary } from "../Settlements";
import angleLeftIcon from "../../../images/angle-left.svg";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import { Table } from "react-bootstrap";
import isEmpty from "../../../validation/isEmpty";
import FEPagination from "../../../components/pagination/fe-pagination/FEPagination";

interface IProps {
  selectedSettlementInvoiceNumber: string;
  selectedSettlementItenerary: IItenerary[];
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
}

const SettlementPreview: React.FC<IProps> = function ({
  selectedSettlementInvoiceNumber,
  selectedSettlementItenerary,
  setPageContent,
}) {
  // Functions, States and Variables
  // States
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPosts, setCurrentPosts] = useState<IItenerary[] | null>(null);

  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // UseEffects
  useEffect(() => {
    if (selectedSettlementItenerary) {
      const currentRows = selectedSettlementItenerary.slice(
        indexOfFirstPost,
        indexOfLastPost
      );

      setCurrentPosts(currentRows);
    }
  }, [selectedSettlementItenerary, currentPage]);

  return (
    <div className="settlement-preview-container--inner">
      {/* Settlement preview Top */}
      <div
        className={`dashboard-setup-top-container settlement-preview-top-container`}
      >
        <div className="settlement-preview-top--left-wrapper">
          {/* Back button Wrapper */}
          <div className="back-button-wrapper">
            <button onClick={() => setPageContent("main")}>
              <img src={angleLeftIcon} alt="" />
            </button>
          </div>
          {/* Reference Number */}
          <div className="dashboard-setup-top-title">
            {selectedSettlementInvoiceNumber || ""}
          </div>
        </div>
      </div>

      {/* Main section wrapper */}
      {selectedSettlementItenerary ? (
        <div className={`settlement-preview-main-section-container`}>
          {/* Employee details container */}
          <div className="settlement-preview--employee-payment-itinerary-container">
            {/* Top Wrapper */}
            <div className="row-two--right--top-wrapper">
              <div className="row-two--box-title payment-itinerary-box-title">
                PAYMENT ITINERARY
              </div>
            </div>

            {/* Bottom Wrapper */}
            <div className="row-two--left--bottom-wrapper">
              {/* Departments with most requests table container */}
              <div className="dept-with-most-requests-table-container payment-itinerary-table-container">
                <Table
                  responsive="lg"
                  className="dept-with-most-requests-table payment-itinerary-table"
                >
                  <thead
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <tr>
                      {["AMOUNT", "DESCRIPTION", "QUANTITY"].map(
                        (COLUMN, index) => (
                          <th key={index + 1}>{COLUMN}</th>
                        )
                      )}
                    </tr>
                  </thead>

                  <tbody>
                    {currentPosts?.map((payment, i) => (
                      <tr key={i + 1}>
                        {/* Amount Column */}
                        <td className="td-amount">
                          <div className="td-amount--wrapper">
                            &#8358;{payment.price.toLocaleString()}
                          </div>
                        </td>

                        {/*  BalanDescription Column */}
                        <td className="td-balance">
                          <div className="td-balance--wrapper">
                            {payment.description}
                          </div>
                        </td>

                        {/*  QUantity Column */}
                        <td className="td-date">
                          <div className="td-date--wrapper">
                            {payment.quantity}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              {selectedSettlementItenerary &&
                !isEmpty(selectedSettlementItenerary) && (
                  <FEPagination
                    paginate={paginate}
                    rowsPerPage={rowsPerPage}
                    totalPosts={selectedSettlementItenerary.length}
                    currentPage={currentPage}
                  />
                )}
            </div>
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default SettlementPreview;
