import React, { useContext, useEffect, useState } from "react";
import "./Celebrations.css";
import PrimaryButton from "../../../../components/buttons/primary-button/PrimaryButton";
import {
  ISearchContextType,
  SearchContext,
} from "../../../../components/contexts/SearchContext";
import DropdownInput from "../../../../components/input-components/dropdown-input/DropdownInput";
import {
  IAPIPaginatedResponse,
  ISingleCelebration,
  SetErrorHandlerType,
} from "../../../../Types";
import { celebrationsTypeDropdown } from "../../../../utils/celebrationsTypeDropdown";
import CelebrationsTable from "./celebrations-table/CelebrationsTable";
import { handleFilterCelebrationsByType } from "../../../../utils/handleFilterCelebrationsByType";
import SecondaryButton from "../../../../components/buttons/secondary-button/SecondaryButton";
import { getCompanyCelebrations } from "../../../../redux/actions/celebrationsActions";
import { useAppDispatch, useAppSelector } from "../../../../hooks";

// Interfaces
interface IProps {
  pageContent: string;
  setIsCelebrationTypeModalOpened: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsCelebrationForAllStaff: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorHandlerObj: SetErrorHandlerType;
}

function Celebrations({
  pageContent,
  setIsCelebrationTypeModalOpened,
  setIsCelebrationForAllStaff,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const { searchedValue }: ISearchContextType | any = useContext(SearchContext);
  const celebrations: IAPIPaginatedResponse<ISingleCelebration[]> | null =
    useAppSelector((state) => state.celebrations.celebarations);
  //   States
  const [currentCelebrationsPosts, setCurrentCelebrationsPosts] = useState<
    ISingleCelebration[] | null
  >(null);
  const [celebrationsDateFilter, setCelebrationsDateFilter] = useState("");
  const [celebrationsTypeFilter, setCelebrationsTypeFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // Pagination
  const [pageNumberIndex, setPageNumberIndex] = useState(1);

  // Functions
  const handleFetchCelebrations = function () {
    dispatch(
      getCompanyCelebrations(pageNumberIndex, setIsLoading, setErrorHandlerObj)
    );
  };

  //   UseEffects
  useEffect(() => {
    // Fetch celebrations based on selected paginated page number
    handleFetchCelebrations();
  }, [pageNumberIndex]);

  useEffect(() => {
    // Handle Filter Celebrations Table on change of search value
    if (celebrations)
      handleFilterCelebrationsByType(
        celebrationsTypeFilter || "All",
        celebrations.data,
        setCurrentCelebrationsPosts,
        searchedValue
      );
  }, [celebrations, searchedValue, celebrationsTypeFilter]);

  return (
    <section
      className={`welfare-table-section ${
        pageContent === "celebrations" ? "" : "none"
      }`}
    >
      {/* Celebrations */}
      {/* Top Wrapper */}
      <div className="welfare-table--top-wrapper">
        {/* Filter Wrapper */}
        <div className="welfare-table-filter-option-wrapper">
          <div className="welfare-table-filter-option-wrapper">Filter:</div>
          {/* Filter by celebrations request application type dropdown */}
          <div className="form-group">
            <DropdownInput
              className={"celebrations-type-filter-dropdown-input"}
              id={"celebrations-type-filter-input"}
              label={""}
              required={true}
              optionArray={celebrationsTypeDropdown}
              value={celebrationsTypeFilter}
              onChange={(e) => {
                setCelebrationsTypeFilter(e.target.value);
              }}
            />
          </div>

          {/* Filter by celebrations request application date dropdown */}
          {/* <div className="form-group">
            <DropdownInput
              className={"celebrations-date-filter-dropdown-input"}
              id={"celebrations-date-filter-input"}
              label={""}
              required={true}
              optionArray={[
                { key: "", value: "Date", default: "Default" },
                {
                  key: "yesterday",
                  value: "Yesterday",
                },
              ]}
              value={celebrationsDateFilter}
              onChange={(e) => setCelebrationsDateFilter(e.target.value)}
            />
          </div> */}
        </div>

        {/* Action Buttons Wrapper */}
        <div className="welfare-top-action-button-wrapper">
          <PrimaryButton
            className="new-celebration-btn-wrapper"
            placeholder="Celebrate all staff"
            onClick={() => {
              setIsCelebrationTypeModalOpened(true);
              setIsCelebrationForAllStaff(true);
            }}
          />

          <SecondaryButton
            className="new-celebration-btn-wrapper"
            placeholder="Celebrate a staff"
            onClick={() => {
              setIsCelebrationTypeModalOpened(true);
            }}
          />
        </div>
      </div>

      {/* CELEBRATIONS TABLE */}
      <CelebrationsTable
        isLoading={isLoading}
        celebrations={celebrations}
        currentCelebrationsPosts={currentCelebrationsPosts}
        setPageNumberIndex={setPageNumberIndex}
      />
    </section>
  );
}

export default Celebrations;
