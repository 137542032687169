import React, { MouseEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IBenefitsSetupForm,
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../../../../Types";
import {
  addingCreditsToWalletDropdown,
  paymentIntervalDropDown,
  paymentTypeDropdown,
} from "../../../../../utils/benefitsFormDropdowns";
import PrimaryButton from "../../../../buttons/primary-button/PrimaryButton";
import DropdownInput from "../../../../input-components/dropdown-input/DropdownInput";
import TextInput from "../../../../input-components/text-input/TextInput";
import LoadingSpinner from "../../../../loading-spinner/LoadingSpinner";
import SelectVoucherCountries from "../../../../voucher-input-comps/select-voucher-countries/SelectVoucherCountries";
import SelectVoucherCategories from "../../../../voucher-input-comps/select-voucher-categories/SelectVoucherCategories";
import { IPageStateObj } from "../../../../dashboard-comps/dashboard-setup/DashboardSetup";
import isEmpty from "../../../../../validation/isEmpty";
import { useAppDispatch } from "../../../../../hooks";
import { updateBenefitsSettings } from "../../../../../redux/actions/benefitsActions";
import { allPayDays } from "../../../../../utils/payDay";

// Interfaces
interface IProps {
  pageType: string;
  benefitsSetupForm: IBenefitsSetupForm;
  pageStateObj: IPageStateObj | null;
  setBenefitsSetupForm: React.Dispatch<
    React.SetStateAction<IBenefitsSetupForm>
  >;
  setSuccessHandlerObj: SetSuccessHandlerType;
  setErrorHandlerObj: SetErrorHandlerType;
}

function BenefitsForm({
  pageType,
  benefitsSetupForm,
  pageStateObj,
  setBenefitsSetupForm,
  setSuccessHandlerObj,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  //   States
  const [isLoading, setIsLoading] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState("");
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [selectedVoucherCategories, setSelectedVoucherCategories] = useState<
    string[]
  >([]);

  //   Functions
  const handleSubmitBenefitsSetupForm = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    // TODO Make sure the basic values have default prefilled values, so they are not empty
    e.preventDefault();
    // Set Error Handler to default false
    setErrorHandlerObj({ hasError: false, message: "" });
    const isFlatAmountEmpty = benefitsSetupForm.flatAmount === "";
    const isGroupsPaymentEmpty = benefitsSetupForm.groups?.some(
      (inputKeys) => inputKeys.amount === ""
    );

    if (
      benefitsSetupForm.paymentType === "perStaffGroup" &&
      isGroupsPaymentEmpty
    ) {
      // If payment type === "perStaffGroup" but amounts on groups is/are not filled
      setErrorHandlerObj({
        hasError: true,
        message:
          "Kindly fill in the payment amount for all groups before saving!",
      });
    } else if (
      benefitsSetupForm.paymentType === "flatAmount" &&
      isFlatAmountEmpty
    ) {
      // If payment type === "flatAmount" but flat amount is not filled
      setErrorHandlerObj({
        hasError: true,
        message: "Kindly fill in the flat amount before saving!",
      });
    } else {
      // if (isEmpty(selectedCountries)) {
      //   setErrorHandlerObj({
      //     hasError: true,
      //     message: "You need to select a voucher country to proceed!",
      //   });
      // } else if (isEmpty(selectedVoucherCategories)) {
      //   setErrorHandlerObj({
      //     hasError: true,
      //     message: "You need to select a voucher category to proceed!",
      //   });
      // } else {
      if (pageStateObj?.allCompanyGroups) {
        const amountPerGroupData = pageStateObj.allCompanyGroups?.map(
          (group, index) => {
            // const currentGroupId = group._id.split("-")[1];
            return {
              groupId: group._id,
              groupName: group.groupName,
              amount: parseInt(benefitsSetupForm.groups[index].amount) || 0,
            };
          }
        );

        // TODO Call the API to update benefits settings
        const data = {
          paymentInterval: benefitsSetupForm.paymentInterval.toLowerCase(),
          walletType: benefitsSetupForm.addingCreditsToWallet,
          paymentType: benefitsSetupForm.paymentType,
          amount: parseInt(benefitsSetupForm.flatAmount) || 0,
          paymentDate: parseInt(benefitsSetupForm.payDay) || 0,
          // countries: selectedCountries,
          // categories: selectedVoucherCategories,
          amountPerGroup: amountPerGroupData,
        };

        console.log(data, "DT");
        // Call The API
        dispatch(
          updateBenefitsSettings(
            data,
            pageType,
            setIsLoading,
            setErrorHandlerObj,
            setSuccessHandlerObj,
            navigate
          )
        );
      }
    }
    // }
  };

  // UseEffects
  useEffect(() => {
    if (pageStateObj?.allCompanyGroups) {
      const updatedGroupsAndFlatAmountArr = pageStateObj.allCompanyGroups?.map(
        (group, index) => {
          // Get Current benefits group amount from benefits obj in users
          const currentGroupAmount =
            !isEmpty(pageStateObj.benefits?.amountPerGroup) &&
            pageStateObj.benefits?.amountPerGroup !== undefined
              ? pageStateObj.benefits?.amountPerGroup[index]?.amount?.toString()
              : "";
          return {
            _id: group._id,
            groupName: group.groupName,
            amount: currentGroupAmount,
            isFormActive: false,
          };
        }
      );
      // Set The groups value in the benfits form object to the set values coming from the user object
      setBenefitsSetupForm({
        paymentInterval: pageStateObj.benefits?.paymentInterval || "monthly",
        payDay: pageStateObj.benefits?.payDay || "30th",
        addingCreditsToWallet: pageStateObj.benefits?.walletType || "rollOver",
        paymentType: pageStateObj.benefits?.paymentType || "perStaffGroup",
        flatAmount: pageStateObj.benefits?.amount?.toString() || "0",
        groups: updatedGroupsAndFlatAmountArr,
      });

      // Voucher Inputs States
      setSelectedVoucherCategories(pageStateObj.benefits?.categories);
      setSelectedCountries(pageStateObj.benefits?.countries);
    }
  }, [pageStateObj]);

  return (
    <form>
      {/* Payment Interval */}
      <div className="form-group">
        <DropdownInput
          id={"payment-interval-input"}
          className={"payment-interval-input"}
          value={benefitsSetupForm.paymentInterval}
          onChange={(e) => {
            setBenefitsSetupForm((prevInputStates) => {
              if (!prevInputStates) return prevInputStates;
              const updatedInputStates: IBenefitsSetupForm = {
                ...prevInputStates,
                paymentInterval: e.target.value,
              };
              return updatedInputStates;
            });
          }}
          required={true}
          label={"Payment Interval"}
          optionArray={paymentIntervalDropDown}
        />
      </div>

      <div className="form-group">
        <DropdownInput
          id={"employer-payment-day-date-input"}
          className={"employer-payment-day-input"}
          value={benefitsSetupForm.payDay}
          onChange={(e) => {
            setBenefitsSetupForm((prevInputStates) => {
              if (!prevInputStates) return prevInputStates;
              const updatedInputStates: IBenefitsSetupForm = {
                ...prevInputStates,
                payDay: e.target.value,
              };
              return updatedInputStates;
            });
          }}
          required={true}
          label={"Payment Date"}
          optionArray={[...allPayDays]}
        />
      </div>

      {/* Adding Credits to Wallet  */}
      <div className="form-group">
        <DropdownInput
          id={"adding-credits-to-wallet-input"}
          className={"adding-credits-to-wallet-input"}
          value={benefitsSetupForm.addingCreditsToWallet}
          onChange={(e) => {
            setBenefitsSetupForm((prevInputStates) => {
              if (!prevInputStates) return prevInputStates;
              const updatedInputStates: IBenefitsSetupForm = {
                ...prevInputStates,
                addingCreditsToWallet: e.target.value,
              };
              return updatedInputStates;
            });
          }}
          required={true}
          label={"Adding Credits to Wallet"}
          optionArray={addingCreditsToWalletDropdown}
        />
      </div>

      {/* Payment Type  */}
      <div className="form-group">
        <DropdownInput
          id={"payment-type-input"}
          className={"payment-type-input"}
          value={benefitsSetupForm.paymentType}
          onChange={(e) => {
            setBenefitsSetupForm((prevInputStates) => {
              if (!prevInputStates) return prevInputStates;
              const updatedInputStates: IBenefitsSetupForm = {
                ...prevInputStates,
                paymentType: e.target.value,
              };
              return updatedInputStates;
            });
          }}
          required={true}
          label={"Payment Type"}
          optionArray={paymentTypeDropdown}
        />
      </div>

      {/*  Flat Amount form group (Shows is payment type === "Flat Amount") */}
      {benefitsSetupForm.paymentType === "flatAmount" && (
        <div className="form-group">
          <TextInput
            type={"number"}
            id={"benefits-flat-amount-text-input"}
            className={"benefits-flat-amount-input"}
            placeholder={""}
            value={benefitsSetupForm.flatAmount}
            onChange={(e) => {
              setBenefitsSetupForm((prevInputStates) => {
                if (!prevInputStates) return prevInputStates;
                const updatedInputStates: IBenefitsSetupForm = {
                  ...prevInputStates,
                  flatAmount: e.target.value,
                };
                return updatedInputStates;
              });
            }}
            required={true}
            label={"Flat Amount"}
            min="0"
          />
        </div>
      )}

      {/* Payment Type === "Per Staff Group", Display Groups and Amount */}
      {benefitsSetupForm.paymentType === "perStaffGroup" && (
        <div className="benefits-per-group-form-group-wrapper">
          {/* Form Row One / Group One */}
          {benefitsSetupForm.groups?.map((group, index) => (
            <div key={index + 1} className={`ranges-form-wrapper`}>
              <div className="form-header-wrapper">Group {index + 1}</div>
              {/* Group Name */}
              <div className="form-body-wrapper form-group">
                <TextInput
                  type={"text"}
                  id={`group-name-${index + 1}-text-input`}
                  className={`employer-group-name-input`}
                  value={benefitsSetupForm.groups[index].groupName}
                  onChange={(e) => {}}
                  label={"Group Name"}
                  disabled={true}
                  required={true}
                />
              </div>

              {/* Group Payment Amount */}
              <div className="form-body-wrapper form-group">
                <TextInput
                  type={"number"}
                  id={`payment-amount-${index + 1}-text-input`}
                  className={`group-payment-amount-input`}
                  placeholder={"e.g 10"}
                  value={
                    !group.isFormActive
                      ? benefitsSetupForm.groups[index].amount?.toString() || ""
                      : paymentAmount
                  }
                  onChange={(e) => {
                    setPaymentAmount(e.target.value);

                    setBenefitsSetupForm((prevInputStates) => {
                      if (!prevInputStates) return prevInputStates;
                      const newGroupsPaymentObj = {
                        ...group,
                        _id:
                          group._id.includes("new") ||
                          group._id.includes("edit")
                            ? group._id
                            : `edit-${group._id}`,
                        amount: e.target.value,
                      };
                      prevInputStates.groups.splice(
                        index,
                        1,
                        newGroupsPaymentObj
                      );
                      return prevInputStates;
                    });
                  }}
                  onFocus={() => {
                    setPaymentAmount(group.amount);
                    // Set the active status of location to true on input focus
                    setBenefitsSetupForm((prevInputStates) => {
                      if (!prevInputStates) return prevInputStates;
                      const newGroupsPaymentObj = {
                        ...group,
                        isFormActive: true,
                      };
                      prevInputStates.groups
                        .map((group) => {
                          return { ...group, isFormActive: false };
                        })
                        .splice(index, 1, newGroupsPaymentObj);
                      return prevInputStates;
                    });
                  }}
                  required={true}
                  label={"Payment Amount"}
                  min="0"
                />
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Select Voucher Countries */}
      {/* <SelectVoucherCountries
        selectedCountries={selectedCountries}
        setSelectedCountries={setSelectedCountries}
      /> */}

      {/* Select Voucher Categories */}
      {/* <SelectVoucherCategories
        selectedVoucherCategories={selectedVoucherCategories}
        setSelectedVoucherCategories={setSelectedVoucherCategories}
      /> */}

      <div className="primary-button-container">
        <PrimaryButton
          className="send-celebration-btn-wrapper"
          placeholder="Save"
          onClick={(e) => handleSubmitBenefitsSetupForm(e)}
        />
        {isLoading && <LoadingSpinner />}
      </div>
    </form>
  );
}

export default BenefitsForm;
