import React, { useContext, useEffect, useState } from "react";
import { ISubscriptionPlan, SetErrorHandlerType } from "../../../../Types";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { getCompanySubscription } from "../../../../redux/actions/userActions";
import moment from "moment";
import { UserContext } from "../../../contexts/UserContext";
import isEmpty from "../../../../validation/isEmpty";

interface IProps {
  setErrorHandlerObj: SetErrorHandlerType;
}

function CompanySubscriptionBox({ setErrorHandlerObj }: IProps) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const companySubscription: ISubscriptionPlan | null = useAppSelector(
    (state) => state.user.companySubscription
  );
  const { user }: any = useContext(UserContext);

  // States
  const [isFreeTrialActive, setIsFreeTrialActive] = useState(false);
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(false);

  useEffect(() => {
    // Dispatch the get company subscription fxn
    dispatch(
      getCompanySubscription(setIsSubscriptionLoading, setErrorHandlerObj)
    );
  }, []);

  useEffect(() => {
    if (!isEmpty(user) && user?.freeTrail) {
      const targetDate = new Date(user.freeTrail.trailExpiryDate);
      const currentDate = new Date();
      const isStillActive = targetDate > currentDate;
      setIsFreeTrialActive(isStillActive);
    }
  }, [user]);
  return (
    <>
      {companySubscription?.data && (
        <div className="subscription-info-container">
          {/* Subscriptions Wrapper */}
          <div className="subscription-info-container--inner">
            <div className="subscription-info-wrapper">
              <div>
                <div className="subscription-info-key">Plan:</div>
                <div className="subscription-info-value">
                  {companySubscription.data.name}
                </div>
              </div>
              {companySubscription?.planDetails && (
                <div>
                  <div className="subscription-info-key">Expiration:</div>
                  <div className="subscription-info-value">
                    {moment(
                      companySubscription?.planDetails.endDate || ""
                    ).format("Do MMM, YYYY")}
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* Subscriptions Wrapper */}
          {isFreeTrialActive && (
            <div className="subscription-info-container--inner">
              <div className="subscription-info-wrapper">
                <div>
                  <div className="subscription-info-key">Free Trial:</div>
                  <div className="subscription-info-value">Enabled</div>
                </div>
                {companySubscription?.planDetails && (
                  <div>
                    <div className="subscription-info-key">Expiration:</div>
                    <div className="subscription-info-value">
                      {moment(user?.freeTrail.trailExpiryDate || "").format(
                        "Do MMM, YYYY"
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default CompanySubscriptionBox;
