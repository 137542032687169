import React, { MouseEvent, useState } from "react";
import "./AwaitingVerification.css";
import AuthNavbar from "../../auth-navbar/AuthNavbar";
import ErrorHandler from "../../error/ErrorHandler";
import SuccessHandler from "../../success/SuccessHandler";
import attentionIcon from "../../../images/attention-icon.svg";
import { useAppDispatch } from "../../../hooks";
import { resendVerificationLink } from "../../../redux/actions/authActions";
import LoadingSpinner from "../../loading-spinner/LoadingSpinner";

function AwaitingVerification() {
  const dispatch = useAppDispatch();
  // Functions, States and Variables
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  // Handle Resend Verification Link
  const handleResendVerificationLink = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    //  Call the Resend Verification Link API
    dispatch(
      resendVerificationLink(
        setIsLoading,
        setErrorHandlerObj,
        setSuccessHandlerObj
      )
    );
  };
  return (
    <div className="awaiting-verification-container">
      <AuthNavbar />

      <div className="verification-message-wrapper">
        {/* Error handler component */}
        <ErrorHandler errorHandlerObj={errorHandlerObj} />

        {/* Success handler component */}
        <SuccessHandler successHandlerObj={successHandlerObj} />
      </div>
      <section className="awaiting-verification-wrapper">
        {/* Container Header  */}
        <div className="dashboard-awaiting-data-section-header">
          <div className="congratulations-image-wrapper">
            <img src={attentionIcon} alt="" />
          </div>

          <div className="dashboard-awaiting-data-section-header-title">
            Your account is not verified!
          </div>

          <div className="dashboard-awaiting-data-section-header-sub-title">
            Kindly visit your mail to verify your account! <br />{" "}
            {/* Resend verification link Button Wrapper */}
            <div className="resend-verification-link--button-wrapper">
              <button onClick={(e) => handleResendVerificationLink(e)}>
                Resend verification link
              </button>
              <div className="verification-loader-wrapper">
                {isLoading && <LoadingSpinner />}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AwaitingVerification;
