import { SetErrorHandlerType } from "../Types";

// Handles Error or Close of Paystack gateway modal
export const onPaystackClose = function (
  setErrorHandlerObj: SetErrorHandlerType
) {
  // implementation for  whatever you want to do when the Paystack dialog closed.
  console.log("closed");
  setErrorHandlerObj({ hasError: true, message: "Payment failed!" });
};
