import React, { MouseEvent, useState } from "react";
import attentionIcon from "../../../../images/attention-icon.svg";
import { useAppDispatch } from "../../../../hooks";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import {
  approveAnyFormOfRequest,
  declineAnyFormOfRequest,
} from "../../../../redux/actions/requestActions";
import TextInput from "../../../../components/input-components/text-input/TextInput";
import { IAllRequests } from "../../../../Types";

interface IProps {
  setIsConfirmApproveDeclineRequestModalOpened: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  isConfirmApproveDeclineRequestModalOpened: boolean;
  selectedEmployeeRequest: IAllRequests | null;
  setSelectedEmployeeRequest: React.Dispatch<
    React.SetStateAction<IAllRequests | null>
  >;
  wantsToApproveRequest: boolean;
  wantsToDeclineRequest: boolean;
  setIsApproved: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeclined: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorHandlerObj: React.Dispatch<
    React.SetStateAction<{
      hasError: boolean;
      message: string;
    }>
  >;
  setSuccessHandlerObj: React.Dispatch<
    React.SetStateAction<{
      isSuccess: boolean;
      message: string;
    }>
  >;
}

const ConfirmApproveDeclineRequestModal: React.FC<IProps> = function ({
  isConfirmApproveDeclineRequestModalOpened,
  setIsConfirmApproveDeclineRequestModalOpened,
  selectedEmployeeRequest,
  setSelectedEmployeeRequest,
  wantsToApproveRequest,
  wantsToDeclineRequest,
  setIsApproved,
  setIsDeclined,
  setErrorHandlerObj,
  setSuccessHandlerObj,
}) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  // Functions, States and Variables
  const [reasonOfDecline, setReasonOfDecline] = useState("");
  // States
  const [isLoading, setIsLoading] = useState(false);

  // Function
  const handleConfirmDeleteRequest = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    if (selectedEmployeeRequest) {
      const data = {
        reason: reasonOfDecline,
      };

      if (wantsToApproveRequest) {
        e.preventDefault();
        // Call the dispatch fxn to approve salary advance OR  BNPL of employee
        dispatch(
          approveAnyFormOfRequest(
            selectedEmployeeRequest._id,
            setIsLoading,
            setErrorHandlerObj,
            setSuccessHandlerObj,
            setIsApproved,
            setIsConfirmApproveDeclineRequestModalOpened
          )
        );
      } else {
        if (reasonOfDecline !== "") {
          e.preventDefault();
          // Call the dispatch fxn to decline salary advance OR  BNPL of employee
          dispatch(
            declineAnyFormOfRequest(
              data,
              selectedEmployeeRequest._id,
              setIsLoading,
              setErrorHandlerObj,
              setSuccessHandlerObj,
              setIsDeclined,
              setIsConfirmApproveDeclineRequestModalOpened
            )
          );
        }
      }
    }
  };
  return (
    <div
      className={`decline-request-modal-container modal-container ${
        isConfirmApproveDeclineRequestModalOpened ? "" : "none"
      }`}
    >
      <div className="approve-request-modal-container--inner modal-container--inner">
        <div className="attention-img-wrapper">
          <img src={attentionIcon} alt="" />
        </div>

        <div className="approve-request-modal-header-text modal-header-text">
          Kindly confirm this action
        </div>
        <div className="approve-request-modal-sub-text modal-sub-text">
          Would you like to {wantsToApproveRequest ? "approve" : "decline"} the
          request of staff{" "}
          <span>
            {selectedEmployeeRequest?.staffId?.firstName}{" "}
            {selectedEmployeeRequest?.staffId?.lastName}
          </span>{" "}
          for your company
        </div>

        <form>
          {wantsToApproveRequest ? (
            ""
          ) : (
            <div className="form-group">
              <TextInput
                type={"text"}
                id={"employer-pay-day-date-input"}
                className={"employer-pay-day-input"}
                placeholder={""}
                value={reasonOfDecline}
                onChange={(e) => {
                  setReasonOfDecline(e.target.value);
                }}
                required={true}
                label={"Declination reason"}
              />
            </div>
          )}

          <div className="modal-action-button-wrapper">
            {/* Cancel Modal Button */}
            <div className="cancel-modal-button-wrapper">
              <button
                onClick={() =>
                  setIsConfirmApproveDeclineRequestModalOpened(false)
                }
              >
                Cancel
              </button>
            </div>
            {/* Conffirm Action Button */}
            <div className="confirm-modal-action-button">
              <button onClick={(e) => handleConfirmDeleteRequest(e)}>
                Confirm
              </button>
              <div className="confirm-modal-button-loader-wrapper">
                {isLoading && <LoadingSpinner />}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfirmApproveDeclineRequestModal;
