import React from "react";
import "./SelectNotificationViewers.css";

// Interfaces
interface IProps {
  notificationViewers: string;
  setNotificationViewers: React.Dispatch<React.SetStateAction<string>>;
}

function SelectNotificationViewers({
  notificationViewers,
  setNotificationViewers,
}: IProps) {
  // Functions, States and Variables
  return (
    <div className="select-notification-viewers-form-container">
      {/* Form Title */}
      <div className="benefits--form-title-wrapper">
        Select Notification Viewers
      </div>

      <div className="select-notification-viewers-form-wrapper">
        {/* Only employees being celebrated */}
        <div className="form-group">
          <label>
            <input
              type="radio"
              value="Single"
              checked={notificationViewers === "Single"}
              onChange={(e) => {
                setNotificationViewers(e.target.value);
              }}
            />
            Only employees being celebrated
          </label>
        </div>

        {/* All employees */}
        <div className="form-group">
          <label>
            <input
              type="radio"
              value="All"
              checked={notificationViewers === "All"}
              onChange={(e) => {
                setNotificationViewers(e.target.value);
              }}
            />
            All employees
          </label>
        </div>
      </div>
    </div>
  );
}

export default SelectNotificationViewers;
