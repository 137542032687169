import axios from "axios";
import { GET_ALL_SETTLEMENTS } from "../Constants";
import { api } from "../../api/config";
import { SetErrorHandlerType, SetIsLoadingType } from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";

const url = `${api}/api/v1/company`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get All Company  Settlemets
export const getAllCompanySettlements =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/settlements`)
      .then((res) => {
        dispatch({
          type: GET_ALL_SETTLEMENTS,
          payload: res.data.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Dowload Invoice for  Settlemets
export const dowloadInvoiceForSettlement =
  (
    settlementId: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    setCurrentInvoiceBuffer: React.Dispatch<
      React.SetStateAction<string | ArrayBuffer | null>
    >
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/settlement/invoice/${settlementId}`)
      .then((res) => {
        setCurrentInvoiceBuffer(res.data.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };
