import React, { useContext, useEffect, useState } from "react";
import "./CreditNewWallet.css";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../../../components/app-sidebar/Sidebar";
import Top from "../../../../../components/app-top/Top";
import ErrorHandler from "../../../../../components/error/ErrorHandler";
import Main from "../../../../../components/main-container/Main";
import PrimaryBackButton from "../../../../../components/primary-back-button/PrimaryBackButton";
import {
  ISearchContextType,
  SearchContext,
} from "../../../../../components/contexts/SearchContext";
import { ISingleEmployee } from "../../../../employer-settlements/Settlements";
import { getAllCompanyStaffs } from "../../../../../redux/actions/staffActions";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import AllStaffTable from "./all-staff-table/AllStaffTable";

function CreditNewWallet() {
  // Functions, States and Variables
  const { searchedValue }: ISearchContextType | any = useContext(SearchContext);
  const staff: ISingleEmployee[] | [] = useAppSelector(
    (state) => state.staff.staffs
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // States
  const [allStaff, setAllStaff] = useState<ISingleEmployee[] | null>(null);
  const [currentStaffPosts, setCurrentStaffPosts] = useState<
    ISingleEmployee[] | null
  >(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;
  const [isLoading, setIsLoading] = useState(false);
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });

  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // UseEffects
  useEffect(() => {
    //scroll to page top
    if (errorHandlerObj.hasError)
      window.scrollTo({ top: 0, behavior: "smooth" });
  }, [errorHandlerObj]);

  useEffect(() => {
    dispatch(getAllCompanyStaffs(setIsLoading, setErrorHandlerObj));
  }, []);

  //   Useeffects
  useEffect(() => {
    if (staff) setAllStaff(staff);
  }, [staff]);

  useEffect(() => {
    if (allStaff) {
      const currentRows = allStaff?.slice(indexOfFirstPost, indexOfLastPost);
      setCurrentStaffPosts(currentRows);
    }
  }, [allStaff, currentPage]);

  useEffect(() => {
    // Set Current Page to 1 to avoid empty screen on search match bug
    setCurrentPage(1);
    // Handle Filter Staff Table on change of search value
    const staffData = staff?.filter(
      (staff) =>
        staff?.firstName.toLowerCase().includes(searchedValue.toLowerCase()) ||
        staff?.lastName.toLowerCase().includes(searchedValue.toLowerCase())
    );
    // Update table state to Filtered Staff for the filter section
    if (staffData) setAllStaff(staffData);
  }, [searchedValue]);

  return (
    <div className="credit-new-wallet-container">
      <Top />
      <Sidebar activeName={"Welfare"} />

      <Main>
        <div className="credit-new-wallet-container--inner">
          {/* Error and Success Handlers */}
          <div className="employees--actions-handler-wrapper">
            <ErrorHandler errorHandlerObj={errorHandlerObj} />
          </div>

          {/* Main Section */}
          <section className={`credit-new-wallet-main-section`}>
            <div className="primary-back-button-container credit-new-wallet--top-wrapper">
              {/* Back button Wrapper */}
              <PrimaryBackButton
                onClick={() =>
                  navigate("/employer/welfare", {
                    state: {
                      currentPageContent: "benefits",
                    },
                  })
                }
              />
              {/* Top Title */}
              <div className="primary-back-button-title">Select Employee</div>
            </div>

            {/* All Staff Table */}
            <AllStaffTable
              isLoading={isLoading}
              allStaff={allStaff}
              currentStaffPosts={currentStaffPosts}
              paginate={paginate}
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
            />
          </section>
        </div>
      </Main>
    </div>
  );
}

export default CreditNewWallet;
