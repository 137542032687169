import React, { MouseEvent } from "react";
import "./Modals.css";
import { ISingleEmployee } from "../../employer-settlements/Settlements";
import attentionIcon from "../../../images/attention-icon.svg";

interface IProps {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessHandlerObj: React.Dispatch<
    React.SetStateAction<{
      isSuccess: boolean;
      message: string;
    }>
  >;
  setErrorHandlerObj: React.Dispatch<
    React.SetStateAction<{
      hasError: boolean;
      message: string;
    }>
  >;
  setIsDeclineRequestRModalOpened: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  isDeclineRequestModalOpened: boolean;

  selectedEmployeeForPreview: ISingleEmployee | null;
}
const DeclineRequestModal: React.FC<IProps> = function ({
  selectedEmployeeForPreview,
  setIsDeclineRequestRModalOpened,
  isDeclineRequestModalOpened,
  setErrorHandlerObj,
  setSuccessHandlerObj,
  setIsLoading,
  isLoading,
}) {
  // Functions

  const handleConfirmDeclineRequest = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    e.preventDefault();
    //TODO Call the dispatch fxn to decline employee

    setIsDeclineRequestRModalOpened(false);
    setSuccessHandlerObj({
      isSuccess: true,
      message: "Employee onboarding declined successfully!",
    });
  };
  return (
    <div
      className={`decline-request-modal-container modal-container ${
        isDeclineRequestModalOpened ? "" : "none"
      }`}
    >
      <div className="approve-request-modal-container--inner modal-container--inner">
        <div className="attention-img-wrapper">
          <img src={attentionIcon} alt="" />
        </div>

        <div className="approve-request-modal-header-text modal-header-text">
          Kindly confirm this action
        </div>
        <div className="approve-request-modal-sub-text modal-sub-text">
          Would you like to decline the onboarding of this user{" "}
          <span>
            {selectedEmployeeForPreview?.firstName}{" "}
            {selectedEmployeeForPreview?.lastName}
          </span>
        </div>

        <div className="modal-action-button-wrapper">
          {/* Cancel Modal Button */}
          <div className="cancel-modal-button-wrapper">
            <button onClick={() => setIsDeclineRequestRModalOpened(false)}>
              Cancel
            </button>
          </div>
          {/* Conffirm Action Button */}
          <div className="confirm-modal-action-button">
            <button onClick={(e) => handleConfirmDeclineRequest(e)}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeclineRequestModal;
