import React from "react";
import cancelIcon from "../../../../images/cancel-secondary.svg";
import messageIcon from "../../../../images/message-icon.svg";
import walletIcon from "../../../../images/empty-wallet-icon.svg";
import angleRightIcon from "../../../../images/angle-right-secondary.svg";
import { useNavigate } from "react-router-dom";

// Interfaces
interface IProps {
  isCelebrationTypeModalOpened: boolean;
  isCelebrationForAllStaff: boolean;
  setIsCelebrationTypeModalOpened: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsCelebrationForAllStaff: React.Dispatch<React.SetStateAction<boolean>>;
}
function CelebrationTypeModal({
  isCelebrationTypeModalOpened,
  isCelebrationForAllStaff,
  setIsCelebrationTypeModalOpened,
  setIsCelebrationForAllStaff,
}: IProps) {
  // Functions, States and Variables
  const navigate = useNavigate();

  return (
    <div
      className={`secondary-modal-container ${
        isCelebrationTypeModalOpened ? "" : "none"
      }`}
    >
      <div className="secondary-modal-container--inner">
        {/* Top Wrapper */}
        <div className="secondary-modal--top-wrapper">
          {/* Title */}
          <div className="secondary-modal--title">
            {isCelebrationForAllStaff
              ? "Celebrate all Staff"
              : "Celebrate a Staff"}
          </div>
          {/* Cancel Button */}
          <div className="secondary-modal--cancel-btn-wrapper">
            <button
              onClick={() => {
                setIsCelebrationTypeModalOpened(false);
                setIsCelebrationForAllStaff(false);
              }}
            >
              <img src={cancelIcon} alt="" />
            </button>
          </div>
        </div>

        {/* Bottom Layer */}
        <div className="secondary-modal--bottom-wrapper">
          <div className="secondary-modal--sub-title">
            Choose a celebration type
          </div>

          {/* Modal Options */}
          <div className="modal-options-container">
            {/* Send Message Option */}
            <div className="modal-option-wrapper">
              <button
                onClick={() =>
                  navigate("/employer/welfare/celebrations/send-messsage", {
                    state: { isCelebrationForAllStaff },
                  })
                }
              >
                {/* Left Wrapper */}
                <div className="modal-option--left-wrapper">
                  {/* Icon */}
                  <div className="modal-option--icon">
                    <img src={messageIcon} alt="" />
                  </div>

                  <div className="modal-option--texts-wrapper">
                    <div className="modal-option--main-text">Send Message</div>
                    <div className="modal-option--sub-text">
                      Celebrate staff by sending them a message
                    </div>
                  </div>
                </div>

                {/* Right Wrapper */}
                <div className="modal-option--right-wrapper">
                  <div className="modal-option--angle-right-icon">
                    <img src={angleRightIcon} alt="" />
                  </div>
                </div>
              </button>
            </div>

            {/* Credit Wallet Option */}
            <div className="modal-option-wrapper">
              <button
                onClick={() =>
                  navigate("/employer/welfare/celebrations/credit-wallet", {
                    state: { isCelebrationForAllStaff },
                  })
                }
              >
                {/* Left Wrapper */}
                <div className="modal-option--left-wrapper">
                  {/* Icon */}
                  <div className="modal-option--icon">
                    <img src={walletIcon} alt="" />
                  </div>

                  <div className="modal-option--texts-wrapper">
                    <div className="modal-option--main-text">Credit Wallet</div>
                    <div className="modal-option--sub-text">
                      Celebrate staff by crediting their wallet
                    </div>
                  </div>
                </div>

                {/* Right Wrapper */}
                <div className="modal-option--right-wrapper">
                  <div className="modal-option--angle-right-icon">
                    <img src={angleRightIcon} alt="" />
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CelebrationTypeModal;
