import React, { MouseEvent, useEffect, useState } from "react";
import "./APIConfiguration.css";
import { useNavigate } from "react-router-dom";
import {
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../../../../Types";
import eyeOpenIcon from "../../../../../images/eye-open-icon.svg";
import eyeCloseIcon from "../../../../../images/eye-close-icon.svg";
import LoadingSpinner from "../../../../loading-spinner/LoadingSpinner";
import PrimaryButton from "../../../../buttons/primary-button/PrimaryButton";
import copyIcon from "../../../../../images/copy-icon-secondary.svg";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import {
  generateAPIDetails,
  getAPIDetails,
} from "../../../../../redux/actions/apiDetailsActions";

// Interfaces
interface IProps {
  pageType: string;
  setIsAPISecretKeyExisting: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessHandlerObj: SetSuccessHandlerType;
  setErrorHandlerObj: SetErrorHandlerType;
}

function APIConfiguration({
  pageType,
  setIsAPISecretKeyExisting,
  setSuccessHandlerObj,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const apiDetails = useAppSelector((state) => state.apiDetails.apiDetails);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  //   States
  const [isLoading, setIsLoading] = useState(false);
  const [secretKey, setSecretKey] = useState("");
  const [isSecretKeyVisible, setIsSecretKeyVisible] = useState(false);

  //   Functions
  const handleGenerateNewSecretKey = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    e.preventDefault();
    // TODO  Call the API to generate new secret key
    dispatch(
      generateAPIDetails(setIsLoading, setErrorHandlerObj, setSuccessHandlerObj)
    );
  };

  // handle Copy Secret Key To Clipboard
  const handleCopySecretKeyToClipboard = function () {
    if (secretKey) {
      navigator.clipboard.writeText(secretKey);
      setSuccessHandlerObj({
        isSuccess: true,
        message: "Secret key copied to clipboard!",
      });
    }
  };

  //   Useeffects
  useEffect(() => {
    dispatch(getAPIDetails(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    if (apiDetails?.apisecret) {
      // Set Secret Key state
      setSecretKey(apiDetails.apisecret || "");
      //   Set secret key is existing state (for header tick)
      setIsAPISecretKeyExisting(true);
    }
  }, [apiDetails]);
  return (
    <div>
      {/* API Secret Key form group */}
      <div className="api-secret-key-form-group form-group">
        <img
          src={isSecretKeyVisible ? eyeOpenIcon : eyeCloseIcon}
          alt=""
          className="password-icon-wrapper"
          onClick={() => setIsSecretKeyVisible(!isSecretKeyVisible)}
        />

        <label htmlFor={"api-secret-key-input"}>API Secret Key</label>
        <input
          type={isSecretKeyVisible ? "text" : "password"}
          id={"api-secret-key-input"}
          className={"sapi-secret-key-text-input"}
          value={secretKey}
          onChange={() => {}}
          disabled
        />

        <div className="secret-key-copy-btn-wrapper">
          <button>
            <img
              src={copyIcon}
              alt=""
              onClick={() => handleCopySecretKeyToClipboard()}
            />
          </button>
        </div>
      </div>

      <div className="primary-button-container generate-secret-key-btn-container">
        <PrimaryButton
          className="generate-secret-key-btn-wrapper"
          placeholder="Generate new secret key"
          onClick={(e) => handleGenerateNewSecretKey(e)}
        />
        {isLoading && <LoadingSpinner />}
      </div>
    </div>
  );
}

export default APIConfiguration;
