import React, { MouseEvent, useContext, useEffect, useState } from "react";
import "./CreditSelectedEmployee.css";
import Sidebar from "../../../../../../components/app-sidebar/Sidebar";
import Top from "../../../../../../components/app-top/Top";
import Main from "../../../../../../components/main-container/Main";
import ErrorHandler from "../../../../../../components/error/ErrorHandler";
import SuccessHandler from "../../../../../../components/success/SuccessHandler";
import { useLocation, useNavigate } from "react-router-dom";
import { ISingleEmployee } from "../../../../../employer-settlements/Settlements";
import useDisplayMessage from "../../../../../../hooks/useDisplayMessage";
import PrimaryBackButton from "../../../../../../components/primary-back-button/PrimaryBackButton";
import CreditAmountForm from "./form-comps/credit-amount-form/CreditAmountForm";
import PrimaryButton from "../../../../../../components/buttons/primary-button/PrimaryButton";
import isEmpty from "../../../../../../validation/isEmpty";
import PaymentMethodModal from "../../../../../employer-subscription/subscription-details/modals/PaymentMethodModal";
import { usePaystackPayment } from "react-paystack";
import { UserContext } from "../../../../../../components/contexts/UserContext";
import { onPaystackClose } from "../../../../../../utils/handleOnPaystackClose";
import { IPaystackReference } from "../../../../../../Types";
import { useAppDispatch } from "../../../../../../hooks";
import { creditASingleStaff } from "../../../../../../redux/actions/benefitsActions";
import SubstituteLoadingSpinner from "../../../../../../components/substitute-loading-spinner/SubstituteLoadingSpinner";

function CreditSelectedEmployee() {
  // Functions, States and variables
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { user }: any = useContext(UserContext);

  //  States
  const [currentStaff, setCurrentStaff] = useState<ISingleEmployee | null>(
    null
  );
  const [creditAmount, setCreditAmount] = useState("");
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [selectedVoucherCategories, setSelectedVoucherCategories] = useState<
    string[]
  >([]);
  const [isPaymentOptionModalOpened, setIsPaymentOptionModalOpened] =
    useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  // Paystack Configuration and Initialization
  const config = {
    reference: new Date().getTime().toString(),
    email: user?.email || "",
    amount: parseInt(creditAmount) * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    // publicKey: "pk_test_cc4c2259b62bc75842e99e60a2e4ac12a883a840",
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY ?? "",
  };
  const initializePayment: any = usePaystackPayment(config);

  //   Functions
  // handle Open Payment Method Choice Modal
  const handleOpenPaymentMethodChoiceModal = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    const isFormEmpty = creditAmount === "";

    if (!isFormEmpty) {
      e.preventDefault();
      //   Check if countries and categories arrays are not empty before opening modal
      // if (isEmpty(selectedCountries)) {
      //   setErrorHandlerObj({
      //     hasError: true,
      //     message: "You need to select a voucher country to proceed!",
      //   });
      // } else
      // if (isEmpty(selectedVoucherCategories)) {
      //   setErrorHandlerObj({
      //     hasError: true,
      //     message: "You need to select a voucher category to proceed!",
      //   });
      // }
      // else {
      // If all necessary input values are filled, open payment choice modal
      setIsPaymentOptionModalOpened(true);
      // }
    }
  };

  // handle Call API To Credit Staff
  const handleCallAPIToCreditStaff = function (
    paymentMethod: string,
    reference?: IPaystackReference | null
  ) {
    // Paystack reference if it exists(if paystack was used instead of ther payment method(s))
    const { reference: referenceValue } = reference || {};
    const data = {
      staffId: currentStaff?._id || "",
      amount: parseInt(creditAmount),
      paymentMethod: paymentMethod,
      reference: referenceValue || "",
      creditType: "single",
      paymentGatewayResponse: reference && !isEmpty(reference) ? reference : {},
    };

    // Call API
    dispatch(
      creditASingleStaff(
        data,
        setIsPaymentLoading,
        setErrorHandlerObj,
        navigate
      )
    );
  };

  // Handles on Sucess of payment using paystack gateway (calls the credit selected/single staff API)
  const onPaystackSuccess = (reference: IPaystackReference) => {
    // Call the API to credit selected staff based on reference generated on success of payment gateway
    const paymentMethod = "paystack";
    handleCallAPIToCreditStaff(paymentMethod, reference);
  };

  // handle Initialize Payment To Credit Selected Staff With PAYSTACK
  const handleInitializePaymentWithPaystack = function () {
    // Set Error and Success message to default false
    setErrorHandlerObj({ hasError: false, message: "" });

    // Close the payment method modal
    setIsPaymentOptionModalOpened(false);

    // Initialize payment through paystack gateway || Trigger Paystack API to completed payment
    initializePayment(onPaystackSuccess, onPaystackClose);
  };

  // handle Initialize Payment To Credit Selected Staff With WALLET
  const handleInitializePaymentWithWallet = function () {
    // Set Error and Success message to default false
    setErrorHandlerObj({ hasError: false, message: "" });

    // Close the payment method modal
    setIsPaymentOptionModalOpened(false);

    // Call the API to credit selected staff based on reference generated on success of payment gateway
    const paymentMethod = "wallet";
    handleCallAPIToCreditStaff(paymentMethod);
  };

  //   UseEffects
  // Handles Display the error and success message and scrolls to top
  const { errorHandlerObj, successHandlerObj, setErrorHandlerObj } =
    useDisplayMessage();

  useEffect(() => {
    // Set Voucher Input States to values already set in settings
    setSelectedCountries(user?.benefits.countries || []);
    setSelectedVoucherCategories(user?.benefits.categories || []);
  }, [user]);

  useEffect(() => {
    if (location.state) {
      const { selectedStaff }: any = location.state;
      if (selectedStaff) setCurrentStaff(selectedStaff);
    } else {
      // If Location contains no state object
      navigate("/employer/welfare/benefits/credit-new-wallet/employee-list");
    }
    // Scroll to page top
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  return (
    <div
      className={`credit-selected-employee-container  ${
        isPaymentOptionModalOpened ? "overflow-hidden" : ""
      }`}
    >
      <Top />
      <Sidebar activeName={"Welfare"} />

      {/* Gray Overlay */}
      <div
        onClick={() => {
          setIsPaymentOptionModalOpened(false);
        }}
        className={`gray-overlay ${isPaymentOptionModalOpened ? "" : "none"}`}
      ></div>

      {/* Select Payment Method Modal */}
      <PaymentMethodModal
        isPaymentOptionModalOpened={isPaymentOptionModalOpened}
        setIsPaymentOptionModalOpened={setIsPaymentOptionModalOpened}
        amount={parseInt(creditAmount)}
        handleInitializePaymentWithPaystack={
          handleInitializePaymentWithPaystack
        }
        handleInitializePaymentWithWallet={handleInitializePaymentWithWallet}
      />

      <Main>
        {currentStaff && (
          <div className="employee-profile-container--inner">
            {/* Error and Success Handlers */}
            <div className="employees--actions-handler-wrapper">
              <ErrorHandler errorHandlerObj={errorHandlerObj} />
              <SuccessHandler successHandlerObj={successHandlerObj} />
            </div>

            {/* Main Section */}
            <section className={`employee-profile-main-section`}>
              {/* Top Wrapper */}
              <div className="primary-back-button-section">
                <div className="primary-back-button-container">
                  {/* Back button Wrapper */}
                  <PrimaryBackButton onClick={() => navigate(-1)} />
                  {/* Top Title */}
                  <div className="primary-back-button-title">Credit Wallet</div>
                </div>

                {/* Payment Loader */}
                {isPaymentLoading && (
                  <div className="payment-loader-wrapper">
                    <SubstituteLoadingSpinner />
                  </div>
                )}
              </div>

              {/*  Form Section */}
              <section className="credit-selected-employee-form-container">
                <form>
                  {/* Staff Info and Credit Amount Form */}
                  <CreditAmountForm
                    currentStaff={currentStaff}
                    creditAmount={creditAmount}
                    setCreditAmount={setCreditAmount}
                  />

                  {/* Voucher Countries and Categories Container */}
                  {/* <div className="voucher-countries-and-categories-container">
                    <div className="section-title">Voucher Details</div>

                    Select Voucher Countries
                    <SelectVoucherCountries
                      selectedCountries={selectedCountries}
                      setSelectedCountries={setSelectedCountries}
                    />

                    Select Voucher Categories
                    <SelectVoucherCategories
                      selectedVoucherCategories={selectedVoucherCategories}
                      setSelectedVoucherCategories={
                        setSelectedVoucherCategories
                      }
                    />
                  </div> */}

                  {/* Credit Wallet Button */}
                  <PrimaryButton
                    className="credit-staff-wallets-btn-wrapper"
                    placeholder="Credit Wallet"
                    onClick={(e) => {
                      handleOpenPaymentMethodChoiceModal(e);
                    }}
                    disabled={isPaymentLoading}
                  />
                </form>
              </section>
            </section>
          </div>
        )}
      </Main>
    </div>
  );
}

export default CreditSelectedEmployee;
