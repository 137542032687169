import axios from "axios";
import { api } from "../../api/config";
import {
  IBenefitWalletHistory,
  IPaystackReference,
  ISingleBenefit,
  IUnstructuredBenefit,
  IUnstructuredBenefitWalletHistory,
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";
import { NavigateFunction } from "react-router-dom";
import {
  GET_ALL_CREDITS_FOR_COMPANY,
  GET_ALL_CREDITS_FOR_STAFF,
} from "../Constants";
import isEmpty from "../../validation/isEmpty";

// Interfaces
interface IBenefitsSettingsData {
  paymentInterval: string;
  paymentDate: number;
  walletType: string;
  paymentType: string;
  amount: number;
  // countries: string[];
  // categories: string[];
  amountPerGroup: {
    groupId: string;
    groupName: string;
    amount: number;
  }[];
}

interface ICreditSingleStaffData {
  staffId: string;
  amount: number;
  paymentMethod: string;
  reference: string;
  creditType: string;
  paymentGatewayResponse: IPaystackReference | {};
}

interface ICreditAllEmployeesData {
  amount: number;
  paymentMethod: string;
  reference: string;
  creditType: string;
  paymentGatewayResponse: IPaystackReference | {};
}

const url = `${api}/api/v1/company`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get All Credits For Staff
export const getAllCreditsForStaff =
  (
    staffId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/get-staff-credits/${staffId}`)
      .then((res) => {
        const benefitWalletHistory: IUnstructuredBenefitWalletHistory[] | [] =
          res.data.data;

        if (benefitWalletHistory) {
          const structuredBenefitWalletHistory = benefitWalletHistory?.map(
            (benefit) => {
              if (benefit.creditType === "single") {
                // For CreditType of "single"
                return benefit;
              } else {
                // For CreditType of "everyOne" (only general amount is needed to be changed)
                if (!isEmpty(benefit.staffIds)) {
                  return {
                    ...benefit,
                    amount: benefit.staffIds[0].amount,
                  };
                }
                return {};
              }
            }
          );
          console.log(structuredBenefitWalletHistory, "--------------");
          dispatch({
            type: GET_ALL_CREDITS_FOR_STAFF,
            payload: structuredBenefitWalletHistory,
          });
        }

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get All Credits For Company
export const getAllCreditsForCompany =
  (
    pageNumberIndex: number,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/get-company-credits?page=${pageNumberIndex}`)
      .then((res) => {
        const benefits: IUnstructuredBenefit[] | [] = res.data.data?.reverse();

        // if (benefits) {
        //   const structuredBenefits = benefits?.map((benefit) => {
        //     if (benefit.creditType === "single") {
        //       // For CreditType of "single"
        //       return benefit;
        //     } else {
        //       // For CreditType of "everyOne"
        //       let everyOneStaffBenefitsArr: ISingleBenefit[] | [] = [];
        //       if (!isEmpty(benefit.staffIds)) {
        //         everyOneStaffBenefitsArr = benefit.staffIds.map((staffObj) => {
        //           return {
        //             ...benefit,
        //             amount: staffObj.amount,
        //             staffId: staffObj.staff,
        //             staffIds: [],
        //           };
        //         });
        //       }
        //       return everyOneStaffBenefitsArr;
        //     }
        //   });
        // console.log(structuredBenefits.flat(), "--------------");
        // }
        dispatch({
          type: GET_ALL_CREDITS_FOR_COMPANY,
          payload: res.data,
          // payload: structuredBenefits.flat(),
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Update Benefits Settings
export const updateBenefitsSettings =
  (
    data: IBenefitsSettingsData,
    pageType: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/update-benefits`, data)
      .then((response) => {
        if (pageType === "settings") {
          setSuccessHandlerObj({
            isSuccess: true,
            message: "Benefits settings updated successfully!",
          });
        } else {
          // Navigate to setup don page
          navigate("/employer/dashboard/setup-done");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Credit A Single Staff
export const creditASingleStaff =
  (
    data: ICreditSingleStaffData,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/credit-a-staff`, data)
      .then((response) => {
        navigate("/employer/welfare", {
          state: {
            currentPageContent: "benefits",
            successMessage: "Benefit funds sent to staff successfully!",
          },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Credit All Employees
export const creditAllEmployees =
  (
    data: ICreditAllEmployeesData,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/credit-all-staff`, data)
      .then((response) => {
        navigate("/employer/welfare", {
          state: {
            currentPageContent: "benefits",
            successMessage: "Benefit funds sent to all staff successfully!",
          },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };
