import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { IInstrumentsAPIArr } from "../../../pages/settings/AccountSettings";
import { getCurrentUser } from "../../../redux/actions/authActions";
import isEmpty from "../../../validation/isEmpty";
import AccountSetup from "../../account-setup/AccountSetup";
import { IBenefitsObjInUser, ICompanyUser } from "../../../Types";

export interface IPageStateObj {
  email: string;
  phoneNumber: string;
  companyName: string;
  payDay: string;
  companySize: string;
  profilePicture: string;
  loanPercent: string | number;
  instrumentRanges: {
    _id: string;
    title: string;
    from: string;
    to: string;
    amountLoanable: string;
    instrumentView: string;
  }[];
  allCompanyGroups: {
    _id: string;
    groupName: string;
    percent?: number | string;
    isFormActive: boolean;
  }[];
  benefits: IBenefitsObjInUser;
}
function DashboardSetup() {
  // Funtion, State and Variables
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userInfo: ICompanyUser = useAppSelector((state) => state.user.user);
  // States
  const [pageStateObj, setPageStateObj] = useState<IPageStateObj | null>(null);
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      // Get Current User data
      dispatch(getCurrentUser(setIsLoading, setErrorHandlerObj));
    }
  }, []);

  useEffect(() => {
    setPageStateObj((prevStateObj) => {
      if (!userInfo) return prevStateObj;
      const instrumentsArr = userInfo?.instruments?.map(
        (instrument: IInstrumentsAPIArr) => {
          return {
            _id: instrument._id,
            title: instrument.title,
            from: instrument.from.toString(),
            to: instrument.to.toString(),
            amountLoanable: instrument.loanAmount.toString(),
            instrumentView: "preview-view",
          };
        }
      );
      const allCompanyGroupsArr = userInfo?.groups?.map((group: any) => {
        return {
          _id: group._id,
          groupName: group.name,
          percent: group.percent ?? "",
          isFormActive: false,
        };
      });

      let newStateObj = {
        // Prefilled from user ddata
        email: userInfo.email || "",
        phoneNumber: userInfo.phoneNumber || "",
        companyName: userInfo.companyName || "",
        payDay: userInfo.payDay?.toString() || "",
        companySize: userInfo.companySize || "",
        profilePicture: userInfo.profilePicture || "",
        loanPercent: userInfo.loanPercent || "",
        instrumentRanges: !isEmpty(instrumentsArr)
          ? instrumentsArr
          : [
              {
                _id: `new-${Math.floor(Math.random() * 10000)}`,
                title: "",
                from: "",
                to: "",
                amountLoanable: "",
                instrumentView: "form-view",
              },
            ],
        allCompanyGroups: !isEmpty(allCompanyGroupsArr)
          ? allCompanyGroupsArr
          : [
              {
                _id: `new-${Math.floor(Math.random() * 10000)}`,
                groupName: "",
                percent: "",
                isFormActive: false,
              },
            ],
        benefits: userInfo.benefits,
      };

      // console.log("newewww", newStateObj);

      return newStateObj;
    });

    // console.log(userInfo, "juwkjnwmskmk");
  }, [userInfo]);

  return (
    <>
      {pageStateObj ? (
        <AccountSetup pageType={"dash-setup"} pageStateObj={pageStateObj} />
      ) : (
        ""
      )}
    </>
  );
}

export default DashboardSetup;
