import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Top.css";
import credleyLogo from "../../images/credley-logo.svg";
import settingsIcon from "../../images/settings-icon.svg";
import searchIcon from "../../images/search-icon.svg";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getCurrentUser } from "../../redux/actions/authActions";
import isEmpty from "../../validation/isEmpty";
import jwtDecode from "jwt-decode";
import { SearchContext } from "../contexts/SearchContext";
import { UserContext } from "../contexts/UserContext";

function Top() {
  const { searchedValue, setSearchedValue }: any = useContext(SearchContext);
  const { fetchUser }: any = useContext(UserContext);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const userInfo = useAppSelector((state) => state.user.user);
  // Functions, States and Variables
  const [user, setUser] = useState<any>(null);
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  // Functions
  const handleNavigateHome = function () {
    const token = localStorage.getItem("jwtToken");

    const decoded: any = jwtDecode(token || "");

    if (token && decoded?.companyName) {
      navigate("/employer/dashboard");
    } else {
      navigate("/signin");
    }
  };
  // UseEffects
  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    const decoded: any = jwtDecode(token || "");
    // Check if User exists || is logged in
    if (token && decoded?.companyName) {
      // Get Current User data
      dispatch(getCurrentUser(setIsLoading, setErrorHandlerObj));
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    setUser(userInfo);
    if (token && !isEmpty(userInfo) && !userInfo.isVerified) {
      navigate("/employer/verification");
    }
  }, [userInfo]);

  useEffect(() => {
    // Refetch and re-update the user context if location changes
    fetchUser();
    // Set searched value to default location change
    setSearchedValue("");

    // Scroll to page top at any page
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  return (
    <div className="app-top-container">
      <div className="app-top-container--inner">
        {/* Company Logo section / Left Section  */}
        <div className="company-logo-wrapper" onClick={handleNavigateHome}>
          <img src={credleyLogo} width={100} height={30} alt="" />
        </div>

        {/* Search Bar Container / Middle Section */}
        <div className="top-search-bar-container">
          <div className="search-icon-wrapper">
            <img src={searchIcon} alt="" />
          </div>
          <input
            type="search"
            id="app-top-search-bar-input"
            className="top-search-input"
            placeholder="Search"
            value={searchedValue}
            onChange={(e) => setSearchedValue(e.target.value)}
          />
        </div>

        {/* Profile section Container / Right Section */}
        <div className="top-profile-container">
          {/* Profile image wrapper */}
          <div className="profile-img-wrapper">
            {user?.profilePicture && <img src={user.profilePicture} alt="" />}
          </div>

          {/* Merchant Name wrapper */}
          <div className="merchant-name-wrapper">
            <div className="merchant-user-name">{user?.companyName}</div>
            <div className="merchant-title">Employer</div>
          </div>

          {/* Settings icon wrapper */}
          <div className="settings-icon-wrapper">
            <Link to={"/settings"}>
              <img src={settingsIcon} alt="" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Top;
