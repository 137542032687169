import { useContext, useEffect } from "react";
import useInactivityLogout from "../../hooks/useInactivityLogout";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { useAppDispatch } from "../../hooks";
import { logoutUser } from "../../redux/actions/authActions";

function Main({ children }: any) {
  // Functions, States and Variables
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setUser }: any = useContext(UserContext);

  // Functions
  // handle Log Out User
  const handleLogOutUser = function () {
    dispatch(logoutUser(navigate, setUser));
  };

  useInactivityLogout({
    inactivityExpireTime: 120,
    onExpired: handleLogOutUser,
  });

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    // Check if User exists || is logged in
    if (!token) {
      window.location.href = "/";
    }
  }, []);
  return <div className="company--main-section-wrapper">{children}</div>;
}

export default Main;
