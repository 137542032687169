import React, { useEffect, useState } from "react";
import "./Employees.css";
import Navbar from "../../components/app-navbar/Navbar";
import Top from "../../components/app-top/Top";
import { ISingleEmployee } from "../employer-settlements/Settlements";
import userIcon from "../../images/user-icon.svg";
import isEmpty from "../../validation/isEmpty";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import CreateNewGroupModal from "./modals/CreateNewGroupModal";
import ErrorHandler from "../../components/error/ErrorHandler";
import SuccessHandler from "../../components/success/SuccessHandler";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getAllCompanyGroups } from "../../redux/actions/groupActions";
import { getAllCompanyStaffs } from "../../redux/actions/staffActions";
import { getCurrentUser } from "../../redux/actions/authActions";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Main from "../../components/main-container/Main";
import useDisplayMessage from "../../hooks/useDisplayMessage";

export interface IAllCompanyGroups {
  _id: string;
  name: string;
  employees: ISingleEmployee[];
}

function Employees() {
  // Functions, States and Variables
  const userInfo = useAppSelector((state) => state.user.user);
  const allGroups = useAppSelector((state) => state.group.groups);
  const allStaff = useAppSelector((state) => state.staff.staffs);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  //   States
  const [allCompanyGroups, setAllCompanyGroups] = useState<
    null | IAllCompanyGroups[]
  >(null);

  const [isCreateNewGroupModalOpened, setIsCreateNewGroupModalOpened] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Is create new group button Clicked
  const [isCreateNewGroupButtonClicked, setIsCreateNewGroupButtonClicked] =
    useState(false);
  // Functions
  // Handle Navigate to create new group
  const handleNavigateToCreateNewGroup = function () {
    setIsCreateNewGroupButtonClicked(true);
    setErrorHandlerObj({ hasError: false, message: "" });

    // Check if userInfo is not empty(i.e is gotten), then check and navigate / throw error respectively
    if (!isEmpty(userInfo)) {
      if (userInfo.loanPercent && !isEmpty(userInfo.groups)) {
        // If user is approved
        if (userInfo.isApproved) {
          setIsCreateNewGroupModalOpened(true);
        } else {
          navigate("/employer/awaiting-approval");
        }
      } else {
        setErrorHandlerObj({
          hasError: true,
          message: "Kindly complete acount setup before you can proceed!",
        });
      }
    }
  };

  // UseEffects
  useEffect(() => {
    // If Button is clicked before userInfo is gotten, trigger call on change of user info
    if (isCreateNewGroupButtonClicked) {
      if (userInfo.loanPercent && !isEmpty(userInfo.groups)) {
        // If user is approved
        if (userInfo.isApproved) {
          setIsCreateNewGroupModalOpened(true);
        } else {
          navigate("/employer/awaiting-approval");
        }
      } else {
        setErrorHandlerObj({
          hasError: true,
          message: "Kindly complete acount setup before you can proceed!",
        });
      }
    }
  }, [userInfo]);

  // Handles Display the error and success message and scrolls to top
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      dispatch(getAllCompanyGroups(setLoading, setErrorHandlerObj));
    }
  }, [successHandlerObj]);

  useEffect(() => {
    dispatch(getAllCompanyGroups(setLoading, setErrorHandlerObj));
    dispatch(getAllCompanyStaffs(setLoading, setErrorHandlerObj));
    dispatch(getCurrentUser(setLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    if (!isEmpty(allGroups) && allStaff) {
      const groupedStaffs = allGroups?.groups?.map((group: any) => {
        return {
          _id: group._id,
          name: group.name,
          employees: allStaff?.filter(
            (staff: ISingleEmployee) =>
              staff.group === group.name && staff.isActive === true
          ),
        };
      });

      // console.log(allGroups, allStaff, groupedStaffs);

      setAllCompanyGroups(groupedStaffs);
    }
  }, [allGroups, allStaff]);

  return (
    <div
      className={`employees-container ${
        isCreateNewGroupModalOpened ? "overflow-hidden" : ""
      }`}
    >
      <Top />
      <Sidebar activeName={"Employees"} />

      {/* Gray Overlay */}
      <div
        onClick={() => {
          setIsCreateNewGroupModalOpened(false);
        }}
        className={`gray-overlay ${isCreateNewGroupModalOpened ? "" : "none"}`}
      ></div>

      {/* Create New Group Modal */}
      <CreateNewGroupModal
        isCreateNewGroupModalOpened={isCreateNewGroupModalOpened}
        setIsCreateNewGroupModalOpened={setIsCreateNewGroupModalOpened}
        setErrorHandlerObj={setErrorHandlerObj}
        setSuccessHandlerObj={setSuccessHandlerObj}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
      />
      <Main>
        <>
          <div className={`employees-container--inner `}>
            <div className="employees--actions-handler-wrapper">
              {/* Error handler component */}
              <ErrorHandler errorHandlerObj={errorHandlerObj} />

              {/* Success handler component */}
              <SuccessHandler successHandlerObj={successHandlerObj} />
            </div>
            {/* All Company groups section container */}
            <section className={`all-company-groups-section-container`}>
              {/* All Groups Top container */}
              <div className={`employees-top-container`}>
                {/* Top Left Wrapper */}
                <div className="employees-top--left-wrapper">
                  {/* Container Title */}
                  <div className="dashboard-setup-top-title">
                    Employee Groups
                  </div>
                </div>

                {/* Top Right Wrapper */}
                <div className="employees-top-action-button-container">
                  <div className="create-new-group-button-wrapper">
                    {isCreateNewGroupButtonClicked && loading && (
                      <LoadingSpinner />
                    )}
                    <button onClick={() => handleNavigateToCreateNewGroup()}>
                      Create New
                    </button>
                  </div>
                </div>
              </div>

              {/* All Groups Company section container */}

              <div className="all-groups-main-container">
                {/* Group Container */}
                {allCompanyGroups?.map((group, index) => (
                  <div key={index + 1} className="company-group-wrapper">
                    <Link to={`/employer/employees/group`} state={group}>
                      <div className="user-icon-wrapper">
                        <img src={userIcon} alt="" />
                      </div>
                      <div className="group-name-wrapper">{group.name}</div>

                      <div className="no-of-employees-in-group-wrapper">
                        {group.employees.length > 1
                          ? `${group.employees.length} employees`
                          : `${group.employees.length} employee`}
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
              <div className="employees-loader-wrapper">
                {loading && <LoadingSpinner />}
              </div>
            </section>
          </div>
        </>
      </Main>
    </div>
  );
}

export default Employees;
