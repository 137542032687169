import { combineReducers } from "redux";
import apiDetailsReducer from "./apiDetailsReducer";
import authReducer from "./authReducer";
import companyDataSummaryReducer from "./companyDataSummaryReducer";
import errorReducer from "./errorReducer";
import groupReducer from "./groupReducer";
import requestReducer from "./requestReducer";
import settlementsReducer from "./settlementsReducer";
import staffReducer from "./staffReducer";
import susbscriptionReducer from "./susbscriptionReducer";
import benefitReducer from "./benefitReducer";
import celebrationReducer from "./celebrationReducer";
import wellnessReducer from "./wellnessReducer";
import transactionsReducer from "./transactionsReducer";

export default combineReducers({
  user: authReducer,
  errors: errorReducer,
  staff: staffReducer,
  group: groupReducer,
  requests: requestReducer,
  dataSummary: companyDataSummaryReducer,
  settlements: settlementsReducer,
  apiDetails: apiDetailsReducer,
  subscription: susbscriptionReducer,
  benefits: benefitReducer,
  celebrations: celebrationReducer,
  wellness: wellnessReducer,
  transactions: transactionsReducer,
});
