import React, { useContext, useEffect, useState } from "react";
import "./GroupedEmployees.css";
import { useLocation, useNavigate } from "react-router-dom";
import Top from "../../../components/app-top/Top";
import { IAllCompanyGroups } from "../Employees";
import angleLeftIcon from "../../../images/angle-left.svg";
import editIcon from "../../../images/edit-icon.svg";
import isEmpty from "../../../validation/isEmpty";
import { ISingleEmployee } from "../../employer-settlements/Settlements";
import { Table } from "react-bootstrap";
import { numberWithCommas } from "../../../utils/numberWithCommas";
import EmployeePreview from "./preview-employee/EmployeePreview";
import ErrorHandler from "../../../components/error/ErrorHandler";
import SuccessHandler from "../../../components/success/SuccessHandler";
import DeleteEmployeeModal from "../modals/DeleteEmployeeModal";
import { getAllCompanyGroups } from "../../../redux/actions/groupActions";
import { getAllCompanyStaffs } from "../../../redux/actions/staffActions";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import {
  ISearchContextType,
  SearchContext,
} from "../../../components/contexts/SearchContext";
import { getCurrentUser } from "../../../redux/actions/authActions";
import SubstituteLoadingSpinner from "../../../components/substitute-loading-spinner/SubstituteLoadingSpinner";
import useDisplayMessage from "../../../hooks/useDisplayMessage";
import Sidebar from "../../../components/app-sidebar/Sidebar";
import Main from "../../../components/main-container/Main";
import FEPagination from "../../../components/pagination/fe-pagination/FEPagination";

export interface ISingleeGroup {
  _id: string;
  name: string;
}

function GroupedEmployees() {
  // Function, states an variables
  const { searchedValue }: ISearchContextType | any = useContext(SearchContext);
  const allGroups = useAppSelector((state) => state.group.groups);
  const allStaff = useAppSelector((state) => state.staff.staffs);
  const userInfo = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const groupState: any = location.state;

  // State
  const [currentGroup, setCurrentGroup] = useState<IAllCompanyGroups | null>(
    null
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [currentPosts, setCurrentPosts] = useState<ISingleEmployee[] | null>(
    null
  );
  const [selectedEmployeeForPreview, setSelectedEmployeeForPreview] =
    useState<ISingleEmployee | null>(null);
  const [pageContent, setPageContent] = useState("main");

  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isDeleteEmployeeModalOpened, setIsDeleteEmployeeModalOpened] =
    useState(false);

  // is Onboard New Employee button Clicked
  const [
    isOnboardNewEmployeeButtonClicked,
    setIsOnboardNewEmployeeButtonClicked,
  ] = useState(false);
  const [isUserInfoloading, setIsUserInfoLoading] = useState(false);

  const [allStaffInGroup, setAllStaffInGroup] = useState<
    ISingleEmployee[] | null
  >(null);

  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // Handle Navigate to onboard new employee(s)
  const handleNavigateToOnboardNewEmployee = function () {
    setIsOnboardNewEmployeeButtonClicked(true);
    setErrorHandlerObj({ hasError: false, message: "" });

    // Check if userInfo is not empty(i.e is gotten), then check and navigate / throw error respectively
    if (!isEmpty(userInfo)) {
      if (!isEmpty(userInfo.loanPercent)) {
        // Navigate User to Onboard Employees Page
        navigate("/employer/employees/onboard-employees");
      } else {
        setErrorHandlerObj({
          hasError: true,
          message: "Kindly complete account setup before you can proceed!",
        });
      }
    }
  };

  // Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  useEffect(() => {
    // If Button is clicked before userInfo is gotten, trigger call on change of user info
    if (isOnboardNewEmployeeButtonClicked) {
      if (!isEmpty(userInfo.loanPercent)) {
        // Navigate User to Onboard Employees Page
        navigate("/employer/employees/onboard-employees");
      } else {
        setErrorHandlerObj({
          hasError: true,
          message: "Kindly complete account setup before you can proceed!",
        });
      }
    }
  }, [userInfo]);

  //

  useEffect(() => {
    dispatch(getAllCompanyGroups(setLoading, setErrorHandlerObj));
    dispatch(getAllCompanyStaffs(setLoading, setErrorHandlerObj));
    dispatch(getCurrentUser(setIsUserInfoLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    if (!groupState) navigate(-1);
    if (!isEmpty(allGroups) && allStaff) {
      const groupedStaffs = allGroups?.groups
        ?.filter((group: ISingleeGroup) => group.name === groupState.name)
        ?.map((group: ISingleeGroup) => {
          return {
            _id: group._id,
            name: group.name,
            employees: allStaff?.filter(
              (staff: ISingleEmployee) =>
                staff.group === group.name && staff.isActive === true
            ),
          };
        });

      //  Call a dispatch function to get the company group based on the group ID gotten from the location state
      setCurrentGroup(groupedStaffs[0]);
      // console.log(allGroups, allStaff, groupedStaffs);
    }
  }, [allGroups, allStaff]);

  useEffect(() => {
    if (currentGroup) {
      const currentFilteredAllStaffInGroup = currentGroup?.employees.filter(
        (employee: ISingleEmployee) =>
          employee.firstName
            .toLowerCase()
            .includes(searchedValue.toLowerCase()) ||
          employee.lastName.toLowerCase().includes(searchedValue.toLowerCase())
      );
      setAllStaffInGroup(currentFilteredAllStaffInGroup);
    }
  }, [currentGroup, searchedValue]);

  useEffect(() => {
    if (allStaffInGroup) {
      const currentRows = allStaffInGroup.slice(
        indexOfFirstPost,
        indexOfLastPost
      );

      setCurrentPosts(currentRows);
    }
  }, [allStaffInGroup, currentPage]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pageContent]);

  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      dispatch(getAllCompanyGroups(setLoading, setErrorHandlerObj));
      dispatch(getAllCompanyStaffs(setLoading, setErrorHandlerObj));
    }
  }, [successHandlerObj, pageContent]);

  return (
    <div
      className={`grouped-employees-container ${
        isDeleteEmployeeModalOpened ? "overflow-hidden" : ""
      }`}
    >
      <Top />
      {/* <Navbar /> */}
      <Sidebar activeName={"Employees"} />
      {/* Gray Overlay */}
      <div
        onClick={() => {
          setIsDeleteEmployeeModalOpened(false);
        }}
        className={`gray-overlay ${isDeleteEmployeeModalOpened ? "" : "none"}`}
      ></div>
      {/* Create New Group Modal */}
      <DeleteEmployeeModal
        selectedEmployeeForPreview={selectedEmployeeForPreview}
        isDeleteEmployeeModalOpened={isDeleteEmployeeModalOpened}
        setIsDeleteEmployeeModalOpened={setIsDeleteEmployeeModalOpened}
        setErrorHandlerObj={setErrorHandlerObj}
        setSuccessHandlerObj={setSuccessHandlerObj}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        setPageContent={setPageContent}
      />

      <Main>
        <div className="grouped-employees-container--inner">
          <div className="employees--actions-handler-wrapper">
            {/* Error handler component */}
            <ErrorHandler errorHandlerObj={errorHandlerObj} />

            {/* Success handler component */}
            <SuccessHandler successHandlerObj={successHandlerObj} />
          </div>

          {/* Grouped employees table section */}
          <section
            className={`grouped-employees-table-section ${
              pageContent === "main" ? "" : "none"
            }`}
          >
            {/* Header */}
            <div className="grouped-employees-header-wrapper">
              {/* Back button Wrapper */}
              <div className="back-button-wrapper">
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <img src={angleLeftIcon} alt="" />
                </button>
              </div>
              {/* Page Title */}
              <div className="grouped-employees-header-title">
                Employees / <span>{groupState?.name}</span>
              </div>
            </div>

            <div className="add-new-employee-btn-wrapper">
              {isOnboardNewEmployeeButtonClicked && isUserInfoloading && (
                <SubstituteLoadingSpinner />
              )}
              <button onClick={() => handleNavigateToOnboardNewEmployee()}>
                Add New
              </button>
            </div>

            {/* Main Container Wrapper */}
            <div className="grouped-employees-main-container-wrapper">
              <div className="grouped-employees-table-section">
                {/*Grouped Empployees Table Container */}
                <div className="grouped-employees-table-container">
                  <Table responsive="lg" className="grouped-employees-table">
                    <thead
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <tr>
                        {[
                          "NAME",
                          "EMAIL",
                          "DEPARTMENT",
                          "PHONE NUMBER",
                          "SALARY (N)",
                          "",
                        ].map((COLUMN, index) => (
                          <th key={index + 1}>{COLUMN}</th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {currentPosts?.map((employee, i) => (
                        <tr
                          key={i + 1}
                          onClick={() => {
                            setPageContent("preview");
                            setSelectedEmployeeForPreview(employee);
                            setSuccessHandlerObj({
                              isSuccess: false,
                              message: "",
                            });
                            setErrorHandlerObj({
                              hasError: false,
                              message: "",
                            });
                          }}
                        >
                          {/* Employee Name Column */}
                          <td className="td-employee-name">
                            <div className="td-employee-name--wrapper">
                              {/* profile image wrapper */}
                              {/* <div className="profile-image-wrapper">
                              <img src={employee?.imgURL} alt="" />
                            </div> */}
                              {employee.firstName} {employee.lastName}
                            </div>
                          </td>

                          {/*  Employee Email Column */}
                          <td className="td-email">
                            <div className="td-email--wrapper">
                              {employee.email}
                            </div>
                          </td>

                          {/*  Department Column */}
                          <td className="td-department">
                            <div className="td-department--wrapper">
                              {employee.department}
                            </div>
                          </td>

                          {/* Phone Column */}
                          <td className="td-phone-no">
                            <div className="td-phone-no--wrapper">
                              {employee.phoneNumber}
                            </div>
                          </td>

                          {/*  Salary Column */}
                          <td className="td-salary">
                            {employee.salary && (
                              <div className="td-salary--wrapper">
                                &#8358;{numberWithCommas(employee.salary)}
                              </div>
                            )}
                          </td>

                          {/* Edit Button Column*/}
                          <td className="td-edit-btn">
                            <div className="td-edit-btn--wrapper">
                              <button>
                                <img src={editIcon} alt="" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className="below-table-loader-wrapper">
                    {loading && <LoadingSpinner />}
                  </div>
                </div>

                {allStaffInGroup && !isEmpty(allStaffInGroup) && (
                  <FEPagination
                    paginate={paginate}
                    rowsPerPage={rowsPerPage}
                    totalPosts={allStaffInGroup.length}
                    currentPage={currentPage}
                  />
                )}
              </div>
            </div>
          </section>

          {/* Employee Preview section */}
          <section
            className={`employee-preview-section ${
              pageContent === "preview" ? "" : "none"
            }`}
          >
            <EmployeePreview
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setSuccessHandlerObj={setSuccessHandlerObj}
              setErrorHandlerObj={setErrorHandlerObj}
              setPageContent={setPageContent}
              selectedEmployeeForPreview={selectedEmployeeForPreview}
              setSelectedEmployeeForPreview={setSelectedEmployeeForPreview}
              isDeleteEmployeeModalOpened={isDeleteEmployeeModalOpened}
              setIsDeleteEmployeeModalOpened={setIsDeleteEmployeeModalOpened}
              allGroups={allGroups}
            />
          </section>
        </div>
      </Main>
    </div>
  );
}

export default GroupedEmployees;
