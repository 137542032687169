import React, { useContext, useEffect, useState } from "react";
import "./Benefits.css";
import PrimaryButton from "../../../../components/buttons/primary-button/PrimaryButton";
import {
  ISearchContextType,
  SearchContext,
} from "../../../../components/contexts/SearchContext";
import DropdownInput from "../../../../components/input-components/dropdown-input/DropdownInput";
import {
  IAPIPaginatedResponse,
  ISingleBenefit,
  SetErrorHandlerType,
} from "../../../../Types";
import SecondaryButton from "../../../../components/buttons/secondary-button/SecondaryButton";
import { useNavigate } from "react-router-dom";
import BenefitsTable from "./benefits-table/BenefitsTable";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { getAllCreditsForCompany } from "../../../../redux/actions/benefitsActions";

// Interfaces
interface IProps {
  pageContent: string;
  setErrorHandlerObj: SetErrorHandlerType;
}

function Benefits({ pageContent, setErrorHandlerObj }: IProps) {
  // Functions, States and Variables
  const { searchedValue }: ISearchContextType | any = useContext(SearchContext);
  const benefits: IAPIPaginatedResponse<ISingleBenefit[]> = useAppSelector(
    (state) => state.benefits.getCreditsForCompany
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  //   States
  // const [allBenefits, setAllBenefits] = useState<ISingleBenefit[] | null>(null);
  const [currentBenefitsPosts, setCurrentBenefitsPosts] = useState<
    ISingleBenefit[] | null
  >(null);
  const [benefitsDateFilter, setBenefitsDateFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Pagination
  const [pageNumberIndex, setPageNumberIndex] = useState(1);

  // Functions
  const handleFilterBenefits = function () {
    const allBenefits: ISingleBenefit[] | [] = benefits?.data;
    const benefitsData = allBenefits?.filter(
      (benefit) =>
        benefit.staffId?.firstName
          .toLowerCase()
          .includes(searchedValue.toLowerCase()) ||
        benefit.staffId?.lastName
          .toLowerCase()
          .includes(searchedValue.toLowerCase())
    );
    setCurrentBenefitsPosts(benefitsData);
  };

  // Fetch benefits
  const handleFetchBenefits = function () {
    dispatch(
      getAllCreditsForCompany(pageNumberIndex, setIsLoading, setErrorHandlerObj)
    );
  };

  //   UseEffects
  useEffect(() => {
    // Fetch benefits based on selected paginated page number
    handleFetchBenefits();
  }, [pageNumberIndex]);

  useEffect(() => {
    // Handle Filter benefits Table on change of search value
    if (benefits) handleFilterBenefits();
  }, [benefits, searchedValue]);

  return (
    <section
      className={`welfare-table-section ${
        pageContent === "benefits" ? "" : "none"
      }`}
    >
      {/* Benefits */}
      {/* Top Wrapper */}
      <div className="welfare-table--top-wrapper">
        {/* Filter Wrapper */}
        <div className="welfare-table-filter-option-wrapper">
          <div className="welfare-table-filter-option-wrapper">Filter:</div>
          {/* Filter by benefits request application date dropdown */}
          <div className="form-group">
            <DropdownInput
              className={"benefits-date-filter-dropdown-input"}
              id={"benefits-date-filter-input"}
              label={""}
              required={true}
              optionArray={[
                { key: "", value: "Date", default: "Default" },
                {
                  key: "yesterday",
                  value: "Yesterday",
                },
              ]}
              value={benefitsDateFilter}
              onChange={(e) => setBenefitsDateFilter(e.target.value)}
            />
          </div>
        </div>

        {/* Action Buttons Wrapper */}
        <div className="welfare-top-action-button-wrapper">
          <PrimaryButton
            className="credit-all-employees-btn-wrapper"
            placeholder="Credit All Employees"
            onClick={() => {
              navigate("/employer/welfare/benefits/credit-all-employees");
            }}
          />
          <SecondaryButton
            className="credit-new-wallet-btn-wrapper"
            placeholder="Credit New Wallet"
            onClick={() => {
              navigate(
                "/employer/welfare/benefits/credit-new-wallet/employee-list"
              );
            }}
          />
        </div>
      </div>

      {/* BENEFITS TABLE */}
      <BenefitsTable
        isLoading={isLoading}
        benefits={benefits}
        currentBenefitsPosts={currentBenefitsPosts}
        setPageNumberIndex={setPageNumberIndex}
      />
    </section>
  );
}

export default Benefits;
