import { GET_ALL_COMPANY_STAFF } from "../Constants";

const initialState = {
  staffs: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state = initialState,
  action: { type?: string; payload?: any }
) {
  switch (action.type) {
    case GET_ALL_COMPANY_STAFF:
      return {
        ...state,
        staffs: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
