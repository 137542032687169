import moment from "moment";
import React from "react";
import { Table } from "react-bootstrap";
import LoadingSpinner from "../../../../../components/loading-spinner/LoadingSpinner";
import { IAllRequests } from "../../../../../Types";
import isEmpty from "../../../../../validation/isEmpty";
import FEPagination from "../../../../../components/pagination/fe-pagination/FEPagination";

// Interfaces
interface IProps {
  isLoading: boolean;
  allOnDemandRequests: IAllRequests[] | null;
  currentOnDemandPosts: IAllRequests[] | null;
  setSelectedEmployeeRequest: React.Dispatch<
    React.SetStateAction<IAllRequests | null>
  >;
  paginate: (pageNumber: number) => void;
  currentPage: number;
  rowsPerPage: number;
  setWelfareDisplayContent: React.Dispatch<React.SetStateAction<string>>;
}

function OnDemandTable({
  isLoading,
  allOnDemandRequests,
  currentOnDemandPosts,
  setSelectedEmployeeRequest,
  paginate,
  currentPage,
  rowsPerPage,
  setWelfareDisplayContent,
}: IProps) {
  // Functions, States and Variables

  //   Functions
  //   handle Get Current Employee
  const handleGetCurrentEmployee = function (
    id: string,
    employee: IAllRequests
  ) {
    // Set the selected employee request state
    setSelectedEmployeeRequest(employee);
  };

  return (
    <>
      <div className="primary-table-container">
        <h3>On-demand Loan Data</h3>
        <Table
          responsive="lg"
          className="primary-table all-on-demand-request-table"
        >
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "NAME",
                "EMAIL",
                "PHONE NUMBER",
                "SALARY (N)",
                "REQUEST AMOUNT",
                "DATE",
                "STATUS",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentOnDemandPosts?.map((employee, i) => (
              <tr
                key={i + 1}
                onClick={() => {
                  setWelfareDisplayContent("requests-preview");
                  if (employee._id) {
                    handleGetCurrentEmployee(employee._id, employee);
                  }
                }}
              >
                {/* Employee Name Column */}
                <td className="td-employee-name">
                  <div className="td-employee-name--wrapper">
                    {employee.staffId?.firstName} {employee.staffId?.lastName}
                  </div>
                </td>

                {/*  Employee Email Column */}
                <td className="td-email">
                  <div className="td-email--wrapper">
                    {employee.staffId?.email}
                  </div>
                </td>

                {/* Phone Column */}
                <td className="td-phone-no">
                  <div className="td-phone-no--wrapper">
                    {employee.staffId?.phoneNumber}
                  </div>
                </td>

                {/*  Salary Column */}
                <td className="td-salary">
                  <div className="td-salary--wrapper">
                    &#8358;{employee.staffId?.salary?.toLocaleString() || ""}
                  </div>
                </td>

                {/*  Request amount Column */}
                <td className="td-request-amount">
                  <div className="td-request-amount--wrapper">
                    &#8358;{employee?.amount?.toLocaleString() || ""}
                  </div>
                </td>

                {/* Date Column*/}
                <td className="td-date">
                  <div className="td-date--wrapper">
                    {moment(employee?.createdAt || "").format("Do MMM, YY")}
                  </div>
                </td>

                {/* Reequest Status Column*/}
                <td className="td-request-status ">
                  <div
                    className={`td-request-status--wrapper ${
                      employee.status === 8 || employee.status === 7
                        ? "request-completed-wrapper"
                        : employee.status === 6
                        ? "request-rejected-wrapper"
                        : employee.status === 5
                        ? "request-awaiting-approval-wrapper"
                        : employee.status === 4
                        ? "request-completed-wrapper"
                        : employee.status === 3
                        ? "request-rejected-wrapper"
                        : employee.status === 2
                        ? "request-completed-wrapper"
                        : "request-awaiting-approval-wrapper"
                    }`}
                  >
                    {employee.status === 8 || employee.status === 7
                      ? "Disbursed"
                      : employee.status === 6
                      ? "Rejected by Credley"
                      : employee.status === 5
                      ? " Awaiting Credly approval"
                      : employee.status === 4
                      ? "Approved by Credley"
                      : employee.status === 3
                      ? "Rejected by HR"
                      : employee.status === 2
                      ? "Approved by HR"
                      : "Awaiting HR approval"}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {allOnDemandRequests && !isEmpty(currentOnDemandPosts) && (
        <FEPagination
          paginate={paginate}
          rowsPerPage={rowsPerPage}
          totalPosts={allOnDemandRequests.length}
          currentPage={currentPage}
        />
      )}
    </>
  );
}

export default OnDemandTable;
