import axios from "axios";
import { GET_ALL_REQUESTS } from "../Constants";
import { api } from "../../api/config";
import { handleAPIError } from "../../utils/handleAPIError";
import { SetErrorHandlerType, SetSuccessHandlerType } from "../../Types";

const url = `${api}/api/v1/company`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get all company requests
export const getAllCompanyRequests =
  (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/withdrawal-request`)
      .then((res) => {
        dispatch({ type: GET_ALL_REQUESTS, payload: res.data?.reverse() });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//   Approve Any Form Of Request
export const approveAnyFormOfRequest =
  (
    requestId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsApproved: React.Dispatch<React.SetStateAction<boolean>>,
    setIsConfirmApproveDeclineRequestModalOpened: React.Dispatch<
      React.SetStateAction<boolean>
    >
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/approve-withdrawal-request/${requestId}`)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Request approved successfully!",
        });
        setIsApproved(true);
        setIsLoading(false);
        // Close confirmation modal
        setIsConfirmApproveDeclineRequestModalOpened(false);
      })
      .catch((err) => {
        // Close confirmation modal
        setIsConfirmApproveDeclineRequestModalOpened(false);
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//   Decline Any Form Of Request
export const declineAnyFormOfRequest =
  (
    data: {
      reason: string;
    },
    requestId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsDeclined: React.Dispatch<React.SetStateAction<boolean>>,
    setIsConfirmApproveDeclineRequestModalOpened: React.Dispatch<
      React.SetStateAction<boolean>
    >
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/decline-withdrawal-request/${requestId}`, data)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Request declined successfully!",
        });
        setIsDeclined(true);
        setIsLoading(false);
        // Close confirmation modal
        setIsConfirmApproveDeclineRequestModalOpened(false);
      })
      .catch((err) => {
        // Close confirmation modal
        setIsConfirmApproveDeclineRequestModalOpened(false);
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };
