import { ISetupSubPages } from "../Types";

// Without Subscription based values (pages)
export const setupSubPages: ISetupSubPages[] = [
  {
    key: "company-info-setup",
    value: "Account Details",
    isSetupFormFilled: false,
  },
  {
    key: "salary-loan-rate-setup",
    value: "Withdrawal limit",
    isSetupFormFilled: false,
  },
  { key: "groups-setup", value: "Groups", isSetupFormFilled: false },
  //   { key: "instruments-setup", value: "Instruments", isSetupFormFilled: false },
];
