import React, { useEffect, useState } from "react";
import "./ChooseSubscriptionPlan.css";
import { useNavigate } from "react-router-dom";
import Top from "../../../components/app-top/Top";
import ErrorHandler from "../../../components/error/ErrorHandler";
import PrimaryBackButton from "../../../components/primary-back-button/PrimaryBackButton";
import SuccessHandler from "../../../components/success/SuccessHandler";
// import { allSubscriptionPlans } from "../../../utils/subscriptionPlans";
import useDisplayMessage from "../../../hooks/useDisplayMessage";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getAllSubscriptionPlans } from "../../../redux/actions/subscriptionActions";
import { ISubscriptionPlanInfo } from "../../../Types";
import SubscriptionPlanCard from "./subscription-plan-card/SubscriptionPlanCard";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";

function ChooseSubscriptionPlan() {
  // Functions, States and Variables
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const subscriptionPlans = useAppSelector(
    (state) => state.subscription.subscriptionPlans
  );
  //   States
  const [currentActivePlanIndex, setCurrentActivePlanIndex] = useState(1);
  const [allSubscriptionPlans, setAllSubscriptionPlans] = useState<
    ISubscriptionPlanInfo[] | []
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  // Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    // setSuccessHandlerObj,
  } = useDisplayMessage();

  // UseEffects
  useEffect(() => {
    dispatch(getAllSubscriptionPlans(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    setAllSubscriptionPlans(subscriptionPlans);
  }, [subscriptionPlans]);

  return (
    <div className="subscription-plan-container">
      <Top />
      {/* <Sidebar activeName={"Dashboard"} /> */}

      {/* <Main> */}
      <div className="subscription-plan-container--inner">
        {/* Error and Success Handlers */}
        <div className="employees--actions-handler-wrapper">
          {/* Error handler component */}
          <ErrorHandler errorHandlerObj={errorHandlerObj} />
          {/* Success handler component */}
          <SuccessHandler successHandlerObj={successHandlerObj} />
        </div>

        {/* Main Section */}
        <section className={`subscription-plan-main-section`}>
          <div className="primary-back-button-container subscription-plan--top-wrapper">
            {/* Back button Wrapper */}
            <PrimaryBackButton onClick={() => navigate(-1)} />
            {/* Top Title */}
            <div className="primary-back-button-title ">
              Choose Subscription Plan
            </div>
          </div>

          {/* Sub Plan Choices Container */}
          <div className="subscription-plan-choices-container">
            {/* Plan Choice Wrapper */}
            {allSubscriptionPlans?.map((plan, index) => (
              <React.Fragment key={index + 1}>
                <SubscriptionPlanCard
                  index={index}
                  plan={plan}
                  currentActivePlanIndex={currentActivePlanIndex}
                  setCurrentActivePlanIndex={setCurrentActivePlanIndex}
                />
              </React.Fragment>
            ))}
          </div>
          {isLoading && (
            <div className="below-table-loader-wrapper ">
              <LoadingSpinner />
            </div>
          )}
        </section>
      </div>
      {/* </Main> */}
    </div>
  );
}

export default ChooseSubscriptionPlan;
