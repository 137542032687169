import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AccountSetup from "../../components/account-setup/AccountSetup";
import { IPageStateObj } from "../../components/dashboard-comps/dashboard-setup/DashboardSetup";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getCurrentUser, logoutUser } from "../../redux/actions/authActions";
import isEmpty from "../../validation/isEmpty";
import { ICompanyUser } from "../../Types";
import useInactivityLogout from "../../hooks/useInactivityLogout";
import { UserContext } from "../../components/contexts/UserContext";

export interface IInstrumentsAPIArr {
  from: number;
  loanAmount: number;
  title: string;
  to: number;
  _id: string;
}
function AccountSettings() {
  // Funtion, State and Variables
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userInfo: ICompanyUser = useAppSelector((state) => state.user.user);
  const { setUser }: any = useContext(UserContext);
  // States
  const [pageStateObj, setPageStateObj] = useState<IPageStateObj | null>(null);
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  // Functions
  // Handles logout of user
  const handleLogOutUser = function () {
    dispatch(logoutUser(navigate, setUser));
  };

  useInactivityLogout({
    inactivityExpireTime: 120,
    onExpired: handleLogOutUser,
  });

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      // Get Current User data
      dispatch(getCurrentUser(setIsLoading, setErrorHandlerObj));
    }
  }, []);

  useEffect(() => {
    if (userInfo && !isEmpty(userInfo)) {
      setPageStateObj((prevStateObj) => {
        if (!userInfo) return prevStateObj;
        const instrumentsArr = userInfo?.instruments?.map(
          (instrument: IInstrumentsAPIArr) => {
            return {
              _id: instrument._id,
              title: instrument.title,
              from: instrument.from.toString(),
              to: instrument.to.toString(),
              amountLoanable: instrument.loanAmount.toString(),
              instrumentView: "preview-view",
            };
          }
        );

        const allCompanyGroupsArr = userInfo?.groups?.map((group: any) => {
          return {
            _id: group._id,
            groupName: group.name,
            percent: group.percent ?? "",
            isFormActive: false,
          };
        });

        let newStateObj = {
          // Prefilled from user ddata
          email: userInfo.email || "",
          phoneNumber: userInfo.phoneNumber || "",
          companyName: userInfo.companyName || "",
          payDay: userInfo.payDay?.toString() || "",
          companySize: userInfo.companySize || "",
          profilePicture: userInfo.profilePicture || "",
          loanPercent: userInfo.loanPercent || "",
          instrumentRanges: !isEmpty(instrumentsArr)
            ? instrumentsArr
            : [
                {
                  _id: `new-${Math.floor(Math.random() * 10000)}`,
                  title: "",
                  from: "",
                  to: "",
                  amountLoanable: "",
                  instrumentView: "form-view",
                },
              ],
          allCompanyGroups: !isEmpty(allCompanyGroupsArr)
            ? allCompanyGroupsArr
            : [
                {
                  _id: `new-${Math.floor(Math.random() * 10000)}`,
                  groupName: "",
                  percent: "",
                  isFormActive: false,
                },
              ],
          benefits: userInfo.benefits,
        };
        return newStateObj;
      });
    }
  }, [userInfo]);

  return (
    <>
      <AccountSetup pageType={"settings"} pageStateObj={pageStateObj} />
      {/* {pageStateObj !== null ? (
        <AccountSetup pageType={"settings"} pageStateObj={pageStateObj} />
      ) : (
        ""
      )} */}
    </>
  );
}

export default AccountSettings;
