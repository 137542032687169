import axios from "axios";
import {
  GET_ALL_COURSES,
  GET_COURSE_ASSESSMENT_FOR_COURSE,
  GET_COURSE_CURRICULUM,
  GET_COURSE_PROGRESS,
} from "../Constants";
import { api } from "../../api/config";
import { handleAPIError } from "../../utils/handleAPIError";
import { SetErrorHandlerType, SetIsLoadingType } from "../../Types";

// Types and Interfaces

// URL
const url = `${api}/api/v1`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get all courses
export const getAllCourses =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/company/course`)
      .then((res) => {
        dispatch({
          type: GET_ALL_COURSES,
          payload: res.data.data?.reverse(),
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get Course Curriculum
export const getCourseCurriculum =
  (
    courseId: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/course/curriculum/${courseId}`)
      .then((res) => {
        dispatch({
          type: GET_COURSE_CURRICULUM,
          payload: res.data.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get Course Assessment for Selected Course
export const getCourseAssessmentForCourse =
  (
    courseId: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/course/assessment/${courseId}`)
      .then((res) => {
        dispatch({
          type: GET_COURSE_ASSESSMENT_FOR_COURSE,
          payload: res.data.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get course progress
export const getCourseProgress =
  (
    courseId: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/company/course/progress/${courseId}`)
      .then((res) => {
        dispatch({
          type: GET_COURSE_PROGRESS,
          payload: res.data.data?.reverse(),
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };
