import React, { ChangeEvent, MouseEvent, useState } from "react";
import {
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../../../../Types";
import axios from "axios";
import { api } from "../../../../../api/config";
import TextInput from "../../../../input-components/text-input/TextInput";
import DropdownInput from "../../../../input-components/dropdown-input/DropdownInput";
import LoadingSpinner from "../../../../loading-spinner/LoadingSpinner";
import { allPayDays } from "../../../../../utils/payDay";
import { useAppDispatch } from "../../../../../hooks";
import { updateCompanyPaydayAndSize } from "../../../../../redux/actions/paydayAndSizeActions";

// Interfaces
interface IProps {
  companyName: string;
  companyEmail: string;
  phoneNumber: string;
  companySize: string;
  payDay: string;
  profilePhoto: string;
  setPayDay: React.Dispatch<React.SetStateAction<string>>;
  setCompanySize: React.Dispatch<React.SetStateAction<string>>;
  setProfilePhoto: React.Dispatch<React.SetStateAction<string>>;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  setIsAccountDetailsFormFilled: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorHandlerObj: SetErrorHandlerType;
  setSuccessHandlerObj: SetSuccessHandlerType;
}
function AccountDetailsForm({
  companyName,
  companyEmail,
  phoneNumber,
  companySize,
  payDay,
  profilePhoto,
  setPayDay,
  setCompanySize,
  setProfilePhoto,
  setPageContent,
  setIsAccountDetailsFormFilled,
  setErrorHandlerObj,
  setSuccessHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  // States
  const [isLoading, setIsLoading] = useState(false);
  const [isFileUploadLoading, setIsFileUploadLoading] = useState(false);

  // Functions
  // Handle Update profile photo
  const handleUpdateProfilePhoto = async function (
    e: ChangeEvent<HTMLInputElement>
  ) {
    // Create or update the  profile photo
    if (!e.target.files) return false;
    setErrorHandlerObj({ hasError: false, message: "" });
    if (e.target.files[0].size <= 512000) {
      // handle Upload Product Img
      const token: any = localStorage.getItem("jwtToken");
      axios.defaults.headers.common["authorization"] = token;

      try {
        const selectedFile = e.target.files[0];
        const formData = new FormData();
        formData.append("file", selectedFile);

        setIsFileUploadLoading(true);
        const { data } = await axios.post(`${api}/api/v1/upload`, formData);

        const profilePictureUrl = {
          profilePicture: data,
        };

        const { data: userDetails } = await axios.patch(
          `${api}/api/v1/company/upload-profile-pic`,
          profilePictureUrl
        );

        setProfilePhoto(userDetails.profilePicture);

        setIsFileUploadLoading(false);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Profile picture updated!",
        });
      } catch (ex) {
        setIsFileUploadLoading(false);

        setErrorHandlerObj({
          hasError: true,
          message: "Upload failed!",
        });
      }

      // setProductImg("");
    } else {
      setErrorHandlerObj({
        hasError: true,
        message: "File size exceeds 500kb!",
      });
    }
  };

  // Handle Submit Company Payday And Size
  const handleSubmitCompanyPaydayAndSize = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    if (companySize !== "" && payDay !== "") {
      e.preventDefault();

      const data = {
        companySize,
        payDay: parseInt(payDay),
      };

      dispatch(
        updateCompanyPaydayAndSize(
          data,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          setPageContent,
          setIsAccountDetailsFormFilled
        )
      );
    }
  };
  return (
    <form>
      {/* Profile photo update form group*/}
      <div className="account-details-setup-profile-photo-form-group">
        <div className="profile-photo-display-wrapper">
          <img src={profilePhoto} alt="" />
        </div>

        {/* Upload photo input */}
        <div className="upload-profile-photo-input-wrapper">
          <input
            type="file"
            name="upload-profile-photo-input"
            id="upload-profile-photo-input"
            onChange={handleUpdateProfilePhoto}
          />
          <span>
            {" "}
            {isFileUploadLoading
              ? "Uploading..."
              : profilePhoto
              ? "Change photo"
              : "Upload photo"}
          </span>
        </div>
        {isFileUploadLoading && (
          <div className="upload-loading-spinner-wrapper"></div>
        )}
      </div>

      {/* Company Name form group */}
      <div className="account-details-setup-company-name-form-group form-group">
        <TextInput
          type={"text"}
          id={"employer-company-name-text-input-1"}
          className={"employer-company-name-input"}
          placeholder={""}
          value={companyName}
          onChange={(e) => {}}
          required={true}
          label={"Company Name"}
          disabled={true}
        />
      </div>

      {/* Company Email form group */}
      <div className="account-details-setup-company-email-form-group form-group">
        <TextInput
          type={"email"}
          id={"employer-company-email-text-input"}
          className={"employer-company-email-input"}
          placeholder={""}
          value={companyEmail}
          onChange={(e) => {}}
          required={true}
          label={"Email"}
          subLabel={"Company mail"}
          disabled={true}
        />
      </div>

      {/* Phone Number One form group */}
      <div className="account-details-setup-phone-number-one-form-group form-group">
        <TextInput
          type={"tel"}
          id={"employer-phone-number-one-text-input"}
          className={"employer-phone-number-input"}
          placeholder={""}
          value={phoneNumber}
          onChange={(e) => {}}
          required={true}
          label={"Phone Number"}
          disabled={true}
        />
      </div>

      {/* Company Size dropdown */}
      <div className="account-details-setup-company-size-form-group form-group">
        <DropdownInput
          className={"employer-company-size-dropdown-input"}
          id={"employer-company-size-input"}
          label={"Company Size"}
          required={true}
          optionArray={[
            { key: "", value: "", default: "default" },
            {
              key: "0 - 10",
              value: "0 - 10",
            },
            { key: "11 - 50", value: "11 - 50" },
            { key: "51 - 100", value: "51 - 100" },
            { key: "101 - 200", value: "101 - 200" },
            { key: "201 - 500", value: "201 - 500" },
            { key: "501 - 1000", value: "501 - 1000" },
            { key: "1000 and above", value: "1000 and above" },
          ]}
          value={companySize}
          onChange={(e) => setCompanySize(e.target.value)}
        />
      </div>

      <div className="account-details-setup-pay-day-form-group form-group">
        <DropdownInput
          id={"employer-pay-day-date-input"}
          className={"employer-pay-day-input"}
          value={payDay}
          onChange={(e) => {
            setPayDay(e.target.value);
          }}
          required={true}
          label={"Pay Day"}
          optionArray={[...allPayDays]}
        />
      </div>

      {/* Submit form button */}
      <div className="submit-setup-form-button-wrapper">
        {isLoading && <LoadingSpinner />}
        <button onClick={handleSubmitCompanyPaydayAndSize}>Save & Next</button>
      </div>
    </form>
  );
}

export default AccountDetailsForm;
