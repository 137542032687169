import React from "react";
import { IDropdownOption } from "../../../../Types";
import { welfarePages } from "../../../../utils/allWelfarePages";
import "./WelfareNav.css";

interface IProps {
  pageContent: string;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
}

function WelfareNav({ pageContent, setPageContent }: IProps) {
  // Functions, States and Variables

  //   TODO NB: To check for paid-for pages, filter out welfare pages for paid for pages and store in a new state, mapping through the new state
  return (
    <section className="welfare-nav-section">
      {/* Welfare filter section */}
      <div className="welfare-filter-container">
        {welfarePages?.map((page: IDropdownOption, index) => (
          <div
            key={index + 1}
            className={`welfare-filter-option ${
              pageContent === page.key ? "active-filter-option" : ""
            }`}
            onClick={() => {
              setPageContent(page.key);
            }}
          >
            {page.value}
          </div>
        ))}
      </div>
    </section>
  );
}

export default WelfareNav;
