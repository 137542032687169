import React from "react";
import { IFormInputStates, ISummaryData } from "../SubscriptionDetails";
import "./SubscriptionSummary.css";

// Interfaces
interface IProps {
  formInputStates: IFormInputStates;
  summaryData: ISummaryData;
  nextBillingDate: string;
}

function SubscriptionSummary({
  formInputStates,
  summaryData,
  nextBillingDate,
}: IProps) {
  return (
    <div className="subscription-summary-container">
      <div className="subscription-detail-title">Subscription Details</div>

      <div className="subscription-summary-wrapper">
        {/* Employee Number */}
        <div className="single-summary-row">
          {/* Title */}
          <div className="summary-title">Number of employees</div>
          {/* Values */}
          <div className="summary-value">
            {parseInt(
              formInputStates?.numberOfEmployees || "0"
            )?.toLocaleString()}
          </div>
        </div>

        {/* Subscription price/employee */}
        <div className="single-summary-row">
          {/* Title */}
          <div className="summary-title">Subscription price/employee</div>
          {/* Values */}
          <div className="summary-value">
            ₦{summaryData.subscriptionPricePerEmployee.toLocaleString()}
          </div>
        </div>

        {/* Total Amount to be paid */}
        <div className="single-summary-row">
          {/* Title */}
          <div className="summary-title">Total Amount to be paid</div>
          {/* Values */}
          <div className="summary-value">
            ₦
            {formInputStates.numberOfEmployees === ""
              ? "0"
              : summaryData.totalAmount.toLocaleString()}
          </div>
        </div>
      </div>

      {/* Next Billing Date */}
      <div className="next-billing-date-wrapper">
        Next billing date: {nextBillingDate}
      </div>
    </div>
  );
}

export default SubscriptionSummary;
