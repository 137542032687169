import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { api } from "../../api/config";
import { ICompanyUser } from "../../Types";
import isEmpty from "../../validation/isEmpty";

interface IUserContextProviderProps {
  children: React.ReactNode;
}

export interface IUserContextType {
  user: ICompanyUser | null;
  setUser: React.Dispatch<React.SetStateAction<ICompanyUser | null>>;
  fetchUser: () => Promise<void>;
}

export const UserContext = createContext<IUserContextType | null>(null);
function UserContextProvider({ children }: IUserContextProviderProps) {
  //   const navigate = useNavigate();
  const [user, setUser] = useState<ICompanyUser | null>(null);

  const fetchUser = async function () {
    const token = localStorage.getItem("jwtToken");
    const url = `${api}/api/v1/company`;

    if (token) {
      const { data } = await axios.get(`${url}/one`);
      setUser(data);
    }
  };

  useEffect(() => {
    // Prevent user fetching while user is on authentication (sign in, sign up, )
    const isUserInAuthPages =
      window.location.pathname === "/" ||
      window.location.pathname === "/signin";
    if (!isUserInAuthPages) fetchUser();
  }, []);
  return (
    <UserContext.Provider value={{ user, setUser, fetchUser }}>
      {children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;
