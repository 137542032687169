import {
  GET_DISBURSED_APPROVED_DECLINED_AMOUNT,
  GET_DISBURSED_APPROVED_DECLINED_AMOUNT_PER_WEEK,
  GET_LAST_TWO_PAYOUTS,
  GET_PERCENTAGE_APPROVED_DECLINED_REQUESTS,
  GET_PERCENTAGE_STAFF_USAGE,
  GET_TOTAL_DEBT,
} from "../Constants";

const initialState = {
  totalDebt: 0,
  lastTwoPayouts: [],
  percentStaffUsage: {
    totalStaff: 0,
    totalStaffUsing: 0,
    totalStaffNotUsing: 0,
  },
  percentApprovedDeclinedReq: {
    totalRequest: 0,
    totalRequestApproved: 0,
    totalRequestDeclined: 0,
  },
  disbursedApprovedDeclinedAmount: {
    totalDisbursedAmount: 0,
    totalApprovedAmount: 0,
    totalDeclinedAmount: 0,
  },
  disbursedApprovedDeclinedAmountPerWeek: {
    week_one: {
      totalDisbursedAmount: 0,
      totalApprovedAmount: 0,
      totalDeclinedAmount: 0,
    },
    week_two: {
      totalDisbursedAmount: 0,
      totalApprovedAmount: 0,
      totalDeclinedAmount: 0,
    },
    week_three: {
      totalDisbursedAmount: 0,
      totalApprovedAmount: 0,
      totalDeclinedAmount: 0,
    },
    week_four: {
      totalDisbursedAmount: 0,
      totalApprovedAmount: 0,
      totalDeclinedAmount: 0,
    },
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state = initialState,
  action: { type?: string; payload?: any }
) {
  switch (action.type) {
    case GET_TOTAL_DEBT:
      return {
        ...state,
        totalDebt: action.payload,
      };
    case GET_LAST_TWO_PAYOUTS:
      return {
        ...state,
        lastTwoPayouts: action.payload,
      };
    case GET_PERCENTAGE_STAFF_USAGE:
      return {
        ...state,
        percentStaffUsage: action.payload,
      };
    case GET_PERCENTAGE_APPROVED_DECLINED_REQUESTS:
      return {
        ...state,
        percentApprovedDeclinedReq: action.payload,
      };
    case GET_DISBURSED_APPROVED_DECLINED_AMOUNT:
      return {
        ...state,
        disbursedApprovedDeclinedAmount: action.payload,
      };
    case GET_DISBURSED_APPROVED_DECLINED_AMOUNT_PER_WEEK:
      return {
        ...state,
        disbursedApprovedDeclinedAmountPerWeek: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
