import React, { MouseEvent, useEffect, useState } from "react";
import PrimaryButton from "../../../../../../components/buttons/primary-button/PrimaryButton";
import DropdownInput from "../../../../../../components/input-components/dropdown-input/DropdownInput";
import Textarea from "../../../../../../components/input-components/textarea/Textarea";
import LoadingSpinner from "../../../../../../components/loading-spinner/LoadingSpinner";
import { IDropdownOption } from "../../../../../../Types";
import { ISingleEmployee } from "../../../../../employer-settlements/Settlements";
import SelectEmployees from "../../comps/select-employees/SelectEmployees";
import { IFormInputStates } from "../SendMessage";

// Interfaces
interface IProps {
  allStaff: [] | ISingleEmployee[];
  allStaffDropdown: IDropdownOption[] | [];
  formInputStates: IFormInputStates;
  isForAllStaff: boolean;
  isSendingLoading: boolean;
  setFormInputStates: React.Dispatch<React.SetStateAction<IFormInputStates>>;
  handleSendMessage: (e: MouseEvent<HTMLButtonElement>) => void;
}

function SendMessageForm({
  allStaff,
  allStaffDropdown,
  formInputStates,
  isForAllStaff,
  isSendingLoading,
  setFormInputStates,
  handleSendMessage,
}: IProps) {
  // Function, States and Variables
  // States
  const [selectedStaff, setSelectedStaff] = useState<string[]>([]);

  // UseEffects
  useEffect(() => {
    // Update Form Input states as selected staff changes
    setFormInputStates({
      ...formInputStates,
      staff: selectedStaff,
    });
  }, [selectedStaff]);
  return (
    <div className="send-message-form-wrapper">
      {/*  Selected Staff*/}
      {!isForAllStaff && (
        <div className="celebartion--select-employees-form-section">
          <SelectEmployees
            allStaffDropdown={allStaffDropdown}
            selectedStaff={selectedStaff}
            setSelectedStaff={setSelectedStaff}
          />
        </div>
      )}

      <form>
        {/* Celebration Message group */}
        <div className="form-group">
          <Textarea
            id={`message-textarea-input`}
            className={"message-input"}
            label={"Celebratory Message"}
            value={formInputStates.message}
            onChange={(e) => {
              setFormInputStates((prevInpuStates) => {
                if (!prevInpuStates) return prevInpuStates;
                const updatedInputStates: IFormInputStates = {
                  ...formInputStates,
                  message: e.target.value,
                };
                // console.log(updatedInputStates);
                return updatedInputStates;
              });
            }}
            required={true}
            resize={true}
          />
        </div>

        <div className="primary-button-container">
          <PrimaryButton
            className="send-celebration-btn-wrapper"
            placeholder="Send Message"
            onClick={(e) => handleSendMessage(e)}
          />
          {isSendingLoading && <LoadingSpinner />}
        </div>
      </form>
    </div>
  );
}

export default SendMessageForm;
