import React, { useState } from "react";
import "./Invoices.css";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Top from "../../components/app-top/Top";
import ErrorHandler from "../../components/error/ErrorHandler";
import Main from "../../components/main-container/Main";
import SuccessHandler from "../../components/success/SuccessHandler";
import useDisplayMessage from "../../hooks/useDisplayMessage";

function Invoices() {
  // Functions, States and Variables
  // Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  return (
    <div className="invoices-container">
      <Top />
      <Sidebar activeName={"Invoices"} />

      <Main>
        <div className="invoices-container--inner">
          {/* Error and Success Handlers */}
          <div className="employees--actions-handler-wrapper">
            {/* Error handler component */}
            <ErrorHandler errorHandlerObj={errorHandlerObj} />
            {/* Success handler component */}
            <SuccessHandler successHandlerObj={successHandlerObj} />
          </div>
          <p>Invoices Page</p>
        </div>
      </Main>
    </div>
  );
}

export default Invoices;
