import React, { useState, useEffect } from "react";
import "./OnboardSuccessful.css";
import CongratulationsIcon from "../../../images/congratulation-icon.svg";
import Top from "../../app-top/Top";
import Navbar from "../../app-navbar/Navbar";
import { useLocation, useNavigate } from "react-router-dom";

function OnboardSuccessful() {
  // Functions, States and Variables
  const location = useLocation();
  const navigate = useNavigate();
  // States
  const [groupName, setGroupName] = useState("");
  const [staffNumber, setStaffNumber] = useState("");
  // const [companyGroups, setCompanyGroups] = useState<[] | ICompanyGroups[]>([]);

  useEffect(() => {
    if (location.state) {
      const { numOfStaff }: any = location.state;
      setStaffNumber(numOfStaff.toString());
    }
  }, [location]);

  return (
    <div className="onboard-successful-container">
      <Top />
      <Navbar />
      <div className="onboard-successful-container--inner">
        <div className="congratulations-image-wrapper">
          <img src={CongratulationsIcon} alt="" />
        </div>

        {/* Main Header text wrapper */}
        <div className="container-main-header-text">
          {staffNumber}{" "}
          {staffNumber && parseInt(staffNumber) > 1 ? "employees" : "employee"}{" "}
          successfully uploaded
        </div>

        <div className="onboard-more-employees-button-wrapper">
          <button
            onClick={() => navigate("/employer/employees/onboard-employees")}
          >
            Onboard more employees
          </button>
        </div>
      </div>
    </div>
  );
}

export default OnboardSuccessful;
