import axios from "axios";
import { api } from "../../api/config";
import { SetErrorHandlerType, SetIsLoadingType } from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";
import {
  GET_COMPANY_SUBSCRIPTION,
  GET_COMPANY_SUBSCRIPTION_STATUS,
} from "../Constants";

interface ISignup {
  userdata: {
    companyName: string;
    email: string;
    phoneNumber: string;
    password: string;
  };
}

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

const url = `${api}/api/v1/company`;

// Register Company
export const registerCompany =
  (
    userData: ISignup["userdata"],
    setErrorHandlerObj: SetErrorHandlerType,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setRegisterSuccessful: React.Dispatch<React.SetStateAction<boolean>>
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(url, userData)
      .then((res) => {
        //  Set the register success state
        setRegisterSuccessful(true);

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

//

// Get company subscription
export const getCompanySubscription =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/subscription`)
      .then((res) => {
        dispatch({
          type: GET_COMPANY_SUBSCRIPTION,
          payload: res.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get company subscription status
export const getCompanySubscriptionStatus =
  (setErrorHandlerObj: SetErrorHandlerType) => (dispatch: any) => {
    axios
      .get(`${url}/get-subscription-status`)
      .then((res) => {
        dispatch({
          type: GET_COMPANY_SUBSCRIPTION_STATUS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        // Set the error handler state
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };
