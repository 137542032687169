import axios from "axios";
import { GET_ALL_SUBSCRIPTION_PLANS } from "../Constants";
import { api } from "../../api/config";
import {
  IPaystackReference,
  SetErrorHandlerType,
  SetIsLoadingType,
} from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";
import { NavigateFunction } from "react-router-dom";

// Interfaces
interface IAddSubscriptionData {
  planId: string;
  billingFrequency: string;
  noOfEmployees: number;
}

interface IPayForSubscriptionData {
  amount: number;
  paymentMethod: string;
  reference: string;
  paymentGatewayResponse: IPaystackReference;
  planId: string;
  subscriptionId: string;
}

const url = `${api}/api/v1/company`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get All Subscription Plans
export const getAllSubscriptionPlans =
  (setIsLoading: SetIsLoadingType, setErrorHandlerObj: SetErrorHandlerType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${api}/api/v1/plans`)
      .then((res) => {
        dispatch({
          type: GET_ALL_SUBSCRIPTION_PLANS,
          payload: res.data.data,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Add Company Subscription (and get subscription and plan id to be called on success of payment)
export const addCompanySubscription =
  (
    data: IAddSubscriptionData,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    initializePayment: any,
    totalAmount: number,
    onPaystackClose: (setErrorHandlerObj: SetErrorHandlerType) => void,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/subscription`, data)
      .then((response) => {
        // Set Sub and Plan Id State
        const subAndPlanIdsObj = {
          planId: response.data.data.planId,
          subscriptionId: response.data.data._id,
        };
        setIsLoading(false);
        // Handles on Sucess of payment using paystack gateway (calls the pay for subscription API)
        const onPaystackSuccess = (reference: IPaystackReference) => {
          // Call the Pay For Subscription API with paystack reference
          const { reference: referenceValue } = reference;
          const data = {
            amount: totalAmount,
            paymentMethod: "paystack",
            reference: referenceValue,
            paymentGatewayResponse: reference,
            planId: subAndPlanIdsObj.planId,
            subscriptionId: subAndPlanIdsObj.subscriptionId,
          };

          // console.log(reference, data, subAndPlanIdsObj);
          // Call API
          dispatch(
            payForSubscription(data, setIsLoading, setErrorHandlerObj, navigate)
          );
        };

        //  Trigger Paystack API to completed payment
        initializePayment(onPaystackSuccess, onPaystackClose);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Pay For Company Subscription (after success of paystack payment process)
export const payForSubscription =
  (
    data: IPayForSubscriptionData,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/pay-subscription`, data)
      .then((response) => {
        // navigate to below page triggering the success message
        navigate("/employer/employees/onboard-employees", {
          state: { message: "Subscription payment successful" },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };
